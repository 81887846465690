import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, ElementRef, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, finalize, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ComponentsService } from '../../components.service';
import { AllItemsDialogNodeComponent } from './all-items-dialog';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { environment } from 'src/environments/environment';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import * as Papa from 'papaparse';
import { GridStack } from 'gridstack';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ProductAddService } from 'src/app/shared/services/product-add.service';
import { ProductUpdateService } from 'src/app/shared/services/product-update.service';
import { PriceFormatService } from 'src/app/shared/services/price-format.service';

@Component({
	selector: 'app-digital-circular-noad',
	templateUrl: './digital-circular-noad.component.html',
	styleUrls: ['./digital-circular-noad.component.scss', '../../../../theme/styles.scss']
})
export class DigitalCircularNoadComponent implements OnInit {

	layout_form: FormGroup;
	product_update_form: FormGroup;
	searchBox: FormGroup;
	product_add_form: FormGroup;
	search_icon_form: FormGroup;

	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('clearLayoutModal') clearLayoutModal;
	@ViewChild('RestoreLayoutModal') RestoreLayoutModal;
	@ViewChild('myDialog') myDialog;
	@ViewChild('fileInput') fileInput: ElementRef;

	page_type: any;
	page_type1: any = 'url';
	page_type2: any = 'url';
	page_type3: any = 'url';
	page_type4: any = 'url';
	page_type5: any = 'url';
	layout_type: any = ''; // 2 values - grid or page
	layout_json: any;
	imageSrc: any;
	imageurl: any;
	ref: any;
	task: any;
	imageurl1: any;
	imageurl2: any;
	imageurl3: any;
	imageurl4: any;
	imageurl5: any;
	last_save_timestamp: any = 0;
	last_publish_timestamp: any = 0;
	get_current_store_id: any;
	ghost_mode = false;
	myTemplate: any;
	current_select_product_id: any;
	current_select_product_index: any;
	current_select_product_data: any;
	current_selected_product_data_full: any;
	img1_url: any = '';
	show_format_panel = false;
	current_price_label_format: any;
	price_label_value1: any = 0;
	price_label_value2: any = 0;
	price_label_value3: any = 0;
	price_label_svg: any = '';
	categories_list: any;
	current_layout_data_test: any = [];
	current_timestamp: any = '';
	current_template_id: any = '';
	do_not_refresh_iframe = false;
	saved_items_list: any = [];
	all_products_list: any = [];
	layout_last_data: any = [];
	csv_data_new: any = []
	loading = false;
	publishLoading=false;
	imgurl1: any = '';
	image_uploading = false;
	closeResult: any;
	icons: any;
	show_icon_results = false;
	current_layout_data: any;
	user: any;
	user_data: any;
	current_store_uid: any;
	current_unsplash_type: any;
	imageurl1x: any = '';
	imageurl2x: any = '';
	show_add_product = false;
	show_edit_form = false;
	master_list: any;
	unmatchedProducts: any = [];
	matchedProducts: any = [];
	csvProductDescriptions: any = [];
	importcsvdata: any = [];
	duplicates_temp: any;
	saved_items_list_temp: any;
	store_data: any;
	final_product_array = [];
	oldProducts = []
	show_discard = false
	publish_btn_class = "disable-class"
	is_save_active = false
	preview_url: any
	preview_obj: any
	PreviewBtnClass: boolean = false;
	is_preview_clicked = false
	add_form_img = ''
	isGIF: boolean
	totalImage: number = 0;
	isVideo: any = false
	master_item_product: any[] = [];
	selected_product_name: any
	selected_product_uid: any
	selected_master_product_uid: any
	CXH_image: any[] = [];
	submitted: boolean = false;
	searchLoading: boolean = false;
	popup_loading: boolean = false;
	number: any;
	unsplash_page: number = 1;
	unsplash_per_page: number = 9;
	CXH_skip: number = 0;
	CXH_take: number = 9;
	storeInMaster: boolean = false;
	add_editorContent: any = '';
	searchClient: SearchClient;
	matchingItems: any;
	matchImage: boolean = true
	dynamicElements: any[] = [];
	dynamicElements1: any;
	master_item_product1: any;
	selected_master_product: any;
	private subscriptions: Subscription[] = [];
	offerLabel: any;
	showLabelSide: boolean = false;
	showbuttons:boolean = true
	selectedLabelSide: string = 'topleft';
	selectedItem: any;
	selected_master_product1: boolean;
	labelSize: string;
	current_user: any;
	show_sale_price_format_panel: boolean;
	show_digital_coupon_format_panel: boolean;
	show_final_price_format_panel: boolean;
	show_digital_coupons: boolean;
	current_sale_price_label_format: any;
	current_digital_coupon_price_label_format: any;
	sale_price_label_value1: any ='';
	sale_price_label_value2: any ='';
	sale_price_label_value3: any ='';
	sale_price_label_svg: any='';
	sale_content_price_label_svg:any='';
	current_final_price_label_format: string;
	final_price_label_value1: any ='';
	final_price_label_value2: any ='';
	final_price_label_value3: any ='';
	final_price_label_svg: any='';
	final_content_price_label_svg:any='';
	digital_coupon_price_label_value1: any ='';
	digital_coupon_price_label_value2: any ='';
	digital_coupon_price_label_value3: any ='';
	digital_coupon_price_label_svg: any='';
	digital_content_coupon_price_label_svg: any='';
	digital_coupon:boolean;
	note: any ='';
	usage_limit: any='';
	link: any='';
	buttonActiveDeactiveCalled: boolean=false;
	grid: GridStack[];
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '5rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		toolbarHiddenButtons: [
			['fontSize'],
			['insertImage'],
			['insertVideo'],
			['fontName'],
			['textColor'],
			['backgroundColor'],
			['unlink'],
			['insertHorizontalRule'],
			['removeFormat'],
			['toggleEditorMode'],
			['subscript'],
			['superscript'],
			['strikeThrough'],
			['underline'],
			['heading'],
			['link']
		]
	}
	tabLoader: boolean;
	tab_btn_class: string;
	deleteProduct: boolean;
	buttonDisabled: boolean;
	csvData: any;
	domain_name:string='';
	loopVideoDisplay: boolean;
	openImageDialogByGrid: boolean = false;
	constructor(@Inject(PLATFORM_ID,)
	private platformId: object,
		public sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private productAddService : ProductAddService,
		private productUpdateService: ProductUpdateService,
		private priceFormatService: PriceFormatService,
		private componentService: ComponentsService) {
		this.searchClient = algoliasearch(
			environment.algolia.appId,
			environment.algolia.apiKey,
		);
		this.current_timestamp = new Date().getTime();
		this.product_update_form = new FormGroup({
			uid: new FormControl(""),
			master_uid: new FormControl(''),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topleft'),
			price_label_format: new FormControl(""),
			new_window: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			hide_price_label: new FormControl(false),
			isBanner: new FormControl(''),
			bannerText: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl("0"),
			price_label_value2: new FormControl("0"),
			price_label_value3: new FormControl("0"),
			price_label_svg: new FormControl(""),
			isVideo: new FormControl(false),
			digital_coupon:new FormControl(false),
			digital_coupon_price_label_format: new FormControl(''),
			sale_price_label_format:new FormControl(''),
			final_price_label_format: new FormControl(''),
			note : new FormControl(''),
			link : new FormControl(''),
			usage_limit : new FormControl(''),
			sale_price_label_value1: new FormControl('0'),
			sale_price_label_value2: new FormControl('0'),
			sale_price_label_value3: new FormControl('0'),
			sale_content_price_label_svg: new FormControl(''),
			sale_price_label_svg: new FormControl(''),
			final_price_label_value1: new FormControl('0'),
			final_price_label_value2: new FormControl('0'),
			final_price_label_value3: new FormControl('0'),
			final_price_label_svg: new FormControl(''),
			digital_coupon_price_label_svg: new FormControl(''),
			final_content_price_label_svg: new FormControl(''),
			digital_coupon_price_label_value1: new FormControl('0'),
			digital_coupon_price_label_value2: new FormControl('0'),
			digital_coupon_price_label_value3: new FormControl('0'),
			digital_content_coupon_price_label_svg: new FormControl(''),
		});

		this.searchBox = new FormGroup({
			query: new FormControl(null, Validators.required),
			radioBox: new FormControl('cxh', Validators.required)
		})

		this.layout_form = new FormGroup({
			uid: new FormControl(''),
			layout_type: new FormControl('grid'),  // 2 values - grid or page
			layout_json: new FormControl([]),
			include_header_checkbox: new FormControl(0),
			start_date: new FormControl(''),
			end_date: new FormControl(''),
			header_text: new FormControl(''),
			terms: new FormControl(''),
			layout_json_saved: new FormControl([]),
			layout_json_temp: new FormControl([]),
			page1_type: new FormControl('url'),
			page1_url: new FormControl(''),
			page2_type: new FormControl('url'),
			page2_url: new FormControl(''),
			page3_type: new FormControl('url'),
			page3_url: new FormControl(''),
			page4_type: new FormControl('url'),
			page4_url: new FormControl(''),
			page5_type: new FormControl('url'),
			page5_url: new FormControl(''),
			is_publish: new FormControl(0),
			is_circular: new FormControl(true),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topleft'),
			price_label_format: new FormControl(""),
			new_window: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			hide_price_label: new FormControl(false),
			isBanner: new FormControl(''),
			bannerText: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl("0"),
			price_label_value2: new FormControl("0"),
			price_label_value3: new FormControl("0"),
			price_label_svg: new FormControl(""),
			isVideo: new FormControl(false),
			digital_coupon:new FormControl(false),
			digital_coupon_price_label_format: new FormControl(''),
			sale_price_label_format:new FormControl(''),
			final_price_label_format: new FormControl(''),
			note : new FormControl(''),
			link : new FormControl(''),
			usage_limit : new FormControl(''),
			sale_price_label_value1: new FormControl('0'),
			sale_price_label_value2: new FormControl('0'),
			sale_price_label_value3: new FormControl('0'),
			sale_content_price_label_svg: new FormControl(''),
			sale_price_label_svg: new FormControl(''),
			final_price_label_value1: new FormControl('0'),
			final_price_label_value2: new FormControl('0'),
			final_price_label_value3: new FormControl('0'),
			final_price_label_svg: new FormControl(''),
			final_content_price_label_svg: new FormControl(''),
			digital_coupon_price_label_value1: new FormControl('0'),
			digital_coupon_price_label_value2: new FormControl('0'),
			digital_coupon_price_label_value3: new FormControl('0'),
			digital_content_coupon_price_label_svg: new FormControl(''),
			digital_coupon_price_label_svg: new FormControl(''),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});
	}

	async ngOnInit() {
		this.tabLoader=true;
		this.tab_btn_class = 'disable-class'
		this.current_user = JSON.parse(localStorage.getItem('userrole'));
		this.product_update_form.reset()
		this.subscriptions[this.subscriptions.length] = this.componentService.testNoadEvent.subscribe(async res => {
			this.loading=true;
			let data = this.componentService.getProductArray()
			var currentData:any = await this.currentData();
			if (Array.isArray(currentData.layout_json_temp)) {
				await this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: data }, { merge: true })
	     	} else if(typeof(currentData.layout_json_temp) == "string"){
				await this.componentService.setTempLayout(data, currentData.layout_json_temp)
			}else{
				console.error("temp undefined")
			}
			this.loading=false;
			this.grid = GridStack.initAll({
				column: 8,
				removable: '#trash',
				float: false
			});
			this.grid[0].load(data);
			if (data.length > 0) {
				this.allFunction()
			}
			this.buttonActiveDeactive()
		})

		const chkghost = localStorage.getItem('ghost_mode');
		if (chkghost) {
			this.ghost_mode = true;
		} else {
			this.ghost_mode = false;
		}
		this.get_current_store_id = localStorage.getItem('current_store_id');
		if (this.get_current_store_id) {
			this.user = JSON.parse(this.get_current_store_id);
		} else {
			this.user = JSON.parse(localStorage.getItem('userrole'));
		}
		this.user_data = JSON.parse(localStorage.getItem('userrole'));
		this.current_store_uid = this.user.store_uid;

		this.subscriptions[this.subscriptions.length] = await this.afs.collection('/lookup-categories').snapshotChanges().subscribe((data: any) => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data().value,
				};
			});
		});


		this.subscriptions[this.subscriptions.length] = await this.afs.collection('/stores', ref => ref.where('uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
			this.store_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					access_coupon: e.payload.doc.data().access_coupon,
					access_layout: e.payload.doc.data().access_layout,
					access_recipe: e.payload.doc.data().access_recipe,
					company_name_slug: e.payload.doc.data().company_name_slug,
					branch_name_slug: e.payload.doc.data().branch_name_slug,
					theme_base_color: e.payload.doc.data().theme_base_color ? e.payload.doc.data().theme_base_color : '#28a745',
					override_noad_layout: e.payload.doc.data().override_noad_layout ? e.payload.doc.data().override_noad_layout : false
				};
			});

			if (this.store_data && this.store_data.length > 0) {
				if (this.store_data[0]?.access_layout == 'Custom Grid Layout Only' || this.store_data[0]?.access_layout == 'Both Layouts') {
					this.layout_type = 'grid';
					// this.componentService.setLayoutType('grid')
				}
				if (this.store_data[0]?.access_layout == 'Upload Circular Images / URL Only') {
					// this.componentService.setLayoutType('page')
					this.layout_type = 'page';
				}
				this.layout_form.patchValue({
					layout_type : this.layout_type
				})
			}
		})

		this.getLayoutData();

		await this.savedListItem()

		this.subscriptions[this.subscriptions.length] = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			this.all_products_list = data.map((e: any) => {
				return {
					upc_code: e.payload.doc.data().upc_code,
					product_description: e.payload.doc.data().product_description,
					product_name_slug: e.payload.doc.data().product_name_slug,
					pack: e.payload.doc.data().pack,
					price_label_format: e.payload.doc.data().price_label_format,
					price_label_value1: e.payload.doc.data().price_label_value1,
					price_label_value2: e.payload.doc.data().price_label_value2,
					price_label_value3: e.payload.doc.data().price_label_value3,
					price_label_highlight: e.payload.doc.data().price_label_highlight,
					product_url: e.payload.doc.data().product_url,
					hide_price_label: e.payload.doc.data().hide_price_label,
					hide_addtolist_label: e.payload.doc.data().hide_addtolist_label,
					hide_productname_label: e.payload.doc.data().hide_productname_label,
					storeInMaster: e.payload.doc.data().storeInMaster ? e.payload.doc.data().storeInMaster : '',
					cost: e.payload.doc.data().cost,
					srp: e.payload.doc.data().srp,
					category: e.payload.doc.data().category,
					offerLabel: e.payload.doc.data().offerLabel ? e.payload.doc.data().offerLabel : '',
					labelSide: e.payload.doc.data().labelSide ? e.payload.doc.data().labelSide : '',
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data().product_image ? e.payload.doc.data().product_image : 'https://via.placeholder.com/30/cecece/cecece',
					price_label_svg: e.payload.doc.data().price_label_svg,
					is_saved: e.payload.doc.data().is_saved,
					isVideo: e.payload.doc.data().isVideo ? e.payload.doc.data().isVideo : false,
				    sale_price_label_format: e.payload.doc.data().sale_price_label_format,
					sale_price_label_value1: e.payload.doc.data().sale_price_label_value1,
					sale_price_label_value2: e.payload.doc.data().sale_price_label_value2,
					sale_price_label_value3: e.payload.doc.data().sale_price_label_value3,
					final_price_label_format: e.payload.doc.data().final_price_label_format,
					final_price_label_value1: e.payload.doc.data().final_price_label_value1,
					final_price_label_value2: e.payload.doc.data().final_price_label_value2,
					final_price_label_value3: e.payload.doc.data().final_price_label_value3,
					digital_coupon_price_label_format: e.payload.doc.data().digital_coupon_price_label_format,
					digital_coupon_price_label_value1: e.payload.doc.data().digital_coupon_price_label_value1,
					digital_coupon_price_label_value2: e.payload.doc.data().digital_coupon_price_label_value2,
					digital_coupon_price_label_value3: e.payload.doc.data().digital_coupon_price_label_value3,
					note: e.payload.doc.data().note,
					link: e.payload.doc.data().link,
					usage_limit: e.payload.doc.data().usage_limit,
					digital_coupon: e.payload.doc.data().digital_coupon,
					secondery_product_image: e.payload.doc.data().secondery_product_image,
					sale_price_label_svg: e.payload.doc.data().sale_price_label_svg,
					sale_content_price_label_svg: e.payload.doc.data().sale_content_price_label_svg,
					final_price_label_svg: e.payload.doc.data().final_price_label_svg,
					final_content_price_label_svg: e.payload.doc.data().final_content_price_label_svg,
					digital_coupon_price_label_svg: e.payload.doc.data().digital_coupon_price_label_svg,
					digital_content_coupon_price_label_svg: e.payload.doc.data().digital_content_coupon_price_label_svg,
					isBundleProduct : e.payload.doc.data().isBundleProduct ? e.payload.doc.data().isBundleProduct : false,
				};
			});

		});
		// Filter documents where uid is undefined
		let dataUndefined = await this.afs.collection("/store-items" ,ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise();
		let undefinedUIDDocs = dataUndefined.docs.filter((doc: any) => !doc.data().uid);
		// Update documents where uid is undefined
		undefinedUIDDocs.forEach(async (doc: any) => {
			try {
				// Get the reference to the document
				const docRef = doc.ref;
				// Update the document with docref.id as the uid
				await docRef.update({ uid: docRef.id });
			} catch (error) {
				console.error("Error updating document:", error);
			}
		});

	}

	getButtonStyles(className: string) {
		if (className === 'disable-class') {
		  return {
			opacity: 0.5,
			pointerEvents: 'none'
		  };
		} else if (className === 'active-class') {
		  return {
			opacity: 1,
			pointerEvents: 'auto'
		  };
		}
		return {};
	  }

	async savedListItem() {
		try {
			const snapshot = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('created_at', 'desc')).get().toPromise();
			this.saved_items_list = snapshot.docs.map((e: any) => {
				return {
					upc_code: e.data()['upc_code'],
					product_description: e.data()['product_description'],
					product_name_slug: e.data().product_name_slug,
					pack: e.data()['pack'],
					price_label_format: e.data()['price_label_format'],
					price_label_value1: e.data()['price_label_value1'] ? e.data()['price_label_value1'] : "",
					price_label_value2: e.data()['price_label_value2'] ? e.data()['price_label_value2'] : "",
					price_label_value3: e.data()['price_label_value3'] ? e.data()['price_label_value3'] : "",
					price_label_highlight: e.data()['price_label_highlight'] ? e.data()['price_label_highlight'] : "",
					product_url: e.data()['product_url'] ? e.data()['product_url'] : "",
					hide_price_label: e.data()['hide_price_label'] ? e.data()['hide_price_label'] : false,
					hide_addtolist_label: e.data()['hide_addtolist_label'] ? e.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.data()['hide_productname_label'] ? e.data()['hide_productname_label'] : false,
					cost: e.data()['cost'] ? e.data()['cost'] : "",
					srp: e.data()['srp'] ? e.data()['srp'] : "",
					storeInMaster: e.data().storeInMaster ? e.data().storeInMaster : '',
					category: e.data()['category'] ? e.data()['category'] : "",
					offerLabel: e.data().offerLabel ? e.data().offerLabel : '',
					labelSide: e.data().labelSide ? e.data().labelSide : '',
					uid: e.id,
					product_image: e.data().product_image ? e.data().product_image : 'https://via.placeholder.com/30/cecece/cecece',
					price_label_svg: e.data().price_label_svg ? e.data().price_label_svg : '',
					is_saved: e.data().is_saved ? e.data().is_saved : '',
					store_uid: e.data().store_uid ? e.data().store_uid : '',
					isVideo: e.data().isVideo ? e.data().isVideo : false,
					master_uid: e.data().master_uid ? e.data().master_uid : '',
					sale_price_label_format: e.data()['sale_price_label_format']?e.data()['sale_price_label_format'] : "",
					sale_price_label_value1: e.data()['sale_price_label_value1'] ? e.data()['sale_price_label_value1'] : "",
					sale_price_label_value2: e.data()['sale_price_label_value2'] ? e.data()['sale_price_label_value2'] : "",
					sale_price_label_value3: e.data()['sale_price_label_value3'] ? e.data()['sale_price_label_value3'] : "",
					final_price_label_format: e.data()['final_price_label_format']?e.data()['final_price_label_format'] : "",
					final_price_label_value1: e.data()['final_price_label_value1'] ? e.data()['final_price_label_value1'] : "",
					final_price_label_value2: e.data()['final_price_label_value2'] ? e.data()['final_price_label_value2'] : "",
					final_price_label_value3: e.data()['final_price_label_value3'] ? e.data()['final_price_label_value3'] : "",
					digital_coupon_price_label_format: e.data()['digital_coupon_price_label_format']?e.data()['digital_coupon_price_label_format'] : "",
					digital_coupon_price_label_value1: e.data()['digital_coupon_price_label_value1'] ? e.data()['digital_coupon_price_label_value1'] : "",
					digital_coupon_price_label_value2: e.data()['digital_coupon_price_label_value2'] ? e.data()['digital_coupon_price_label_value2'] : "",
					digital_coupon_price_label_value3: e.data()['digital_coupon_price_label_value3'] ? e.data()['digital_coupon_price_label_value3'] : "",
					note: e.data()['note'] ? e.data()['note'] : "",
					link: e.data()['link'] ? e.data()['link'] : "",
					usage_limit: e.data()['usage_limit'] ? e.data()['usage_limit'] : "",
					digital_coupon: e.data()['digital_coupon'] ? e.data()['digital_coupon'] : false,
					sale_price_label_svg: e.data().sale_price_label_svg ? e.data().sale_price_label_svg : '',
					sale_content_price_label_svg: e.data().sale_content_price_label_svg ? e.data().sale_content_price_label_svg : '',
					final_price_label_svg: e.data().final_price_label_svg ? e.data().final_price_label_svg : '',
					final_content_price_label_svg: e.data().final_content_price_label_svg ? e.data().final_content_price_label_svg : '',
					digital_coupon_price_label_svg: e.data().digital_coupon_price_label_svg ? e.data().digital_coupon_price_label_svg : '',
					digital_content_coupon_price_label_svg: e.data().digital_content_coupon_price_label_svg ? e.data().digital_content_coupon_price_label_svg : '',
					isBundleProduct : e.data().isBundleProduct ? e.data().isBundleProduct : false,
				};
			});
		} catch (error) {
			console.error("Error fetching saved list items:", error);
			throw error;
		}
	}

	async getLayoutData(){
		try {
			const layoutSnapshot = await this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

			this.current_layout_data = layoutSnapshot.docs.map((doc: any) => {
				return doc.data();
			}).filter(data => data !== null);
			console.log("No-ad Circular Id :- ",this.current_layout_data[0]?.uid,)
            console.log(this.current_layout_data, "current_layout_data");
		} catch (error) {
			// Handle errors here
			console.error("Error fetching layout data:", error);
		}
		this.layoutData()
	}

	async layoutData() {
		this.grid = GridStack.initAll({
			column: 8,
			removable: '#trash',
			float: false
		});
		if (this.current_layout_data?.length > 0) {
			if (this.current_layout_data[0]) {
				localStorage.setItem('Current_layout_EndDate', this.current_layout_data[0].end_date.toDate())
				this.layout_form.patchValue({ terms: this.current_layout_data[0].terms })
			}

			this.show_add_product = false;

			if (this.is_preview_clicked) {
				this.current_layout_data = [JSON.parse(this.preview_obj)]
			}

			if (window.location.origin == 'https://admin-digitalcircular.web.app') {
				this.preview_url = `https://store-digitalcircular.web.app/${this.store_data[0].company_name_slug}/${this.current_layout_data[0].uid}?preview=true&type=noad`
			} else if (window.location.origin == 'https://admin.digitalmenuspro.com') {
				this.preview_url = `https://store.digitalmenuspro.com/${this.store_data[0].company_name_slug}/${this.current_layout_data[0].uid}?preview=true&type=noad`
			} else {
				this.preview_url = `https://digital-menus-pro-store.web.app/${this.store_data[0].company_name_slug}/${this.current_layout_data[0].uid}?preview=true&type=noad`
			}

			// this.preview_url = `http://localhost:4300/${this.store_data[0].company_name_slug}/${this.store_data[0].branch_name_slug}/${this.current_layout_data[0].uid}?preview=true`
			this.layout_type = this.current_layout_data[0].layout_type
			if (!(this.current_layout_data[0].layout_json_temp.length > 0)) {
				this.is_save_active = false
				this.show_discard = false
				this.publish_btn_class = "disable-class"
			}
			
			this.layout_form.patchValue({
				uid: this.current_layout_data[0]?.uid,
				layout_json_temp: this.current_layout_data[0]?.layout_json_temp,
				layout_json: this.current_layout_data[0]?.layout_json,
				layout_json_saved: this.current_layout_data[0]?.layout_json_saved || []
			});
			this.show_add_product = false

			this.current_template_id = this.current_layout_data[0]?.uid;
			this.componentService.currentTemplateId(this.current_template_id)

			this.current_select_product_id = this.current_layout_data[0]?.selected_product_id;
			if (this.current_select_product_id !== '' && this.current_select_product_id !== undefined) {
				this.show_edit_form = true;
				this.matchImage = true
			}
			if (typeof (this.current_layout_data[0]?.layout_json_temp) == "string") {
				const layoutRef = await this.afs.doc('/layout-json-temp/' + this.current_layout_data[0]?.layout_json_temp);
				try {
					const doc: any = await layoutRef.get().toPromise();
					const layout_json_temp = doc.data() || {};
					this.current_layout_data[0].layout_json_temp = layout_json_temp.layout_json_temp;
				} catch (error) {
					this.tabLoader=false;
		            this.tab_btn_class = 'active-class'
					console.error(error)
				}
			}
			if (typeof (this.current_layout_data[0]?.layout_json_saved) == "string") {
				const layoutRef = await this.afs.doc('/layout-json-saved/' + this.current_layout_data[0]?.layout_json_saved);
				try {
					const doc: any = await layoutRef.get().toPromise();
					const layout_json_temp = doc.data() || {};

					this.current_layout_data[0].layout_json_saved = layout_json_temp.layout_json_saved;
				} catch (error) {
				    this.tabLoader=false;
		            this.tab_btn_class = 'active-class'
					console.error(error)
				}
			}

			if (this.do_not_refresh_iframe != true && this.current_layout_data[0]?.layout_json_temp.length > 0) {
				this.grid = GridStack.initAll({
					column: 8,
					removable: '#trash',
					float: false
				});
				this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

				if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
					this.allFunction()
				}
			}

			if (this.current_select_product_id !== '' && this.current_select_product_id !== undefined) {
				this.isVideo = this.current_select_product_data?.isVideo
				this.updateFormData(this.current_select_product_id)
			} else {
				this.show_edit_form = false
			}
			this.do_not_refresh_iframe = true;
			this.last_publish_timestamp = this.current_layout_data[0]?.last_publish_timestamp;
			this.last_save_timestamp = this.current_layout_data[0]?.last_save_timestamp;
			const start_date = new Date(this.current_layout_data[0]?.start_date.seconds * 1000).getFullYear() + '-' + ('0' + (new Date(this.current_layout_data[0]?.start_date.seconds * 1000).getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date(this.current_layout_data[0]?.start_date.seconds * 1000).getDate())).slice(-2);
			const end_date = new Date(this.current_layout_data[0]?.end_date.seconds * 1000).getFullYear() + '-' + ('0' + (new Date(this.current_layout_data[0]?.end_date.seconds * 1000).getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date(this.current_layout_data[0]?.end_date.seconds * 1000).getDate())).slice(-2);
			// this.layout_type = this.current_layout_data[0]?.layout_type;
			this.page_type1 = this.current_layout_data[0]?.page1_type;
			this.page_type2 = this.current_layout_data[0]?.page2_type;
			this.page_type3 = this.current_layout_data[0]?.page3_type;
			this.page_type4 = this.current_layout_data[0]?.page4_type;
			this.page_type5 = this.current_layout_data[0]?.page5_type;
			this.imageurl1 = this.current_layout_data[0]?.page1_url;
			this.imageurl2 = this.current_layout_data[0]?.page2_url;
			this.imageurl3 = this.current_layout_data[0]?.page3_url;
			this.imageurl4 = this.current_layout_data[0]?.page4_url;
			this.imageurl5 = this.current_layout_data[0]?.page5_url;

			// if(this.current_layout_data[0]?.last_save_timestamp > this.current_layout_data[0]?.last_publish_timestamp)
			// {
			// 	//we need to show the locally saved post as changes were done after publishing.
			// 	let json_data = [];
			// 	json_data = this.current_layout_data[0]?.layout_json;
			// }

			this.layout_form.patchValue({
				layout_type: this.layout_type ? this.layout_type : this.current_layout_data[0]?.layout_type,
				page1_type: this.current_layout_data[0]?.page1_type,
				page2_type: this.current_layout_data[0]?.page2_type,
				page3_type: this.current_layout_data[0]?.page3_type,
				page4_type: this.current_layout_data[0]?.page4_type,
				page5_type: this.current_layout_data[0]?.page5_type,
				page1_url: this.current_layout_data[0]?.page1_url,
				page2_url: this.current_layout_data[0]?.page2_url,
				page3_url: this.current_layout_data[0]?.page3_url,
				page4_url: this.current_layout_data[0]?.page4_url,
				page5_url: this.current_layout_data[0]?.page5_url,
				terms: this.current_layout_data[0]?.terms,
				is_circular: this.current_layout_data[0]?.is_circular,
				include_header_checkbox: this.current_layout_data[0]?.include_header_checkbox,
				start_date: start_date,
				end_date: end_date,
				header_text: this.current_layout_data[0]?.header_text,
			});
		} else {
			this.loading = true
			let start_date = new Date();
			start_date = new Date(start_date.getTime() - 86400000)
			const end_date = new Date(start_date.getTime() + (86400000 * 15));

			start_date.setHours(0, 0, 0, 0);
			end_date.setHours(23, 59, 59, 999);
			const include_header_checkbox = 0;

			const layout_store_data =
			{
				store_uid: this.current_store_uid,
				start_date,
				end_date,
				header_text: '',
				include_header_checkbox,
				is_publish: 0,
				layout_json: [],
				layout_type: this.layout_type ? this.layout_type : 'grid',
				page1_type: '',
				page2_type: '',
				page3_type: '',
				page4_type: '',
				page5_type: '',
				page1_url: '',
				page2_url: '',
				page3_url: '',
				page4_url: '',
				page5_url: '',
				layout_json_temp: '',
				layout_json_saved: '',
				terms: '',
				is_circular: true,
				last_save_timestamp: new Date().getTime(),
				last_publish_timestamp: new Date().getTime(),
				updated_at: new Date()
			};
			if (!this.componentService.noAdLayoutCreated) {
				this.componentService.noAdLayoutCreated = true;
			let layout_json_temp = { layout_json_temp: layout_store_data.layout_json_temp }
			const layout_data_temp = await this.afs.collection('/layout-json-temp').add(layout_json_temp);
			const docRef_temp = await layout_data_temp.get();
			const itemRefx2_temp = this.afs.doc('/layout-json-temp/' + docRef_temp.id);
			await itemRefx2_temp.update({ uid: docRef_temp.id });
			layout_store_data.layout_json_temp = docRef_temp.id

			let layout_json_saved = { layout_json_saved: layout_store_data.layout_json_saved }
			let layout_data_saved = await this.afs.collection('/layout-json-saved').add(layout_json_saved);
			const docRef_saved = await layout_data_saved.get();
			const itemRefx2_saved = this.afs.doc('/layout-json-saved/' + docRef_saved.id);
			await itemRefx2_saved.update({ uid: docRef_saved.id });
			layout_store_data.layout_json_saved = docRef_saved.id

			const layout_data_new = await this.afs.collection('/layouts-noad').add(layout_store_data)
			const docRef = await layout_data_new.get();
			const itemRefx2 = await this.afs.doc('/layouts-noad/' + docRef.id);
			await itemRefx2.update({ uid: docRef.id });
			this.current_template_id = docRef.id;
			this.layout_form.patchValue({
				uid: docRef.id,
			});
			itemRefx2.update({}).then(() => {
				this.componentService.noAdLayoutCreated = false;
			});
			this.getLayoutData()
		}
		}
		console.log(this.layout_form.value, "layout_form")
		this.product_update_form.reset()
		this.product_add_form.reset({
			hide_price_label: false,
			hide_addtolist_label: false,
			hide_productname_label: false,
		})
		await this.buttonActiveDeactive()
		this.subscriptions[this.subscriptions.length] = this.layout_form.valueChanges.subscribe(selectedValue => {
			this.publish_btn_class = 'active-class'
		})
		this.loading = false;
		this.tabLoader=false;
		this.tab_btn_class = 'active-class'
	}
	onAddCategory(newCategory: any): void {
		if(newCategory?.label){
			  const existingCategory = this.categories_list.find(
			  (category) => category?.value?.trim().toLowerCase() === newCategory?.label?.trim().toLowerCase()
			);
		if(!existingCategory){
		  const newItem =  newCategory ;
		  console.log(newItem,"newItem")
		  this.categories_list.push(newItem);
		  const newItemCat = { value : newCategory.label}
		  this.afs.collection('/lookup-categories').add(newItemCat).then((docref) => {
			 return docref.update({uid : docref.id});
		  }).then(() => {
			 console.log('Category added successfully with UID');
		  }).catch(error => {
			 console.error('Error adding category: ', error);
		  });
		 }
	   }
	  }
	async buttonActiveDeactive() {
		const temp_form_data = this.layout_form.value
		if (temp_form_data.layout_json_temp.length > 0) {
			this.PreviewBtnClass = true
		} else {
			this.PreviewBtnClass = false
		}
		let currentDataTemp
		let currentDataSaved
		var currentData: any = await this.currentData();
		if (Array.isArray(currentData?.layout_json_temp)) {
			currentDataTemp = currentData
			currentDataSaved = currentData
		} else if (typeof (currentData.layout_json_temp) == "string") {
			currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
	
			const layoutRefSaved = this.afs.doc('/layout-json-saved/' + currentData?.layout_json_saved);
			const docSaved: any = await layoutRefSaved.get().toPromise();
			currentDataSaved = await docSaved.data();
		} else {
			console.error("temp undefined")
		}
		if (this.compareObjects(currentDataTemp?.layout_json_temp, currentDataSaved?.layout_json_saved)) {
			this.is_save_active = false
			this.show_discard = false
			if (this.compareObjects(currentDataSaved?.layout_json_saved, currentData.layout_json)) {
				this.publish_btn_class = 'disable-class'
			} else {
				this.publish_btn_class = 'active-class'
			}
		} else {
			this.show_discard = true
			this.is_save_active = true
			this.publish_btn_class = 'active-class'
		}
	}

	async currentData(){
		const layoutRef = this.afs.doc('/layouts-noad/' + this.current_template_id);
		const doc: any = await layoutRef.get().toPromise();
		var currentData = await doc.data();
		return currentData;
	}

	async allFunction() {
		var currentData: any = await this.currentData();
		if (Array.isArray (currentData?.layout_json_temp)) {
			this.current_layout_data[0].layout_json_temp = currentData.layout_json_temp
		}else if(typeof(currentData.layout_json_temp) == "string"){
			const currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
			this.current_layout_data[0].layout_json_temp = currentDataTemp.layout_json_temp
		}else{
			console.error("temp undefined")
		}
		this.current_layout_data[0]?.layout_json_temp.forEach((item, index) => {
			if (document.getElementById(item.id)) {
				let main_item
				let img_dialog
				let delete_icon
				if (document.getElementById(item.id).tagName != 'VIDEO') {
					main_item = document.getElementById(item.id)
				} else {
					main_item = document.getElementById(item.id)?.parentElement
				}
				delete_icon = main_item?.parentElement.parentElement.getElementsByClassName('deleteicon')
				let deleteicon_click = main_item?.parentElement.parentElement.getElementsByClassName('deleteicon_click')
				let delete_icon_deleteicon_click = main_item?.parentElement.parentElement.getElementsByClassName('deleteicon deleteicon_click')
				img_dialog = main_item?.parentElement.parentElement.getElementsByClassName('img_dialog')
				if (delete_icon.length > 0) {
					(delete_icon[1] ? delete_icon[1] : delete_icon[0]).onclick = async () => {
						const layoutJsonTemp = this.current_layout_data[0]?.layout_json_temp;
						this.current_layout_data[0].layout_json_temp = layoutJsonTemp.filter(i => i.id !== item.id);
						this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

						if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
							this.allFunction()
						}
						if (Array.isArray (currentData?.layout_json_temp)) {
							this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else if(typeof(currentData.layout_json_temp) == "string"){
							this.afs.collection('layout-json-temp').doc(currentData.layout_json_temp).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else{
							console.error("temp undefined")
						}
						if(!this.buttonActiveDeactiveCalled){
						this.buttonActiveDeactive()
						this.buttonActiveDeactiveCalled=true;
						}							
						if(this.current_select_product_id == item.id){
							this.updateFormData('hideForm')
						}
					}
				}
				if (deleteicon_click.length > 0) {
					deleteicon_click[0].onclick = async () => {
						this.deleteProduct=true;
						this.loading=true;
						const layoutJsonTemp = this.current_layout_data[0]?.layout_json_temp;
						this.current_layout_data[0].layout_json_temp = layoutJsonTemp.filter(i => i.id !== item.id);
						this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

						if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
							this.allFunction()
						}
						if (Array.isArray (currentData?.layout_json_temp)) {
							this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else if(typeof(currentData.layout_json_temp) == "string"){
							this.afs.collection('layout-json-temp').doc(currentData.layout_json_temp).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else{
							console.error("temp undefined")
						}
						if(!this.buttonActiveDeactiveCalled){
						this.buttonActiveDeactive()
						this.buttonActiveDeactiveCalled=true;
						}			
						if(this.current_select_product_id == item.id){
							this.updateFormData('hideForm')
						}
					}
				}
				if (delete_icon_deleteicon_click.length > 0) {
					delete_icon_deleteicon_click[0].onclick = async () => {
						this.deleteProduct=true;
						this.loading=true;
						const layoutJsonTemp = this.current_layout_data[0]?.layout_json_temp;
						this.current_layout_data[0].layout_json_temp = layoutJsonTemp.filter(i => i.id !== item.id);
						this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

						if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
							this.allFunction()
						}
						if (Array.isArray (currentData?.layout_json_temp)) {
							this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else if(typeof(currentData.layout_json_temp) == "string"){
							this.afs.collection('layout-json-temp').doc(currentData.layout_json_temp).set({ layout_json_temp: this.current_layout_data[0]?.layout_json_temp }, { merge: true });
						}else{
							console.error("temp undefined")
						}
						if(!this.buttonActiveDeactiveCalled){
						this.buttonActiveDeactive()
						this.buttonActiveDeactiveCalled=true;
						}	
						if(this.current_select_product_id == item.id){
							this.updateFormData('hideForm')
						}
					}
				}

				if (img_dialog[0]) {
					img_dialog[0].onclick = async () => {
						const itemsVal: any = await this.afs.collection('store-items').doc(item.id).get().toPromise()
						if (!itemsVal.empty) {
							const docData = itemsVal.data();
							this.openImageDialogByGrid=true;
							this.openImgDialog(docData.product_description, item.id)
						}
					}
				}

				if (main_item) {
					main_item.onclick = async () => {
						const loader = document.createElement('div');
						loader.className = 'editloader';
						main_item.parentElement.parentElement.appendChild(loader);
						const parentElement = loader.parentElement;
       					parentElement.style.opacity = '0.5';
					try {
						const h = main_item.parentElement.parentElement.gridstackNode?.h
						const w = main_item.parentElement.parentElement.gridstackNode?.w
						await this.afs.collection('layouts-noad').doc(this.current_template_id).update({ selected_product_id: item.id, selected_product_width: w, selected_product_height: h })
						await this.updateFormData(item.id);
					} finally {
						parentElement.style.opacity = '1';
						loader.remove();						
					}
					function scrollToTop() {
						const element = document.querySelector('.dcp-button-bar');
						  if (element) {
							element.scrollIntoView({
							  behavior: 'smooth', // Smooth scroll
							  block: 'start' // Align the top of the element with the top of the scroll area
							});
						  } else {
							console.log('Element with class "dcp-button-bar" not found.');
						  }
						}
					scrollToTop()	
					}
				}
				const pricelabel = main_item?.parentElement.parentElement.getElementsByClassName('pricelabel');
				if (pricelabel.length > 0) {
					pricelabel[0].onclick = async () => {
						const loader = document.createElement('div');
						loader.className = 'editloader';
						main_item.parentElement.parentElement.appendChild(loader);
						const parentElement = loader.parentElement;
       					parentElement.style.opacity = '0.5';				
						try {
							const h = main_item.parentElement.parentElement.gridstackNode?.h
							const w = main_item.parentElement.parentElement.gridstackNode?.w
							await this.afs.collection('layouts-noad').doc(this.current_template_id).update({ selected_product_id: item.id, selected_product_width: w, selected_product_height: h });
							await this.updateFormData(item.id);
						} finally {
							parentElement.style.opacity = '1';
							loader.remove();
						}
						function scrollToTop() {
							const element = document.querySelector('.dcp-button-bar');
							  if (element) {
								element.scrollIntoView({
								  behavior: 'smooth', // Smooth scroll
								  block: 'start' // Align the top of the element with the top of the scroll area
								});
							  } else {
								console.log('Element with class "dcp-button-bar" not found.');
							  }
							}
						scrollToTop()	
					};
				}
			}
		})

		if (this.grid[0].off) {
            this.grid[0].off('change added'); // Remove any existing 'change' listeners
        }

		this.grid[0].on('change', async (e, items) => {
			this.show_edit_form=false;
			const gridData = this.grid[0].save();
			var currentData: any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
                await this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: gridData }, { merge: true });
			}else if(typeof(currentData.layout_json_temp) == "string"){
			    await this.afs.collection('layout-json-temp').doc(currentData.layout_json_temp).set({ layout_json_temp: gridData }, { merge: true });
			}else{
				console.error("temp undefined")
			}
			var currentData: any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
				this.current_layout_data[0].layout_json_temp = currentData.layout_json_temp
			}else if(typeof(currentData.layout_json_temp) == "string"){
				const currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
				this.current_layout_data[0].layout_json_temp = currentDataTemp.layout_json_temp
			}else{
				console.error("temp undefined")
			}
		    if(!this.buttonActiveDeactiveCalled){
			this.buttonActiveDeactiveCalled=true;
			this.buttonActiveDeactive();
			}
			if(this.deleteProduct){
				this.loading=false;
				this.deleteProduct=false;

			}else{
			setTimeout(() => {
				this.loading=false;
			}, 2000);
		}
	});
		this.grid[0].on('added', async (e, items) => {
			const gridData = this.grid[0].save();
			var currentData: any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
                await this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: gridData }, { merge: true });
			}else if(typeof(currentData.layout_json_temp) == "string"){
			    await this.afs.collection('layout-json-temp').doc(currentData.layout_json_temp).set({ layout_json_temp: gridData }, { merge: true });
			}else{
				console.error("temp undefined")
			}
			this.show_edit_form=false;	
	});
		let t1
		this.grid[0].on('resizestop', function (e, ui: any) {
			let width
			if (ui?.attributes['gs-w'] && ui?.attributes['gs-h']) {
				width = (ui?.attributes['gs-w'].value > ui.attributes['gs-h'].value) ? ui.attributes['gs-h'].value : ui.attributes['gs-w'].value
			} else {
				width = 1
			}
			t1 = ui
			const value1 = ui.getElementsByClassName('dollar-amount')[0]?.innerText
			const value2 = ui.getElementsByClassName('cents-amount')[0]?.innerText
			const textLength = value1 ? value2 ? (value1 + value2)?.replace('.', '')?.length : value1?.replace('.', '')?.length : ''
			if (ui.getElementsByClassName('offerLabel')[0]?.innerHTML) {
				const ele = t1.getElementsByClassName('offerLabel')[0]
				switch (parseInt(width)) {
					case 0:
					case 1:
						getFontClassOfferGrid('textSize1', ele)
						break;
					case 2:
						getFontClassOfferGrid('textSize2', ele)
						break;
					case 3:
						getFontClassOfferGrid('textSize3', ele)
						break;
					case 4:
						getFontClassOfferGrid('textSize4', ele)
						break;
					case 5:
						getFontClassOfferGrid('textSize5', ele)
						break;
					case 6:
						getFontClassOfferGrid('textSize6', ele)
						break;
					case 7:
						getFontClassOfferGrid('textSize7', ele)
						break;
					case 8:
						getFontClassOfferGrid('textSize8', ele)
						break;
					case 9:
					case 10:
						getFontClassOfferGrid('textSize9', ele)
						break;
					case 11:
					case 12:
						getFontClassOfferGrid('textSize10', ele)
						break;
					default:
						getFontClassOfferGrid('textSize11', ele)
						break;
				}
			}
			if (ui.getElementsByClassName('pricelabel')[0]?.innerHTML) {
				const ele = t1.getElementsByClassName('circular-card-deal-wrapper')[0]
				switch (parseInt(width)) {
					case 0:
					case 1:
						getFontClassGrid('extraSmall', textLength, ele)
						break;
					case 2:
						getFontClassGrid('baseSmall', textLength, ele)
						break;
					case 3:
						getFontClassGrid('base', textLength, ele)
						break;
					case 4:
						getFontClassGrid('medium', textLength, ele)
						break;
					case 5:
						getFontClassGrid('large', textLength, ele)
						break;
					case 6:
						getFontClassGrid('extraLarge', textLength, ele)
						break;
					case 7:
						getFontClassGrid('extra2large', textLength, ele)
						break;
					case 8:
						getFontClassGrid('extra3large', textLength, ele)
						break;
					case 9:
					case 10:
						getFontClassGrid('extra3large', textLength, ele)
						break;
					case 11:
					case 12:
						getFontClassGrid('extra3large', textLength, ele)
						break;
					default:
						getFontClassGrid('extraSmall', textLength, ele)
						break;
				}
			}
			function getFontClassGrid(className, textLength, ele) {
				switch (textLength) {
					case 1:
						ele.className = `circular-card-deal-wrapper ${className} singleDigit`
						break;
					case 2:
						ele.className = `circular-card-deal-wrapper ${className} doubleDigit`
						break;
					case 3:
						ele.className = `circular-card-deal-wrapper ${className} tripleDigit`
						break;
					case 4:
						ele.className = `circular-card-deal-wrapper ${className} fourDigit`
						break;
					default:
						ele.className = `circular-card-deal-wrapper ${className} singleDigit`
						break;
				}
			}
			function getFontClassOfferGrid(className, ele) {
				ele.className = `offerLabel ${className}`
				return ele.className
			}
		})
		if(this.deleteProduct){
			this.loading=false;
			this.deleteProduct=false;
		}
	}

	compareObjects(obj1: any, obj2: any): boolean {
		if(Array.isArray(obj2) && obj2.length == 0 && typeof obj1 == 'string' && obj1 == ''){
			return true
		}
		// Compare object types
		if (typeof obj1 !== typeof obj2) {
			return false;
		}

		// Compare arrays
		if (Array.isArray(obj1)) {
			if (obj1.length !== obj2.length) {
				return false;
			}
			for (let i = 0; i < obj1.length; i++) {
				if (!this.compareObjects(obj1[i], obj2[i])) {
					return false;
				}
			}
			return true;
		}

		// Compare objects
		if (typeof obj1 === 'object') {
			const keys1 = Object.keys(obj1);
			const keys2 = Object.keys(obj2);

			if (keys1.length !== keys2.length) {
				return false;
			}

			for (const key of keys1) {
				if (!this.compareObjects(obj1[key], obj2[key])) {
					return false;
				}
			}

			return true;
		}

		// Compare primitive values
		return obj1 === obj2;
	}

	getSVG(data) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		return temp.getElementsByClassName('pricelabel')[0]?.innerHTML
	}

	getFormate(data) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		return temp?.getElementsByClassName("circular-card-deal-wrapper")[0]?.getAttribute("id") || ''
	}

	getPrice(data, priceLabel) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		if (temp.getElementsByClassName('pricelabel')[0]?.innerHTML) {
			var formate = this.getFormate(data)
			let price = ''
			switch (priceLabel) {
				case 'lable1':
					price = $(temp).find('#dollar').text().trim()
					break
				case 'lable2':
					price = $(temp).find('#cent').text().trim()
					break
				case 'lable3':
					price = $(temp).find('#third').text().trim()
					break
				default:
					price = '0'
					break
			}
			switch (formate) {
				case 'format1':
					return price
				case 'format2':
					return price
				case 'format3':
					return price
				case 'format4':
					return price
				case 'format5':
					return price
				case 'format6':
					return price
				case 'format7':
					return price
				default:
					return ''
			}
		} else {
			return ''
		}
	}

	getFontsize(width) {
		switch (parseInt(width)) {
			case 0:
			case 1:
				return this.getFontClassOffer('textSize1');
			case 2:
				return this.getFontClassOffer('textSize2');
			case 3:
				return this.getFontClassOffer('textSize3');
			case 4:
				return this.getFontClassOffer('textSize4');
			case 5:
				return this.getFontClassOffer('textSize5');
			case 6:
				return this.getFontClassOffer('textSize6');
			case 7:
				return this.getFontClassOffer('textSize7');
			case 8:
				return this.getFontClassOffer('textSize8');
			case 9:
			case 10:
				return this.getFontClassOffer('textSize9');
			case 11:
			case 12:
				return this.getFontClassOffer('textSize10');
			default:
				return this.getFontClassOffer('textSize11');
		}
	}

	getFontClassOffer(className) {
		return `${className}`;
	}

	getImage(data, id) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		var doc = new DOMParser().parseFromString(temp?.innerHTML, "text/html")
		var imageElement = doc.getElementById(id);
		if (imageElement) {
			var src = imageElement.getAttribute('src');
			var updatedImageUrl = this.removeParameterFromUrl(src, 'size');
			updatedImageUrl = this.removeParameterFromUrl(updatedImageUrl, 'thumbnail');
			updatedImageUrl = this.removeParameterFromUrl(updatedImageUrl, 'palette');
			return updatedImageUrl;
		}
		return null;
	}

	removeParameterFromUrl(url, parameterToRemove) {
		var urlParts = url?.split('?');
		if (urlParts?.length > 1) {
			var baseUrl = urlParts[0];
			var queryParams = urlParts[1]?.split('&');

			var updatedParams = queryParams?.filter(function (param) {
				return param.indexOf(parameterToRemove + '=') !== 0;
			});

			var updatedUrl = baseUrl + '?' + updatedParams.join('&');
			return updatedUrl;
		}
		return url;
	}

	async deleteImage(val, item) {
		let removeItem = [
			{
				'id': item.id,
				'url': item.url
			}
		]
		const data: any = await this.afs.collection('master-items').doc(val)
		data.update({
			secondery_product_image: firebase.firestore.FieldValue.arrayRemove(removeItem[0])
		});
		this.selected_master_product.secondery_product_image = this.selected_master_product.secondery_product_image.filter((x) => { if (x.id !== item.id) { return x } })
		this.dynamicElements = this.dynamicElements.filter((x) => { if (x.id !== item.id) { return x } })
		this._snackBar.open("Products image delete successfully!", '', {
			duration: 2000,
		});
	}

	async defaultImage(val, item) {
		const data: any = await this.afs.collection('master-items').doc(val)
		data.update({
			product_image: item.url
		});
		this.imageurl1 = item.url
		this.selected_master_product.product_image = item.url
		await this.setImage(item);
		this._snackBar.open("Products image set successfully!", '', {
			duration: 2000,
		});
	}

	privew_click() {
		window.open(this.preview_url, "_blank")
		this.loading = false
		this.is_preview_clicked = false
	}

	copy_url() {
		if (this.preview_obj) {
			this.afs.doc('/layouts-noad/' + this.current_template_id).set({
				preview_obj: this.preview_obj
			}, { merge: true }
			).then(docRef => {
				var textarea = document.createElement("textarea");
				textarea.textContent = this.preview_url
				textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
				document.body.appendChild(textarea);
				textarea.select();
				try {
					document.execCommand("copy");  // Security exception may be thrown by some browsers.
					this._snackBar.open('Link is copied to your clipboard', '', {
						duration: 2000,
					});
				}
				catch (ex) {
					console.warn("Copy to clipboard failed.", ex);
				}
				finally {
					document.body.removeChild(textarea);
				}
			})
		} else {
			var textarea = document.createElement("textarea");
			textarea.textContent = this.preview_url
			textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
			document.body.appendChild(textarea);
			textarea.select();
			try {
				document.execCommand("copy");  // Security exception may be thrown by some browsers.
				this._snackBar.open('Link is copied to your clipboard', '', {
					duration: 2000,
				});
			}
			catch (ex) {
				console.warn("Copy to clipboard failed.", ex);
			}
			finally {
				document.body.removeChild(textarea);
			}
		}
	}

	async price_format_value_change(event, label, format) {
		const width = this.current_layout_data[0].selected_product_width
		const height = this.current_layout_data[0].selected_product_height
		const val = event.target.value;
		if (label == 'value1') {
			this.price_label_value1 = val;
			this.product_update_form.patchValue({
				price_label_value1: val
			});
			this.product_add_form.patchValue({
				price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.price_label_value2 = val;
			this.product_update_form.patchValue({
				price_label_value2: val
			});
			this.product_add_form.patchValue({
				price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.price_label_value3 = val;
			this.product_update_form.patchValue({
				price_label_value3: val
			});

			this.product_add_form.patchValue({
				price_label_value3: val
			});
		}

		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}
		this.price_label_svg=await this.priceFormatService.price_format(format,this.labelSize,this.price_label_value1,this.price_label_value2,this.price_label_value3,this.current_price_label_format);

		this.product_update_form.patchValue({
			price_label_svg: this.price_label_svg
		});
		this.product_add_form.patchValue({
			price_label_svg: this.price_label_svg
		});
	}
	async sale_price_format_value_change(event, label, format) {
		const width = this.current_layout_data[0].selected_product_width
		const height = this.current_layout_data[0].selected_product_height
		const val = event.target.value;
			this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		if (label == 'value1') {
			this.sale_price_label_value1 = val;
			this.product_update_form.patchValue({
				sale_price_label_value1: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.sale_price_label_value2 = val;
			this.product_update_form.patchValue({
				sale_price_label_value2: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.sale_price_label_value3 = val;
			this.product_update_form.patchValue({
				sale_price_label_value3: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value3: val
			});
		}

		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.sale_price_label_value1?.trim() + this.sale_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.sale_price_label_value1?.trim() + this.sale_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}
        let sale_price_format=await this.priceFormatService.sale_price(format,this.sale_price_label_value1,this.sale_price_label_value2,this.sale_price_label_value3);
		this.sale_price_label_svg=sale_price_format.sale_price_label_svg;
		this.sale_content_price_label_svg=sale_price_format.sale_content_price_label_svg
		this.product_update_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
		this.product_add_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
	}
	async final_price_format_value_change(event, label, format) {
		const width = this.current_layout_data[0].selected_product_width
		const height = this.current_layout_data[0].selected_product_height
		const val = event.target.value;
		if (label == 'value1') {
			this.final_price_label_value1 = val;
			this.product_update_form.patchValue({
				final_price_label_value1: val
			});
			this.product_add_form.patchValue({
				final_price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.final_price_label_value2 = val;
			this.product_update_form.patchValue({
				final_price_label_value2: val
			});
			this.product_add_form.patchValue({
				final_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.final_price_label_value3 = val;
			this.product_update_form.patchValue({
				final_price_label_value3: val
			});

			this.product_add_form.patchValue({
				final_price_label_value3: val
			});
		} 
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.final_price_label_value1?.trim() + this.final_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.final_price_label_value1?.trim() + this.final_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}	
		
		let final_price_format=await this.priceFormatService.final_price(format,this.final_price_label_value1,this.final_price_label_value2,this.final_price_label_value3);
		this.final_price_label_svg=final_price_format.final_price_label_svg;
		this.final_content_price_label_svg=final_price_format.final_content_price_label_svg
	
		this.product_update_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg

		});
		this.product_add_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg
		});
	}
	async digital_coupon_price_format_value_change(event, label, format) {
		const width = this.current_layout_data[0].selected_product_width
		const height = this.current_layout_data[0].selected_product_height
		const val = event.target.value;
		this.current_digital_coupon_price_label_format='Digital Coupon'
		this.show_digital_coupon_format_panel = false;
		if (label == 'value1') {
			this.digital_coupon_price_label_value1 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value1: val
			});
			this.product_add_form.patchValue({
				digital_coupon_price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.digital_coupon_price_label_value2 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value2: val
			});
			this.product_add_form.patchValue({
				digital_coupon_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.digital_coupon_price_label_value3 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value3: val
			});

			this.product_add_form.patchValue({
				digital_coupon_price_label_value3: val
			});
		}
		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.digital_coupon_price_label_value1?.trim() + this.digital_coupon_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.digital_coupon_price_label_value1?.trim() + this.digital_coupon_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}
		let digital_coupon_price_format = await this.priceFormatService.digital_coupon_price_format(format,this.digital_coupon_price_label_value1,this.digital_coupon_price_label_value2,this.digital_coupon_price_label_value3);
		this.digital_coupon_price_label_svg = digital_coupon_price_format.digital_coupon_price_label_svg;
		this.digital_content_coupon_price_label_svg = digital_coupon_price_format.digital_content_coupon_price_label_svg;
		this.product_update_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
		
	}
	showDigitalCoupons(checked: boolean) {
		if (checked) {
			this.product_add_form.patchValue({
				digital_coupon:true
			})
		  this.show_digital_coupons = true;
		} else {
			this.product_add_form.patchValue({
				digital_coupon:false
			});
		  this.show_digital_coupons = false;
		}
	  }

	getSVGsize(width, textLength) {
		switch (parseInt(width)) {
			case 0:
			case 1:
				return this.getFontClass('extraSmall', textLength)
			case 2:
				return this.getFontClass('baseSmall', textLength)
			case 3:
				return this.getFontClass('base', textLength)
			case 4:
				return this.getFontClass('medium', textLength)
			case 5:
				return this.getFontClass('large', textLength)
			case 6:
				return this.getFontClass('extraLarge', textLength)
			case 7:
				return this.getFontClass('extra2large', textLength)
			case 8:
				return this.getFontClass('extra3large', textLength)
			case 9:
			case 10:
				return this.getFontClass('extra3large', textLength)
			case 11:
			case 12:
				return this.getFontClass('extra3large', textLength)
			default:
				return this.getFontClass('extraSmall', textLength)
		}
	}

	getFontClass(svgSize, textLength) {
		switch (textLength) {
			case 1:
				return svgSize + ' singleDigit';
			case 2:
				return svgSize + ' doubleDigit';
			case 3:
				return svgSize + ' tripleDigit';
			case 4:
				return svgSize + ' fourDigit';
			default:
				return svgSize + ' singleDigit';
		}
	}

	offerlabel(data) {
		if (data != '' && data != null) {
			this.showLabelSide = true
			this.offerLabel = data;
		} else {
			this.showLabelSide = false;
		}
	}

	upload(event, ind) {
		this.matchImage = false
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
		}

		// create a random id
		const randomId = "digital-menus-" + new Date().getTime() + Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/' + randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task = this.ref.put(event.target.files[0]);

		//this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
				this.subscriptions[this.subscriptions.length] = this.ref.getDownloadURL().subscribe(url => {

					this.subscriptions[this.subscriptions.length] = this.ref.getMetadata().subscribe(res => {
						if (res.contentType.split('/')[1] == 'gif') {
							this.isGIF = true
							this.isVideo = false
						} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
							this.isVideo = true
							this.isGIF = false
							this.product_add_form.patchValue({
								isVideo: true
							})
						}
						else {
							this.isGIF = false
							this.isVideo = false
						}
						if (this.isVideo || this.isGIF) {
							this.storeInMaster = true;
						} else {
							this.product_add_form.controls['storeInMaster'].setValue(false);
							this.storeInMaster = false;
						}
					})

					if (ind == '1') {
						this.imageurl1 = url;
						this.layout_form.patchValue({
							page1_type: "upload",
							page1_url: url
						});
					}
					if (ind == '2') {
						this.imageurl2 = url;
						this.imageurl2x = url;
						this.add_form_img = url
						this.layout_form.patchValue({
							page2_type: "upload",
							page2_url: url
						});
					}
					if (ind == '3') {
						this.imageurl3 = url;
						this.layout_form.patchValue({
							page3_type: "upload",
							page3_url: url
						});
					}
					if (ind == '4') {
						this.imageurl4 = url;
						this.layout_form.patchValue({
							page4_type: "upload",
							page4_url: url
						});
					}
					if (ind == '5') {
						this.imageurl5 = url;
						this.layout_form.patchValue({
							page5_type: "upload",
							page5_url: url
						});
					}
					this.image_uploading = false;

				});
			})
		).subscribe();


		// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";

	}

	showStoreInMaster(data, update = false) {
		if (data != '' && data != null) {
			this.storeInMaster = true;
			var domain = new URL(data)
		    if(domain.hostname == 'www.facebook.com'){
				this.isVideo='facebook'
		    	this.loopVideoDisplay=false;
		    }else{
		    	this.loopVideoDisplay=true;
		    }
			if(domain.hostname == 'www.youtube.com'){
				this.isVideo='youtube'
			}
		} else {
			if (update) {
				this.product_update_form.controls['storeInMaster'].setValue(false);
			} else {
				this.product_add_form.controls['storeInMaster'].setValue(false);
				this.storeInMaster = false;
			}
		}
	}

	showEditor_add() {
		const isBannerChecked = this.product_add_form.get('isBanner').value;
	}

	async trigger_from_save_btn(call) {
		const current_timestamp = new Date().getTime();

		this.last_save_timestamp = current_timestamp;
		this.last_publish_timestamp = current_timestamp;

		var currentData: any = await this.currentData();
		if (Array.isArray (currentData?.layout_json_temp)) {
			this.layout_form.patchValue({
				layout_json_saved: currentData?.layout_json_temp
			});
		}else if (typeof (currentData.layout_json_saved) == "string") {
			const currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
			currentData.layout_json_saved = currentDataTemp.layout_json_temp
		}else{
			console.error("temp undefined")
		}
		if (call == 'publish') {
			this.layout_form.patchValue({
				is_publish: 1,
				layout_json: currentData?.layout_json_saved
			});
			await this.trigger_from_publish_btn(this.layout_form.value)
			this.getLayoutData()
			this._snackBar.open('Your changes published successfully', '', {
				duration: 1500,
			});
			localStorage.setItem('is_saved', 'false')
		} else {
			this.layout_form.patchValue({
				is_publish: currentData?.is_publish,
				layout_json_saved: currentData?.layout_json_saved
			});
			await this.save_data(this.layout_form.value)
			this.getLayoutData()
			this._snackBar.open('layout is saved successfully!', '', {
				duration: 1500,
			});
			localStorage.setItem('is_saved', 'true')
		}
		await this.buttonActiveDeactive();
		this.buttonActiveDeactiveCalled = false; // Set the flag to true after the first call
		this.publishLoading=false;
	}

	save_data(data) {
		this.publishLoading=true;
		return new Promise<void>(async (resolve, reject) => {
			const start_date = new Date(data.start_date.replace(/\-/g, '/'));
			const end_date = new Date(data.end_date.replace(/\-/g, '/'));

			start_date.setHours(0, 0, 0, 0); // Set to 00:00:00
			end_date.setHours(23, 59, 59, 999); // Set to 23:59:59.999

			var currentData:any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
				this.afs
					.doc('/layouts-noad/' + this.current_template_id)
					.set({
						layout_json_saved: data?.layout_json_saved
					}, { merge: true })
			}else if (typeof (currentData.layout_json_saved) == "string") {
				const currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
				this.afs
					.doc('/layout-json-saved/' + currentData?.layout_json_saved)
					.set({
						layout_json_saved: currentDataTemp?.layout_json_temp
					}, { merge: true })
				}else{
					console.error("temp undefined")
				}

			this.afs
				.doc('/layouts-noad/' + this.current_template_id)
				.set({
					uid: data.uid,
					layout_type: data.layout_type,
					layout_json: data.layout_json,
					include_header_checkbox: data.include_header_checkbox,
					start_date: start_date,
					end_date: end_date,
					header_text: data.header_text,
					terms: data.terms,
					is_circular: data.is_circular,
					last_save_timestamp: this.last_save_timestamp,
					page1_type: data.page1_type,
					page1_url: data.page1_url,
					page2_type: data.page2_type,
					page2_url: data.page2_url,
					page3_type: data.page3_type,
					page3_url: data.page3_url,
					page4_type: data.page4_type,
					page4_url: data.page4_url,
					page5_type: data.page5_type,
					page5_url: data.page5_url,
					is_publish: data.is_publish,
				}, { merge: true })
				.then(() => {
					resolve(); // Resolve the Promise if the Firestore operation is successful
				})
				.catch((error) => {
					console.error('Error saving data:', error);
					reject(error); // Reject the Promise with an error if the Firestore operation fails
				});
		});
	}

	async trigger_from_publish_btn(data) {
		this.publishLoading=true;
		return new Promise<void>(async (resolve, reject) => {
			const start_date = new Date(data.start_date.replace(/\-/g, '/'));
			const end_date = new Date(data.end_date.replace(/\-/g, '/'));

			start_date.setHours(0, 0, 0, 0); // Set to 00:00:00
			end_date.setHours(23, 59, 59, 999); // Set to 23:59:59.999

			let layout_data
			var currentData:any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
				layout_data = data?.layout_json_saved
				await this.afs
					.doc('/layouts-noad/' + this.current_template_id)
					.set({
						layout_json_saved: data?.layout_json_saved
					}, { merge: true })
			}else if (typeof (currentData.layout_json_saved) == "string") {
				const currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
				layout_data = currentDataTemp?.layout_json_temp
				this.afs
					.doc('/layout-json-saved/' + currentData?.layout_json_saved)
					.set({
						layout_json_saved: currentDataTemp?.layout_json_temp
					}, { merge: true })
			}else{
				console.error("temp undefined")
			}
			this.afs
				.doc('/layouts-noad/' + this.current_template_id)
				.set({
					uid: data.uid,
					layout_type: data.layout_type,
					layout_json: layout_data,
					include_header_checkbox: data.include_header_checkbox,
					start_date: start_date,
					end_date: end_date,
					last_save_timestamp: this.last_save_timestamp,
					last_publish_timestamp: this.last_publish_timestamp,
					header_text: data.header_text,
					terms: data.terms,
					is_circular: data.is_circular,
					page1_type: data.page1_type,
					page1_url: data.page1_url,
					page2_type: data.page2_type,
					page2_url: data.page2_url,
					page3_type: data.page3_type,
					page3_url: data.page3_url,
					page4_type: data.page4_type,
					page4_url: data.page4_url,
					page5_type: data.page5_type,
					page5_url: data.page5_url,
					is_publish: data.is_publish,
				}, { merge: true })
				.then(() => {
					resolve(); // Resolve the Promise if the Firestore operation is successful
				})
				.catch((error) => {
					console.error('Error saving data:', error);
					reject(error); // Reject the Promise with an error if the Firestore operation fails
				});
		});
	}

	async discard_changes_btn() {
		this.componentService.setLayoutType('grid')

		try {
			// Step 1: Fetch data from Firestore
			const dataSnapshot: any = await this.afs.collection('/layouts-noad', ref => ref.where('uid', '==', this.current_template_id)).get().toPromise();
			if (!dataSnapshot.empty) {
				const docData = dataSnapshot.docs[0].data();
				var layout_json_saved
				if (Array.isArray (docData.layout_json_saved)) {
					layout_json_saved = docData.layout_json_saved || [];

					// Step 2: Update Firestore document
					await this.afs.doc('/layouts-noad/' + this.current_template_id).set({ layout_json_temp: layout_json_saved }, { merge: true });
				}else if (typeof (docData.layout_json_saved) == "string") {
					const dataSnapshotSaved: any = await this.afs.collection('/layout-json-saved', ref => ref.where('uid', '==', docData.layout_json_saved)).get().toPromise();
					const docDataSaved = dataSnapshotSaved.docs[0].data();
					layout_json_saved = docDataSaved.layout_json_saved
					await this.afs.doc('/layout-json-temp/' + docData.layout_json_temp).set({ layout_json_temp: docDataSaved.layout_json_saved }, { merge: true });

				}else{
					console.error("temp undefined")
				}

				// Step 3: Update the Gridstack
				this.grid[0].load(layout_json_saved);

				if (layout_json_saved.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()

				// Step 4: Display success message
				this._snackBar.open('Your changes are discarded successfully', '', {
					duration: 1500,
				});
			} else {
				console.log('No data found for the specified uid.');
			}
		} catch (error) {
			console.error('An error occurred:', error);
		}

		// Step 5: Dismiss modal and remove localStorage item
		this.modalService.dismissAll();
		localStorage.removeItem('is_saved');
	}

	async save_and_publish() {
		this.modalService.dismissAll()
		await this.trigger_from_save_btn('publish')
	}

	radio_change(id, type) {
		switch (id) {
			case 1:
				this.page_type1 = type;
				break;
			case 2:
				this.page_type2 = type;
				break;
			case 3:
				this.page_type3 = type;
				break;
			case 4:
				this.page_type4 = type;
				break;
			case 5:
				this.page_type5 = type;
				break;
			default:
				// Handle unexpected id values, if necessary
				break;
		}
	}

	layout_grid_store(data) {
		if (data.start_date == "") {
			this._snackBar.open("Please enter start date", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.end_date == "") {
			this._snackBar.open("Please enter End date", '', {
				duration: 1500,
			});
			return false;
		}

		if (data.uid != "") {
			let start_date = new Date(data.start_date); // changing start date to firebase timestamp
			let end_date = new Date(data.end_date); // converting end time to firebase timestamp
			start_date.setHours(0, 0, 0, 0);
			end_date.setHours(23, 59, 59, 999);

			let include_header_checkbox = 0;
			if (data.include_header_checkbox == true) {
				include_header_checkbox = 1;
			}

			const layout_store_data =
			{
				store_uid: this.current_store_uid,
				start_date: start_date,
				end_date: end_date,
				header_text: data.header_text,
				include_header_checkbox: include_header_checkbox,
				is_publish: data.is_publish,
				layout_json: data.layout_json_temp,
				layout_type: data.layout_type,
				layout_json_temp: data.layout_json_temp,
				terms: data.terms,
				is_circular: data.is_circular,
				last_save_timestamp: this.last_save_timestamp,
				last_publish_timestamp: this.last_publish_timestamp,
				updated_at: new Date(),
			};
			// let layout_data_update = this.afs.doc('/layouts-noad/'+ this.current_layout_data[0]?.uid).set(layout_store_data,{merge:true}).then(docRef => {
			const layout_data_update = this.afs.doc('/layouts-noad/' + data.uid).set(layout_store_data, { merge: true }).then((docRef) => {
				console.log('layout for grid layout is updated successfully');
			});

			this._snackBar.open("Layout saved successfully", '', {
				duration: 1500,
			});
		}
		return false;
	}

	async layout_store(data) {
		if (data.layout_type == "grid") {
			return this.layout_grid_store(data);
		}

		if (data.start_date == "") {
			this._snackBar.open("Please enter start date", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.end_date == "") {
			this._snackBar.open("Please enter End date", '', {
				duration: 1500,
			});
			return false;
		}

		if (data.include_header_checkbox == "") {
			this._snackBar.open("Plese select whether you want to includde the header", '', {
				duration: 1500,
			});
			return false;
		}

		if (data.include_header_checkbox == true && data.header_text == "") {
			this._snackBar.open("Please enter the header text you want to include", "", {
				duration: 1500,
			});
			return false;
		}
		if (data.layout_type == "") {
			this._snackBar.open("Please select a layout type", "", {
				duration: 1500,
			});
			return false;
		}

		if (data.layout_type == "page") {
			if (data.page1_url == "") {
				this._snackBar.open("Please upload an image or atleast 1 image url", "", {
					duration: 1500,
				});
			}

		}


		this.layout_json = []; // store data in this variable on tile change

		const start_date = new Date(data.start_date); // changing start date to firebase timestamp
		const end_date = new Date(data.end_date); // converting end time to firebase timestamp
		start_date.setHours(0, 0, 0, 0);
		end_date.setHours(23, 59, 59, 999);

		let include_header_checkbox = 0;
		if (data.include_header_checkbox == true) {
			include_header_checkbox = 1;
		}

		const layout_store_data =
		{
			store_uid: this.current_store_uid,
			start_date: start_date,
			end_date: end_date,
			header_text: data.header_text,
			include_header_checkbox: include_header_checkbox,
			// is_publish 				: data.is_publish,
			layout_json: data.layout_json,
			layout_json_saved: data.layout_json_saved,
			layout_json_temp: data.layout_json_temp,
			layout_type: data.layout_type,
			page1_type: data.page1_type,
			page2_type: data.page2_type,
			page3_type: data.page3_type,
			page4_type: data.page4_type,
			page5_type: data.page5_type,
			page1_url: data.page1_url,
			page2_url: data.page2_url,
			page3_url: data.page3_url,
			page4_url: data.page4_url,
			page5_url: data.page5_url,
			terms: data.terms,
			is_circular: data.is_circular,
			last_save_timestamp: this.last_save_timestamp,
			last_publish_timestamp: this.last_publish_timestamp,
			updated_at: new Date(),
			created_at: new Date()
		};

		if (this.current_layout_data && this.current_layout_data.length > 0) {
			var currentData:any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {

				const layout_data_update = this.afs.doc('/layouts-noad/' + this.current_layout_data[0]?.uid).set(layout_store_data, { merge: true })
				this.grid[0].load(data.layout_json_temp);

				if (data.layout_json_temp.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()
			}else if (typeof (currentData.layout_json_saved) == "string") {
				this.afs
				.doc('/layout-json-temp/' + currentData.layout_json_temp)
				.set({
					layout_json_saved: layout_store_data.layout_json_temp
				}, { merge: true })
			this.afs
				.doc('/layout-json-saved/' + currentData.layout_json_saved)
				.set({
					layout_json_saved: layout_store_data.layout_json_temp
				}, { merge: true })
				delete layout_store_data.layout_json_temp
				delete layout_store_data.layout_json_saved
				const layout_data_update = this.afs.doc('/layouts-noad/' + this.current_layout_data[0]?.uid).set(layout_store_data, { merge: true })
				this.grid[0].load(data.layout_json_temp);

			if (data.layout_json_temp.length > 0) {
				this.allFunction()
			}
			this.buttonActiveDeactive()
			}else{
				console.error("temp undefined")
			}

		} else {
			layout_store_data['created_at'] = new Date();

			const layout_data_new = this.afs.collection('/layouts-noad').add(layout_store_data).then(async docRef => {
				let layout_json_temp = { layout_json_temp: layout_store_data?.layout_json_temp }
				const layout_data_temp = await this.afs.collection('/layout-json-temp').add(layout_json_temp);
				const docRef_temp = await layout_data_temp.get();
				const itemRefx2_temp = this.afs.doc('/layout-json-temp/' + docRef_temp.id);
				await itemRefx2_temp.update({ uid: docRef_temp.id });
				const itemRefx2 = this.afs.doc('/layouts-noad/' + docRef.id);
				itemRefx2.update({ uid: docRef.id });
				this.grid[0].load(data.layout_json_temp);

				if (data.layout_json_temp.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()
			});
		}
	}

	toggleLayout(layoutType) {
		this.layout_type = layoutType;
		if (layoutType === 'page') {
			$('.layout-two').removeClass('d-none');
			$('.product-thumbnail').addClass('d-none');
		} else if (layoutType === 'grid') {
			$('.layout-two').addClass('d-none');
			$('.product-thumbnail').removeClass('d-none');
		}
	}

	no_ad_layout() {
		this.router.navigate(['/store/digital-circular/no-ad-layout']);
	}

	upcoming_layout() {
		this.router.navigate(['/store/digital-circular/upcoming-layout']);
	}

	Bundles() {
		this.router.navigate(['/store/digital-circular/bundles']);
	}

	Menu() {
		this.router.navigate(['/store/digital-circular/menu']);
	}

	current_layout() {
		this.router.navigate(['/store/digital-circular']);
		
		// this.ngOnInit();
	}


	open_clear_all_items_modal() {
		this.open(this.clearLayoutModal, 'modal-basic-title3');
	}

	async clear_all_items() {
		var currentData:any = await this.currentData();
		if (Array.isArray (currentData?.layout_json_temp)) {
			this.afs.doc("/layouts-noad/"+this.current_template_id).set({layout_json_temp:[]},{merge:true})
		}else if (typeof (currentData.layout_json_temp) == "string") {
			const data = [];
			this.componentService.setTempLayout(data, currentData.layout_json_temp)
		}else{
			console.error("temp undefined")
		}
		this.current_layout_data[0].layout_json_temp = []
		this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

		if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
			this.allFunction()
		}
		this.buttonActiveDeactive()
		this.updateFormData('hideForm')
		
		this._snackBar.open("All Products cleared from your grid", "", {
			duration: 1500,
		});
		this.final_product_array = []
		let element: HTMLElement = document.getElementsByClassName('close_clear_layout_modal')[0] as HTMLElement;
		element.click();
	}

	async product_update(data) {
		data.isBundleProduct=false;
		if (this.show_edit_form == false) {
			return false;
		}
		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}
		if (data.upc_code == "" || data.upc_code == null) {
			this._snackBar.open("Error:UPC Code is Required", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			return false;
		}
		if(data.digital_coupon){
			data.price_label_value1='';
			data.price_label_value2='';
			data.price_label_value3='';
			if(data.sale_price_label_value1=='' && data.sale_price_label_value2=='' && data.sale_price_label_value3==''){
				this._snackBar.open('Error:Sale Price is Required', '', {
					duration: 1500,
				});
				// this.buttonDisabled = false;
				return false;
			}
		
			if(data.digital_coupon_price_label_value1=='' && data.digital_coupon_price_label_value2=='' && data.digital_coupon_price_label_value3==''){
				this._snackBar.open('Error:Digital Coupon is Required', '', {
					duration: 1500,
				});
				// this.buttonDisabled = false;
				return false;
			}
				if(data.final_price_label_value1=='' && data.final_price_label_value2=='' && data.final_price_label_value3==''){
				this._snackBar.open('Error:Final Price is Required', '', {
					duration: 1500,
				});
				// this.buttonDisabled = false;
				return false;
			}
			if(data.usage_limit=='' || data.usage_limit==null){		
				this._snackBar.open('Error:Frequency Field is Required', '', {
					  duration: 1500,
				  });
				//   this.buttonDisabled = false;
				  return false;
			  }
		}
		if (data.product_description == "" || data.product_description == null) {
			this._snackBar.open("Error:Product description is Required", '', {
				duration: 1500,
			});
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		if (!data.isBanner) {
			data.bannerText = '';
		}
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		data.tag = data?.product_description.split(" ")
		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube-nocookie.com" || domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				var ytid = data.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
				ytid = ytid.replace('/embed/', '');
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name='youtube'
	     	}		
	     	if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
	     		data.product_url=await this.convertFacebookWatchLink(data.product_url)
	     		const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
	     		const encodedURL = encodeURIComponent(data.product_url);
	     		const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
	     		data.product_url=data.product_url
				this.domain_name='facebook'
	     	}
	    }

		// if(data.digital_coupon){
		// 	data.pack='';
		// 	data.category='';
		// }
		if (data.isVideo == 'youtube' || data.isVideo == 'facebook' || data.isVideo == true) {
			if (data.storeInMaster) {
				if (data.master_uid == '' || data.master_uid == null) {
					data.created_at = new Date();
					const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
						const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
						data.master_uid = docRef.id
						itemRefx2.update({ uid: docRef.id });
					})
				} else if (data.master_uid) {
					const itemRefx2 = await this.afs.doc('/master-items/' + data.master_uid).get().toPromise();
					const master_old_data: any = itemRefx2.data();
					const master_data = { ...data, secondery_product_image: master_old_data.secondery_product_image || [] }
					delete master_data.uid
					if (!this.matchImage) {
						if (data.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726' && this.current_selected_product_data_full[0].product_image != data.product_image) {
							master_data.secondery_product_image.push({ 'id': Date.now(), 'url': data?.product_image })
						}
					}
					if (this.user_data?.role != 'admin' && this.current_selected_product_data_full[0].product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						delete master_data.product_image
					}
					const master_product_data = await this.afs.doc('master-items/' + data.master_uid).set(master_data, { merge: true }).then(docRef => {
						// console.log('PRODUCT STORE ITEMS UPDATED SUCCESS', docRef); 
					});
				}
			} else {
				if (data.master_uid != '' && data.master_uid != null) {
					await this.afs.doc('/master-items/' + data.master_uid).delete().then(() => {
						data.master_uid = '';
						// Perform additional actions if needed
					})
						.catch((error) => {
							console.error('Error deleting product: ', error);
						});
				}
			}
		} else {
			const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description).limit(1)).get().toPromise();
			this.master_list = querySnapshot.docs.map((doc: any) => ({
				upc_code: doc.data()['upc_code'],
				product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
				product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
				pack: doc.data()['pack'] ? doc.data()['pack'] : '',
				price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
				price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
				price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
				price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
				price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
				product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
				hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : false,
				hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : false,
				hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : false,
				cost: doc.data()['cost'] ? doc.data()['cost'] : '',
				srp: doc.data()['srp'] ? doc.data()['srp'] : '',
				category: doc.data()['category'] ? doc.data()['category'] : '',
				uid: doc.data()['uid'],
				product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
				price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
				is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
				tag: doc.data()['tag'] ? doc.data()['tag'] : [],
				secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
				upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
				final_price_label_format: doc.data()['final_price_label_format'] ? doc.data()['final_price_label_format'] : '',
			    final_price_label_value1: doc.data()['final_price_label_value1'] ? doc.data()['final_price_label_value1'] : '',
			    final_price_label_value2: doc.data()['final_price_label_value2'] ? doc.data()['final_price_label_value2'] : '',
			    final_price_label_value3: doc.data()['final_price_label_value3'] ? doc.data()['final_price_label_value3'] : '',
			    sale_price_label_format: doc.data()['sale_price_label_format'] ? doc.data()['sale_price_label_format'] : '',
			    sale_price_label_value1: doc.data()['sale_price_label_value1'] ? doc.data()['sale_price_label_value1'] : '',
			    sale_price_label_value2: doc.data()['sale_price_label_value2'] ? doc.data()['sale_price_label_value2'] : '',
			    sale_price_label_value3: doc.data()['sale_price_label_value3'] ? doc.data()['sale_price_label_value3'] : '',
			    digital_coupon_price_label_format: doc.data()['digital_coupon_price_label_format'] ? doc.data()['digital_coupon_price_label_format'] : '',
			    digital_coupon_price_label_value1: doc.data()['digital_coupon_price_label_value1'] ? doc.data()['digital_coupon_price_label_value1'] : '',
			    digital_coupon_price_label_value2: doc.data()['digital_coupon_price_label_value2'] ? doc.data()['digital_coupon_price_label_value2'] : '',
			    digital_coupon_price_label_value3: doc.data()['digital_coupon_price_label_value3'] ? doc.data()['digital_coupon_price_label_value3'] : '',
			    note: doc.data()['note'] ? doc.data()['note'] : '',
			    usage_limit: doc.data()['usage_limit'] ? doc.data()['usage_limit'] : '',
			    link: doc.data()['link'] ? doc.data()['link'] : '',
			    digital_coupon: doc.data()['digital_coupon'] ? doc.data()['digital_coupon'] : false,
			    sale_price_label_svg: doc.data()['sale_price_label_svg'] ? doc.data()['sale_price_label_svg'] : '',
			    sale_content_price_label_svg: doc.data()['sale_content_price_label_svg'] ? doc.data()['sale_content_price_label_svg'] : '',
			    final_price_label_svg: doc.data()['final_price_label_svg'] ? doc.data()['final_price_label_svg'] : '',
			    final_content_price_label_svg: doc.data()['final_content_price_label_svg'] ? doc.data()['final_content_price_label_svg'] : '',
			    digital_coupon_price_label_svg: doc.data()['digital_coupon_price_label_svg'] ? doc.data()['digital_coupon_price_label_svg'] : '',
			    digital_content_coupon_price_label_svg: doc.data()['digital_content_coupon_price_label_svg'] ? doc.data()['digital_content_coupon_price_label_svg'] : '',
			    isBundleProduct: doc.data()['isBundleProduct'] ? doc.data()['isBundleProduct'] : false,
			}));
			let master_result = querySnapshot.docs[0]?.data();
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result?.secondery_product_image.push({ 'id': Date.now(), 'url': data.product_image })
					}
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				if (data.product_image == "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726") {
					data.secondery_product_image = []
				} else {
					data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
				}
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data.secondery_product_image
				})
			}
		}
		const product_data = await this.afs.doc('store-items/' + data.uid).set(data, { merge: true })

		this._snackBar.open('Product details updated successfully!', '', {
			duration: 1500,
		});
		//current_select_product_index
		//111
		//001
		//011
		//101
		//000
		let gifClass = ''
		if (this.isGIF == true) {
			gifClass = ' gifImg '
		}
		const width = this.current_layout_data[0].selected_product_width
		const height = this.current_layout_data[0].selected_product_height
		let className = this.getFontsize(width > height ? height : width)
		let content_data =await this.productUpdateService.generateContentData(data,gifClass,className,this.store_data)
		if(data.digital_coupon){
			this.current_select_product_data.w=2;
			this.current_select_product_data.h=2
		}
		let product_data_card 
		if(data.digital_coupon){
			product_data_card = {
				id: data.uid,
				upc_code: data?.upc_code,
				w: this.current_select_product_data?.w ? this.current_select_product_data?.w : 1,
				h: this.current_select_product_data?.h ? this.current_select_product_data?.h : 1,
				x: this.current_select_product_data?.x ?this.current_select_product_data?.x:0,
				y: this.current_select_product_data?.y ?this.current_select_product_data?.y:0,
				category: data.category ? data.category : '',
				content: content_data,
				youtubeURL: data.product_url ? data.product_url : '',
				domain_name:this.domain_name?this.domain_name:'',
				openNewTab: data.new_window ? data.new_window : '',
				isVideo: data.isVideo ? data.isVideo : '',
				isBanner: data.isBanner ? data.isBanner : '',
				bannerText: data.bannerText ? data.bannerText : '',
				product_image: data.product_image ? data.product_image : '',
				product_description: data.product_description ? data.product_description : '',
				autoplay: data.autoplay ? data.autoplay : false,
				loopvideo: data.loopvideo ? data.loopvideo : false,
				price_label_svg: data.price_label_svg ? data.price_label_svg : '',
				sale_price_label_value1: data.sale_price_label_value1 ? data.sale_price_label_value1 : '',
				sale_price_label_value2: data.sale_price_label_value2 ? data.sale_price_label_value2 : '',
				sale_price_label_value3: data.sale_price_label_value3 ? data.sale_price_label_value3 : '',
				sale_price_label_format: data.sale_price_label_format ? data.sale_price_label_format : '',
				sale_price_label_svg: data.sale_price_label_svg ? data.sale_price_label_svg : '',
				sale_content_price_label_svg: data.sale_content_price_label_svg ? data.sale_content_price_label_svg : '',
				final_content_price_label_svg: data.final_content_price_label_svg ? data.final_content_price_label_svg : '',
				digital_content_coupon_price_label_svg: data.digital_content_coupon_price_label_svg ? data.digital_content_coupon_price_label_svg : '',
				digital_coupon: data.digital_coupon ? data.digital_coupon : false,
				note: data.note ? data.note : '',
				usage_limit: data.usage_limit ? data.usage_limit : '',
				link: data.link ? data.link : '',
				pack:data.pack ? data.pack :'',
				isBundleProduct: data.isBundleProduct ? data.isBundleProduct : false,
			};
		}else{
		 product_data_card = {
			id: data.uid,
			upc_code: data?.upc_code,
			w: this.current_select_product_data?.w ? this.current_select_product_data?.w : 1,
			h: this.current_select_product_data?.h ? this.current_select_product_data?.h : 1,
			x: this.current_select_product_data?.x ?this.current_select_product_data?.x:0,
			y: this.current_select_product_data?.y ?this.current_select_product_data?.y:0,
			category: data.category ? data.category : '',
			content: content_data,
			youtubeURL: data.product_url ? data.product_url : '',
			domain_name:this.domain_name?this.domain_name:'',
			openNewTab: data.new_window ? data.new_window : '',
			isVideo: data.isVideo ? data.isVideo : '',
			isBanner: data.isBanner ? data.isBanner : '',
			bannerText: data.bannerText ? data.bannerText : '',
			product_image: data.product_image ? data.product_image : '',
			product_description: data.product_description ? data.product_description : '',
			autoplay: data.autoplay ? data.autoplay : false,
			loopvideo: data.loopvideo ? data.loopvideo : false,
			price_label_value1: data.price_label_value1 ? data.price_label_value1 : '',
			price_label_value2: data.price_label_value2 ? data.price_label_value2 : '',
			price_label_value3: data.price_label_value3 ? data.price_label_value3 : '',
			price_label_format: data.price_label_format ? data.price_label_format : '',
			price_label_svg: data.price_label_svg ? data.price_label_svg : '',
			digital_coupon: data.digital_coupon ? data.digital_coupon : false,
			pack:data.pack ? data.pack :'',
			isBundleProduct: data.isBundleProduct ? data.isBundleProduct : false,
		};
	}
		const index = this.current_select_product_index.toString().includes(' ') ? this.current_select_product_index.split(' ') : this.current_select_product_index
		if (index.length > 0) {
			this.current_layout_data[0].layout_json_temp[index[0]].subGridOpts.children[index[1]] = product_data_card;
		} else {
			this.current_layout_data[0].layout_json_temp[index] = product_data_card;
		}		
		var currentData:any = await this.currentData();
			if (Array.isArray (currentData?.layout_json_temp)) {
			const layout_data_updatex = this.afs.doc('/layouts-noad/' + this.current_template_id).set({ layout_json_temp: this.current_layout_data[0].layout_json_temp }, { merge: true }).then(docRef => {
				if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
					this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);
				}
			});
		}else if (typeof (currentData.layout_json_temp) == "string") {
			this.componentService.setTempLayout(this.current_layout_data[0].layout_json_temp, currentData.layout_json_temp)
			this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);
		}else{
			console.error("temp undefined")
		}
		await this.updateFormData(data.uid);
		await this.allFunction()
		this.buttonActiveDeactive()
	}

	open_format(format) {
		if (this.show_format_panel == true) {
			this.show_format_panel = false;
		} else {
			this.show_format_panel = true;
		}
	}
	open_sale_price_format(format){
		this.current_sale_price_label_format = 'Sale Price format1' ;
		this.show_format_panel = false;

		if (this.show_sale_price_format_panel == true) {
			this.show_sale_price_format_panel = false;
		} else {
			this.show_sale_price_format_panel = true;
		}		
		
		this.product_update_form.patchValue({
			sale_price_label_format: 'Sale Price format1' ,
		});

		this.product_add_form.patchValue({
			sale_price_label_format: 'Sale Price format1' ,
		});
	
		if (this.current_sale_price_label_format == 'Sale Price format1') {
			if (this.sale_price_label_value1) {
				this.sale_content_price_label_svg='<div class="sell_title_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div></div></div></div>'
				this.sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3">' + this.sale_price_label_value2 + '</div></div></div></div>';
			} else {
				if(this.sale_price_label_value2){
					this.sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.sale_price_label_svg = '<div class="sell_title_wrapper sell_wrapper_format_1"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_2">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					this.sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.sale_price_label_svg = '<div id="sale_price_format1" class="circular-card-deal-wrapper DigitalExtraSmall"></div>';
				}
			}
		}
	}
	open_digital_coupon_format(format){
		this.current_digital_coupon_price_label_format = 'Digital Coupon';
		this.show_format_panel = false;
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		if (this.current_digital_coupon_price_label_format == 'Digital Coupon') {
			if (this.digital_coupon_price_label_value1) {
				this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + this.digital_coupon_price_label_value2 + '</div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
				this.digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + this.digital_coupon_price_label_value2 + '</div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>';
			} else {
				if(this.digital_coupon_price_label_value2){
					this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
					this.digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
				}else{
					this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
					this.digital_coupon_price_label_svg = '<div id="digital_coupon" class="circular-card-deal-wrapper DigitalExtraSmall"></div>'
				}
			}
		}
	}
	
	open_final_price_format(format){
		this.current_final_price_label_format = 'Final Price Format1'
		this.show_format_panel = false;

		if (this.show_final_price_format_panel == true) {
			this.show_final_price_format_panel = false;
		} else {
			this.show_final_price_format_panel = true;
		}
		this.product_update_form.patchValue({
			final_price_label_format: 'Final Price Format1' 
		});

		this.product_add_form.patchValue({
			final_price_label_format: 'Final Price Format1' 
		});
	
		if (this.current_final_price_label_format == 'Final Price Format1') {
			if (this.final_price_label_value1) {
				this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div></div></div></div>'
				this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div></div></div></div>';
			} else {
				if(this.final_price_label_value2){
					this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"></div>';
				}
			}
		}
	}
	
	async selected_price_label(id) {
		this.current_price_label_format = 'format' + id;
		this.show_format_panel = false;
		this.product_update_form.patchValue({
			price_label_format: 'format' + id,
		});

		this.product_add_form.patchValue({
			price_label_format: 'format' + id,
		});
		if (this.current_price_label_format == 'format6' && this.price_label_value3 == '') {
			this.product_update_form.patchValue({
				price_label_value3: 'Free'
			});
			this.price_label_value3 = 'Free'
		}

		this.price_label_svg = await this.priceFormatService.selected_price_format(this.current_price_label_format,this.price_label_value1,this.price_label_value2,this.price_label_value3);
	}
	async selected_sale_price_label(id) {
		this.current_sale_price_label_format = 'Sale Price format' + id;
		this.show_format_panel = false;
		this.show_sale_price_format_panel=false;
		this.product_update_form.patchValue({
			sale_price_label_format: 'Sale Price format' + id,
		});
		this.product_add_form.patchValue({
			sale_price_label_format: 'Sale Price format' + id,
		});
	
		let sale_price_format =await this.priceFormatService.selected_sale_price_format(this.current_sale_price_label_format,this.sale_price_label_value1,this.sale_price_label_value2,this.sale_price_label_value3)
	    this.sale_price_label_svg = sale_price_format.sale_price_label_svg;
		this.sale_content_price_label_svg = sale_price_format.sale_content_price_label_svg;		
		this.product_add_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
		this.product_update_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
	}



	async selected_final_price_label(id) {
		this.current_final_price_label_format = 'Final Price Format' + id;
		this.show_final_price_format_panel = false;
		this.product_update_form.patchValue({
			final_price_label_format: 'Final Price Format' + id,
		});

		this.product_add_form.patchValue({
			final_price_label_format: 'Final Price Format' + id,
		});
		
		let final_price_format = await this.priceFormatService.selected_final_price_format(this.current_final_price_label_format,this.final_price_label_value1,this.final_price_label_value2,this.final_price_label_value3);
		this.final_price_label_svg = final_price_format.final_price_label_svg;
		this.final_content_price_label_svg = final_price_format.final_content_price_label_svg
		this.product_update_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg

		});
		this.product_add_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg
		});
		
	}
	async selected_digital_Coupon_price_label(id) {
		this.current_digital_coupon_price_label_format = 'Digital Coupon';
		this.show_digital_coupon_format_panel=false;
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		let digital_coupon_price_format = await this.priceFormatService.selected_digital_coupon_price(this.current_digital_coupon_price_label_format,this.digital_coupon_price_label_value1,this.digital_coupon_price_label_value2,this.digital_coupon_price_label_value3);
		this.digital_coupon_price_label_svg = digital_coupon_price_format.digital_coupon_price_label_svg;
		this.digital_content_coupon_price_label_svg = digital_coupon_price_format.digital_content_coupon_price_label_svg;

		this.product_update_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
	}
	
	async openDialog(type) {
		var currentData:any = await this.currentData();
		if (Array.isArray (currentData?.layout_json_temp)) {
					this.subscriptions[this.subscriptions.length] = this.afs.collection('/layouts-noad', ref => ref.where('uid', '==', this.current_layout_data[0].uid).orderBy('end_date', 'asc')).snapshotChanges().subscribe(data => {
				this.oldProducts = data.map((e: any) => {
					return {
						layout_json_temp: e.payload.doc.data().layout_json_temp ? e.payload.doc.data().layout_json_temp : [],
					};
				});
				this.componentService.setOldProducts(this.oldProducts[0].layout_json_temp)		
			});
		}else if (typeof (currentData.layout_json_temp) == "string") {
			const currentDataTemp = await this.componentService.getTempLayout(currentData.layout_json_temp)
			this.componentService.setOldProducts(currentDataTemp.layout_json_temp)
		}else{
			console.error("temp undefined")
		}
		if (type === 'save') {
			const dialogRef = this.dialog.open(SavedItemsDialogNodeComponent, {
				width: '815px',
				height: '820px',
				data: { from: 'digital-circular' }
			});

			this.subscriptions[this.subscriptions.length] = dialogRef.afterClosed().subscribe(result => {
				// this.passedValues = result;
			});
		}
		if (type === 'all') {
			const dialogRef = this.dialog.open(AllItemsDialogNodeComponent, {
				width: '815px',
				height: '820px',
				data: { from: 'digital-circular' }
			});

			this.subscriptions[this.subscriptions.length] = dialogRef.afterClosed().subscribe(result => {
				// this.passedValues = result;
			});
		}
		if (type === 'library') {
			const dialogRef = this.dialog.open(AllItemsDialogNodeComponent, {
				width: '815px',
				height: '820px',
				data: {
					from: 'digital-circular-library',
					selected_product_uid: this.selected_product_uid,
					selected_master_product_uid: this.selected_master_product_uid,
					current_template_id: this.current_template_id
				}
			});
			if(this.show_add_product || this.show_edit_form){
				dialogRef.afterClosed().subscribe(result => {
					this.imageurl1x = dialogRef.componentInstance.imageUrl1Pro
				});
			}
			this.subscriptions[this.subscriptions.length] = dialogRef.afterClosed().subscribe(result => {
				this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

				if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()
			});
		}
	}

	grid_import_from_last_layout() {
		this.current_layout_data[0].layout_json_temp = this.layout_last_data[0].layout_json_temp;
		this.afs.doc('layouts-noad/' + this.current_template_id).set({ layout_json_temp: this.current_layout_data[0].layout_json_temp }, { merge: true }).then((docRef) => {
			this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

			if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
				this.allFunction()
			}
			this.buttonActiveDeactive()

			this._snackBar.open('Last layout imported successfully', '', {
				duration: 1500,
			});
			this.loading = false;
		});
	}

	grid_import_all_products() {
		let img_dialog = '<svg xmlns="http://www.w3.org/2000/svg" class="image-suggetion-icon" width="40" height="40" viewBox=" 0 0 24 24" style="background-color: white;"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z"/></svg>'
		this.loading = true;
		if (this.all_products_list.length > 0) {
			const product_array = [];
			this.all_products_list.forEach((product, index) => {
				product.price_label_svg = product.price_label_svg ? product.price_label_svg : '';
				let content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div></div>';
				if (product.bannerText) {
					product = `<style>
					.banner-text{
						position: absolute;
						top: 50%;
						left: 50%;
  						width: 100%;
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}
					
					@media only screen and (max-width: 576px) {
						.banner-text h1{
							font-size: 20px;
							line-height: 1;
						}
					}
					</style><div id="${product.uid}" upc="${product.upc_code}" class="banner-text-admin hide_bar" style="height: 100%; background-color: ${this.store_data[0].theme_base_color};"><div class="banner-text">${product.bannerText}</div></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 0px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div><img src="imgs/minus.png" id='${product.uid}' class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><img src="imgs/minus.png" id='${product.uid}' class="deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>`;
				} else {
					if (product.hide_price_label === true && product.hide_productname_label === true && product.hide_addtolist_label === true) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === false) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div></div>';
					}
					if (product.hide_addtolist_label === true && product.hide_productname_label === true && product.hide_price_label === false) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === true) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === false && product.hide_productname_label === true && product.hide_price_label === false) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === true && product.hide_productname_label === true && product.hide_price_label === false) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === false && product.hide_productname_label === true && product.hide_price_label === true) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === false && product.hide_productname_label === false && product.hide_price_label === true) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
					if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === true) {
						content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon"  style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
					}
				}
				const product_data = {
					id: product.uid,
					upc_code: product.upc_code,
					w: 1,
					h: 1,
					category: product.category,
					content: content_data
				};
				product_array.push(product_data);
			});

			// this.final_product_array = product_array
			this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: product_array }, { merge: true }).then(docRef => {
				this.grid[0].load(product_array);

				if (product_array.length > 0) {
					this.allFunction()
				}

				this._snackBar.open('All products from your store has been imported to your grid.', '', {
					duration: 1500,
				});
				this.loading = false;
			});
		}

	}

	grid_import_from_csv() {
		this.fileInput.nativeElement.value = ''; // Reset the input element
		this.fileInput.nativeElement.click();
	}

	trigger_upload_image() {
		const element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
		element.click();
	}

	upload2(event, ind) {
		this.matchImage = false
		this.image_uploading = true;
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
		}

		// create a random id
		const randomId = 'digital-menus-' + new Date().getTime() + Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/' + randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task = this.ref.put(event.target.files[0]);

		// this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
				this.subscriptions[this.subscriptions.length] = this.ref.getDownloadURL().subscribe(url => {
					this.subscriptions[this.subscriptions.length] = this.ref.getMetadata().subscribe(res => {
						if (res.contentType.split('/')[1] == 'gif') {
							this.isGIF = true
							this.isVideo = false
						} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
							this.isVideo = true
							this.isGIF = false
							this.product_update_form.patchValue({
								isVideo: true
							})
							this.product_add_form.patchValue({
								isVideo: true
							})
							if (this.isVideo || this.isGIF) {
								this.storeInMaster = true;
							} else {
								this.product_add_form.controls['storeInMaster'].setValue(false);
								this.storeInMaster = false;
							}
						} else {
							if (this.isVideo == 'youtube' ) {
								this.product_update_form.patchValue({
									isVideo: false,
									product_url: ''
								})
								this.isVideo = false
								this.storeInMaster = false;
							}else if (this.isVideo=='facebook') {
								this.product_update_form.patchValue({
									isVideo: "facebook",	
								})
								this.isVideo = "facebook"
								this.storeInMaster = true;
							} else {
								this.isGIF = false
								this.isVideo = false
								this.storeInMaster = false;
							}
						}

					})
					if (this.isVideo) {
						this.storeInMaster = true;
					} else {
						this.product_update_form?.controls['storeInMaster']?.setValue(false);
						this.storeInMaster = false;
					}

					if (ind == '1') {
						this.imageurl1x = url;
						this.product_update_form.patchValue({
							product_image: url,
						});
					}

					if (ind == '2') {
						this.imageurl2 = url;
						this.imageurl2x = url;
						this.product_add_form.patchValue({
							product_image: url,
						});
					}
					this.image_uploading = false;
				});
			})
		).subscribe();
		// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";
	}

	openIconModal(type) {
		this.current_unsplash_type = type;
		this.open(this.iconSearchModal, 'modal-basic-title5');
		// https://api.iconify.design/search?query=dashboard&limit=120
		// https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
	}

	set_current_image(value) {
		this.matchImage = false
		//setting image from unsplash;
		if (this.current_unsplash_type == 'edit') {
			// setting unsplash image when editing a product
			this.imageurl1x = value;
			this.product_update_form.patchValue({
				product_image: value,
			});
		}
		if (this.current_unsplash_type == 'add') {
			// setting unsplash image wheen adding a product
			this.imageurl2 = value;
			this.imageurl2x = value;
			this.add_form_img = value
			this.product_add_form.patchValue({
				product_image: value,
			});
		}
		const element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
		element.click();
	}

	open(content, title) {
		this.modalService.open(content, { windowClass: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	submit_search_icon(value) {
		this.icons = []
		this.totalImage = 0;
		this.subscriptions[this.subscriptions.length] = this.http
			.get(`https://api.unsplash.com/search/photos?orientation=landscape&per_page=${this.unsplash_per_page}&page=${this.unsplash_page}&order_by=relevant&query='${value.query}'&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4`)
			.subscribe((res: any) => {
				this.show_icon_results = true;
				this.icons = res.results
				this.totalImage = res.total
			});
		this.searchLoading = false
	}

	grid_add_new_product() {
		this.storeInMaster = false;
		this.product_add_form.reset({
			hide_price_label: false,
			hide_addtolist_label: false,
			hide_productname_label: false,
			sale_price_label_value1:'',
			sale_price_label_value2:'',
			sale_price_label_value3:'',
			price_label_value1:'',
			price_label_value2:'',
			price_label_value3:'',
            final_price_label_value1:'',
			final_price_label_value2:'',
			final_price_label_value3:'',
			digital_coupon_price_label_value1:'',
			digital_coupon_price_label_value2:'',
			digital_coupon_price_label_value3:'',
			sale_price_label_svg : '',
			final_price_label_svg:'',
			digital_coupon_price_label_svg:'',
			sale_content_price_label_svg:'',
			digital_content_coupon_price_label_svg:'',
			final_content_price_label_svg:'',
		});
		this.product_add_form.reset({
			hide_price_label: false,
			hide_addtolist_label: false,
			hide_productname_label: false,
			price_label_value1:'',
			price_label_value2:'',
			price_label_value3:'',
			sale_price_label_value1:'',
			sale_price_label_value2:'',
			sale_price_label_value3:'',
            final_price_label_value1:'',
			final_price_label_value2:'',
			final_price_label_value3:'',
			digital_coupon_price_label_value1:'',
			digital_coupon_price_label_value2:'',
			digital_coupon_price_label_value3:'',
			sale_price_label_svg : '',
			final_price_label_svg:'',
			digital_coupon_price_label_svg:'',
			sale_content_price_label_svg:'',
			digital_content_coupon_price_label_svg:'',
			final_content_price_label_svg:'',
		})
		this.show_add_product = true;
		this.show_edit_form = false;
		this.current_price_label_format = '';
		this.current_sale_price_label_format = '';
		this.current_final_price_label_format = '';
		this.current_digital_coupon_price_label_format = '';
		this.price_label_svg = '';
		this.sale_price_label_svg = '';
		this.sale_content_price_label_svg=','
		this.final_price_label_svg='';
		this.final_content_price_label_svg='';
		this.digital_coupon_price_label_svg=''
		this.digital_content_coupon_price_label_svg='';
		this.show_digital_coupons=false;
		this.sale_price_label_value1='',
		this.sale_price_label_value2='',
		this.sale_price_label_value3='',
        this.final_price_label_value1='',
		this.final_price_label_value2='',
		this.final_price_label_value3='',
		this.digital_coupon_price_label_value1='',
		this.digital_coupon_price_label_value2='',
		this.digital_coupon_price_label_value3='',
		this.price_label_value1='',
		this.price_label_value2='',
		this.price_label_value3='',
		this.add_form_img = ''
		this.imageurl2x = ''
	}

	convertFacebookWatchLink(link) {
		const regex = /^https:\/\/www\.facebook\.com\/watch\/\?v=(\d+)$/i;
		const match = link.match(regex);
		if (match) {
			const videoId = match[1];
			const canonicalUrl = `https://www.facebook.com/facebook/videos/${videoId}/`;
			return canonicalUrl;
		}else{
			return link
		}
	}
	async product_store(data) {
		data.isBundleProduct=false;
		this.buttonDisabled = true;
		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}
		if (!data.isBanner) {
			data.bannerText = '';
		}
		if (data.upc_code == '' || data.upc_code == null) {
			this._snackBar.open('Error:UPC Code is Required', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.product_description == '' || data.product_description == null) {
			this._snackBar.open('Error:Product description is Required', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		if(data.digital_coupon){
			data.price_label_value1='';
			data.price_label_value2='';
			data.price_label_value3='';
			if(data.sale_price_label_value1=='' && data.sale_price_label_value2=='' && data.sale_price_label_value3==''){
				this._snackBar.open('Error:Sale Price is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			}
			if(data.final_price_label_value1=='' && data.final_price_label_value2=='' && data.final_price_label_value3==''){
				this._snackBar.open('Error:Final Price is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			}
			if(data.digital_coupon_price_label_value1=='' && data.digital_coupon_price_label_value2=='' && data.digital_coupon_price_label_value3==''){
				this._snackBar.open('Error:Digital Coupon is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			}
			if(data.usage_limit=='' || data.usage_limit==null){		
				this._snackBar.open('Error:Frequency Field is Required', '', {
					  duration: 1500,
				  });
				  this.buttonDisabled = false;
				  return false;
			  }
		}
		
		const temp_store = [];
		temp_store.push(data);
		await this.savedListItem();
		const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description).limit(1)).get().toPromise();
		this.master_list = querySnapshot.docs.map((doc: any) => ({
			upc_code: doc.data()['upc_code'],
			product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
			product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
			pack: doc.data()['pack'] ? doc.data()['pack'] : '',
			price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
			price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
			price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
			price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
			price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
			product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
			hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : false,
			hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : false,
			hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : false,
			cost: doc.data()['cost'] ? doc.data()['cost'] : '',
			srp: doc.data()['srp'] ? doc.data()['srp'] : '',
			category: doc.data()['category'] ? doc.data()['category'] : '',
			uid: doc.data()['uid'],
			product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
			price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
			is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
			tag: doc.data()['tag'] ? doc.data()['tag'] : [],
			secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
			upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
			final_price_label_format: doc.data()['final_price_label_format'] ? doc.data()['final_price_label_format'] : '',
			final_price_label_value1: doc.data()['final_price_label_value1'] ? doc.data()['final_price_label_value1'] : '',
			final_price_label_value2: doc.data()['final_price_label_value2'] ? doc.data()['final_price_label_value2'] : '',
			final_price_label_value3: doc.data()['final_price_label_value3'] ? doc.data()['final_price_label_value3'] : '',
			sale_price_label_format: doc.data()['sale_price_label_format'] ? doc.data()['sale_price_label_format'] : '',
			sale_price_label_value1: doc.data()['sale_price_label_value1'] ? doc.data()['sale_price_label_value1'] : '',
			sale_price_label_value2: doc.data()['sale_price_label_value2'] ? doc.data()['sale_price_label_value2'] : '',
			sale_price_label_value3: doc.data()['sale_price_label_value3'] ? doc.data()['sale_price_label_value3'] : '',
			digital_coupon_price_label_format: doc.data()['digital_coupon_price_label_format'] ? doc.data()['digital_coupon_price_label_format'] : '',
			digital_coupon_price_label_value1: doc.data()['digital_coupon_price_label_value1'] ? doc.data()['digital_coupon_price_label_value1'] : '',
			digital_coupon_price_label_value2: doc.data()['digital_coupon_price_label_value2'] ? doc.data()['digital_coupon_price_label_value2'] : '',
			digital_coupon_price_label_value3: doc.data()['digital_coupon_price_label_value3'] ? doc.data()['digital_coupon_price_label_value3'] : '',
			note: doc.data()['note'] ? doc.data()['note'] : '',
			usage_limit: doc.data()['usage_limit'] ? doc.data()['usage_limit'] : '',
			link: doc.data()['link'] ? doc.data()['link'] : '',
			digital_coupon: doc.data()['digital_coupon'] ? doc.data()['digital_coupon'] : false,
			sale_price_label_svg: doc.data()['sale_price_label_svg'] ? doc.data()['sale_price_label_svg'] : '',
			sale_content_price_label_svg: doc.data()['sale_content_price_label_svg'] ? doc.data()['sale_content_price_label_svg'] : '',
			final_price_label_svg: doc.data()['final_price_label_svg'] ? doc.data()['final_price_label_svg'] : '',
			final_content_price_label_svg: doc.data()['final_content_price_label_svg'] ? doc.data()['final_content_price_label_svg'] : '',
			digital_coupon_price_label_svg: doc.data()['digital_coupon_price_label_svg'] ? doc.data()['digital_coupon_price_label_svg'] : '',
			digital_content_coupon_price_label_svg: doc.data()['digital_content_coupon_price_label_svg'] ? doc.data()['digital_content_coupon_price_label_svg'] : '',
			isBundleProduct: doc.data()['isBundleProduct'] ? doc.data()['isBundleProduct'] : false,
		}));
		let master_result = querySnapshot.docs[0]?.data();
		if(master_result){
			data.master_uid=master_result.uid
		}
		if (Array.isArray(this.saved_items_list)) {
		const result = this.saved_items_list?.filter(o1 => temp_store?.some(o2 => o1.upc_code === o2.upc_code));
		const result2 = this.saved_items_list?.filter(o1 => temp_store?.some(o2 => o1.product_description === o2.product_description && o1?.pack === o2?.pack));
		if ((result2?.length > 0)) {
			this._snackBar.open('Error: This Product already exists in your Saved Products!', '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
	}

		data.price_label_value1 = data.price_label_value1 ? data.price_label_value1 : '';
		data.price_label_value2 = data.price_label_value2 ? data.price_label_value2 : '';
		data.price_label_value3 = data.price_label_value3 ? data.price_label_value3 : '';
		data.price_format_highlight = data.price_format_highlight ? data.price_format_highlight : '';
		data.price_label_format = data.price_label_format ? data.price_label_format : '';
		data.price_label_svg = data.price_label_svg ? data.price_label_svg : '';
		data.sale_price_label_value1 = data.sale_price_label_value1 ? data.sale_price_label_value1 : '';
		data.sale_price_label_value2 = data.sale_price_label_value2 ? data.sale_price_label_value2 : '';
		data.sale_price_label_value3 = data.sale_price_label_value3 ? data.sale_price_label_value3 : '';
		data.sale_price_label_format = data.sale_price_label_format ? data.sale_price_label_format : '';
		data.sale_price_label_svg = data.sale_price_label_svg ? data.sale_price_label_svg : '';
		data.sale_content_price_label_svg = data.sale_content_price_label_svg ? data.sale_content_price_label_svg : '';
		data.final_price_label_value1 = data.final_price_label_value1 ? data.final_price_label_value1 : '';
		data.final_price_label_value2 = data.final_price_label_value2 ? data.final_price_label_value2 : '';
		data.final_price_label_value3 = data.final_price_label_value3 ? data.final_price_label_value3 : '';
		data.final_price_label_format = data.final_price_label_format ? data.final_price_label_format : '';
		data.final_price_label_svg = data.final_price_label_svg ? data.final_price_label_svg : '';
		data.final_content_price_label_svg = data.final_content_price_label_svg ? data.final_content_price_label_svg : '';
		data.digital_coupon_price_label_value1 = data.digital_coupon_price_label_value1 ? data.digital_coupon_price_label_value1 : '';
		data.digital_coupon_price_label_value2 = data.digital_coupon_price_label_value2 ? data.digital_coupon_price_label_value2 : '';
		data.digital_coupon_price_label_value3 = data.digital_coupon_price_label_value3 ? data.digital_coupon_price_label_value3 : '';
		data.digital_coupon_price_label_format = data.digital_coupon_price_label_format ? data.digital_coupon_price_label_format : '';
		data.digital_coupon_price_label_svg = data.digital_coupon_price_label_svg ? data.digital_coupon_price_label_svg : '';
		data.digital_content_coupon_price_label_svg = data.digital_content_coupon_price_label_svg ? data.digital_content_coupon_price_label_svg : '';
		data.note = data.note ? data.note : '';
		data.link = data.link ? data.link : '';
		data.usage_limit = data.usage_limit ? data.usage_limit : '';
		data.digital_coupon = data.digital_coupon ? data.digital_coupon : '';
		data.cost = data.cost ? data.cost : '';
		data.srp = data.srp ? data.srp : '';
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		if (data?.product_image) {
			data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
		} else {
			data.product_image = "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726"
			data.secondery_product_image = []
		}
		// if(data.digital_coupon){
		// 	data.pack='';
		// 	data.category='';
		// }
		data.tag = data?.product_description.split(" ")
		data.created_at = new Date();
		data.system_notes = "Created manually using form";
		data.store_uid = this.current_store_uid;
		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				var ytid = data.product_url.match(/[\w\-]{11,}/)[0];
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name ='youtube'
			}
			if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
				data.product_url=await this.convertFacebookWatchLink(data.product_url)
				const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
				const encodedURL = encodeURIComponent(data.product_url);
				
				const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url=data.product_url
				this.domain_name='facebook';
			}
		}

		if (data.storeInMaster) {
			if (master_result) {
				const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(data, { merge: true })
			}else{
		   const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
			   const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
			   data.master_uid = docRef.id
			   itemRefx2.update({ uid: docRef.id });
			   delete data.secondery_product_image
		   })
	   }
   }

		if (!data.isVideo) {
		
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result?.secondery_product_image.push({ 'id': Date.now(), 'url': data.product_image })
					}
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data.secondery_product_image
				})
			}
		}

		const product_data = await this.afs.collection('store-items').add(data).then(async docRef => {
			const itemRefx2 = this.afs.doc('/store-items/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			this.product_add_form.reset({
				hide_price_label: false,
				hide_addtolist_label: false,
				hide_productname_label: false,
			});
			this.imageurl2 = '';
			const data_uid = docRef.id;
			let gifClass = ''
			if (this.isGIF == true) {
				gifClass = ' gifImg '
			}

			let product_data_card = await this.productAddService.generateContentData(data,this.store_data,data_uid,gifClass,this.domain_name);

			if(this.current_layout_data[0]?.layout_json_temp.length > 0){
				this.current_layout_data[0]?.layout_json_temp.push(product_data_card);
			}else{
				this.current_layout_data[0].layout_json_temp = []
				this.current_layout_data[0]?.layout_json_temp.push(product_data_card);
			}

			var currentData:any = await this.currentData();
				if (Array.isArray (currentData?.layout_json_temp)) {
						const layout_data_updatex = this.afs.doc('/layouts-noad/' + this.current_template_id).set({ layout_json_temp: this.current_layout_data[0].layout_json_temp }, { merge: true }).then(docRef => {
					this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);
					if (this.current_layout_data[0]?.layout_json_temp.length > 0) {
						this.allFunction()
					}
				});
			}else if (typeof (currentData.layout_json_temp) == "string") {
				this.componentService.setTempLayout(this.current_layout_data[0].layout_json_temp, currentData.layout_json_temp)
				this.grid[0].load(this.current_layout_data[0]?.layout_json_temp);

				if (currentData?.layout_json_temp.length > 0) {
					this.allFunction()
				}
			}else{
				console.error("temp undefined")
			}
			this.buttonActiveDeactive()
		});

		this.product_add_form.reset({
			hide_price_label: false,
			hide_addtolist_label: false,
			hide_productname_label: false,
		});
		this.show_add_product = false;
		this.current_price_label_format = '';
		this.price_label_svg = '';
		this.add_form_img = '';
		this.imageurl2x = '';
		this.current_sale_price_label_format = '';
		this.current_final_price_label_format = '';
		this.current_digital_coupon_price_label_format = '';
		this.price_label_svg = '';
		this.sale_price_label_svg = '';
		this.sale_content_price_label_svg = '';
		this.final_price_label_svg = '';
		this.final_content_price_label_svg = '';
		this.digital_coupon_price_label_svg = '';
		this.digital_content_coupon_price_label_svg = '';

		this._snackBar.open('Product details added successfully!', '', {
			duration: 1500,
		});
		this.buttonDisabled = false;
	}

	trigger_upload_image_add() {
		const element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
		element.click();
	}

	trigger_import_csv() {
		const element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
		element.click();
	}

	import_csv(files: FileList) {
		this.csvData=[]
		if (files && files.length > 0) {
			const file: File = files.item(0);
			const reader: FileReader = new FileReader();
			const fileid = new Date().getTime();      // setting temporary name so that we can know what was the source of the upload
			reader.readAsText(file, 'UTF-8');
			reader.onload = (e) => {
				let filestring: string = reader.result as string;

				Papa.parse(filestring, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
						// Process the parsed CSV data here
						if (result.data && result.data.length > 0) {
							this.importcsvdata = result.data.map(row1 => {
                                const row: any = {};
								Object.keys(row1).forEach(key => {
                                    const trimmedKey = key?.trim();
									const value = row1[key];
									const trimmedValue = typeof value === 'string' ? value.trim() : value;
									row[trimmedKey] = trimmedValue;           
                                });
								if (row.Description && row.Description.trim()) {
									const tag = row.Description.trim().split(" ")
									let product_name_slug = row.Description.trim().replace(`'`, '');

									let product_upload_data = {
										upc_code: row.UPC,
										product_description: row.Description.trim(),
										pack: row.Pack,
										srp: row.SRP,
										category: row.Category,
										upload_file_id: fileid,
										product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
										product_name_slug: product_name_slug,
										price_label_format: row.PriceLabelFormat ? row.PriceLabelFormat : "",
										price_label_svg: '',
										price_label_value1: '',
										price_label_value2: '',
										price_label_value3: '',
										price_label_highlight: row.PriceLabelHighlight ? row.PriceLabelHighlight : "",
										product_url: row.ProductUrl ? row.ProductUrl : "",
										hide_price_label: row.HidePriceLable ? row.HidePriceLable : false,
										hide_addtolist_label: row.HideAddtolistLabel ? row.HideAddtolistLabel : false,
										hide_productname_label: row.HideProductnameLabel ? row.HideProductnameLabel : false,
										cost: row.Cost ? row.Cost : "",
										store_uid: this.current_store_uid,
										system_notes: "Uploaded using csv from saved-items",
										new_window: row.NewWindow ? row.NewWindow : "",
										autoplay: row.AutoPlay ? row.AutoPlay : "",
										secondery_product_image: [],
										tag: tag ? tag : [],
										isVideo: row.isVidoe ? row.isVidoe : false,
										digital_coupon :false,
										isBundleProduct:false
									};
									// Your specific logic for handling the "cols[3]" data
									if (row.SRP) {
										const srpString = row.SRP;
										const srpValue = parseFloat(srpString).toString();

										if (srpString.includes('/off')) {
											product_upload_data.price_label_format = "format7";
											const parts = srpValue.split(".");
											if (parts[0] == '0') {
												product_upload_data.price_label_value2 = parts[1];
											} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
											}
										} else if (srpString.includes('Buy') && srpString.includes('Get')) {
											product_upload_data.price_label_format = "format6";
											const buyGetParts = srpString.split(' ');
											const buyIndex = buyGetParts.indexOf('Buy');
											const getIndex = buyGetParts.indexOf('Get');

											if (buyIndex !== -1 && getIndex !== -1 && getIndex - buyIndex === 2) {
												const buyValue = parseInt(buyGetParts[buyIndex + 1]);
												const getValue = parseInt(buyGetParts[getIndex + 1]);

												if (!isNaN(buyValue) && !isNaN(getValue)) {
													product_upload_data.price_label_value1 = buyValue.toString();
													product_upload_data.price_label_value2 = getValue.toString();

													const forIndex = buyGetParts.indexOf('For');
													if (forIndex !== -1 && forIndex + 1 < buyGetParts.length) {
														const forValue = parseFloat(buyGetParts[forIndex + 1]);
														if (!isNaN(forValue)) {
															product_upload_data.price_label_value3 = forValue.toString();
														}
													}
												}
											}
										} else if (srpString.includes('each') || srpString.includes('pound')) {
											product_upload_data.price_label_format = "format4";
											const parts = srpValue.split(".");
											if (parts[0] == '0') {
												product_upload_data.price_label_value2 = parts[1];
											} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
											}
										} else if (srpString.includes('/lb')) {
											product_upload_data.price_label_format = "format3";
											const parts = srpValue.split(".");
											if (parts[0] == '0') {
												product_upload_data.price_label_value2 = parts[1];
											} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
											}
										} else if (srpString.includes('/') || srpString.includes('for')) {
											product_upload_data.price_label_format = "format5";
											const separator = srpString.includes('/') ? '/' : 'for';
											const parts = srpString.split(separator);
											product_upload_data.price_label_value1 = parts[0];
											if (parts[1].includes('.')) {
												const parts2 = parts[1].split('.');
												product_upload_data.price_label_value2 = parts2[0];
												product_upload_data.price_label_value3 = parts2[1];
											} else {
												product_upload_data.price_label_value2 = parts[1];
											}
										} else if (srpString.includes('.')) {
											product_upload_data.price_label_format = "format2";
											const parts = srpValue.split(".");
											if (parts[0] == '0') {
												product_upload_data.price_label_value2 = parts[1];
											} else {
											product_upload_data.price_label_value1 = parts[0];
											if (parts.length > 1) {
												product_upload_data.price_label_value2 = parts[1];
											}
											}
										} else {
											product_upload_data.price_label_value1 = srpString;
											product_upload_data.price_label_format = "format1";
										}
									}
									this.getCSVpricelabel(product_upload_data);
									product_upload_data.price_label_svg=this.price_label_svg;	
									return product_upload_data;
								}
							});
							this.importcsvdata = this.importcsvdata.filter(data => data !== null && data !== undefined);
							let temp_des = []
							const layoutTemp = this.current_layout_data[0]?.layout_json_temp;
							if (Array.isArray(layoutTemp)) {
								layoutTemp?.forEach(i => temp_des.push(i.content));
							} else {
								console.error("Layout JSON temp is not an array:", layoutTemp);
							}
							if (Array.isArray(layoutTemp)) {
							var currentProductTitles = layoutTemp?.map(item => {
								const titleMatch = item?.content?.match(/<div class="product-title".*?<\/div>/s); 
								const pack = item?.pack
								let title
								if (titleMatch) {
									title = this.componentService.extractTextFromHTML(titleMatch[0]);
								} else {
									title = null;
								}
								return {
									pack: pack,
									title: title
								};
							});
						}
							const csv_Data = this.importcsvdata;
							this.csvData =this.importcsvdata;
							this.csvProductDescriptions = csv_Data?.map(item => ({
								product_description: item?.product_description,
								pack: item?.pack
							}));
							this.matchedProducts = currentProductTitles?.filter(currentProduct => 
								this.csvProductDescriptions?.some(csvProduct => {
									const titleMatch = currentProduct?.title?.trim().toUpperCase() === csvProduct?.product_description?.trim().toUpperCase();
									const packMatch = csvProduct?.pack?.trim().toUpperCase() === currentProduct?.pack?.trim().toUpperCase();
									return titleMatch && packMatch;
								})
							);							
							
							this.unmatchedProducts = this.csvProductDescriptions?.filter(csvProduct => 
								!currentProductTitles?.some(title => {
									const titleMatch = title?.title?.trim().toUpperCase() === csvProduct?.product_description?.trim().toUpperCase();
									const packMatch = csvProduct?.pack?.trim().toUpperCase() === title?.pack?.trim().toUpperCase();					
									return titleMatch && packMatch;
								})
							);							
							const filterArray = [];
							csv_Data?.forEach(item => {
								this.unmatchedProducts?.forEach(description => {
									if (item?.product_description === description?.product_description?.trim()&& item?.pack === description?.pack?.trim()) {
										filterArray.push(item);
									}
								});
							});
							this.importcsvdata = filterArray;
						}
					},
				});
				// Filter out duplicates based on product_description
				this.importcsvdata = this.importcsvdata?.filter((obj, index, array) =>
					array.findIndex(item => item.product_description === obj.product_description && item?.pack ===obj?.pack) === index
				);
				const result = this.saved_items_list?.filter(o1 => this.importcsvdata.some(o2 => o1.upc_code === o2.upc_code));
				this.duplicates_temp = result;
				if (this.unmatchedProducts?.length == 0 &&this.csvData?.length>0) {
					this._snackBar.open("Selected CSV Products are already present in layout!", '', {
						duration: 1500,
					});
					
				} else {
					if (this.importcsvdata.length == 0) {
						this._snackBar.open("No products to import.", '', {
							duration: 1500,
						});
					}
					if (this.importcsvdata.length > 0) {
						this.checkCSVDataIntoList(); 
					}
				}
			}
		}
	}

	async getMasterProduct(productName) {
		const index = this.searchClient.initIndex(environment.algolia.indexName);
		let master_result
		let finalMasterList = []
		let product_description = productName
		console.log(product_description, "product_description")
		do {
			const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', product_description).where('product_image', '!=', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726').limit(1)).get().toPromise();
            console.log(querySnapshot.docs[0]?.data(), "querySnapshot.docs[0]?.data()")
			if (querySnapshot.docs[0]?.data()) {
				master_result = querySnapshot.docs[0]?.data();
				finalMasterList.push(master_result);
				console.log(finalMasterList,"masterList")
			} else {
				console.log("algoliablock")
				var data;
				data = await index.search(`"${product_description}"`, {
					filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
					hitsPerPage: 1000
				});

				if (!Array.isArray(data.hits) || data.hits.length === 0) {
					data = await index.search(product_description, {
						filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
						hitsPerPage: 1000
					});
				}

				const product = data.hits;

				if (product.length > 0) {
					finalMasterList.push(...product);
				}
			}

			product_description = product_description.replace(/^\S*\s/, '');
			console.log(product_description, "product_description")
		} while (product_description.split(' ').length > 1);

		// Remove duplicate objects based on product_description
		const uniqueFinalMasterList = finalMasterList.filter((product, index, self) =>
			index === self.findIndex((p) => p.product_description === product.product_description)
		);
		this.master_item_product = uniqueFinalMasterList.filter((item) => item.uid !== this.selected_master_product.uid);
	}

	async imageAPI(val) {
		this.submitted = true;
		this.CXH_skip = 0;
		if (this.searchBox.valid) {
			this.searchLoading = true
			if (val.radioBox == 'unsplash') {
				this.submit_search_icon(val)
			} else {
				this.cxh_image(val)
			}
			if (val.query != this.selectedItem) {
				this.master_item_product = []

				try {
					await this.getMasterProduct(val.query);
				} catch (error) {
					console.error('Error fetching Algolia images:', error);
				} finally {
					this.searchLoading = false;
				}
			}
			try {
				for (const image1 of this.master_item_product) {
					this.dynamicElements1 = [];
					if (image1?.secondery_product_image?.length>0) {
						for (const image of image1?.secondery_product_image) {
							const id1 = image?.id;
							const imageUrl1 = image?.url;
							if (this.isYouTubeThumbnail(imageUrl1)) {
								this.dynamicElements1.push({
									id: id1,
									url: imageUrl1,
									mp4_Webm: false,
									isVideo: 'youtube'
								});
							} else if (imageUrl1.includes("firebasestorage.googleapis.com")) {
								const fileRef1 = this.storage.refFromURL(imageUrl1);
								const metadata1 = await fileRef1.getMetadata().toPromise();
								const contentType1 = metadata1.contentType;
								if (contentType1 === 'video/mp4' || contentType1 === 'video/webm') {
									this.dynamicElements1.push({
										id: id1,
										url: imageUrl1,
										mp4_Webm: true,
										isVideo: true
									});
								} else {
									this.dynamicElements1.push({
										id: id1,
										url: imageUrl1,
										mp4_Webm: false,
										isVideo: false
									});
								}
							} else {
								this.dynamicElements1.push({
									id: id1,
									url: imageUrl1,
									mp4_Webm: false,
									isVideo: ""
								});
							}
						}
					}
					image1.secondery_product_image = this.dynamicElements1;
				}
			} catch (error) {
				console.error('Error fetching file metadata:', error);
			}
		}
	}

	async next_prev_imageAPI(val) {
		this.submitted = true;
		if (this.searchBox.valid) {
			this.searchLoading = true
			if (val.radioBox == 'unsplash') {
				this.submit_search_icon(val)
			} else {
				this.cxh_image(val)
			}
		}
	}
	isYouTubeThumbnail(url) {
		return url.includes('i1.ytimg.com/vi/') && url.includes('/sddefault.jpg');
	}
	closeDialog(modal: any) {
		this.openImageDialogByGrid = false;  // Set your variable to false
		modal.dismiss('Cross click'); // Close the modal
	  }
	async openImgDialog(name, uid) {
		this.dynamicElements = [];
		this.loading = true;
		this.selectedItem = name
		if (name && uid) {
			const nameTrim = name.trim()
			this.searchBox.reset({
				query: nameTrim,
				radioBox: 'cxh'
			})
			this.searchLoading = false
			this.master_item_product = []
			this.CXH_image = []
			this.icons = []
			this.submitted = false
			const split_name = nameTrim.includes(" ") ? nameTrim.split(" ") : [nameTrim]

			try {
				const master_item_id: any = await (await this.afs.collection('master-items').ref.where('product_description', '==', name).get()).docs[0].data()
				this.selected_master_product = master_item_id
				if (this.selected_master_product) {
					this.selected_master_product1 = true
				}
				try {
					if (this.selected_master_product?.secondery_product_image?.length>0) {
						for (const image of this.selected_master_product?.secondery_product_image) {
							const id = image?.id;
							const imageUrl = image?.url;
							if (this.isYouTubeThumbnail(imageUrl)) {
								this.dynamicElements.push({
									id: id,
									url: imageUrl,
									mp4_Webm: false,
									isVideo: 'youtube'
								});
							} else if (imageUrl.includes("firebasestorage.googleapis.com")) {

								const fileRef = this.storage.refFromURL(imageUrl);

								const metadata = await fileRef.getMetadata().toPromise();

								const contentType = metadata.contentType;

								if (contentType === 'video/mp4' || contentType === 'video/webm') {
									this.dynamicElements.push({
										id: id,
										url: imageUrl,
										mp4_Webm: true,
										isVideo: true
									});
								} else {
									this.dynamicElements.push({
										id: id,
										url: imageUrl,
										mp4_Webm: false,
										isVideo: ""
									});
								}
							} else {
								this.dynamicElements.push({
									id: id,
									url: imageUrl,
									mp4_Webm: false,
									isVideo: ""
								});
							}
						}
					}
				} catch (error) {
					console.error('Error fetching file metadata:', error);
				}


				this.selected_master_product_uid = master_item_id.uid
				await this.getMasterProduct(this.selected_master_product.product_description);
				console.log(this.master_item_product, "this.master_item_product")
			} catch (e) {
				console.log(e)
				const store_item_id: any = await (await this.afs.collection('store-items').ref.where('product_description', '==', name).get()).docs[0].data()
				console.log(store_item_id, "store_item_id")
				if (store_item_id == undefined) {
					this._snackBar.open("The product is not found in database.", '', {
						duration: 1500,
					});
					return false;
				} else {
					this.master_item_product = [];
					this.master_item_product.push(store_item_id)
					this.selected_master_product_uid = store_item_id.uid;
				}
			}

			this.selected_product_name = name
			this.selected_product_uid = uid
			this.loading = false;
			this.imageAPI(this.searchBox.value)
			this.open(this.myDialog, "image_dialog");
		}
		try {
			for (const image1 of this.master_item_product) {
				this.dynamicElements1 = [];
				if (image1?.secondery_product_image?.length>0) {
					for (const image of image1?.secondery_product_image) {
						const id1 = image?.id;
						const imageUrl1 = image?.url;
						if (this.isYouTubeThumbnail(imageUrl1)) {
							this.dynamicElements1.push({
								id: id1,
								url: imageUrl1,
								mp4_Webm: false,
								isVideo: 'youtube'
							});
						} else if (imageUrl1.includes("firebasestorage.googleapis.com")) {
							const fileRef1 = this.storage.refFromURL(imageUrl1);
							const metadata1 = await fileRef1.getMetadata().toPromise();
							const contentType1 = metadata1.contentType;
							if (contentType1 === 'video/mp4' || contentType1 === 'video/webm') {
								this.dynamicElements1.push({
									id: id1,
									url: imageUrl1,
									mp4_Webm: true,
									isVideo: true
								});
							} else {
								this.dynamicElements1.push({
									id: id1,
									url: imageUrl1,
									mp4_Webm: false,
									isVideo: false
								});
							}
						} else {
							this.dynamicElements1.push({
								id: id1,
								url: imageUrl1,
								mp4_Webm: false,
								isVideo: ""
							});
						}
					}
				}
				image1.secondery_product_image = this.dynamicElements1;
			}
		} catch (error) {
			console.error('Error fetching file metadata:', error);
		}

	}

	nextPage(val) {
		if (val == 'cxh') {
			this.CXH_skip += this.CXH_take
		} else {
			this.unsplash_page += 1
		}
		this.next_prev_imageAPI(this.searchBox.value)
	}

	prevPage(val) {
		if (val == 'cxh') {
			this.CXH_skip -= this.CXH_take
		} else {
			this.unsplash_page -= 1
		}
		this.next_prev_imageAPI(this.searchBox.value)
	}

	get searchFormControl() {
		return this.searchBox.controls;
	}

	async cxh_image(val) {
		this.CXH_image = []
		this.totalImage = 0;
		if (val.query == "" || val.query == undefined) {
			this.searchLoading = false
			return false;
		} else {
			var APItoken = localStorage.getItem('CHX_token')
			const headerDict = {
				'Authorization': 'EN ' + APItoken,
			}
			const bodyData =
			{
				"OrderBy": "26834672-7c90-4918-9b19-5bd419023b12",
				"Desc": false,
				"AttributeFilterOperator": "And",
				"SearchStringAttributes": [
					"6d030ff8-72ce-4f42-ba53-023f55c53a20",
					"bda64a3f-507c-49de-883c-fa832c6539e7",
					"0994d0f8-35e7-4a6d-9cd9-2ae97cd8b993",
					"54f9b50d-671d-4895-8f02-cd757999ec9e",
					"2bce6533-87c7-4646-a3b7-d5efb49bf927",
					"cd250fb8-fb00-4f9d-813a-dea949605715",
					"29d438d9-64f2-4623-8f4e-09f9368e93bb"
				],
				"SearchString": `${val.query}`,
				"DataOwner": "07725f7b-db7a-4a89-9ac6-5429a9a51638",
				"PreferredProducts": null,
				"OnHold": false,
				"UserId": "f5a7a747-e05f-4e5d-bc45-2d004c10e26e",
				"Language": "en-US"
			};

			const requestOptions = {
				headers: new HttpHeaders(headerDict),
			};
			let result: any
			try {
				result = await this.http
					.post(`https://api.syndigo.com/api/marketplace/search?skip=${this.CXH_skip}&take=${this.CXH_take}&companyId=07725f7b-db7a-4a89-9ac6-5429a9a51638`, bodyData, requestOptions)
					.toPromise();
				for (let i = 0; i < result.Results.length; i++) {
					if (result?.Results[i]?.Components[0]?.Assets['en-US']?.filter(x => x.AttributeId == "645296e8-a910-43c3-803c-b51d3f1d4a89")[0]?.Value) {
						this.CXH_image.push(result?.Results[i]?.Components[0]?.Assets['en-US']?.filter(x => x.AttributeId == "645296e8-a910-43c3-803c-b51d3f1d4a89")[0].Value)
					} else {
						this.CXH_image.push('default_image_placeholder');
					}
				}
				this.totalImage = result.TotalHitCount;
			} catch (error) {
				if (error.status === 401) {
					try {
						await this.componentService.renewToken();
						this.cxh_image(val)
					} catch (error) {
						console.error("Token renewal failed:", error);
					}
				} else {
					console.error("API error:", error);
				}
			}
			this.searchLoading = false
		}
	}
	getDefaultImageSrc(item) {
		if (item === 'default_image_placeholder') {
			return './assets/imgs/default_cxh.png';
		} else {
			return `https://assets.edgenet.com/${item}?fileType=png&size=418&thumbnail=true&palette=RGB`;
		}
	}
	async handleImageClick(item, val) {
		if (item === 'default_image_placeholder') {
			console.log('Default image clicked!');
		} else {
			if (val) {
				const data: any = await this.afs.collection('master-items').doc(this.selected_master_product.uid)
				data.update({
					product_image: `https://assets.edgenet.com/${item}?fileType=png&thumbnail=true`
				});
				this.imageurl1 = `https://assets.edgenet.com/${item}?fileType=png&thumbnail=true`
				this.selected_master_product.product_image = `https://assets.edgenet.com/${item}?fileType=png&thumbnail=true`
			}
			this.setImage({ url: `https://assets.edgenet.com/${item}?fileType=png&thumbnail=true`, id: item });
		}
	}

	async setImage(item) {
		this.matchImage = false
		this.popup_loading = true
		const data: any = await this.afs.collection('master-items').doc(this.selected_master_product_uid)
		try {
			const item_data: any = (await data.get().toPromise()).data()
			let updateData:{ product_image: any; isVideo?: any }
			if ((this.current_user.role == 'admin' || this.current_user.role == 'admin-manager') || (item_data.product_image == "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726")) {
				if(item_data?.secondery_product_image.length>0  || item.url){
					updateData  = { product_image: item.url };
				   }else{
					updateData= { product_image: item.product_image };
				   }				
				if (item.isVideo !== undefined) {
					updateData.isVideo = item.isVideo;
				}
				if (!item.isVideo) {
					updateData.isVideo = '';
				}
				data.update(updateData, { merge: true }).then((data) => {
				}).catch(err => {
					console.error("err", err);
				});
			}
			const isObjectpresent = await item_data.secondery_product_image.find((x) => (x.id == item.id))
			let obj_item
			if (!isObjectpresent) {
				if(item_data?.secondery_product_image.length>0 || item.url){
					obj_item = { id: item.id, url: item.url }
					updateData  = { product_image: item.url };
				  }else{
						obj_item = { id:  Date.now(), url: item.product_image }
						updateData= { product_image: item.product_image };
					  }	
					  data.update({
						secondery_product_image: firebase.firestore.FieldValue.arrayUnion(obj_item)
					});
				}	
			if (item.isVideo !== undefined) {
				updateData.isVideo = item.isVideo;
			}
			if (!item.isVideo) {
				updateData.isVideo = '';
			}
			await this.afs.collection('store-items').doc(this.selected_product_uid).set(updateData, { merge: true }).then((data) => {
			}).catch(err => {
				console.error("err", err);
			});
		
		const singleLayout: any = await this.afs.collection('layouts-noad').doc(this.current_template_id).get().toPromise()
		var temp_layout_data
		if (Array.isArray(singleLayout.data()?.layout_json_temp)) {
			temp_layout_data = singleLayout.data().layout_json_temp
		}else if (typeof (singleLayout.data()?.layout_json_temp) == "string") {
			const layoutRef = await this.afs.doc('/layout-json-temp/' + singleLayout.data()?.layout_json_temp);
			try {
				const doc: any = await layoutRef.get().toPromise();
				const layout_json_temp = doc.data() || {};
				temp_layout_data = layout_json_temp.layout_json_temp;
			} catch (error) {
				console.error(error)
			}
		}else{
			console.error("temp undefined")
		}
		temp_layout_data = temp_layout_data.map((x) => {
			if (x.id == this.selected_product_uid) {
				if(x.product_image){
					if(item_data?.secondery_product_image.length>0 || item.url){
					x.product_image=item.url
					}else{
						x.product_image=item.product_image
					}
				}
				const temp = document.createElement('div');
				temp.innerHTML = x.content;
				const img_ele: any = temp.querySelector('img, video');
				if (img_ele) {
					const img_id = img_ele.id;
					const img_upc = img_ele.getAttribute('upc');
					img_ele.upc = img_upc;
					img_ele.id = img_id;
				}
				let content_data = '';
				if(item_data?.secondery_product_image.length>0 || item.url){
					if (item.isVideo == true) {
						content_data = '<div class="fill_video"><video playsinline id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + item.url + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: 100%;object-fit:cover;object-position: top;" autoplay muted loop></video></div>';
					} else {
						content_data = '<img id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + item.url + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">';
						}
				}else{
					if (item.isVideo == true) {
						content_data = '<div class="fill_video"><video playsinline id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + item.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: 100%;object-fit:cover;object-position: top;" autoplay muted loop></video></div>';
					} else {
						content_data = '<img id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + item.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">';
					}
	
				}
				img_ele.parentNode.removeChild(img_ele);
				temp.innerHTML = content_data + temp.innerHTML;
				x.content = temp.innerHTML
				return x
			} else {
				return x
			}
		})
		if (Array.isArray (singleLayout.data()?.layout_json_temp)) {
					this.afs.collection('layouts-noad').doc(this.current_template_id).set({ layout_json_temp: temp_layout_data }, { merge: true }).then(async (data) => {
				this.grid[0].load(temp_layout_data);
	
				if (temp_layout_data.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()
				let element: HTMLElement = document.getElementsByClassName('close_image_model')[0] as HTMLElement;
				element?.click();
				this._snackBar.open("Product Image set successfully.", '', {
					duration: 1500,
				});
				this.popup_loading = false
			}).catch(err => {
				console.error("err", err);
			});
		}else if (typeof (singleLayout.data()?.layout_json_temp) == "string") {
			
			this.afs.collection('layout-json-temp').doc(singleLayout.data()?.layout_json_temp).set({ layout_json_temp: temp_layout_data }, { merge: true }).then(async (data)=>{
				this.grid[0].load(temp_layout_data);

				if (temp_layout_data.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()
				let element: HTMLElement = document.getElementsByClassName('close_image_model')[0] as HTMLElement;
				element?.click();
				this._snackBar.open("Product Image set successfully.", '', {
					duration: 1500,
				});
				this.popup_loading = false
			}).catch(err => {
				console.error("err", err);
			});
		}else{
			console.error("temp undefined")
		}
		if(!this.openImageDialogByGrid){
			if(item_data?.secondery_product_image.length>0 || item?.url){
			this.imageurl1x=item?.url
			}else{
				this.imageurl1x=item?.product_image
			}
		 }else{
			this.show_edit_form=false;
			this.show_add_product=false;
		 }
	   this.openImageDialogByGrid=false;
      }catch (error) {
      	console.error("Error:", error);
      	// Handle error or show appropriate message to the user
      } 	
	}

	async addDataToCollection(collectionName, data) {
		const listRef = (await this.afs.collection(`/${collectionName}`).add(data))
		const listId = (await listRef.get()).id
		const updatedList = await listRef.update({ uid: listId })
		const colData = await listRef.get()
		return colData.data()
	}

	async addToLayout(data) {
		this.number--
		let img_dialog = '<svg xmlns="http://www.w3.org/2000/svg" class="image-suggetion-icon" width="40" height="40" viewBox=" 0 0 24 24" style="background-color: white;"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z"/></svg>'
		let gifClass = ''
		let product_url=''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube-nocookie.com" || domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				var ytid = data.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
				ytid = ytid.replace('/embed/', '');
				// data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name='youtube'
			}		
		    if (domain?.hostname == "www.facebook.com") {
		    	data.product_url=await this.convertFacebookWatchLink(data?.product_url)
		    	const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
		    	const encodedURL = encodeURIComponent(data?.product_url);
		    	const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
		    	data.product_url=data?.product_url
				this.domain_name='facebook'
		    }
	}
		let content_data
		if (data.isVideo==true) {
			content_data = `<div class="fill_video"><video playsinline class=\'${ gifClass}\'  id='${data.uid}' upc=\'${data.upc_code}\' src="${data.product_image}&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: 100%;object-fit:cover;object-position: top;" autoplay muted loop></video></div><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;"> ${data.product_description}</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog('${data.product_name_slug}','${data.uid}')" > ${img_dialog}</div><div><img src="imgs/minus.png" id='${data.uid}'  class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">${data.price_label_svg}</div></div>`;
		} else {
			content_data = `<img id='${data.uid}' upc=\'${data.upc_code}\' src="${data.product_image}&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;"> ${data.product_description}</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog('${data.product_name_slug}','${data.uid}')" > ${img_dialog} </div><div><img src="imgs/minus.png" id='${data.uid}' class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">${data.price_label_svg}</div></div>`;
		}
		let product_data_card
		if(data.digital_coupon){
		 product_data_card = {
			id: data.uid,
			upc_code: data.upc_code,
			w: 1,
			h: 1,
			category: data.category ? data.category : '',
			content: content_data,
			youtubeURL: data.product_url ? data.product_url : '',
			domain_name:this.domain_name ? this.domain_name : '',
			product_image: data.product_image ? data.product_image : '',
			product_description: data.product_description ? data.product_description : '',
			isBanner: data.isBanner ? data.isBanner : '',
			bannerText: data.bannerText ? data.bannerText : '',
			openNewTab: data.new_window ? data.new_window : '',
			isVideo: data.isVideo ? data.isVideo : false,
			autoplay: data.autoplay ? data.autoplay : false,
			loopvideo: data.loopvideo ? data.loopvideo : false,
			sale_price_label_value1: data.sale_price_label_value1 ? data.sale_price_label_value1 : '',
			sale_price_label_value2: data.sale_price_label_value2 ? data.sale_price_label_value2 : '',
			sale_price_label_value3: data.sale_price_label_value3 ? data.sale_price_label_value3 : '',
			sale_price_label_format: data.sale_price_label_format ? data.sale_price_label_format : '',
			sale_price_label_svg: data.sale_price_label_svg ? data.sale_price_label_svg : '',
			sale_content_price_label_svg: data.sale_content_price_label_svg ? data.sale_content_price_label_svg : '',
			final_content_price_label_svg: data.final_content_price_label_svg ? data.final_content_price_label_svg : '',
			digital_content_coupon_price_label_svg: data.digital_content_coupon_price_label_svg ? data.digital_content_coupon_price_label_svg : '',
			digital_coupon: data.digital_coupon ? data.digital_coupon : false,
			note: data.note ? data.note : '',
			usage_limit: data.usage_limit ? data.usage_limit : '',
			link: data.link ? data.link : '',
			pack:data.pack ? data.pack :'',
			isBundleProduct: data.isBundleProduct ? data.isBundleProduct : false
		};
	}else{
		product_data_card = {
			id: data.uid,
			upc_code: data.upc_code,
			w: 1,
			h: 1,
			category: data.category ? data.category : '',
			content: content_data,
			youtubeURL: data.product_url ? data.product_url : '',
			domain_name:this.domain_name ? this.domain_name : '',
			product_image: data.product_image ? data.product_image : '',
			product_description: data.product_description ? data.product_description : '',
			isBanner: data.isBanner ? data.isBanner : '',
			bannerText: data.bannerText ? data.bannerText : '',
			openNewTab: data.new_window ? data.new_window : '',
			isVideo: data.isVideo ? data.isVideo : false,
			autoplay: data.autoplay ? data.autoplay : false,
			loopvideo: data.loopvideo ? data.loopvideo : false,
			price_label_value1: data.price_label_value1 ? data.price_label_value1 : '',
			price_label_value2: data.price_label_value2 ? data.price_label_value2 : '',
			price_label_value3: data.price_label_value3 ? data.price_label_value3 : '',
			price_label_format: data.price_label_format ? data.price_label_format : '',
			price_label_svg: data.price_label_svg ? data.price_label_svg : '',
			digital_coupon: data.digital_coupon ? data.digital_coupon : false,
			pack:data.pack ? data.pack :'',
			isBundleProduct: data.isBundleProduct ? data.isBundleProduct : false
			}
		}

		this.csv_data_new.push(product_data_card);
		// CSV DATA HAS BEEN UPLOADED AND NOW HERE DATA TO LAYOUT IS PASSED
	}

	async getCSVpricelabel(data) {
		// circular price label
		if (data.price_label_format != '') {
			switch (data.price_label_format) {
				case "format1":
					return this.price_label_svg= '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
				case "format2":
					if (data.price_label_value1) {
					return this.price_label_svg= '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
					} else {
					return this.price_label_svg= '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>';
					}
				case "format3":
					if (data.price_label_value1) {
					return this.price_label_svg= '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
					} else {
					return this.price_label_svg= '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
					}
				case "format4":
				   if (data.price_label_value1) {
                   return this.price_label_svg= '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
				   } else {
				   return this.price_label_svg= '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
				   }				
				case "format5":
					if (data.price_label_value2) {
						return this.price_label_svg= '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + data.price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
					}				
				case "format6":
					if (data.price_label_value3 == '') {
						return this.price_label_svg= '<div id="format6" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="third-amount"> Free</span></div></div></div></div></div></div></div>';
					} else {
						return this.price_label_svg= '<div id="format6" class="circular-card-deal-wrapper extraSmall withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div></div></div>';
					}
				case "format7":
					if (data.price_label_value1) {
					  return this.price_label_svg= '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
					} else {
					 return this.price_label_svg= '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
					}			
				default:
					return this.price_label_svg= '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
			}
		} else {
			return this.price_label_svg= ''
		}
	}

	async upc_search(search_value) {
		if (search_value == "" || search_value == undefined) {
			return false;
		}
		var APItoken = localStorage.getItem('CHX_token')
		const headerDict = {
			'Authorization': 'EN ' + APItoken,
		}
		const bodyData =
		{
			"OrderBy": "26834672-7c90-4918-9b19-5bd419023b12",
			"Desc": false,
			"AttributeFilterOperator": "And",
			"IdentifierAttribute": [
				"575ad9c5-3da2-472e-ada0-85d227d2cbaf"
			],
			"Identifiers": [
				search_value
			],
			"DataOwner": "07725f7b-db7a-4a89-9ac6-5429a9a51638",
			"PreferredProducts": null,
			"OnHold": false,
			"UserId": "f5a7a747-e05f-4e5d-bc45-2d004c10e26e",
			"Language": "en-US"
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		let result: any
		try {
			result = await this.http
				.post('https://api.syndigo.com/api/marketplace/search?skip=0&take=100&companyId=07725f7b-db7a-4a89-9ac6-5429a9a51638',
					bodyData, requestOptions).toPromise()
			return result?.Results[0]?.Components[0]?.Assets['en-US']?.filter(x => x.AttributeId == "645296e8-a910-43c3-803c-b51d3f1d4a89");
		} catch (error) {
			if (error.status === 401) {
				try {
					await this.componentService.renewToken();
					this.upc_search(search_value)
				} catch (error) {
					console.error("Token renewal failed:", error);
				}
			} else {
				console.error("API error:", error);
			}
		}
	}

	async checkCSVDataIntoList() {
		await this.savedListItem()
		this.loading = true
		this.csv_data_new = [];
		let promiseArr = []
		this.number = this.importcsvdata.length
		for (const value of this.importcsvdata) {
			await promiseArr.push(
			await	new Promise(async (resolve, reject) => {
					const index = this.searchClient.initIndex(environment.algolia.indexName);
					let store_result = this.saved_items_list.find(o1 => o1.product_description == value.product_description && o1.store_uid == value.store_uid  && o1.pack == value.pack);
					let master_result
					let isFirstIteration = true;
					let product_description = value.product_description
					if (this.componentService.countWords(value.product_description) > 1) {
						while (!master_result && this.componentService.countWords(product_description) > 1) {
							const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', product_description).limit(1)).get().toPromise();
							if (querySnapshot.docs[0]?.data()) {
								this.master_list = await querySnapshot.docs.map((doc: any) => ({
									upc_code: doc.data()['upc_code'],
									product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
									product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
									pack: doc.data()['pack'] ? doc.data()['pack'] : '',
									price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
									price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
									price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
									price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
									price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
									product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
									hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : false,
									hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : false,
									hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : false,
									cost: doc.data()['cost'] ? doc.data()['cost'] : '',
									srp: doc.data()['srp'] ? doc.data()['srp'] : '',
									category: doc.data()['category'] ? doc.data()['category'] : '',
									uid: doc.data()['uid'],
									product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
									price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
									is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
									tag: doc.data()['tag'] ? doc.data()['tag'] : [],
									secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
									upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
									isVideo: doc.data()['isVideo'] ? doc.data()['isVideo'] : false,
									storeInMaster: doc.data().storeInMaster ? doc.data().storeInMaster : '',
									digital_coupon :false,
			                        isBundleProduct: doc.data()['isBundleProduct'] ? doc.data()['isBundleProduct'] : false,
								}));
								master_result = await querySnapshot.docs[0]?.data();
								if (value.product_description != product_description) {
									value.product_image = master_result.product_image;
									value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									const master_colData: any = await this.addDataToCollection('master-items', value);
								}
								isFirstIteration = false;
							} else {
								var data
								data = await index.search(`"${product_description}"`, {
									filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
									hitsPerPage: 1000
								});

								if (!Array.isArray(data.hits) || data.hits.length === 0) {
									data = await index.search(product_description, {
										filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
										hitsPerPage: 1000
									});
								}

								const product = data.hits
								if (product.length > 0) {
									master_result = product[0]
									value.product_image = master_result.product_image;
									value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									const master_colData: any = await this.addDataToCollection('master-items', value);
								}
							}
							product_description = product_description.replace(/^\S*\s/, '');
						}
					} else {
						const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', value.product_description).limit(1)).get().toPromise();
						if (querySnapshot.docs[0]?.data()) {
							this.master_list = await querySnapshot.docs.map((doc: any) => ({
								upc_code: doc.data()['upc_code'],
								product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
								product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
								pack: doc.data()['pack'] ? doc.data()['pack'] : '',
								price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
								price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
								price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
								price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
								price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
								product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
								hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : false,
								hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : false,
								hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : false,
								cost: doc.data()['cost'] ? doc.data()['cost'] : '',
								srp: doc.data()['srp'] ? doc.data()['srp'] : '',
								category: doc.data()['category'] ? doc.data()['category'] : '',
								uid: doc.data()['uid'],
								product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
								price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
								is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
								tag: doc.data()['tag'] ? doc.data()['tag'] : [],
								secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
								upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
								isVideo: doc.data()['isVideo'] ? doc.data()['isVideo'] : false,
								storeInMaster: doc.data().storeInMaster ? doc.data().storeInMaster : '',
								digital_coupon :false,
			                    isBundleProduct: doc.data()['isBundleProduct'] ? doc.data()['isBundleProduct'] : false,
						}));
							master_result = await querySnapshot.docs[0]?.data();
						} else {
							var data
							data = await index.search(`"${product_description}"`, {
								filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
								hitsPerPage: 1000
							});
							const product = data.hits
							// Add a new property 'numberOfWords' to each product
							const productsWithWordCount = product
								.filter(product => this.componentService.countWords(product.product_description) < 3)
								.map(product => ({
									...product,
									numberOfWords: this.componentService.countWords(product.product_description),
								}));

							const sortedProducts = productsWithWordCount.sort((a, b) => a.numberOfWords - b.numberOfWords);
							if (sortedProducts.length > 0) {
								master_result = sortedProducts[0];
								value.product_image = master_result.product_image;
								value.secondery_product_image.push({ 'id': Date.now(), 'url': master_result.product_image });
								value.isVideo = master_result.isVideo ? master_result.isVideo : false
								const master_colData: any = await this.addDataToCollection('master-items', value);
							}
						}
					}
					value.created_at = new Date()
					value.fromCSV = true;
					let temp;
					if (!store_result) {
						if (!master_result) {
							if (value.upc_code > 9) {
								temp = await this.upc_search(value.upc_code)
								if (temp) {
									value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`
								}
							}
							if(value.product_image != "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726"){
									value.secondery_product_image.push({ 'id': Date.now(), 'url': value.product_image });
							}
							const master_colData: any = await this.addDataToCollection('master-items', value)
							const temp_master_data = { ...master_colData }
							temp_master_data.master_uid = master_colData.uid;
							delete temp_master_data.secondery_product_image
							temp_master_data.fromCSV = true;
							const colData = await this.addDataToCollection('store-items', temp_master_data)
							await this.addToLayout(colData)
						} else {
							master_result.store_uid = this.current_store_uid
							master_result.created_at = new Date()
							if (master_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = master_result.product_image
								value.isVideo = master_result.isVideo ? master_result.isVideo : false
								value.storeInMaster = master_result.storeInMaster ? master_result.storeInMaster : ''
								value.product_url = master_result.product_url? master_result.product_url :""
							} else {
								if (value.upc_code > 9) {
									temp = await this.upc_search(value.upc_code)
									if (temp) {
										value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										master_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`
										master_result?.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
										const master_colData: any = this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
									}
								}
							}
							delete value.secondery_product_image
							value.master_uid = master_result.uid?master_result.uid :''
							const colData = await this.addDataToCollection('store-items', value)
							await this.addToLayout(colData)
						}
					} else {
						if (!master_result) {
							delete store_result.store_uid
							store_result.created_at = new Date()
							if (store_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = store_result.product_image
								value.secondery_product_image.push({ 'id': Date.now(), 'url': store_result.product_image })
								value.isVideo = store_result?.isVideo ? store_result.isVideo : false
								value.storeInMaster = store_result.storeInMaster ? store_result.storeInMaster : ''
								value.product_url = store_result.product_url? store_result.product_url :""
							} else {
								if (value.upc_code > 9) {
									temp = await this.upc_search(value.upc_code)
									if (temp) {
										value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										value.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
										store_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
										const store_colData: any = this.afs.doc('/store-items/' + store_result.uid).set(store_result, { merge: true })
									}
								}
							}
							const colData: any = await this.addDataToCollection('master-items', value)
							await this.addToLayout(store_result)
						} else {
							if (store_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
								value.product_image = store_result.product_image
								value.isVideo = store_result?.isVideo ? store_result.isVideo : false
								value.storeInMaster = store_result.storeInMaster ? store_result.storeInMaster : ''
								value.product_url = store_result.product_url? store_result.product_url :""
							} else {
								if (master_result.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
									value.product_image = master_result.product_image
									value.isVideo = master_result.isVideo ? master_result.isVideo : false
									value.storeInMaster = master_result.storeInMaster ? master_result.storeInMaster : ''
								    value.product_url = master_result.product_url? master_result.product_url :""
									}else{
									if (value.upc_code > 9) {
										temp = await this.upc_search(value.upc_code)
										if (temp) {
											value.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
											value.secondery_product_image.push({ 'id': temp[0].Value, 'url': `https://assets.edgenet.com/${temp[0].Value}?fileType=png` })
											store_result.product_image = `https://assets.edgenet.com/${temp[0].Value}?fileType=png`;
											const store_colData: any = this.afs.doc('/store-items/' + store_result.uid).set(store_result, { merge: true })
										}
									}
								}
							}
							delete value.secondery_product_image
							value.master_uid = master_result.uid?master_result.uid :''
							const store_colData: any =await this.afs.doc('/store-items/' + store_result.uid).set(value, { merge: true })
							this.getDataAndUseIt(store_result.uid).then(async updatedData => {
								await this.addToLayout(updatedData);
							});
						}
					}
					resolve("Done!!")
				})
			)
		}

		Promise.all(promiseArr).then(async () => {
			if(!this.current_template_id){
				try{
					let start_date = new Date();
					start_date = new Date(start_date.getTime() + (86400000 * 30))
					let end_date = new Date(start_date.getTime() + 86400000);
					start_date.setHours(0, 0, 0, 0);
					end_date.setHours(23, 59, 59, 999);
					const include_header_checkbox = 0;

					const layout_store_data =
					{
						store_uid: this.current_store_uid,
						start_date,
						end_date,
						header_text: '',
						include_header_checkbox,
						is_publish: 0,
						layout_json: [],
						layout_type: this.layout_type ? this.layout_type : 'grid',
						page1_type: '',
						page2_type: '',
						page3_type: '',
						page4_type: '',
						page5_type: '',
						page1_url: '',
						page2_url: '',
						page3_url: '',
						page4_url: '',
						page5_url: '',
						layout_json_temp: '',
						layout_json_saved: '',
						terms: '',
						is_circular: true,
						last_save_timestamp: new Date().getTime(),
						last_publish_timestamp: new Date().getTime(),
						updated_at: new Date()
					};

					if (!this.componentService.upcomingLayoutCreated) {
						this.componentService.upcomingLayoutCreated = true;
						let layout_json_temp = { layout_json_temp: layout_store_data.layout_json_temp }
						const layout_data_temp = await this.afs.collection('/layout-json-temp').add(layout_json_temp);
						const docRef_temp = await layout_data_temp.get();
						const itemRefx2_temp = this.afs.doc('/layout-json-temp/' + docRef_temp.id);
						await itemRefx2_temp.update({ uid: docRef_temp.id });
						layout_store_data.layout_json_temp = docRef_temp.id

						let layout_json_saved = { layout_json_saved: layout_store_data.layout_json_saved }
						let layout_data_saved = await this.afs.collection('/layout-json-saved').add(layout_json_saved);
						const docRef_saved = await layout_data_saved.get();
						const itemRefx2_saved = this.afs.doc('/layout-json-saved/' + docRef_saved.id);
						await itemRefx2_saved.update({ uid: docRef_saved.id });
						layout_store_data.layout_json_saved = docRef_saved.id

						const layout_data_new = await this.afs.collection('/layouts-noad').add(layout_store_data)
						const docRef = await layout_data_new.get();
						const itemRefx2 = this.afs.doc('/layouts/' + docRef.id);
						itemRefx2.update({ uid: docRef.id });
						await itemRefx2.update({ uid: docRef.id });
						this.current_template_id = docRef.id;
						this.layout_form.patchValue({
							uid: docRef.id
						});
						await this.getLayoutData();
						itemRefx2.update({}).then(() => {
							this.componentService.noAdLayoutCreated = false;
						});
					}
				} catch (error) {
					console.error('Error updating Firebase document:', error);
				}	
			}
			const layoutRef = this.afs.doc('/layouts-noad/' + this.current_template_id);

			try {
				// Fetch the current data from Firebase
				const doc: any = await layoutRef.get().toPromise();
				const currentData = doc.data() || {}; // Initialize with an empty object if the document doesn't exist

				if (Array.isArray(currentData.layout_json_temp)) {
					// Append the new data to the existing array
					currentData.layout_json_temp = currentData.layout_json_temp.concat(this.csv_data_new);
					// Update the document in Firebase with the merged data
					await layoutRef.update({ layout_json_temp: currentData.layout_json_temp });
				}else if (typeof (currentData?.layout_json_temp) == "string") {
					const tempID = currentData.layout_json_temp
					const currentDataTemp = await this.componentService.getTempLayout(currentData.layout_json_temp)
					if (!Array.isArray(currentDataTemp.layout_json_temp)) {
						currentDataTemp.layout_json_temp = [];
					}
					currentData.layout_json_temp = currentDataTemp.layout_json_temp.concat(this.csv_data_new);
					await this.componentService.setTempLayout(currentData.layout_json_temp, tempID)
				} else {
					// if (!Array.isArray(currentData.layout_json_temp)) {
					// 	currentData.layout_json_temp = [];
					// }
					console.error("temp undefined")
				}
				
				
				this.grid[0].load(currentData.layout_json_temp);

				if (currentData.layout_json_temp.length > 0) {
					this.allFunction()
				}
				this.buttonActiveDeactive()

				this.loading = false;

				if (this.matchedProducts?.length > 0) {
					this._snackBar.open(`${this.matchedProducts?.length} products already present in layout. ${this.unmatchedProducts?.length} products imported successfully!`, '', {
						duration: 2000,
					});
				}else{
					this._snackBar.open("Products imported successfully!", '', {
						duration: 2000,
					});
				}
				this.number = 0;
				this.importcsvdata = [];
				if (this.csv_data_new.length != 0) {
					this.componentService.setProductArray(this.csv_data_new);
					this.current_layout_data[0].layout_json_temp = this.csv_data_new
				}
			} catch (error) {
				console.error('Error updating Firebase document:', error);
			}
		});
	}

	getDataAndUseIt(uid: string): Promise<any> {
		return this.afs.doc('/store-items/' + uid).get().toPromise().then(docSnapshot => {
			if (docSnapshot.exists) {
				return docSnapshot.data();
			} else {
				return null; // You can return a default value or handle the case where the document doesn't exist
			}
		});
	}

	onLoad() {
		this.loading = false
		this.popup_loading = false
	}

	image_url_change(event, id) {
		if (id == '1') { this.imageurl1 = event.target.value; }
		if (id == '2') { this.imageurl2 = event.target.value; }
		if (id == '3') { this.imageurl3 = event.target.value; }
		if (id == '4') { this.imageurl4 = event.target.value; }
		if (id == '5') { this.imageurl5 = event.target.value; }
	}

	validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	}


	toggle_change(event) {
		let override_checked = event.target.checked;
		const itemRefx = this.afs.doc('/stores/' + this.current_store_uid).set({ override_noad_layout: override_checked }, { merge: true }).then(docRef => { });
		if (override_checked == true) {
			this._snackBar.open("No Ad Layout override enabled now!", "", {
				duration: 1500,
			});
		} else {
			this._snackBar.open("No Ad Layout override disabled now!", "", {
				duration: 1500,
			});
		}

	}

	async autoComplete(value) {
		const index = this.searchClient.initIndex(environment.algolia.indexName);
		try {
			var data
			data = await index.search(value, {
				hitsPerPage: 10,
				page: 0,
				filters: `NOT product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
				attributesToRetrieve: ['product_description', 'product_image', 'upc_code','isVideo','storeInMaster'],
			});
			this.matchingItems = data.hits
		}
		catch (error) {
			console.error('Error searching Algolia:', error);
		}
	}

	selectItem(item) {
		if(item.isVideo){
			this.isVideo=true;
	    }
		if(item.storeInMaster){
			this.storeInMaster=true;
		}else{
			this.storeInMaster=false;
		}
		this.imageurl2 = item.product_image;
		this.add_form_img = item.product_image
		this.imageurl2x = item.product_image
		this.product_add_form.patchValue({
			product_image: item.product_image,
			product_description: item.product_description,
			upc_code: item.upc_code,
			isVideo:item.isVideo,
			storeInMaster:this.storeInMaster
		});
		this.matchingItems = undefined
		this.matchImage = true
	}

	async resizeGrid(val, isCategoryUpdate = false) {
		this.loading=true;
		try {
			await this.componentService.resizeGrid(val, this.current_template_id, isCategoryUpdate, 'layouts-noad')
			let currentData:any = await this.currentData();
			if (typeof (currentData.layout_json_temp) == 'string') {
				const currentDataTemp = await this.componentService.getTempLayout(currentData.layout_json_temp)
				this.current_layout_data[0].layout_json_temp = currentDataTemp.layout_json_temp
			}
			this.grid[0].load(this.current_layout_data[0].layout_json_temp);

			this.grid[0].compact()

			if (this.current_layout_data[0].layout_json_temp.length > 0) {
				this.allFunction()
			}
			this.buttonActiveDeactive()
		} catch (error) {
			console.error("Error fetching/updating document:", error);
		}
	}

	openRestoreModel() {
		this.open(this.RestoreLayoutModal, 'RestoreLayoutModal');
	}

	// async restoreLayout() {
	// 	const layoutRef = this.afs.doc('/layouts-noad/' + this.current_template_id);
	// 	const doc = await layoutRef.get().toPromise();
	// 	const currentData: any = doc.data();

	// 	// Update the document in Firebase with the merged data
	// 	if (currentData.layout_json_backup && currentData.layout_json_backup.length > 0) {
	// 		await layoutRef.update({
	// 			layout_json_temp: currentData.layout_json_backup
	// 		});
	// 		//Update the Gridstack
	// 		this.grid[0].load(currentData.layout_json_backup);

	// 		if (currentData.layout_json_backup.length > 0) {
	// 			this.allFunction()
	// 		}
	// 	}
	// 	const element: HTMLElement = document.getElementsByClassName('close_restore_layout_modal')[0] as HTMLElement;
	// 	element.click();
	// }

	async updateFormData(id) {
		this.show_add_product = false
		if (id == 'hideForm') {
			this.show_edit_form = false
		} else {
			var currentData: any = await this.currentData();
			if (currentData?.layout_json_temp) {
				let currentDataTemp
	             if (Array.isArray (currentData?.layout_json_temp)) {
					currentDataTemp = currentData
		       }else if (typeof (currentData?.layout_json_temp) == "string") {
			        currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
				}else{
					console.error("temp undefined")
				}
				this.current_select_product_index = currentDataTemp?.layout_json_temp.findIndex(obj => obj.id == id);
				this.current_select_product_data = currentDataTemp?.layout_json_temp.find(obj => obj.id == id);
				this.current_select_product_id = id
			}
			if (this.current_select_product_data) {
				this.subscriptions[this.subscriptions.length] = this.afs.collection('/store-items', ref => ref.where('uid', '==', id)).snapshotChanges().subscribe(data => {
					if (!data.length) {
						this.subscriptions[this.subscriptions.length] = this.afs.collection('/master-items', ref => ref.where('uid', '==', id)).snapshotChanges().subscribe(masterData => {
							this.current_selected_product_data_full = masterData.map((e: any) => {
								return {
									upc_code: e.payload.doc.data().upc_code ? e.payload.doc.data().upc_code : '',
									product_description: e.payload.doc.data().product_description ? e.payload.doc.data().product_description : '',
									product_name_slug: e.payload.doc.data().product_name_slug ? e.payload.doc.data().product_name_slug : '',
									pack: e.payload.doc.data().pack ? e.payload.doc.data().pack : '',
									price_label_format: this.getFormate(this.current_select_product_data?.content),
									price_label_value1: this.getPrice(this.current_select_product_data?.content, 'lable1') ? this.getPrice(this.current_select_product_data?.content, 'lable1') : '',
									price_label_value2: this.getPrice(this.current_select_product_data?.content, 'lable2') ? this.getPrice(this.current_select_product_data?.content, 'lable2') : '',
									price_label_value3: this.getPrice(this.current_select_product_data?.content, 'lable3') ? this.getPrice(this.current_select_product_data?.content, 'lable3') : '',
									price_label_svg: this.getSVG(this.current_select_product_data?.content),
									price_label_highlight: e.payload.doc.data().price_label_highlight ? e.payload.doc.data().price_label_highlight : '',
									product_url: e.payload.doc.data().product_url ? e.payload.doc.data().product_url : '',
									new_window: e.payload.doc.data().new_window ? e.payload.doc.data().new_window : '',
									autoplay: e.payload.doc.data().autoplay ? e.payload.doc.data().autoplay : false,
									loopvideo: e.payload.doc.data().loopvideo ? e.payload.doc.data().loopvideo : false,
									isBanner: e.payload.doc.data().isBanner ? e.payload.doc.data().isBanner : false,
									bannerText: e.payload.doc.data().bannerText ? e.payload.doc.data().bannerText : false,
									hide_price_label: e.payload.doc.data().hide_price_label ? e.payload.doc.data().hide_price_label : false,
									hide_addtolist_label: e.payload.doc.data().hide_addtolist_label ? e.payload.doc.data().hide_addtolist_label : false,
									hide_productname_label: e.payload.doc.data().hide_productname_label ? e.payload.doc.data().hide_productname_label : false,
									cost: e.payload.doc.data().cost ? e.payload.doc.data().cost : '',
									srp: e.payload.doc.data().srp ? e.payload.doc.data().srp : '',
									category: e.payload.doc.data().category ? e.payload.doc.data().category : '',
									offerLabel: e.payload.doc.data().offerLabel ? e.payload.doc.data().offerLabel : '',
									labelSide: e.payload.doc.data().labelSide ? e.payload.doc.data().labelSide : '',
									storeInMaster: e.payload.doc.data().storeInMaster ? e.payload.doc.data().storeInMaster : '',
									uid: e.payload.doc.id,
									master_uid: e.payload.doc.data().master_uid ? e.payload.doc.data().master_uid : '',
									product_image: this.getImage(this.current_select_product_data?.content, e.payload.doc.id) ? this.getImage(this.current_select_product_data?.content, e.payload.doc.id) : 'https://via.placeholder.com/30/cecece/cecece',
									is_saved: e.payload.doc.data().is_saved ? e.payload.doc.data().is_saved : false,
									isVideo: e.payload.doc.data().isVideo ? e.payload.doc.data().isVideo : '',
									digital_coupon: e.payload.doc.data().digital_coupon ? e.payload.doc.data().digital_coupon : false,
									sale_content_price_label_svg: e.payload.doc.data().sale_content_price_label_svg?e.payload.doc.data().sale_content_price_label_svg:'',
									sale_price_label_format: e.payload.doc.data().sale_price_label_format ?e.payload.doc.data().sale_price_label_format:'',
									sale_price_label_value1: e.payload.doc.data().sale_price_label_value1 ? e.payload.doc.data().sale_price_label_value1: '',
									sale_price_label_value2: e.payload.doc.data().sale_price_label_value2? e.payload.doc.data().sale_price_label_value2: '',
									sale_price_label_value3: e.payload.doc.data().sale_price_label_value3 ? e.payload.doc.data().sale_price_label_value3: '',
									sale_price_label_svg: e.payload.doc.data().sale_price_label_svg?e.payload.doc.data().sale_price_label_svg:'',
									final_price_label_format: e.payload.doc.data().final_price_label_format ?e.payload.doc.data().final_price_label_format:'',
									final_price_label_value1: e.payload.doc.data().final_price_label_value1 ? e.payload.doc.data().final_price_label_value1: '',
									final_price_label_value2: e.payload.doc.data().final_price_label_value2? e.payload.doc.data().final_price_label_value2: '',
									final_price_label_value3: e.payload.doc.data().final_price_label_value3 ? e.payload.doc.data().final_price_label_value3: '',
									final_price_label_svg: e.payload.doc.data().final_price_label_svg ?e.payload.doc.data().final_price_label_svg :'',
									final_content_price_label_svg: e.payload.doc.data().final_content_price_label_svg ?e.payload.doc.data().final_content_price_label_svg :'',
									digital_coupon_price_label_format: e.payload.doc.data().digital_coupon_price_label_format ?e.payload.doc.data().digital_coupon_price_label_format:'',
									digital_coupon_price_label_value1: e.payload.doc.data().digital_coupon_price_label_value1 ? e.payload.doc.data().digital_coupon_price_label_value1: '',
									digital_coupon_price_label_value2: e.payload.doc.data().digital_coupon_price_label_value2? e.payload.doc.data().digital_coupon_price_label_value2: '',
									digital_coupon_price_label_value3: e.payload.doc.data().digital_coupon_price_label_value3 ? e.payload.doc.data().digital_coupon_price_label_value3: '',
									digital_coupon_price_label_svg: e.payload.doc.data().digital_coupon_price_label_svg ?e.payload.doc.data().digital_coupon_price_label_svg:'',
									digital_content_coupon_price_label_svg: e.payload.doc.data().digital_content_coupon_price_label_svg ?e.payload.doc.data().digital_content_coupon_price_label_svg:'',
									note: e.payload.doc.data().note ?e.payload.doc.data().note:'',
									link: e.payload.doc.data().link ?e.payload.doc.data().link:'',
									usage_limit: e.payload.doc.data().usage_limit ?e.payload.doc.data().usage_limit:'',
									isBundleProduct: e.payload.doc.data().isBundleProduct ?e.payload.doc.data().isBundleProduct:false,
									// created_at          : e.payload.doc.data()['created_at'],
								};
							});
						});
					} else {
						this.current_selected_product_data_full = data.map((e: any) => {
							return {
								upc_code: e.payload.doc.data().upc_code ? e.payload.doc.data().upc_code : '',
								product_description: e.payload.doc.data().product_description ? e.payload.doc.data().product_description : '',
								product_name_slug: e.payload.doc.data().product_name_slug ? e.payload.doc.data().product_name_slug : '',
								pack: e.payload.doc.data().pack ? e.payload.doc.data().pack : '',
								price_label_format: this.getFormate(this.current_select_product_data?.content),
								price_label_value1: this.getPrice(this.current_select_product_data?.content, 'lable1') ? this.getPrice(this.current_select_product_data?.content, 'lable1') : '',
								price_label_value2: this.getPrice(this.current_select_product_data?.content, 'lable2') ? this.getPrice(this.current_select_product_data?.content, 'lable2') : '',
								price_label_value3: this.getPrice(this.current_select_product_data?.content, 'lable3') ? this.getPrice(this.current_select_product_data?.content, 'lable3') : '',
								price_label_svg: this.getSVG(this.current_select_product_data?.content),
								price_label_highlight: e.payload.doc.data().price_label_highlight ? e.payload.doc.data().price_label_highlight : '',
								product_url: e.payload.doc.data().product_url ? e.payload.doc.data().product_url : '',
								new_window: e.payload.doc.data().new_window ? e.payload.doc.data().new_window : '',
								autoplay: e.payload.doc.data().autoplay ? e.payload.doc.data().autoplay : false,
								loopvideo: e.payload.doc.data().loopvideo ? e.payload.doc.data().loopvideo : false,
								isBanner: e.payload.doc.data().isBanner ? e.payload.doc.data().isBanner : false,
								bannerText: e.payload.doc.data().bannerText ? e.payload.doc.data().bannerText : false,
								hide_price_label: e.payload.doc.data().hide_price_label ? e.payload.doc.data().hide_price_label : false,
								hide_addtolist_label: e.payload.doc.data().hide_addtolist_label ? e.payload.doc.data().hide_addtolist_label : false,
								hide_productname_label: e.payload.doc.data().hide_productname_label ? e.payload.doc.data().hide_productname_label : false,
								cost: e.payload.doc.data().cost ? e.payload.doc.data().cost : '',
								srp: e.payload.doc.data().srp ? e.payload.doc.data().srp : '',
								category: e.payload.doc.data().category ? e.payload.doc.data().category : '',
								offerLabel: e.payload.doc.data().offerLabel ? e.payload.doc.data().offerLabel : '',
								labelSide: e.payload.doc.data().labelSide ? e.payload.doc.data().labelSide : '',
								storeInMaster: e.payload.doc.data().storeInMaster ? e.payload.doc.data().storeInMaster : '',
								uid: e.payload.doc.id,
								master_uid: e.payload.doc.data().master_uid ? e.payload.doc.data().master_uid : '',
								product_image: this.getImage(this.current_select_product_data?.content, e.payload.doc.id) ? this.getImage(this.current_select_product_data?.content, e.payload.doc.id) : 'https://via.placeholder.com/30/cecece/cecece',
								is_saved: e.payload.doc.data().is_saved ? e.payload.doc.data().is_saved : false,
								isVideo: e.payload.doc.data().isVideo ? e.payload.doc.data().isVideo : '',
								digital_coupon: e.payload.doc.data().digital_coupon ? e.payload.doc.data().digital_coupon : false,
									sale_content_price_label_svg: e.payload.doc.data().sale_content_price_label_svg?e.payload.doc.data().sale_content_price_label_svg:'',
									sale_price_label_format: e.payload.doc.data().sale_price_label_format ?e.payload.doc.data().sale_price_label_format:'',
									sale_price_label_value1: e.payload.doc.data().sale_price_label_value1 ? e.payload.doc.data().sale_price_label_value1: '',
									sale_price_label_value2: e.payload.doc.data().sale_price_label_value2? e.payload.doc.data().sale_price_label_value2: '',
									sale_price_label_value3: e.payload.doc.data().sale_price_label_value3 ? e.payload.doc.data().sale_price_label_value3: '',
									sale_price_label_svg: e.payload.doc.data().sale_price_label_svg?e.payload.doc.data().sale_price_label_svg:'',
									final_price_label_format: e.payload.doc.data().final_price_label_format ?e.payload.doc.data().final_price_label_format:'',
									final_price_label_value1: e.payload.doc.data().final_price_label_value1 ? e.payload.doc.data().final_price_label_value1: '',
									final_price_label_value2: e.payload.doc.data().final_price_label_value2? e.payload.doc.data().final_price_label_value2: '',
									final_price_label_value3: e.payload.doc.data().final_price_label_value3 ? e.payload.doc.data().final_price_label_value3: '',
									final_price_label_svg: e.payload.doc.data().final_price_label_svg ?e.payload.doc.data().final_price_label_svg :'',
									final_content_price_label_svg: e.payload.doc.data().final_content_price_label_svg ?e.payload.doc.data().final_content_price_label_svg :'',
									digital_coupon_price_label_format: e.payload.doc.data().digital_coupon_price_label_format ?e.payload.doc.data().digital_coupon_price_label_format:'',
									digital_coupon_price_label_value1: e.payload.doc.data().digital_coupon_price_label_value1 ? e.payload.doc.data().digital_coupon_price_label_value1: '',
									digital_coupon_price_label_value2: e.payload.doc.data().digital_coupon_price_label_value2? e.payload.doc.data().digital_coupon_price_label_value2: '',
									digital_coupon_price_label_value3: e.payload.doc.data().digital_coupon_price_label_value3 ? e.payload.doc.data().digital_coupon_price_label_value3: '',
									digital_coupon_price_label_svg: e.payload.doc.data().digital_coupon_price_label_svg ?e.payload.doc.data().digital_coupon_price_label_svg:'',
									digital_content_coupon_price_label_svg: e.payload.doc.data().digital_content_coupon_price_label_svg ?e.payload.doc.data().digital_content_coupon_price_label_svg:'',
									note: e.payload.doc.data().note ?e.payload.doc.data().note:'',
									link: e.payload.doc.data().link ?e.payload.doc.data().link:'',
									usage_limit: e.payload.doc.data().usage_limit ?e.payload.doc.data().usage_limit:'',
								// created_at          : e.payload.doc.data()['created_at'],
							};
						});
					}
					if(this.current_selected_product_data_full[0].digital_coupon ==true){
						this.show_digital_coupons=true;
						}else{
						this.show_digital_coupons=false;
						}
					if (this.current_selected_product_data_full[0]?.isVideo) {
						this.storeInMaster = true;
					} else {
						this.storeInMaster = false;
					}
					this.imageurl1x = this.current_selected_product_data_full[0]?.product_image ? this.current_selected_product_data_full[0]?.product_image : '';
					this.current_price_label_format = this.current_selected_product_data_full[0]?.price_label_format;
					this.price_label_svg = this.current_selected_product_data_full[0]?.price_label_svg;
					this.price_label_value1 = this.current_selected_product_data_full[0]?.price_label_value1 ? this.current_selected_product_data_full[0]?.price_label_value1 : '';
					this.price_label_value2 = this.current_selected_product_data_full[0]?.price_label_value2 ? this.current_selected_product_data_full[0]?.price_label_value2 : '';
					this.price_label_value3 = this.current_selected_product_data_full[0]?.price_label_value3 ? this.current_selected_product_data_full[0]?.price_label_value3 : '';
					this.add_editorContent = this.current_selected_product_data_full[0]?.bannerText ? this.current_selected_product_data_full[0]?.bannerText : false;
					this.isVideo = this.current_selected_product_data_full[0]?.isVideo ? this.current_selected_product_data_full[0]?.isVideo : '';
					this.selectedLabelSide = this.current_selected_product_data_full[0]?.labelSide ? this.current_selected_product_data_full[0]?.labelSide : '',
					this.sale_price_label_value1= this.current_selected_product_data_full[0]?.sale_price_label_value1 ? this.current_selected_product_data_full[0]?.sale_price_label_value1 : '',
					this.sale_price_label_value2 =this.current_selected_product_data_full[0]?.sale_price_label_value2 ? this.current_selected_product_data_full[0]?.sale_price_label_value2 : '',
					this.sale_price_label_value3 = this.current_selected_product_data_full[0]?.sale_price_label_value3 ? this.current_selected_product_data_full[0]?.sale_price_label_value3 : '',
					this.sale_price_label_svg = this.current_selected_product_data_full[0]?.sale_price_label_svg ? this.current_selected_product_data_full[0]?.sale_price_label_svg : '',
					this.sale_content_price_label_svg = this.current_selected_product_data_full[0]?.sale_content_price_label_svg ? this.current_selected_product_data_full[0]?.sale_content_price_label_svg : '',
					this.final_price_label_value1= this.current_selected_product_data_full[0]?.final_price_label_value1 ? this.current_selected_product_data_full[0]?.final_price_label_value1 : '',
					this.final_price_label_value2= this.current_selected_product_data_full[0]?.final_price_label_value2 ? this.current_selected_product_data_full[0]?.final_price_label_value2 : '',
					this.final_price_label_value3= this.current_selected_product_data_full[0]?.final_price_label_value3 ? this.current_selected_product_data_full[0]?.final_price_label_value3 : '',
					this.final_price_label_svg= this.current_selected_product_data_full[0]?.final_price_label_svg ? this.current_selected_product_data_full[0]?.final_price_label_svg : '',
					this.final_content_price_label_svg = this.current_selected_product_data_full[0]?.final_content_price_label_svg ? this.current_selected_product_data_full[0]?.final_content_price_label_svg : '',
					this.digital_coupon_price_label_value1= this.current_selected_product_data_full[0]?.digital_coupon_price_label_value1 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value1 : '',
					this.digital_coupon_price_label_value2= this.current_selected_product_data_full[0]?.digital_coupon_price_label_value2 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value2 : '',
					this.digital_coupon_price_label_value3= this.current_selected_product_data_full[0]?.digital_coupon_price_label_value3 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value3 : '',
					this.digital_coupon_price_label_svg= this.current_selected_product_data_full[0]?.digital_coupon_price_label_svg ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_svg : '',
					this.digital_coupon= this.current_selected_product_data_full[0]?.digital_coupon ? this.current_selected_product_data_full[0]?.digital_coupon : false,
					this.digital_content_coupon_price_label_svg = this.current_selected_product_data_full[0]?.digital_content_coupon_price_label_svg ? this.current_selected_product_data_full[0]?.digital_content_coupon_price_label_svg : '',
					this.current_sale_price_label_format = this.current_selected_product_data_full[0]?.sale_price_label_format ?this.current_selected_product_data_full[0]?.sale_price_label_format:'';
					this.current_final_price_label_format = this.current_selected_product_data_full[0]?.final_price_label_format ?this.current_selected_product_data_full[0]?.final_price_label_format :'';
					this.note =this.current_selected_product_data_full[0]?.note ?this.current_selected_product_data_full[0]?.note:'',
					this.link =this.current_selected_product_data_full[0]?.link ?this.current_selected_product_data_full[0]?.link:''
					this.usage_limit =this.current_selected_product_data_full[0]?.usage_limit ?this.current_selected_product_data_full[0]?.usage_limit:''
					this.current_digital_coupon_price_label_format = this.current_selected_product_data_full[0]?.digital_coupon_price_label_format ?this.current_selected_product_data_full[0]?.digital_coupon_price_label_format:'';
					this.product_update_form.patchValue({
						uid: this.current_selected_product_data_full[0]?.uid,
						master_uid: this.current_selected_product_data_full[0]?.master_uid,
						product_image: this.current_selected_product_data_full[0]?.product_image,
						upc_code: this.current_selected_product_data_full[0]?.upc_code,
						product_name_slug: this.current_selected_product_data_full[0]?.product_name_slug,
						product_description: this.current_selected_product_data_full[0]?.product_description,
						pack: this.current_selected_product_data_full[0]?.pack,
						price_label_format: this.current_selected_product_data_full[0]?.price_label_format ?this.current_selected_product_data_full[0]?.price_label_format:'',
						category: this.current_selected_product_data_full[0]?.category,
						product_url: this.current_selected_product_data_full[0]?.product_url.split('?')[0],
						new_window: this.current_selected_product_data_full[0]?.new_window ? this.current_selected_product_data_full[0]?.new_window : false,
						autoplay: this.current_selected_product_data_full[0]?.autoplay ? this.current_selected_product_data_full[0]?.autoplay : false,
						loopvideo: this.current_selected_product_data_full[0]?.loopvideo ? this.current_selected_product_data_full[0]?.loopvideo : false,
						isBanner: this.current_selected_product_data_full[0]?.isBanner ? this.current_selected_product_data_full[0]?.isBanner : false,
						bannerText: this.current_selected_product_data_full[0]?.bannerText ? this.current_selected_product_data_full[0]?.bannerText : '',
						storeInMaster: this.current_selected_product_data_full[0]?.storeInMaster ? this.current_selected_product_data_full[0]?.storeInMaster : false,
						offerLabel: this.current_selected_product_data_full[0]?.offerLabel ? this.current_selected_product_data_full[0]?.offerLabel : '',
						labelSide: this.current_selected_product_data_full[0]?.labelSide ? this.current_selected_product_data_full[0]?.labelSide : '',
						hide_price_label: this.current_selected_product_data_full[0]?.hide_price_label ? this.current_selected_product_data_full[0]?.hide_price_label : false,
						hide_addtolist_label: this.current_selected_product_data_full[0]?.hide_addtolist_label ? this.current_selected_product_data_full[0]?.hide_addtolist_label : false,
						hide_productname_label: this.current_selected_product_data_full[0]?.hide_productname_label ? this.current_selected_product_data_full[0]?.hide_productname_label : false,
						price_label_value1: this.current_selected_product_data_full[0]?.price_label_value1 ? this.current_selected_product_data_full[0]?.price_label_value1 : '',
						price_label_value2: this.current_selected_product_data_full[0]?.price_label_value2 ? this.current_selected_product_data_full[0]?.price_label_value2 : '',
						price_label_value3: this.current_selected_product_data_full[0]?.price_label_value3 ? this.current_selected_product_data_full[0]?.price_label_value3 : '',
						price_label_svg: this.current_selected_product_data_full[0]?.price_label_svg ? this.current_selected_product_data_full[0]?.price_label_svg : '',
						isVideo: this.current_selected_product_data_full[0]?.isVideo ? this.current_selected_product_data_full[0]?.isVideo : '',
						sale_price_label_value1: this.current_selected_product_data_full[0]?.sale_price_label_value1 ? this.current_selected_product_data_full[0]?.sale_price_label_value1 : '',
						sale_price_label_value2: this.current_selected_product_data_full[0]?.sale_price_label_value2 ? this.current_selected_product_data_full[0]?.sale_price_label_value2 : '',
						sale_price_label_value3: this.current_selected_product_data_full[0]?.sale_price_label_value3 ? this.current_selected_product_data_full[0]?.sale_price_label_value3 : '',
						sale_price_label_svg: this.current_selected_product_data_full[0]?.sale_price_label_svg ? this.current_selected_product_data_full[0]?.sale_price_label_svg : '',
						sale_content_price_label_svg : this.current_selected_product_data_full[0]?.sale_content_price_label_svg ? this.current_selected_product_data_full[0]?.sale_content_price_label_svg : '',
						final_price_label_value1: this.current_selected_product_data_full[0]?.final_price_label_value1 ? this.current_selected_product_data_full[0]?.final_price_label_value1 : '',
						final_price_label_value2: this.current_selected_product_data_full[0]?.final_price_label_value2 ? this.current_selected_product_data_full[0]?.final_price_label_value2 : '',
						final_price_label_value3: this.current_selected_product_data_full[0]?.final_price_label_value3 ? this.current_selected_product_data_full[0]?.final_price_label_value3 : '',
						final_price_label_svg: this.current_selected_product_data_full[0]?.final_price_label_svg ? this.current_selected_product_data_full[0]?.final_price_label_svg : '',
						final_content_price_label_svg : this.current_selected_product_data_full[0]?.final_content_price_label_svg ? this.current_selected_product_data_full[0]?.final_content_price_label_svg : '',
						digital_coupon_price_label_value1: this.current_selected_product_data_full[0]?.digital_coupon_price_label_value1 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value1 : '',
						digital_coupon_price_label_value2: this.current_selected_product_data_full[0]?.digital_coupon_price_label_value2 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value2 : '',
						digital_coupon_price_label_value3: this.current_selected_product_data_full[0]?.digital_coupon_price_label_value3 ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_value3 : '',
						digital_coupon_price_label_svg: this.current_selected_product_data_full[0]?.digital_coupon_price_label_svg ? this.current_selected_product_data_full[0]?.digital_coupon_price_label_svg : '',
						digital_coupon: this.current_selected_product_data_full[0]?.digital_coupon ? this.current_selected_product_data_full[0]?.digital_coupon : false,
						digital_content_coupon_price_label_svg :this.current_selected_product_data_full[0]?.digital_content_coupon_price_label_svg ? this.current_selected_product_data_full[0]?.digital_content_coupon_price_label_svg : '',
						note :this.current_selected_product_data_full[0]?.note ?this.current_selected_product_data_full[0]?.note:'',
						link :this.current_selected_product_data_full[0]?.link ?this.current_selected_product_data_full[0]?.link:'',
						usage_limit :this.current_selected_product_data_full[0]?.usage_limit ?this.current_selected_product_data_full[0]?.usage_limit:'',
						sale_price_label_format: this.current_selected_product_data_full[0]?.sale_price_label_format ?this.current_selected_product_data_full[0]?.sale_price_label_format:'',
						final_price_label_format: this.current_selected_product_data_full[0]?.final_price_label_format ?this.current_selected_product_data_full[0]?.final_price_label_format:'',
						digital_coupon_price_label_format: this.current_selected_product_data_full[0]?.digital_coupon_price_label_format ?this.current_selected_product_data_full[0]?.digital_coupon_price_label_format:'',
					});
				});
				this.show_final_price_format_panel=false;
				this.show_sale_price_format_panel=false;
				this.show_digital_coupon_format_panel=false;
				this.show_edit_form = true
			} else {
				this.show_edit_form = false
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			if (subscription) {
				subscription.unsubscribe();
			}
		});
	}

}

@Component({
	selector: 'app-saved-items-dialog',
	templateUrl: 'saved-items-dialog.html',
	styleUrls: ['./saved-items-dialog.scss']
})
export class SavedItemsDialogNodeComponent implements OnInit {
	datasource = [];
	tableColumns: string[] = ['checkbox', 'id', 'upc', 'image', 'product_desc', 'category', 'price'];
	title = 'appBootstrap';
	saved_items_list: any;
	user: any;
	current_store_uid: any;
	importcsvdata: any = [];
	saved_items_list_temp: any;
	duplicates_temp: any;
	closeResult: any;
	current_selected_product: any;
	imageurl1: any = '';
	imageurl2: any = '';
	ref: any;
	task: any;
	imageSrc: any;
	imageurl: any;
	show_add_product: boolean = false;
	checkbox_filterd_save_items_array: any = [];
	checkbox_select_all: any;
	categories_list: any;

	product_edit_form: FormGroup;
	product_add_form: FormGroup;
	image_uploading: boolean = false;
	show_icon_results: any;
	icons: any;
	master_list: any;
	ghost_mode: boolean = false;
	get_current_store_id: any;
	items_for_grid: any = []

	search_icon_form: FormGroup;
	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('leadsSort', { static: true }) leadsSort: MatSort;
	@ViewChild('leadsPaginator', { static: true }) leadsPaginator: MatPaginator;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('deleteModal') deleteModal;


	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private componentService: ComponentsService,
		public dialogRef: MatDialogRef<DigitalCircularNoadComponent>,
		private cdr: ChangeDetectorRef
	) {

		this.product_edit_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});

	}

	submit_search_icon(value) {
		this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&query=' + value.query + '&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => {
				this.icons = res['results'];
				this.show_icon_results = true;
			});
	}

	ngOnInit() {
		let chkghost = localStorage.getItem('ghost_mode');
		if (chkghost) {
			this.ghost_mode = true;
		} else {
			this.ghost_mode = false;
		}
		this.get_current_store_id = localStorage.getItem('current_store_id');
		if (this.get_current_store_id) {
			this.user = JSON.parse(this.get_current_store_id);
		} else {
			this.user = JSON.parse(localStorage.getItem('userrole'));
		}
		//this.user = JSON.parse(localStorage.getItem('userrole'));
		this.current_store_uid = this.user.store_uid;
		this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_saved', '==', true).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					pack: e.payload.doc.data()['pack'],
					price_label_format: e.payload.doc.data()['price_label_format'],
					price_label_value1: e.payload.doc.data()['price_label_value1'],
					price_label_value2: e.payload.doc.data()['price_label_value2'],
					price_label_value3: e.payload.doc.data()['price_label_value3'],
					price_label_highlight: e.payload.doc.data()['price_label_highlight'],
					product_url: e.payload.doc.data()['product_url'],
					hide_price_label: e.payload.doc.data()['hide_price_label'],
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'],
					hide_productname_label: e.payload.doc.data()['hide_productname_label'],
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					cost: e.payload.doc.data()['cost'],
					srp: e.payload.doc.data()['srp'],
					category: e.payload.doc.data()['category'],
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg'],
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'],
					isBundleProduct: e.payload.doc.data()['isBundleProduct'],
					//created_at  			: e.payload.doc.data()['created_at'],
				};
			});

			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
		});

		this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data()['value'],
				};
			});
		});
	}

	checkbox_select_all_event(event) {
		let value = event.target.checked;
		let cnt = this.saved_items_list_temp.length;
		for (let i = 0; i < cnt; i++) {
			if (value == true) {
				this.checkbox_filterd_save_items_array[i] = true;
			} else {
				this.checkbox_filterd_save_items_array[i] = false;
			}
		}

	}
	checkbox_single_select_event(event, i) {
		if (event.target.checked == true) {
			this.checkbox_filterd_save_items_array[i] = true;
		} else {
			this.checkbox_filterd_save_items_array[i] = false;
		}
	}

	filter_saved_items() {
		let tempIndexes = []
		if (this.checkbox_filterd_save_items_array.length > 0) {
			this.checkbox_filterd_save_items_array.forEach((item, index) => {
				if (item === true) {
					tempIndexes.push(index)
				}
			});
		}
		let saved_items_to_return = []
		let temp_ids = []
		this.componentService.getOldProducts().forEach(i => temp_ids.push(i.id))
		let duplicate = false
		tempIndexes.forEach(p => {
			if (temp_ids.includes(this.saved_items_list_temp[p].uid)) {
				duplicate = true
			} else {
				saved_items_to_return.push(this.saved_items_list_temp[p])
			}
		})
		this.dialogRef.close();
		if (duplicate) {
			this._snackBar.open("Some Items you selected are already persent in layout!", '', {
				duration: 1500,
			});
		}
		let img_dialog = '<svg xmlns="http://www.w3.org/2000/svg" class="image-suggetion-icon" width="40" height="40" viewBox=" 0 0 24 24" style="background-color: white;"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z"/></svg>'
		const product_array = this.componentService.getOldProducts();
		saved_items_to_return.forEach((product, index) => {
			product.price_label_svg = product.price_label_svg ? product.price_label_svg : '';
			let content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div></div>';
			if (product.hide_price_label === true && product.hide_productname_label === true && product.hide_addtolist_label === true) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === false) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div></div>';
			}
			if (product.hide_addtolist_label === true && product.hide_productname_label === true && product.hide_price_label === false) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === true) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === false && product.hide_productname_label === true && product.hide_price_label === false) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === true && product.hide_productname_label === true && product.hide_price_label === false) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" class="hide_bar" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div><div class="pricelabel" style="position: absolute;bottom: 6px;left: 8px;">' + product.price_label_svg + '</div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === false && product.hide_productname_label === true && product.hide_price_label === true) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">\n<div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === false && product.hide_productname_label === false && product.hide_price_label === true) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			if (product.hide_addtolist_label === true && product.hide_productname_label === false && product.hide_price_label === true) {
				content_data = '<img id=\'' + product.uid + '\' upc=\'' + product.upc_code + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;"><div class="product-title" style="background-size:cover;position: absolute;top: 0;background-color: #fff;color: #000;font-weight: 600;height: 36px;overflow: hidden;color: #000;\nwidth: 100%;    padding-left: 5px;padding-right: 40px;"><p style=" display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;">' + product.product_description + '</p></div><div class="count-products layout_count_product d-flex flex-column align-items-center justify-content-center"><i class="fas fa-plus"></i><span class="layout_product_qnt product_qnt" style="border: 2px solid;margin: 5px 0;padding: 2px 6px;background-color: #ffffff;"></span><i class="fas fa-minus"></i></div><div class="img_dialog image-suggetion-icon-parent" onclick="img_dialog(\'' + product.product_name_slug + '\', \'' + product.uid + '\')" >' + img_dialog + '</div><div><img src="imgs/minus.png" id=\"' + product.uid + '\" class="deleteicon_click deleteicon" style="position: absolute;\nwidth: 24px;\nheight: 24px;\nright: 5px;\ntop: 4px;"></div>';
			}
			const product_data = {
				id: product.uid,
				upc_code: product.upc_code,
				w: 1,
				h: 1,
				category: product.category,
				content: content_data
			};
			product_array.push(product_data);
		});
		this.componentService.setProductArray(product_array)
		this.componentService.displayItems()
		this.dialogRef.close();
	}
	close_dialog() {
		this.dialogRef.close();
	}

	save_item(uid) {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: true }, { merge: true }).then(docRef => {
		});
		this._snackBar.open("Item has been moved to Saved Products!", '', {
			duration: 1500,
		});
	}

	unsave_item(uid) {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: false }, { merge: true }).then(docRef => {
		});

		this._snackBar.open("Item removed from saved!", '', {
			duration: 1500,
		});
	}

	doFilter = (value: string) => {
		this.saved_items_list.filter = value.trim().toLocaleLowerCase();
	}
}

