<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<div class="leads-visualization visualizations-section">
				<div class="row no-gutters">
					
					<div class=" col-3">
						<div class="heading-info">
							<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Saved Templates</h4>
						</div>
					</div>
					
					<div class="col-6">
						<div class="row">
						
							
							<div class="col-6 offset-6" >
				    				<div class="input-group mb-3">
									  <div class="input-group-prepend">
									    <span class="input-group-text" id="basic-addon1" style="background:#fff;"><i class="fas fa-search" style="color:#ddd;"></i></span>
									  </div>
									  <input class="form-control"   (keyup)="search_temp($event.target.value)" type="text" placeholder="Search Template" style="border-left: 0;" >
									</div>
				  			</div>
				  			
						</div>
					</div>

					<div class="col-3">
						<button type="button" (click)="openCreateLog()" style="float:right;box-shadow: none !important;background: #71B01D;color: #fff;border: none !important;border-radius: 4px;cursor:pointer;padding: 6px 13px;font-weight: 600;" ><i class="fas fa-plus" style="margin-right:4px;"></i>Create New Log Template</button>
					</div>

				</div>
			</div>


			<div class="row no-gutters" style="margin-top: 1rem;">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="templates">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="template_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Template Name </th>
							<td mat-cell *matCellDef="let element"> {{element.template_name}} </td>
						</ng-container>
						
						<ng-container matColumnDef="created_by">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Created by </th>
							<td mat-cell *matCellDef="let element" >{{element.created_by}}
							</td>
						</ng-container> 
						<ng-container matColumnDef="assigned_to">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions </th>
							<td mat-cell *matCellDef="let element" style="display: flex;"> 
								<button class="btn btn-outline-success btn-sm" (click)="view_template(element.uid)" style="margin-right:1rem;">View</button>
								<button class="btn btn-sm btn-outline-primary">Assign to Store</button>
							</td>
						</ng-container>
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


					
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>




		</div>
	</div>

</div>
