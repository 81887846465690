import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, HostBinding, Input } from '@angular/core';

import { SideMenusService } from '../side-menus/side-menus.service';
import { LoggedUserModel } from 'src/app/authentication/logged-user.model';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";

import { AngularFirestore, AngularFirestoreDocument, combineChange, combineChanges } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ComponentsService } from 'src/app/components/components.service';


@Component({
	selector: 'app-top-navbar',
	templateUrl: './top-navbar.component.html',
	styleUrls: [
		'./styles/top-navbar.component.scss'
	]
})
export class TopNavbarComponent implements OnInit {
	loggedUser: LoggedUserModel = null;

	// tslint:disable-next-line:variable-name
	_mode = 'expanded';

	@HostBinding('attr.mode')
	@Input()
	set mode(val: string) {
		this._mode = (val !== undefined && val !== null) ? val : 'expanded';
	}
	get mode(): string {
		return this._mode;
	}


	validationMessages = {
		add_store_name: [
			{ type: 'required', message: 'Please enter Store Name' }
		],
		add_email: [
			{ type: 'required', message: 'Email is required' },
			{ type: 'email', message: 'Please enter a valid email' },
		],
		add_so_name: [
			{ type: 'required', message: 'Please enter Store Owner Name' },
		],
		update_store_name: [
			{ type: 'required', message: 'Please enter Store Name' }
		],
		update_email: [
			{ type: 'required', message: 'Email is required' },
			{ type: 'email', message: 'Please enter a valid email' },
		],
		update_so_name: [
			{ type: 'required', message: 'Please enter Store Owner Name' },
		],
		group_name: [
			{ type: 'required', message: 'Please enter Group Name' },
		],
		go_name: [
			{ type: 'required', message: 'Please enter Group Owner Name' },
		],
		go_email: [
			{ type: 'required', message: 'Email is required' },
			{ type: 'email', message: 'Please enter a valid email' },
		],
		warehouse_name: [
			{ type: 'required', message: 'Warehouse name is required' }
		]
	};


	user: any;
	users: any = [];
	manager_store_data: any;
	store_data: any;
	group_data: any = [];
	stores_list_data: any;
	current_store_id: any;
	get_current_store_id: any;
	temp_user: any;
	ghost_mode: boolean = false;
	closeResult: any;
	set_first_store: any;
	current_user: any;
	store_id: any
	live_url: any

	@ViewChild('deleteStoreModal') deleteStoreModal;
	constructor(
		private sideMenusService: SideMenusService,
		private authenticationService: AuthenticationService,
		private router: Router,
		public authService: AuthService,
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		public afs: AngularFirestore,
		private afStorage: AngularFireStorage,
		private componentService: ComponentsService,
		private _snackBar: MatSnackBar) {
		this.authenticationService.loggedUserSubject.subscribe({
			next: (loggedUser) => {
				this.loggedUser = loggedUser;
			}
		});

	}

	ngOnInit() {

		this.current_user = JSON.parse(localStorage.getItem('userrole'));
		let temp_store_id = localStorage.getItem('current_store_id');
		let chkghost = localStorage.getItem('ghost_mode');
		if (chkghost) {
			this.ghost_mode = true;
		} else {
			this.ghost_mode = false;
		}
		if (chkghost) {
			this.user = JSON.parse(temp_store_id);
		} else {
			this.user = JSON.parse(localStorage.getItem('userrole'));
		}
		//this.user = JSON.parse(localStorage.getItem('userrole'));
		this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
			this.users = data.map(e => {
				return {
					uid: e.payload.doc.id,
					photoURL: e.payload.doc.data()['photoURL'] ? e.payload.doc.data()['photoURL'] : './assets/imgs/user-placeholder.png',
					displayName: e.payload.doc.data()['displayName'],
					role: e.payload.doc.data()['role']
				};
			});
		});
		this.afs.collection('/stores', ref => ref.where('uid', '==', this.user.store_uid)).snapshotChanges().subscribe(data => {
			this.store_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					company_name: e.payload.doc.data()['company_name'],
					company_name_slug: e.payload.doc.data()['company_name_slug'],
					branch_name_slug: e.payload.doc.data()['branch_name_slug']
				};
			});
			if(window.location.origin == 'https://admin-digitalcircular.web.app'){
				this.live_url = `https://store-digitalcircular.web.app/${this.store_data[0]?.company_name_slug}`
			}else if(window.location.origin == 'https://admin.digitalmenuspro.com'){
				this.live_url = `https://store.digitalmenuspro.com/${this.store_data[0]?.company_name_slug}`
			}else{
				this.live_url = `https://digital-menus-pro-store.web.app/${this.store_data[0]?.company_name_slug}`
			}
		});
		// if(this.user.role == 'group')
		// {
		this.afs.collection('/stores', ref => ref.where('uid', '==', this.user?.store_uid)).snapshotChanges().subscribe(data => {
			this.group_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					company_name: e.payload.doc.data()['company_name'],
				};
			});
		});

		if(localStorage.getItem('parentStoreId')){
			const parentStoreId = localStorage.getItem('parentStoreId')
				this.afs.collection('/stores', ref => ref.where('parent_id', '==', parentStoreId).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
					this.stores_list_data = data.map(e => {
						return {
							uid: e.payload.doc.id,
							user_id: e.payload.doc.data()['user_id']
						};
					});
					this.get_current_store_id = localStorage.getItem('current_store_id');
					if (this.get_current_store_id) {
						let dat = JSON.parse(this.get_current_store_id);
						this.current_store_id = dat.store_uid;
					} else {
						localStorage.setItem('group_default_store', this.stores_list_data[0]?.uid);
						this.current_store_id = this.stores_list_data[0]?.uid;
						//this.current_store_id = this.stores_list_data[0]?.uid;
						// this.afs.collection('/users', ref => ref.where('store_uid', '==', this.current_store_id).where('role','==','store')).snapshotChanges().subscribe(data => {
						// 	this.set_first_store = data.map(e => {
						// 		return{ 
						// 			uid: e.payload.doc.id,
						// 			can_login:  e.payload.doc.data()['can_login'],
						// 			displayName: e.payload.doc.data()['displayName'],
						// 			email:  e.payload.doc.data()['email'],
						// 			emailVerified: true,
						// 			group_uid:  e.payload.doc.data()['group_uid'],
						// 			photoURL:  e.payload.doc.data()['photoURL'],
						// 			role: e.payload.doc.data()['role'],
						// 			store_uid: e.payload.doc.data()['store_uid']
						// 		};
						// 	});
						// 	localStorage.setItem('current_store_id',JSON.stringify(this.set_first_store[0]));
						// 	location.reload();
						// });
					}
				})
		}else{
			this.componentService.parentStoreEvent.subscribe(res => {
				const parentStoreId = localStorage.getItem('parentStoreId')
				this.afs.collection('/stores', ref => ref.where('parent_id', '==', parentStoreId).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
					this.stores_list_data = data.map(e => {
						return {
							uid: e.payload.doc.id,
							user_id: e.payload.doc.data()['user_id']
						};
					});
					this.get_current_store_id = localStorage.getItem('current_store_id');
					if (this.get_current_store_id) {
						let dat = JSON.parse(this.get_current_store_id);
						this.current_store_id = dat.store_uid;
					} else {
						localStorage.setItem('group_default_store', this.stores_list_data[0].uid);
						this.current_store_id = this.stores_list_data[0]?.uid;
						//this.current_store_id = this.stores_list_data[0]?.uid;
						// this.afs.collection('/users', ref => ref.where('store_uid', '==', this.current_store_id).where('role','==','store')).snapshotChanges().subscribe(data => {
						// 	this.set_first_store = data.map(e => {
						// 		return{ 
						// 			uid: e.payload.doc.id,
						// 			can_login:  e.payload.doc.data()['can_login'],
						// 			displayName: e.payload.doc.data()['displayName'],
						// 			email:  e.payload.doc.data()['email'],
						// 			emailVerified: true,
						// 			group_uid:  e.payload.doc.data()['group_uid'],
						// 			photoURL:  e.payload.doc.data()['photoURL'],
						// 			role: e.payload.doc.data()['role'],
						// 			store_uid: e.payload.doc.data()['store_uid']
						// 		};
						// 	});
						// 	localStorage.setItem('current_store_id',JSON.stringify(this.set_first_store[0]));
						// 	location.reload();
						// });
					}
				})
			});
		}

		// } 	
	}

	open(content, title) {
		this.modalService.open(content, { ariaLabelledBy: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}


	open_delete_store() {
		this.open(this.deleteStoreModal, 'modal-basic-title2');

	}

	async delete_store_submit() {
		try {
		  // Delete store from group list
		  const groupListQuery = await this.afs.collection('/group_list').ref
			.where('store', 'array-contains', this.user.company_name)
			.get();
	  
		  const groupListPromises = groupListQuery.docs.map(async (doc) => {
			const arr = doc.data();
			const newArr = arr['store'].filter(object => object !== this.user.company_name);
			await this.afs.collection("/group_list").doc(arr['uid']).update({ store: newArr });
		  });
	  
		  await Promise.all(groupListPromises);
	  
		  // Delete store from user's store list
		  const userListQuery = await this.afs.collection('/users').ref
			.where('store', 'array-contains', { company_name: this.user.company_name, uid: this.user.store_uid })
			.get();
	  
		  const userListPromises = userListQuery.docs.map(async (doc) => {
			const arr = doc.data();
			const newArr = arr['store'].filter(object => object.uid !== this.user.store_uid);
			await this.afs.collection("/users").doc(arr['uid']).update({ store: newArr });
		  });
	  
		  await Promise.all(userListPromises);
	  
		  // Delete store-items
		  const storeItemsQuery = await this.afs.collection('/store-items').ref
			.where('store_uid', '==', this.user.store_uid)
			.get();
	  
		  const storeItemsPromises = storeItemsQuery.docs.map(async (doc) => {
			const docId = doc.id;
			await this.afs.doc(`/store-items/${docId}`).delete();
		  });
	  
		  await Promise.all(storeItemsPromises);
	  
		  // Delete store
		  const storesQuery = await this.afs.collection('/stores').ref
			.where('uid', '==', this.user.store_uid)
			.get();
	  
		  const storePromises = storesQuery.docs.map(async (doc) => {
			await this.afs.doc(`/stores/${this.user.store_uid}`).delete();
		  });
	  
		  await Promise.all(storePromises);
	  
		  localStorage.removeItem('current_store_id');
		  localStorage.removeItem('ghost_mode');
		  let element: HTMLElement = document.getElementsByClassName('close_store_delete_modal')[0] as HTMLElement;
		  element.click();
	  
		  this._snackBar.open('Store deleted successfully', '', {
			duration: 2000,
		  });
		  this.router.navigate(['admin/stores']).then(() => {
			window.location.reload();
		  });
		} catch (error) {
		  console.error('Error deleting store:', error);
		}
	}	  

	logout() {
		this.authenticationService.logout().subscribe(
			() => this.router.navigate(['sign-in'])
		);
	}

	toggleMainMenu(): void {
		this.sideMenusService.toggleMainMenuSubject.next('toggle');
	}

	toggleAltMenu(): void {
		this.sideMenusService.toggleAltMenuSubject.next('toggle');
	}

	toggleSettingsMenu(): void {
		this.sideMenusService.renderAltMenuSubject.next('settings');
		this.toggleAltMenu();
	}

	toggleNotificationsMenu(): void {
		this.sideMenusService.renderAltMenuSubject.next('notifications');
		this.toggleAltMenu();
	}

	store_change(uid) {

		this.afs.collection('/users', ref => ref.where('store_uid', '==', uid)).snapshotChanges().subscribe(data => {
			this.temp_user = data.map(e => {
				return {
					uid: e.payload.doc.id,
					can_login: e.payload.doc.data()['can_login'],
					displayName: e.payload.doc.data()['displayName'],
					email: e.payload.doc.data()['email'],
					emailVerified: true,
					group_uid: e.payload.doc.data()['group_uid'],
					photoURL: e.payload.doc.data()['photoURL'],
					role: e.payload.doc.data()['role'],
					store_uid: e.payload.doc.data()['store_uid']
				};
			});
			localStorage.removeItem('group_default_store');
			localStorage.setItem('current_store_id', JSON.stringify(this.temp_user[0]));
			location.reload();
			// this._snackBar.open('Stored changed successfully', '', {
			//    duration: 3000,
			//  });
		});
	}
}
