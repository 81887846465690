import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { finalize, catchError, tap } from 'rxjs/operators';


@Component({
	selector: 'app-coupons',
	templateUrl: './coupons.component.html',
	styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {


	modalRef: NgbModalRef;
	coupons_list: any;
	current_store_uid: any;
	store_data: any;
	closeResult: any;
	user: any;
	photoURL: any;
	imageurl: any = "";
	imageSrc: any = "";
	task: any;
	ref: any;
	current_edit_coupon: any;

	coupon_add_form: FormGroup;
	coupon_cms_form: FormGroup;
	coupon_edit_form: FormGroup;

	ghost_mode: boolean = false;
	get_current_store_id: any;

	@ViewChild('couponAddModal') couponAddModal;
	@ViewChild('clearAllModal') clearAllModal;

	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private afStorage: AngularFireStorage,
		private _snackBar: MatSnackBar) {


		this.coupon_add_form = new FormGroup({
			coupon_image: new FormControl(null),
			upc: new FormControl(null),
			coupon_description: new FormControl(null),
			label: new FormControl(null),
			coupon_code: new FormControl(null),
			valid_from: new FormControl(null),
			valid_till: new FormControl(null),
		});

		this.coupon_cms_form = new FormGroup({
			coupon_header_text: new FormControl(""),
			coupon_header_include: new FormControl(0),
			coupon_terms: new FormControl(""),
		});

		this.coupon_edit_form = new FormGroup({
			coupon_image: new FormControl(null),
			upc: new FormControl(null),
			coupon_description: new FormControl(null),
			label: new FormControl(null),
			coupon_code: new FormControl(null),
			valid_from: new FormControl(null),
			valid_till: new FormControl(null),
			is_publish: new FormControl(false)
		});
	}

	ngOnInit() {

		//this.user = JSON.parse(localStorage.getItem('userrole'));
		//this.current_store_uid = this.user.store_uid;
		let chkghost = localStorage.getItem('ghost_mode');
		if (chkghost) {
			this.ghost_mode = true;
		} else {
			this.ghost_mode = false;
		}
		this.get_current_store_id = localStorage.getItem('current_store_id');
		if (this.get_current_store_id) {
			this.user = JSON.parse(this.get_current_store_id);
		} else {
			this.user = JSON.parse(localStorage.getItem('userrole'));
		}
		this.current_store_uid = this.user.store_uid;


		//getting all the coupons list from the coupons table
		 this.afs.collection('/coupons', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('created_at', 'desc')).snapshotChanges().subscribe(async data => {
			this.coupons_list =  data.map(e => {
				const validTillTimestamp = e.payload.doc.data()['valid_till'].seconds * 1000; // Firestore timestamp in milliseconds
				const validTillDate = new Date(validTillTimestamp); // Convert to JavaScript Date object
				validTillDate.setHours(0, 0, 0, 0);
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
				const isExpired = validTillDate < currentDate ? 'yes' : 'no';
				return {
					uid: e.payload.doc.id,
					coupon_image: e.payload.doc.data()['coupon_image'],
					upc: e.payload.doc.data()['upc'],
					coupon_description: e.payload.doc.data()['coupon_description'],
					label: e.payload.doc.data()['label'],
					coupon_code: e.payload.doc.data()['coupon_code'],
					valid_from: e.payload.doc.data()['valid_from'],
					valid_till: e.payload.doc.data()['valid_till'],
					is_publish: e.payload.doc.data()['is_publish'],
					is_expired:isExpired
				};						
			});
		});

		//coupon extra data from store table
		this.afs.collection('/stores', ref => ref.where('uid', '==', this.current_store_uid)).snapshotChanges().subscribe(data => {
			this.store_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					coupon_header_include: e.payload.doc.data()['coupon_header_include'],
					coupon_header_text: e.payload.doc.data()['coupon_header_text'],
					coupon_terms: e.payload.doc.data()['coupon_terms'],
					access_coupon: e.payload.doc.data()['access_coupon'],
					access_layout: e.payload.doc.data()['access_layout'],
					access_recipe: e.payload.doc.data()['access_recipe'],
				};
			});
			this.coupon_cms_form.patchValue({
				coupon_header_text: this.store_data[0]?.coupon_header_text,
				coupon_header_include: this.store_data[0]?.coupon_header_include,
				coupon_terms: this.store_data[0]?.coupon_terms,
			});
		});

	}
	async deleteCoupon(couponUid) {
		try {
			const querySnapshot = await this.afs.collection('/coupons', ref => 
			  ref.where('store_uid', '==', this.current_store_uid)
				 .where('uid', '==', couponUid) 
			).get().toPromise();
			if (!querySnapshot.empty) {
			  const docId = querySnapshot.docs[0].id; 
			  await this.afs.doc('coupons/' + docId).delete(); 
			} else {
			  console.log(`Coupon with UID ${couponUid} not found.`);
			}
		  } catch (error) {
			console.error('Error deleting coupon:', error);
		  }
	}

	open(content, title) {
		this.modalService.open(content, { ariaLabelledBy: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		// this.imageurl = ''
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	trigger_cms_submit_btn() {
		let element: HTMLElement = document.getElementsByClassName('submit_cms_form_btn')[0] as HTMLElement;
		element.click();
	}

	trigger_addimage_click() {
		let element: HTMLElement = document.getElementsByClassName('add_image_file')[0] as HTMLElement;
		element.click();
	}


	open_create_modal() {
		this.imageurl= '';
		this.imageSrc='';
		this.open(this.couponAddModal, 'modal-basic-title1');
	}


	upload(event) {

		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;

			reader.readAsDataURL(file);
		}

		// create a random id
		const randomId = "coupon-" + new Date().getTime() + Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/' + randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task = this.ref.put(event.target.files[0]);

		//this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
				this.ref.getDownloadURL().subscribe(url => {
					this.imageurl = url;
				});
			})
		).subscribe();


		// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";
	}


	coupon_add(data) {
		let valid_from = new Date(data.valid_from);
		let valid_till = new Date(data.valid_till);

		let coupondata = {
			store_uid: this.current_store_uid,
			coupon_image: this.imageurl,
			upc: data.upc ? data.upc : "",
			coupon_description: data.coupon_description ? data.coupon_description : "",
			label: data.label ? data.label : "",
			coupon_code: data.coupon_code ? data.coupon_code : "",
			valid_from: data.valid_from ? valid_from : "",
			valid_till: data.valid_till ? valid_till : "",
			created_at: new Date(),
			is_publish: false
		};
		let element: HTMLElement = document.getElementsByClassName('close_add_coupon_modal')[0] as HTMLElement;
			element.click();
		this.coupon_add_form.reset();

		let coupon_add = this.afs.collection('/coupons').add(coupondata).then(docRef => {
			// const itemRefx= this.afs.doc('/stores/'+ this.current_store_uid).set(coupon_store_data,{merge:true}).then(docRef => {});
			const itemRefx2 = this.afs.doc('/coupons/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			this._snackBar.open("Coupon added successfully", '', {
				duration: 1500,
			});

		});
		this.imageurl = ''
		this.imageSrc = ''
	}

	coupon_cms_update(data) {
		let coupon_header_include = 0;
		if (data.coupon_header_include == true) { coupon_header_include = 1; }

		let coupon_store_data = {
			coupon_header_include: coupon_header_include,
			coupon_header_text: data.coupon_header_text ? data.coupon_header_text : "",
			coupon_terms: data.coupon_terms ? data.coupon_terms : "",
		};
		const itemRefx = this.afs.doc('/stores/' + this.current_store_uid).set(coupon_store_data, { merge: true }).then(docRef => { });
		const doc = this.afs.collection('/coupons',ref=> ref.where('store_uid','==', this.current_store_uid).orderBy('created_at','desc'));
		doc.snapshotChanges().subscribe((res: any) => {
			let id = res[0].payload.doc.id;
			this.afs.collection('/coupons').doc(id).update({is_publish: true});
		  });
		this._snackBar.open("Coupon data updated successfully!", '', {
			duration: 1000,
		});
	}

	open_clearall_modal() {
		this.open(this.clearAllModal, 'modal-basic-title2');
	}

	delete_all_coupons() {
		//check if coupons exists
		if (this.coupons_list.length > 0) {
			//coupons exist and so we are deleting them
			this.coupons_list.forEach((value, key) => {
				this.afs.doc('coupons/' + value.uid).delete();
			});
		}

		let element: HTMLElement = document.getElementsByClassName('close_clearall_modal')[0] as HTMLElement;
		element.click();

		this._snackBar.open("All coupons deleted successfully!", '', {
			duration: 1000,
		});
	}

	editCouponPass(data) {
		this.current_edit_coupon = data;
		let valid_from = new Date(data.valid_from.seconds * 1000).getFullYear() + "-" + ("0" + (new Date(data.valid_from.seconds * 1000).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date(data.valid_from.seconds * 1000).getDate())).slice(-2);
		let valid_till = new Date(data.valid_till.seconds * 1000).getFullYear() + "-" + ("0" + (new Date(data.valid_till.seconds * 1000).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date(data.valid_till.seconds * 1000).getDate())).slice(-2);
		this.imageurl = data.coupon_image
		this.coupon_edit_form.patchValue({
			upc: data.upc,
			// coupon_image: data.coupon_image,
			coupon_description: data.coupon_description,
			label: data.label,
			coupon_code: data.coupon_code,
			valid_from: valid_from,
			valid_till: valid_till,
			is_publish: false
		});
		
	}

	coupon_update(data) {

		let valid_from = new Date(data.valid_from);
		let valid_till = new Date(data.valid_till);
		let coupon_image_url = "";

		let coupon_data = {
			store_uid: this.current_store_uid,
			coupon_image: this.imageurl,
			upc: data.upc ? data.upc : "",
			coupon_description: data.coupon_description ? data.coupon_description : "",
			label: data.label ? data.label : "",
			coupon_code: data.coupon_code ? data.coupon_code : "",
			valid_from: data.valid_from ? valid_from : "",
			valid_till: data.valid_till ? valid_till : "",
			is_publish: true
		};

		const itemRefx = this.afs.doc('/coupons/' + this.current_edit_coupon.uid).set(coupon_data, { merge: true }).then(docRef => { });
		this.imageurl = ''
		this.current_edit_coupon = ''
	}

}
