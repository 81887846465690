<ng-template #empModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Employee</h4>
		<button type="button" class="close close_emp_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="emp_add_form" (ngSubmit)="emp_add(emp_add_form.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Employee Name" formControlName="emp_name">
				<mat-error *ngFor="let validation of validationMessages.emp_name">
				<mat-error class="error-message" *ngIf="emp_add_form.get('emp_name').hasError(validation.type) && (emp_add_form.get('emp_name').dirty || emp_add_form.get('emp_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="emp_email">
				<mat-error *ngFor="let validation of validationMessages.emp_email">
				<mat-error class="error-message" *ngIf="emp_add_form.get('emp_email').hasError(validation.type) && (emp_add_form.get('emp_email').dirty || emp_add_form.get('emp_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="emp_phone">
			</mat-form-field>
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Add Employee</button>
	</div>
	</form>
</ng-template>


<ng-template #empUpdateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title7" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Employee Details</h4>
		<button type="button" class="close close_emp_update_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="emp_update_form" (ngSubmit)="emp_update(emp_update_form.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Employee Name" formControlName="emp_name">
				<mat-error *ngFor="let validation of validationMessages.emp_name">
				<mat-error class="error-message" *ngIf="emp_update_form.get('emp_name').hasError(validation.type) && (emp_update_form.get('emp_name').dirty || emp_update_form.get('emp_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="emp_email">
				<mat-error *ngFor="let validation of validationMessages.emp_email">
				<mat-error class="error-message" *ngIf="emp_update_form.get('emp_email').hasError(validation.type) && (emp_update_form.get('emp_email').dirty || emp_update_form.get('emp_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="emp_phone">
			</mat-form-field>
			<input type="hidden" formControlName="emp_uid">
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Update</button>
	</div>
	</form>
</ng-template>


<ng-template #mgrModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Manager</h4>
		<button type="button" class="close close_mgr_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="mgr_add_form" (ngSubmit)="mgr_add(mgr_add_form.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Manager Name" formControlName="mgr_name">
				<mat-error *ngFor="let validation of validationMessages.mgr_name">
				<mat-error class="error-message" *ngIf="mgr_add_form.get('mgr_name').hasError(validation.type) && (mgr_add_form.get('mgr_name').dirty || mgr_add_form.get('mgr_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="mgr_email">
				<mat-error *ngFor="let validation of validationMessages.mgr_email">
				<mat-error class="error-message" *ngIf="mgr_add_form.get('mgr_email').hasError(validation.type) && (mgr_add_form.get('mgr_email').dirty || mgr_add_form.get('mgr_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="mgr_phone">
			</mat-form-field>
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Add Manager</button>
	</div>
	</form>
</ng-template>

<ng-template #mgrUpdateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title8" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Manager Details</h4>
		<button type="button" class="close close_mgr_update_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="mgr_update_form" (ngSubmit)="mgr_update(mgr_update_form.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Manager Name" formControlName="mgr_name">
				<mat-error *ngFor="let validation of validationMessages.mgr_name">
				<mat-error class="error-message" *ngIf="mgr_update_form.get('mgr_name').hasError(validation.type) && (mgr_update_form.get('mgr_name').dirty || mgr_update_form.get('mgr_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="mgr_email">
				<mat-error *ngFor="let validation of validationMessages.mgr_email">
				<mat-error class="error-message" *ngIf="mgr_update_form.get('mgr_email').hasError(validation.type) && (mgr_update_form.get('mgr_email').dirty || mgr_update_form.get('mgr_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="mgr_phone">
			</mat-form-field>
			<input type="hidden" formControlName="mgr_uid">
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Update</button>
	</div>
	</form>
</ng-template>


<ng-template #empDeleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title3" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete Employee</h4>
		<button type="button" class="close close_emp_delete_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

			<p>Are you sure you want to delete this employee permanently?  </p>
			<p style="font-size: 14px;color:#858585;">Note - All data related to this employee will be deleted permanently!</p>
				<button class="btn btn-danger float-right" (click)="delete_emp_submit()">Yes, Delete Employee</button>
				<button class="btn btn-light float-right" (click)="modal.dismiss('Cross click')" style="margin-right: 1rem;">No</button>

	</div>
	
	
</ng-template>

<ng-template #mgrDeleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title4" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete Manager</h4>
		<button type="button" class="close close_mgr_delete_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

			<p>Are you sure you want to delete this manager permanently?  </p>
			<p style="font-size: 14px;color:#858585;">Note - All data related to this manager will be deleted permanently!</p>
				<button class="btn btn-danger float-right" (click)="delete_mgr_submit()">Yes, Delete Manager</button>
				<button class="btn btn-light float-right" (click)="modal.dismiss('Cross click')" style="margin-right: 1rem;">No</button>

	</div>
	
	
</ng-template>
<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-9">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Staff</h4>
							</div>
						</div>
						
						
						

						<div class="col-3">
							
						</div>
					</div>
				</div>
				<div class="leads-visualization visualizations-section" style="margin-top: 1rem;">
					<div class="row no-gutters">
						<div class=" col-6">
							<div class="heading-info" *ngIf="current_role == 'employee'">
								<button class="btn btn-primary" style="margin-right: 1rem;border-radius: 32px;" (click)="switch_emp()">Employees</button>
								<button class="btn btn-outline-primary" style="border-radius: 32px;" (click)="switch_manager()">Manager</button>
							</div>
							<div class="heading-info" *ngIf="current_role == 'manager'">
								<button class="btn btn-outline-primary" style="margin-right: 1rem;border-radius: 32px;" (click)="switch_emp()">Employees</button>
								<button class="btn btn-primary" style="border-radius: 32px;" (click)="switch_manager()">Manager</button>
							</div>
						</div>
						
						<div class="col-3">
							<div class="row">
							
								
								<div class="col-12" *ngIf="current_role == 'employee'">
					    				
					    			<div class="input-group mb-3">
										<div class="input-group-prepend">
									    	<span class="input-group-text" id="basic-addon1" style="background:#fff;"><i class="fas fa-search" style="color:#ddd;"></i></span>
									  	</div>
									 	<input class="form-control"   (keyup)="search_emp($event.target.value)" type="text" placeholder="Search by Name or Email" >
									</div>

					  			</div>
					  			<div class="col-12" *ngIf="current_role == 'manager'">
					    				
					    				<div class="input-group mb-3">
											<div class="input-group-prepend">
										    	<span class="input-group-text" id="basic-addon1" style="background:#fff;"><i class="fas fa-search" style="color:#ddd;"></i></span>
										  	</div>
									 		<input class="form-control"   (keyup)="search_mgr($event.target.value)" type="text" placeholder="Search by Name or Email" >
										</div>
					  			</div>
					  			
							</div>
						</div>
						

						<div class="col-3">
							<button type="button" class="btn btn-success float-right" (click)="open(empModal,'modal-basic-title')" *ngIf="current_role=='employee'"><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Employee</button>
							<button type="button" class="btn btn-success float-right" (click)="open(mgrModal,'modal-basic-title2')" *ngIf="current_role=='manager'"><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Manager</button>
						</div>
					</div>
				</div>

			<div class="row no-gutters" style="margin-top: 1rem;" *ngIf="current_role=='employee'">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="emp_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Employee Name </th>
							<td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
						</ng-container>
						
						<ng-container matColumnDef="added_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Added Date </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy'}} </td>
						</ng-container>
						<ng-container matColumnDef="email">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Email </th>
							<td mat-cell *matCellDef="let element"> {{element.email}} </td>
						</ng-container>
						<ng-container matColumnDef="phone">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Phone </th>
							<td mat-cell *matCellDef="let element"> {{element.phone}} </td>
						</ng-container>
						
						<ng-container matColumnDef="login">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Login </th>
							<td mat-cell *matCellDef="let element"> 
								<button style="background: transparent;border: none !important;outline: none;"><div class="custom-control custom-switch">
						              <input type="checkbox" class="custom-control-input" id="customSwitch{{element.uid}}" [checked]="element.can_login == '1'" (click)="change_login_status(element.uid,element.can_login)">
						              <label class="custom-control-label" for="customSwitch{{element.uid}}" ></label>
						            </div>
						        </button>
						        
							</td>
						</ng-container>
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
							<button class="btn btn-sm btn-outline-dark" (click)="open_update_emp_modal(element)" style="    margin-right: 1rem;border-radius: 32px;"><img src="assets/imgs/icon-pencil-dark.svg" style="margin-right: 5px;">Edit</button>
							<button class="btn btn-sm btn-outline-danger" (click)="delete_emp(element.uid)" style="border-radius: 32px;margin-right: 15px;"><img src="assets/imgs/icon-trash.svg" style="margin-right: 5px;">Delete</button>
							<button class="btn btn-sm btn-outline-primary" (click)="view_logs(element.uid)" style="border-radius: 32px;">View Assigned Logs</button>
							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>



			<div class="row no-gutters" style="margin-top: 1rem;" *ngIf="current_role=='manager'">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort2="matSort" [dataSource]="mgr_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Manager Name </th>
							<td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
						</ng-container>
						
						<ng-container matColumnDef="added_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Added Date </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy'}} </td>
						</ng-container>
						<ng-container matColumnDef="email">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Email </th>
							<td mat-cell *matCellDef="let element"> {{element.email}} </td>
						</ng-container>
						<ng-container matColumnDef="phone">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Phone </th>
							<td mat-cell *matCellDef="let element"> {{element.phone}} </td>
						</ng-container>
						
						<ng-container matColumnDef="login">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Login </th>
							<td mat-cell *matCellDef="let element"> 
								<button style="background: transparent;border: none !important;outline: none;"><div class="custom-control custom-switch">
						              <input type="checkbox" class="custom-control-input" id="customSwitch{{element.uid}}" [checked]="element.can_login == '1'" (click)="change_login_status(element.uid,element.can_login)">
						              <label class="custom-control-label" for="customSwitch{{element.uid}}" ></label>
						            </div>
						        </button>
						        
							</td>
						</ng-container>
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
							<button class="btn btn-sm btn-outline-dark" (click)="open_update_mgr_modal(element)" style="    margin-right: 1rem;border-radius: 32px;"><img src="assets/imgs/icon-pencil-dark.svg" style="margin-right: 5px;">Edit</button>
							<button class="btn btn-sm btn-outline-danger" (click)="delete_mgr(element.uid)" style="border-radius: 32px;"><img src="assets/imgs/icon-trash.svg" style="margin-right: 5px;">Delete</button>

							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator2 [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
