<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<form [formGroup]="filter_store_form" (ngSubmit)="filter_store(filter_store_form.value)"  style="width:100%;">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-3">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">All Groups </h4>
							</div>
						</div>
						
						<div class="col-12">
							<div class="row">					  			
					  			<div class="col" style="padding: 0;">
									<button type="button" class="btn btn-success" (click)="addNewGroup()" style="    float: right;margin-right: 10px;margin-bottom: 10px;"><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Group</button>
								</div>
							</div>
						</div>

					</div>
				</div>
			</form>
            <div class="row no-gutters">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					<table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="groups_list">
					    <ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left:24px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;" style="padding-left:24px;"> {{i + 1}} </td>
						</ng-container>

						<ng-container matColumnDef="Name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: 700;"> Group Name </th>
							<td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
						</ng-container>
						
						<ng-container matColumnDef="Store">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: 700;"> Store </th>
							<td mat-cell *matCellDef="let element">
								<span *ngIf="element.store.length <= 0">None</span>
								<span *ngIf="element.store.length > 0">{{element.store.join(", ") }}</span>
							</td>
						</ng-container>

						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
								<button class="btn btn-sm  btnicon" style="border:1px solid #71B01D;margin-right: 5px;"  (click)="edit_group(element.uid)"><img src="assets/imgs/icon-pencil.svg" >
								</button>
							<button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="deleteGroup(element.uid)">Delete</button>
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div> 

	</div>
</div>
