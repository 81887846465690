import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { finalize, catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentsService } from '../../components.service';

@Component({
	selector: 'app-recipe-api-test',
	templateUrl: './recipe-api-test.component.html',
	styleUrls: ['./recipe-api-test.component.scss']
})
export class RecipeApiTestComponent implements OnInit {

	recipe_list: any;
	loading: boolean = false;
	product_image: any;
	product_image_data: any;

	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private http: HttpClient,
		private componentService: ComponentsService
	) { }

	ngOnInit(): void {

		const headerDict = {
			'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
			'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
			'Access-Control-Allow-Headers': 'Content-Type',
		}

		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		this.http
			.get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/random?tags=vegetarian&number=12',
				requestOptions)
			.subscribe(res => {
				if (res) { this.recipe_list = res; }
			});


	}


	recipe_search(search_value) {
		this.recipe_list = [];
		if (search_value == "" || search_value == undefined) {
			return false;
		}
		this.loading = true;
		const headerDict = {
			'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
			'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
			'Access-Control-Allow-Headers': 'Content-Type',
		}
		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		this.http
			.get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/findByIngredients?ingredients=' + encodeURIComponent(search_value) + '&number=12&ignorePantry=true&ranking=1',
				requestOptions)
			.subscribe(res => {
				this.recipe_list = res;
				this.loading = false;
			});

	}

	ingredient_search(search_value) {
		if (search_value == "" || search_value == undefined) {
			return false;
		}
		this.recipe_list = [];

		this.loading = true;
		const headerDict = {
			'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
			'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
			'Access-Control-Allow-Headers': 'Content-Type',
		}

		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		this.http
			.get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/search?query=' + encodeURIComponent(search_value) + '&number=10&offset=0&type=main%20course',
				requestOptions)
			.subscribe(res => { this.recipe_list = res; this.loading = false; });
	}

	gotoRecipeDetail(id) {
		this.router.navigate(['/store/recipe-api-test-detail/' + id]);
	}


	async upc_search(search_value) {
		if (search_value == "" || search_value == undefined) {
			return false;
		}
		this.product_image_data = [];

		//this.loading = true;
		var APItoken = localStorage.getItem('CHX_token')
		const headerDict = {
			'Authorization': 'EN ' + APItoken,
		}
		const bodyData =
		{
			"OrderBy": "26834672-7c90-4918-9b19-5bd419023b12",
			"Desc": false,
			"AttributeFilterOperator": "And",
			"IdentifierAttribute": [
				"575ad9c5-3da2-472e-ada0-85d227d2cbaf"
			],
			"Identifiers": [
				search_value
			],
			"DataOwner": "07725f7b-db7a-4a89-9ac6-5429a9a51638",
			"PreferredProducts": null,
			"OnHold": false,
			"UserId": "f5a7a747-e05f-4e5d-bc45-2d004c10e26e",
			"Language": "en-US"
		};

		const requestOptions = {
			headers: new HttpHeaders(headerDict),
		};
		let result: any
		try {
			this.http
				.post('https://api.syndigo.com/api/marketplace/search?skip=0&take=100&companyId=07725f7b-db7a-4a89-9ac6-5429a9a51638',
					bodyData, requestOptions)
				.subscribe((res: any) => {
					if (res.Results[0]) {
						this.product_image_data = res.Results[0].Components[0].Assets['en-US'].filter(x => x.AttributeId == "645296e8-a910-43c3-803c-b51d3f1d4a89");
					} else {
						this.product_image_data = 'Not get'
					}
					this.loading = false;
				});
		} catch (error) {
			if (error.status === 401) {
				try {
					await this.componentService.renewToken();
					this.upc_search(search_value)
				} catch (error) {
					console.error("Token renewal failed:", error);
				}
			} else {
				console.error("API error:", error);
			}
		}
	}
}
