<ng-template #iconSearchModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left"
                style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Search Images
        </h4>
        <button type="button" class="close close_unsplash_modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>


    <div class="modal-body">
        <form [formGroup]="search_icon_form" (ngSubmit)="submit_search_icon(search_icon_form.value)"
            style="width: 100%;padding: 0 1rem;">


            <div class="row">
                <div class="col-9">
                    <input placeholder="Search by product name" class="form-control" formControlName="query">
                </div>

                <div class="col-3 text-center">
                    <button type="submit" class="btn btn-primary float-center text-center">Search</button>
                </div>
            </div>
        </form>

        <div class="row" style="margin-top: 1rem;padding: 0 2rem;">

            <div class="row" *ngIf="show_icon_results">
                <label class="col-12">Results</label>
                <div class="col-12">
                    <img src="{{data.urls.regular}}"
                        style="cursor: pointer;height:85px;margin: 5px;width: 132px;padding: 0px;border: 1px solid #cfcaca;border-radius:5px;image-rendering: -webkit-optimize-contrast;"
                        *ngFor="let data of icons" (click)="set_current_image(data.urls.regular)">
                    <div *ngIf="icons && icons.length <= 0">No images found</div>
                </div>
            </div>

        </div>
        <div class="row" style="padding-right:2rem;color: #818181;">
            <div class="col-12 text-right" style="    padding-right: 5px;">
                <p style="    margin-bottom: 0;margin-top: 1rem">Powered by <a href="https://unsplash.com"
                        target="_blank"
                        style="color: #818181;text-decoration: underline;margin-bottom: 0;margin-top: 1rem;">Unsplash</a>
                </p>
            </div>
        </div>



    </div>

</ng-template>
<ng-template #importSavedModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title6" style="font-size: 18px;"><i class="fas fa-chevron-left"
                style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Product
            Gallery</h4>
        <button type="button" class="close close_import_saved_modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="search_icon_form" (ngSubmit)="submit_search_icon(search_icon_form.value)"
            style="width: 100%;padding: 0 1rem;">


            <div class="row">
                <div class="col-12">
                    <input placeholder="Search by product name" class="form-control" formControlName="query"
                        (keyup)="doFilter($event.target.value)">
                </div>

                <!--  <div class="col-3 text-center" >
                        <button type="submit" class="btn btn-primary float-center text-center" >Search</button>
                    </div> -->
            </div>
        </form>

        <div class="row" style="margin-top: 1rem;padding: 0 2rem;">

            <div class="row">
                <div class="col-12" style="max-height: 420px;overflow: auto;">
                    <table class="table" style="width:100%;font-size: 11px;">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>UPC Code</td>
                                <td>Image</td>
                                <td>Description</td>
                                <td>Category</td>
                            </tr>

                            <tr *ngFor="let data of filterData;let i = index;">
                                <td><input type="checkbox" id="" [(ngModel)]="checkbox_array[i]"
                                        (click)="checkbox_select_event($event,data,i)"></td>
                                <td>{{data.upc_code}}</td>
                                <td><img src="{{data.product_image}}&size=418&thumbnail=true&palette=RGB"
                                        style="margin-bottom: 1rem;    border-radius: 5px;height:24px;width:24px;"></td>
                                <td>{{data.product_description}}</td>
                                <td>{{data.category}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</ng-template>
<div class="content">
    <section class="add-new-recipe-section">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-md-9">
                    <div class="headline-with-buttons">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="back-to-recipe-listing">
                                    <span class="cursor-pointer" (click)="backToRecipeDashboard()"><img
                                            src="../../assets/images/Arrow.png" alt=""></span>
                                    <h3 class="mb-0">View/Edit Recipe</h3>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="recipe-btns">
                                    <!-- <span><a class="recipe-preview-btn"
                                            style="cursor:pointer;    background: #fff;color: #71b01d;border: 1px solid #d1d1d1;">Preview</a></span> -->
                                    <span><a class="recipe-save-btn" style="cursor:pointer;"
                                            (click)="trigger_from_save_btn()">Update</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="recipe_add_form" (ngSubmit)="store_recipe(recipe_add_form.value);">
                        <button type="submit" class="submit_form"
                            style="opacity:0; width: 0;position: absolute;right: 0;">a</button>
                        <div class="recipe-box">
                            <div class="box-header">
                                <div class="product-details-text">
                                    <h5 class="mb-0">Add New Recipe</h5>
                                    <span>Click on the product from layout to view/edit details</span>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="product-image mt-3 mb-3 ">

                                            <div class="pro-image">
                                                <label for="file-input" class="img-icon">
                                                    <img src="{{imagesrc}}" alt=""
                                                        style="height: 70px;width: 70px;border-radius: 6px;">
                                                </label>

                                                <input id="file-input" type="file" accept="image/*"
                                                    (change)="upload($event,'1')" style="display:none;" />
                                            </div>
                                            <div class="pro-image-details">
                                                <h5>Recipe Cover Image</h5>
                                                <span>Upload PNG/JPG/JPEG/GIF</span> <br>
                                                <span>Max. 2 MB</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="recipe-name-input">
                                            <input type="text" id="RecipeName" class="form-control"
                                                formControlName="recipe_name" placeholder="Recipe Name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-content">
                                <div class="recipe-ingredients-list mb-3">
                                    <label for="ListOfIngredients">Ingredients List</label>
                                    <textarea id="ListOfIngredients" rows="13" class="form-control"
                                        formControlName="ingredients">
1/2 cup sugar
1/2 cup packed brown sugar 
3 tablespoons all-purpose flour
1 teaspoon ground cinnamon
1/4 teaspoon ground ginger
1/4 teaspoon ground nutmeg
6 to 7 cups thinly sliced peeled tart apples
1 tablespoon lemon juice
Pastry for double-crust pie (9 inches)
1 tablespoon butter
1 large egg white
Additional sugar
                               </textarea>
                                </div>

                                <div class="recipe-Instructions mb-3">
                                    <label for="RecipeInstructions">Instructions </label>
                                    <textarea id="RecipeInstructions" rows="8" class="form-control"
                                        formControlName="instructions">
In a small bowl, combine the sugars, flour and spices; set aside. In a large bowl, toss apples with lemon juice. Add sugar mixture; toss to coat.Line a 9-in. pie plate with bottom crust; trim even with edge. Fill with apple mixture; dot with butter. Roll remaining crust to fit top of pie; place over filling. Trim, seal and flute edges. Cut slits in crust.Beat egg white until foamy; brush over crust. Sprinkle with sugar. Cover edges loosely with foil.Bake at 375° for 25 minutes. Remove foil and bake until crust is golden brown and filling is bubbly, 20-25 minutes longer. Cool on a wire rack.
                               </textarea>
                                </div>

                                <div class="recipe-Instructions mb-3">
                                    <label for="RecipeInstructions">Product List</label>
                                    <div style="margin-bottom: 1rem;">
                                        <span class="btn btn-success btn-sm" (click)="add_manual()"
                                            style="margin-right:1rem;">Add Manually</span>
                                        <span class="btn btn-success btn-sm" style="margin-right:1rem;"
                                            (click)="trigger_import_csv()">Import from csv</span>
                                        <input type="file" class="upload_file_import" accept=".csv"
                                            (change)="import_csv($event.target.files)"
                                            style="opacity:0.1;position: absolute;width: 1px;">

                                        <span class="btn btn-success btn-sm" style="margin-right:1rem;"
                                            (click)="import_from_saved()">Product Gallery</span>
                                    </div>
                                    <div class="recipe-image-thumbnail">
                                        <div class="product-thumbnail"
                                            style="max-height: 513px !important;overflow:auto;">
                                            <div class="row">

                                                <div class="add-new-product">

                                                </div>
                                                <div class="col-3" *ngFor="let data of recipe_products;let i= index;">
                                                    <div class="card"
                                                        style="padding: 10px;    height: 200px;margin-bottom:1rem;">
                                                        <img src="assets/imgs/minus-iconn.svg"
                                                            style="    position: absolute;right: -9px;top: -4px;cursor:pointer;"
                                                            (click)="remove_recipe_product(i)">
                                                        <img src="{{data.product_image}}&size=418&thumbnail=true&palette=RGB"
                                                            style="margin-bottom:5px;height:120px;width:120px;background:#2f2f2f;"
                                                            *ngIf="data.product_image!=''">
                                                        <img src="https://via.placeholder.com/120/2f2f2f/2f2f2f"
                                                            style="margin-bottom:5px;height:120px;width:120px;background:#2f2f2f;"
                                                            *ngIf="data.product_image==''">
                                                        {{data.product_description}}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-3 col-sm-6 pl-0" *ngIf="show_add_product">
                    <form [formGroup]="product_add_form" (ngSubmit)="product_store(product_add_form.value);">

                        <div class="product-details">
                            <div class="product-details-text">
                                <h5 class="mb-0">Add New Product</h5>
                                <span>Enter product details below</span>
                            </div>
                            <div class="product-image mt-3 mb-3 flex-nowrap ">
                                <div class="pro-image" (click)="trigger_upload_image_add()">
                                    <div *ngIf="image_uploading">
                                        <img src="assets/imgs/loading2.gif">
                                    </div>
                                    <div *ngIf="!image_uploading">
                                        <img src="../../assets/images/Rectangle 316.png" alt="" *ngIf="imageurl2 == ''">
                                        <img src="{{imageurl2}}" alt="" *ngIf="imageurl2 != ''"
                                            style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;background: #ececec;">
                                    </div>
                                    <input type="file" name="upload_image_update" accept="image/*"
                                        (change)="upload($event,'2')" class="update_image_upload2"
                                        style="opacity:0;position:absolute;">
                                </div>
                                <div class="pro-image-details">
                                    <h5>Product Image</h5>
                                    <span style="font-size: 9px;cursor:pointer;" (click)="openIconModal('add')">Select
                                        via Unsplash</span><br>
                                    <span>Upload PNG/JPG/JPEG/Gif</span> <br>
                                    <span>Max. 2 MB</span>
                                </div>
                            </div>
                            <div class="product-form">

                                <div class="form-group">
                                    <input type="text" class="form-control" id="Upc" placeholder="UPC"
                                        formControlName="upc_code" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="ProductDescription"
                                        placeholder="Product Description" formControlName="product_description" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder="Pack"
                                        formControlName="pack" />
                                </div>
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" id="PriceLabel"
                                        placeholder="Product Price Label" formControlName="price_label_format" />
                                </div>
                                <div class="form-group">
                                    <ng-select bindLabel="Select Group" class="" formControlName="category"
                                        [clearable]="false" placeholder="Select Category"
                                        notFoundText="No category found" #addgroupid>
                                        <ng-option *ngFor="let type of categories_list;let j = index"
                                            [value]="type.value">{{type.value}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="videoUrl"
                                        placeholder="video URL/ banner link/ site links"
                                        formControlName="product_url" />
                                </div>Z
                                <div class="pro-checkboxes">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                            formControlName="hide_price_label">
                                        <label class="form-check-label" for="defaultCheck1">
                                            Hide Price Label
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2"
                                            formControlName="hide_addtolist_label">
                                        <label class="form-check-label" for="defaultCheck2">
                                            Hide Add to List Label
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck3"
                                            formControlName="hide_productname_label">
                                        <label class="form-check-label" for="defaultCheck3">
                                            Hide Product Name Label
                                        </label>
                                    </div>
                                </div> -->
                                <button type="submit" class="submit_product_add" style="display:none;">a</button>
                                <div class="submit-btn text-center"><span type="submit"
                                        class="pro-detail-submit-btn btn btn-primary"
                                        (click)="trigger_product_store_click()">Add</span></div>

                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>