<ng-template #radioAddModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add Radio Values</h4>
		<button type="button" class="close close_add_radio_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">
				<form [formGroup]="create_radio_form" (ngSubmit)="submit_radio(create_radio_form.value)" style="width: 100%;padding: 0 1rem;margin-top:1rem;" >

				
				<div class="row">
					<div class="col-12">
						<label>Radio Label</label>
						<input  placeholder="Yes/No/NA"  class="form-control" formControlName="temp_name">
						<p *ngIf="required_label_radio" class="text-danger" style="margin-bottom: 0;font-size: 12px;"> Please Enter the Label</p>
					</div>
					<div class="col-12" *ngFor="let ticket of t2.controls; let i = index"  [formGroup]="ticket" style="margin-top: 1rem;">
						<div class="row" style="margin-left:0;">
							<div>
							<label *ngIf="i == 0">Values</label>
							<input  placeholder="Enter Radio Value"  class="form-control" formControlName="value">
							</div>
							<div style=" display: flex;" *ngIf="i != 0">
								<span class="fas fa-trash " style="margin-top:10px;color:#828282;margin-left: 2rem;" (click)="removeRow2(i)" ></span>
							</div>
						</div>
					</div>
					<div class="col-12 text-left">
						<p class="btn btn-light btn-sm" style="margin-top: 1rem;background:#fff;border:1px solid #E0E0E0;" (click)="appendDiv2()"><i class="fas fa-plus" style="margin-right:14px;color:#71B01D;"></i>Add More</p>
					</div>


					<div class="col-12 text-center" style="margin-top: 1rem;">
						<button type="submit" class="btn btn-primary float-center text-center">Submit</button>
					</div>
				</div>
			</form>

			<div class="row" style="margin-top: 1rem;padding: 0 2rem;">
				<label>Radios List</label>
				<ul class="list-group" style="width: 100%;height:185px;overflow: auto;">
					<li class="list-group-item" *ngFor="let item of radios">{{item.name}} <span (click)="delete_radio(item.uid)"><img src="assets/imgs/icon-trash.svg" style="    height: 13px;float: right;margin-top: 5px;"></span></li>
					<li class="list-group-item" *ngIf="radios && radios.length == 0" style="background: #f7f7f7;">No Radios added</li>
				</ul>
			</div>


	
	</div>
	
</ng-template>


<ng-template #checkAddModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add Checkbox Values</h4>
		<button type="button" class="close close_add_check_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">
				<form [formGroup]="create_check_form" (ngSubmit)="submit_check(create_check_form.value)" style="width: 100%;padding: 0 1rem;margin-top:1rem;" >

				
				<div class="row">
					<div class="col-12">
						<label>Checkbox Label</label>
						<input  placeholder="Yes/No/NA"  class="form-control" formControlName="temp_name">
						<p *ngIf="required_label_check" class="text-danger" style="margin-bottom: 0;font-size: 12px;"> Please Enter the Label</p>
					</div>
					<div class="col-12" *ngFor="let ticket of t3.controls; let i = index"  [formGroup]="ticket" style="margin-top: 1rem;">
						<div class="row" style="margin-left:0;">
							<div>
							<label *ngIf="i == 0">Values</label>
							<input  placeholder="Enter Checkbox Value"  class="form-control" formControlName="value">

							</div>
							<div style=" display: flex;" *ngIf="i != 0">
								<span class="fas fa-trash " style="margin-top:10px;color:#828282;margin-left: 2rem;" (click)="removeRow3(i)" ></span>
							</div>
						</div>
					</div>
					<div class="col-12 text-left">
						<p class="btn btn-light btn-sm" style="margin-top: 1rem;background:#fff;border:1px solid #E0E0E0;" (click)="appendDiv3()"><i class="fas fa-plus" style="margin-right:14px;color:#71B01D;"></i>Add More</p>
					</div>


					<div class="col-12 text-center" style="margin-top: 1rem;">
						<button type="submit" class="btn btn-primary float-center text-center">Submit</button>
					</div>
				</div>
			</form>

			<div class="row" style="margin-top: 1rem;padding: 0 2rem;">
				<label>Checkboxes List</label>
				<ul class="list-group" style="width: 100%;height:185px;overflow: auto;">
					<li class="list-group-item" *ngFor="let item of checks">{{item.name}} <span (click)="delete_check(item.uid)"><img src="assets/imgs/icon-trash.svg" style="    height: 13px;float: right;margin-top: 5px;"></span></li>
					<li class="list-group-item" *ngIf="checks && checks.length == 0" style="background: #f7f7f7;">No Checkbox added</li>
				</ul>
			</div> 
	
	</div>
	
</ng-template>
<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;" *ngIf="current_section== 'creator'">
		<div class="container-fluid">
			<div class="leads-visualization visualizations-section">
				<div class="row no-gutters">
					
					<div class=" col-6">
						<div class="heading-info">
							<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 24px;cursor: pointer;" (click)="openLogs()"></i>{{template[0]?.template_name}}</h4>
						</div>
					</div>
					
					

					<div class="col-6">
						<button type="submit" (click)="createForm.ngSubmit.emit()" style="float:right;box-shadow: none !important;background: #71B01D;color: #fff;border: none !important;border-radius: 4px;cursor:pointer;font-weight: 600;padding: 6px 30px;" ><i class="far fa-save" style="margin-right:15px;"></i>Update</button>
						<button type="button" (click)="current_section='preview';preview_temp()"  class="btn btn-light float-right" style="margin-right: 1rem;background:#fff;border:1px solid #CECECE;color:#4891E0;padding: 6px 30px;"><i class="fas fa-eye" style="margin-right:14px;color:#4891E0"></i>Preview</button>
					</div>

				</div>


				<div class="row">

					<form [formGroup]="create_temp_form" (ngSubmit)="submit_temp(create_temp_form.value)" style="width: 100%;padding: 0 1rem;margin-top:1rem;" id="createForm" #createForm="ngForm">

						<div class="row">
						<div class="col-md-4 mb-3">
							<label>Template Name</label>
							<input type="text" formControlName="temp_name"  placeholder="Log Template Name" class="form-control ">
						</div>
						</div>
						<div class="row"  id="productrow"  *ngFor="let ticket of t.controls; let i = index"  [formGroup]="ticket"style="margin-top: 1rem;"  >
							
							<div class="col-6">
								<label  *ngIf="i == 0">Question</label>
<!-- 								<div class="row">
 -->									<!-- <div class="col-1" style="padding-right: 0;margin-top:5px;cursor: grab;">
										<i class="fas fa-bars"></i>
									</div> -->
										<input type="text"   class="form-control" placeholder="Enter Question {{i+1}}" formControlName="question">
									
<!-- 								</div>				
 -->															<!-- 	<input type="text"   class="form-control" placeholder="Response" formControlName="response"> -->

							</div>
							<div class="col-6">
								<div class="row">
								<div>
								<label *ngIf="i == 0" style="margin-left: 15px;">Type of Response</label>
								<div class="col-6">
									<button type="submit" style="display:none;background: #fff;border: 1px solid #ced4da;width:217px;">77</button>
								<button type="button" *ngIf="temp_value[i]"mat-button [matMenuTriggerFor]="response_type" style="background: #fff;border: 1px solid #ced4da;width:217px;">{{temp_value[i]}} <i class="fas fa-chevron-down" style="margin-left: 1rem;    float: right;margin-top: 10px;"></i></button>
								<button type="button" *ngIf="!temp_value[i]"  mat-button [matMenuTriggerFor]="response_type" style="background: #fff;border: 1px solid #ced4da;width:217px;">Select type of Response <i class="fas fa-chevron-down" style="margin-left: 1rem;    float: right;margin-top: 10px;"></i></button>
									<mat-menu #response_type="matMenu">
									  <button mat-menu-item [matMenuTriggerFor]="radio" >Radio Buttons</button>
									  <button mat-menu-item (click)="setType(i,'textbox')">Textbox</button>
									  <button mat-menu-item (click)="setType(i,'number')">Number</button>
									  <button mat-menu-item [matMenuTriggerFor]="checkbox">Checkbox</button>
									</mat-menu>

									<mat-menu #radio="matMenu">
									  <button mat-menu-item *ngFor="let data of radios" (click)="setRadio(i,data)">{{data.name}}</button>
									  <button mat-menu-item (click)="openradioAddModal(i)">+ Add new response</button>
									</mat-menu>

									<mat-menu #checkbox="matMenu">
									  <button mat-menu-item *ngFor="let data of checks" (click)="setCheck(i,data)">{{data.name}}</button>
									  <button mat-menu-item (click)="opencheckAddModal(i)">+ Add new response</button>
									</mat-menu>
								</div>
								</div>

								<div style=" display: flex;" *ngIf="i != 0">
									<span class="fas fa-trash " style="margin-top:10px;color:#828282;margin-left: 2rem;" (click)="removeRow(i);$event.preventDefault();" ></span>
								</div>
								
							</div>
							</div>
						</div>
						<button class="btn btn-light btn-sm" style="margin-top: 1rem;background:#fff;border:1px solid #E0E0E0;" (click)="appendDiv();$event.preventDefault();"><i class="fas fa-plus" style="margin-right:14px;color:#71B01D;"></i>Add Question</button>

								
							
							
				
					</form>


				</div>
			</div>
		</div>
	</div>


	<div class="highlighted-section" style="padding-top:0;" *ngIf="current_section== 'preview'">
		<div class="container-fluid">
			<div class="leads-visualization visualizations-section">
				<div class="row no-gutters">
					
		
					<div class=" col-12">
						<div class="heading-info text-center">
							<i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 24px;cursor: pointer;position: absolute;left: 0;" (click)="current_section='creator'"></i>
								<button type="submit" (click)="createForm.ngSubmit.emit()" style="float:right;box-shadow: none !important;background: #71B01D;color: #fff;border: none !important;border-radius: 4px;cursor:pointer;font-weight: 600;padding: 6px 30px;" ><i class="far fa-save" style="margin-right:15px;"></i>Update</button>
							</div>
					</div>

					<div class=" col-5 offset-3">
						<div class="row">
							<div class="col-12 text-center">
								<h4 class="text-successs">{{form_values?.temp_name}}</h4>
							</div>
							<div  *ngFor ="let quest of form_values?.questions; index as q" style="width:100%;">
							<div class="col-12" style="margin-top: 1rem;border: 1px solid #e6e6e6;padding: 1rem;border-radius: 6px;background: #fff;" *ngIf="quest.type == 'textbox'">
								<div >
								<label style="font-weight: 600;color: #272727;">Q{{q+1}}. {{quest.question}}</label>
								<input  placeholder="Enter your answer"  class="form-control">
								</div>
							</div>

							<div class="col-12" style="margin-top: 1rem;border: 1px solid #e6e6e6;padding: 1rem;border-radius: 6px;background: #fff;" *ngIf="quest.type == 'number'">
								<div >
								<label style="font-weight: 600;color: #272727;">Q{{q+1}}. {{quest.question}}</label>
								<input type="number" placeholder="Enter your answer"  class="form-control">
								</div>
							</div>

							<div class="col-12" style="margin-top: 1rem;border: 1px solid #e6e6e6;padding: 1rem;border-radius: 6px;background: #fff;" *ngIf="quest.type == 'radio'">
								<label style="font-weight: 600;color: #272727;">Q{{q+1}}. {{quest.question}}</label>
								<div class="form-check" *ngFor="let res of quest.response; index as i">
									<input class="form-check-input" type="radio" name="exampleRadios{{q+1}}" id="exampleRadios{{q+1}}{{i+1}}" value="" >
									<label class="form-check-label" for="exampleRadios{{q+1}}{{i+1}}">{{res.value}}</label>
								</div>
								
							</div>



							<div class="col-12" style="margin-top: 1rem;border: 1px solid #e6e6e6;padding: 1rem;border-radius: 6px;background: #fff;"  *ngIf="quest.type == 'checkbox'">
								<label style="font-weight: 600;color: #272727;">Q{{q+1}}. {{quest.question}}</label>
								<div class="form-check"  *ngFor="let res of quest.response;index as i">
									<input class="form-check-input" type="checkbox" name="examplecheck{{q+1}}" id="examplecheck{{q+1}}{{i+1}}" value="" >
									<label class="form-check-label" for="examplecheck{{q+1}}{{i+1}}">{{res.value}}</label>
								</div>
							</div>
						</div>





						</div>
					</div>

				</div>
			</div>
		</div>
	</div>

</div>
