import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";
import * as $ from 'jquery';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  usereditForm: FormGroup;
  loading:boolean = false;
  submitted = false;
  selectedStoreData = [];
  user:any;
  users:any;
  stores_list:any;
  id:any;
  isUser:any;
  user_role = [
    {id: 'admin', role:'Admin'},
    {id: 'admin-manager', role:'Admin Manager'},
    {id: 'user', role:'User'},
  ];

  constructor(
    private route: ActivatedRoute,
    public afs: AngularFirestore,
    private router:Router,
    public authService: AuthService,
    private _snackBar: MatSnackBar
  ) {
    this.usereditForm = new FormGroup({
			displayName: new FormControl(null, Validators.required),
      userRole : new FormControl(null, Validators.required),
			email       : new FormControl(null, [Validators.required, Validators.email]),
      store      : new FormControl([])
	  });
   }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('userrole'));

    this.id = this.route.snapshot.params['id'];
    this.afs.collection('/users',ref=>ref.where('uid','==',this.id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
        this.user = data.map(e => {
          return{
            uid: e.payload.doc.id,
            displayName: e.payload.doc.data()['displayName'],
            email : e.payload.doc.data()['email'],
            store : e.payload.doc.data()['store']?.map((x) => {return x.uid}),
            role: e.payload.doc.data()['role']
          };
        });
        this.usereditForm.patchValue({
          displayName 		: this.user[0]?.displayName,
          userRole 		: this.user[0]?.role,
          email           : this.user[0]?.email,
          store          : this.user[0]?.store
        });
    });

    this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
      this.users = data.map(e => {
      return{
        uid: e.payload.doc.id,
        photoURL: e.payload.doc.data()['photoURL']?e.payload.doc.data()['photoURL']:'./assets/imgs/user-placeholder.png',
        displayName: e.payload.doc.data()['displayName'],
        role: e.payload.doc.data()['role']
      };
      });
    });

    this.afs.collection('/stores',ref=>ref.where('type','==', 'main_store').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
      this.stores_list = data.map(e => {
        return{
          uid: e.payload.doc.id,
          company_name: e.payload.doc.data()['company_name'],
          email: e.payload.doc.data()['email'],
          group_id: e.payload.doc.data()['group_id'],
          group_name: e.payload.doc.data()['group_name'],
          phone: e.payload.doc.data()['phone'],
          store_id: e.payload.doc.data()['store_id'],
          store_owner_name: e.payload.doc.data()['store_owner_name'],
          warehouse_id: e.payload.doc.data()['warehouse_id'],
          warehouse_name: e.payload.doc.data()['warehouse_name'],
          created_at:e.payload.doc.data()['created_at'],
          can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
          status:e.payload.doc.data()['status'],
          user_id:e.payload.doc.data()['user_id'],
          branch_count: e.payload.doc.data()['branch_count']
        };
      });	
      this.selectAllForDropdownItems(this.stores_list)
    });
  }

  get registerFormControl() {
    return this.usereditForm.controls;
  }

  goToLogin() {
    window.location.href = '/login';
  }
  ChangePassword() {
    $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
    $('#exampleModal').css('opacity', '1');
  }
  backToUser(){
    this.router.navigate(['/admin/users-permission']);
  }

  async user_edit(data){
    this.submitted = true;
    this.loading = true;
    if(this.usereditForm.valid){
      if(data.email  == "")
      {
        return false;
      }
      let userdata = {
        email: data.email?data.email:"",
        displayName: data.displayName?data.displayName:"",
        store : this.selectedStoreData,
        role : data.userRole ? data.userRole: 'user',
      };
      this.loading = false;
      let user_update = this.afs.doc('/users/'+ this.id).set(userdata,{merge:true});
      this.router.navigate(['/admin/users-permission']);
      this._snackBar.open("User updated successfully", '', {
        duration: 1000,
			});
    }
  }

  select_branch(event){
    this.selectedStoreData = event.map((x) => {
      return {'uid' : x.uid, 'company_name': x.company_name }
    })
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  handle_user(event){
    if(event == 'admin'){
      this.usereditForm.get('store').clearValidators();               
    } else {                
      this.usereditForm.get('store').setValidators(Validators.required);               
    }
    this.usereditForm.controls['store'].updateValueAndValidity();
  }
}
