import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";



@Component({
  selector: 'app-group-owners',
  templateUrl: './group-owners.component.html',
  styleUrls: ['./group-owners.component.scss']
})
export class GroupOwnersComponent implements OnInit {

validationMessages = {
    add_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    add_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    add_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    group_name: [
      { type: 'required', message: 'Please enter Group Name' },
    ],
    go_name: [
      { type: 'required', message: 'Please enter Group Owner Name' },
    ],
    go_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    warehouse_name: [
      { type: 'required', message: 'Warehouse name is required' }
    ]
  };


group_add_form:FormGroup;
tableColumns  :  string[] = ['id', 'group_owner_name', 'group_name','added_date','email','login','actions'];
groups_list:any;
closeResult:any;



@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('groupModal') groupModal;


  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
		this.group_add_form = new FormGroup({
			group_name: new FormControl(null, Validators.compose([Validators.required])),
			go_name: new FormControl(null, Validators.compose([Validators.required])),
			go_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			go_phone: new FormControl(),
    	});
		

   }

  	ngOnInit() {

		this.afs.collection('/groups',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.groups_list = data.map(e => {
				return{
					uid: e.payload.doc.id,
					email: e.payload.doc.data()['email'],
					group_id: e.payload.doc.data()['group_id'],
					group_name: e.payload.doc.data()['group_name'],
					group_owner_name: e.payload.doc.data()['group_owner_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					phone:e.payload.doc.data()['phone'],
					user_id:e.payload.doc.data()['user_id']
				};
			});	

			this.groups_list = new MatTableDataSource(this.groups_list);
			this.groups_list.sort = this.leadsSort;
			this.groups_list.paginator = this.leadsPaginator;
		});
 
  	}


  	change_login_status(uid,user_id,status)
  	{
		this._snackBar.open('Status updated successfully', '', {
			duration: 2000,
		});
		if(status === '1'){
			let upd_data = {
			can_login: '0'
			};
			let stores = this.afs.doc('/groups/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});	
			let userup = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
			});
		}
		if(status === '0'){
			let upd_data = {
			can_login: '1'
			};
			let aa = this.afs.doc('/groups/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});
			let userupd = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
			});
		}
   	}

	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		});
	}


   open_group_modal(){
   		this.open(this.groupModal,'modal-basic-title2');
   }

   view_store(uid){
   		this.router.navigate(['/admin/group-owners/'+uid]);
   }

   search_group = (value: string) => {
    this.groups_list.filter = value.trim().toLocaleLowerCase();
  }

   group_add(value)
  	{
	  	if(this.group_add_form.valid)
	  	{
		  	let groupData = {
		  		group_name:value.group_name,
		  		group_owner_name: value.go_name,
		  		email: value.go_email,
		  		phone: value.go_phone,
		  		created_at: new Date()
		  	};
	  		this.afAuth.createUserWithEmailAndPassword(value.go_email, "123456")
		      .then((result) => {
		        let userdata = {
			   		email: value.go_email?value.go_email:"",
					displayName: value.go_name?value.go_name:"",
					uid: result.user.uid,
					role: "group",
					created_at: new Date()
				};

			  	let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  	});

			  	this._snackBar.open("Creating Group...Please wait...", '', {
			    	duration: 2000,
			    });

		  		groupData['user_id'] = result.user.uid;
			  	let groupadd = this.afs.collection('/groups').add(groupData).then(docRef => {
			  		const itemRefx= this.afs.doc('/groups/'+docRef.id);
			        itemRefx.update({ uid: docRef.id,user_id: result.user.uid});
			        const itemRefx2= this.afs.doc('/users/'+result.user.uid);
			        itemRefx2.update({ group_uid: docRef.id});
			  		
			  	});

	        this.ForgotPassword(value.go_email);
	        this._snackBar.open("Group created successfully", '', {
								duration: 2000,
							});
	        let element: HTMLElement = document.getElementsByClassName('close_group_add_modal')[0] as HTMLElement;
							element.click();
			this.group_add_form.reset();
	      }).catch((error) => {
		       	this._snackBar.open("Error: "+error.message, '', {
			      duration: 3000,
			    });
	      });
  		}
  	}

  	ForgotPassword(passwordResetEmail) {
		return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		}).catch((error) => {
		})
  	}
}