<ng-template #groupModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Group</h4>
		<button type="button" class="close close_group_add_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="group_add_form" (ngSubmit)="group_add(group_add_form.value)" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Group Name" formControlName="group_name">
				<!-- <mat-error *ngFor="let validation of validationMessages.fullname">
				<mat-error class="error-message" *ngIf="storeaddForm.get('fullname').hasError(validation.type) && (storeaddForm.get('fullname').dirty || storeaddForm.get('fullname').touched)">{{validation.message}}</mat-error>
				</mat-error> -->
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Group Owner Name" formControlName="go_name">
				<mat-error *ngFor="let validation of validationMessages.go_name">
				<mat-error class="error-message" *ngIf="group_add_form.get('go_name').hasError(validation.type) && (group_add_form.get('go_name').dirty || group_add_form.get('go_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="go_email">
				<mat-error *ngFor="let validation of validationMessages.go_email">
				<mat-error class="error-message" *ngIf="group_add_form.get('go_email').hasError(validation.type) && (group_add_form.get('go_email').dirty || group_add_form.get('go_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="go_phone">
			</mat-form-field>
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Add Group</button>
	</div>
	</form>
</ng-template>

<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-3">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Group Owners</h4>
							</div>
						</div>
						
						<div class="col-6">
							<div class="row">
							
								
								<div class="col-6 offset-6" >
									<div class="input-group mb-3">
									  <div class="input-group-prepend">
									    <span class="input-group-text" id="basic-addon1" style="background:#fff;"><i class="fas fa-search" style="color:#ddd;"></i></span>
									  </div>
									  <input class="form-control"   (keyup)="search_group($event.target.value)" type="text" placeholder="Search Group" style="border-left: 0;" >
									</div>
					    				
					  			</div>
					  			
							</div>
						</div>

						

						<div class="col-3">
							<button type="button" (click)="open(groupModal,'modal-basic-title')" style="float:right;box-shadow: none !important;background: #71B01D;color: #fff;border: none !important;border-radius: 4px;cursor:pointer;padding: 6px 13px;font-weight: 600;" ><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Group</button>
						</div>
					</div>
				</div>

			<div class="row no-gutters" style="margin-top: 1rem;">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="groups_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="group_owner_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Group Owner Name </th>
							<td mat-cell *matCellDef="let element"> {{element.group_owner_name}} </td>
						</ng-container>
						
						<ng-container matColumnDef="group_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Group Name </th>
							<td mat-cell *matCellDef="let element"> {{element.group_name}}
							</td>
						</ng-container>
						<ng-container matColumnDef="added_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Added Date </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy'}} </td>
						</ng-container>
						<ng-container matColumnDef="email">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Email </th>
							<td mat-cell *matCellDef="let element"> {{element.email}} </td>
						</ng-container>
						
						<ng-container matColumnDef="login">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Login </th>
							<td mat-cell *matCellDef="let element"> 
								<button style="background: transparent;border: none !important;outline: none;"><div class="custom-control custom-switch">
						              <input type="checkbox" class="custom-control-input" id="customSwitch{{element.uid}}" [checked]="element.can_login == '1'" (click)="change_login_status(element.uid,element.user_id,element.can_login)">
						              <label class="custom-control-label" for="customSwitch{{element.uid}}" ></label>
						            </div>
						        </button>
						        
							</td>
						</ng-container>
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
							<button class="btn btn-sm btn-outline-success" (click)="view_store(element.uid)">View Stores</button>
							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
