import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ResponsiveBreakpointsService } from '../../responsive-breakpoints.service';
import { SideMenusService } from '../../side-menus/side-menus.service';
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: 'app-option-b-layout',
  templateUrl: './option-b-layout.component.html',
  styleUrls: ['./option-b-layout.component.scss']
})
export class OptionBLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.layout-b') layoutFlag = true;
  @HostBinding('class.server-side-rendered') serverSideRendered;
  @HostBinding('class.main-sidenav-opened') mainSidenavOpened;
  @HostBinding('class.alt-sidenav-opened') altSidenavOpened;

  @ViewChild('mainSidenav') mainSidenav: MatSidenav;
  @ViewChild('altSidenav') altSidenav: MatSidenav;

  // Subscription to the Main Sidenav movement
  mainSidenavSubscription: Subscription;
  // Subscription to the Alt Sidenav movement
  altSidenavSubscription: Subscription;
  // Subscription to the screen size changes
  screenSizeChangeSubscription: Subscription;
  // Subscription to the main side menu mode changes
  changeMainMenuModeSubscription: Subscription;

  mainSideMenuMode: string;
  ghostmode:boolean = false;
  user:any;
  constructor(
    private router: Router,
    public authService: AuthService,
    private sideMenusService: SideMenusService,
    private responsiveBreakpointsService: ResponsiveBreakpointsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.serverSideRendered = isPlatformServer(this.platformId);

    // main menu initialization
    switch (this.responsiveBreakpointsService.currentScreenSize) {
      case 'small':
        this.mainSidenavOpened = false;
        this.mainSideMenuMode = 'push';
        break;
      case 'medium':
        this.mainSidenavOpened = true;
        this.mainSideMenuMode = 'side';
        break;
      case 'large':
        this.mainSidenavOpened = true;
        this.mainSideMenuMode = 'side';
        break;
      default:
        this.mainSidenavOpened = true;
        this.mainSideMenuMode = 'side';
    }
  }

  ngOnInit() {
    let ghost = localStorage.getItem('ghost_mode');
    if(ghost)
    {
      this.ghostmode = true;  
    } else {
      this.ghostmode = false;
    }

    this.user = JSON.parse(localStorage.getItem('userrole'));
    
    // subscribe to screen size changes
    this.screenSizeChangeSubscription = this.responsiveBreakpointsService.screenSizeChangeSubject.pipe(
      delay(0)
    ).subscribe(
      (event) => {
        if (event === 'small') {
          this.sideMenusService.changeMainMenuModeSubject.next('push');
          this.sideMenusService.toggleMainMenuSubject.next('close');
        }
        if (event === 'medium') {
          this.sideMenusService.changeMainMenuModeSubject.next('side');
          this.sideMenusService.toggleMainMenuSubject.next('open');
        }
        if (event === 'large') {
          this.sideMenusService.changeMainMenuModeSubject.next('side');
          this.sideMenusService.toggleMainMenuSubject.next('open');
        }
      }
    );
  }

  ngOnDestroy() {
    this.mainSidenavSubscription.unsubscribe();
    this.altSidenavSubscription.unsubscribe();
    this.screenSizeChangeSubscription.unsubscribe();
    this.changeMainMenuModeSubscription.unsubscribe();
  }

  back_to_admin()
  {
    localStorage.removeItem('ghost_mode');
    localStorage.removeItem('current_store_id');
    this.router.navigate(['admin/dashboard'])
    .then(() => {
      window.location.reload();
    });
    //location.reload();
  }

  ngAfterViewInit(): void {
    this.mainSidenavSubscription = this.sideMenusService.toggleMainMenuSubject.subscribe(
      (event) => {
        switch (event) {
          case 'toggle':
            this.mainSidenav.toggle();
            break;
          case 'open':
            this.mainSidenav.open();
            break;
          case 'close':
            this.mainSidenav.close();
            break;
          default:
            this.mainSidenav.toggle();
        }
      },
      (error) => {
        console.log('toggleMainMenu [Layout B] - error', error);
      },
      () => {}
    );

    this.altSidenavSubscription = this.sideMenusService.toggleAltMenuSubject.subscribe(
      (event) => {
        switch (event) {
          case 'toggle':
            this.altSidenav.toggle();
            break;
          case 'open':
            this.altSidenav.open();
            break;
          case 'close':
            this.altSidenav.close();
            break;
          default:
            this.altSidenav.toggle();
        }
      },
      (error) => {
        console.log('toggleAltMenu [Layout B] - error', error);
      },
      () => {}
    );

    this.changeMainMenuModeSubscription = this.sideMenusService.changeMainMenuModeSubject.subscribe(
      (menuMode) => {
        this.mainSideMenuMode = menuMode;
      },
      (error) => {
        console.log('changeMainMenuModeSubject [Layout B] - error', error);
      },
      () => {}
    );
  }

  // It's better to have the class applied when the opening starts as we want it ready before the opening animation ends
  openedStart(): void {
    this.mainSidenavOpened = true;
  }

  mainSidenavToggled(opened: boolean): void {
    this.mainSidenavOpened = opened;
  }

  altSidenavToggled(opened: boolean): void {
    this.altSidenavOpened = opened;
  }
}
