<div class="modal_div">
  <h1 mat-dialog-title>Add from Saved Products</h1>
  <div class="model_buttons">
    <button class="btn-success add_btn_style" (click)="filter_saved_items()">Add</button>
    <button class="btn-danger add_btn_style" (click)="close_dialog()">Close</button>
  </div>
</div>

<div class="col-md-4">
  <div class="filter-and-search-area d-flex">
    <div class="dropdown">
    <div class="select-access-box">
      <span _ngcontent-eke-c147=""><img _ngcontent-eke-c147=""
        src="../../assets/images/search-icon-for-placeholder.png" alt=""></span>
        <input id="SearchForm" type="text" placeholder="Search Product" class="form-control search-form" style="padding-left: 40px;"  (keyup)="doFilter($event.target.value)">
    </div>
    </div>

  
  </div>

  </div>

<div class="visualization-table col-12 table-responsive" style="padding-top: 0px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

		 
  <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="saved_items_list" style="padding-top:0;" >
     <ng-container matColumnDef="checkbox">
  <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">
    <div class="recipe-checkbox">
     <input type="checkbox" id="" [ngModel]="checkbox_select_all" (click)="checkbox_select_all_event($event)">
   </div>
 </th>
   <td mat-cell *matCellDef="let element;let i = index;">
     <div class="recipe-checkbox">
     <input type="checkbox" id="" class="checkbox-delete-{{element.serial}}" [(ngModel)]="checkbox_filterd_save_items_array[element.serial]" (click)="checkbox_single_select_event($event,element.serial)" >
   </div>
   </td>
 </ng-container>

 <ng-container matColumnDef="id">
  <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
   <td mat-cell *matCellDef="let element; let i = index;"> {{element.serial +1}} </td>
 </ng-container>
 <!-- <ng-container matColumnDef="uid">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
   <td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
 </ng-container> -->
 <ng-container matColumnDef="upc">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> UPC </th>
   <td mat-cell *matCellDef="let element">{{element.upc_code}}</td>
 </ng-container>
 
 <ng-container matColumnDef="image">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Image </th>
   <td mat-cell *matCellDef="let element">
     <a href="{{element.product_image}}&size=408&thumbnail=true&palette=RGB" target="_blank" *ngIf="element.product_image !=''">
       <img src="{{element.product_image}}&size=408&thumbnail=true&palette=RGB" style="    margin-bottom: 1rem;    border-radius: 5px;height:24px;width:24px;" >
     </a>
     <img src="assets/images/Rectangle 316.png" style="    margin-bottom: 1rem;    border-radius: 5px;height:24px;width:24px;" *ngIf="element.product_image ==''">
   </td>
 </ng-container>
 <!-- <ng-container matColumnDef="group">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Group </th>
   <td mat-cell *matCellDef="let element">
   {{element.group_name}}

   </td>
 </ng-container> -->
 <ng-container matColumnDef="product_desc">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Product Description </th>
   <td mat-cell *matCellDef="let element">{{element.product_description}}</td>
 </ng-container>
 <!-- <ng-container matColumnDef="store_id">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Store ID </th>
   <td mat-cell *matCellDef="let element"> {{element.store_id}} </td>
 </ng-container> -->
 <ng-container matColumnDef="category">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Category </th>
   <td mat-cell *matCellDef="let element">{{element.category}}</td>
 </ng-container>
 <!-- <ng-container matColumnDef="status">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Status </th>
   <td mat-cell *matCellDef="let element"> {{element.status}} </td>
 </ng-container> -->

 <ng-container matColumnDef="price">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Price</th>
   <td mat-cell *matCellDef="let element">{{element.cost}}</td>
 </ng-container>

 <!--  <ng-container matColumnDef="lastlogindate">
   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
   <td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
 </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
 </table>
 <mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
</div>

<!-- <div mat-dialog-actions> -->
    <!-- <button mat-button (click)="onNoClick()">Cancel</button> -->
    <!-- <div  class="add-delete-btn" style="cursor:pointer;" *ngIf="checkbox_delete_array.length >0"> -->
      <!-- </div> -->
    <!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button> -->
<!-- </div> -->
