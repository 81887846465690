import {
  Component,
  PLATFORM_ID,
  Inject,
  ViewChild,
  OnInit,
  NgZone,
  ElementRef,
} from "@angular/core";
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { User } from "../../../shared/services/user";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from "jquery";
import { finalize, catchError, tap } from "rxjs/operators";
import { saveAs } from "file-saver";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-all-recipes",
  templateUrl: "./all-recipes.component.html",
  styleUrls: ["./all-recipes.component.scss"],
})
export class AllRecipesComponent implements OnInit {
  tableColumns: string[] = ["checkbox", "image", "name", "status", "action"];

  @ViewChild("leadsSort", { static: true }) leadsSort: MatSort;
  @ViewChild("leadsPaginator", { static: true }) leadsPaginator: MatPaginator;

  saved_items_list: any;
  saved_items_list_temp: any;
  user: any;
  current_store_uid: any;
  checkbox_delete_array: any = [];
  checkbox_select_all: any;

  recipes_list_temp: any;
  recipes_list: any;
  closeResult: any;
  ghost_mode: boolean = false;
  get_current_store_id: any;
  recipes_list_temp_data: any;
  loading: boolean;
  @ViewChild("deleteModal") deleteModal;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: object,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private modalService: NgbModal,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.afs
      .collection("/recipes", (ref) =>
        ref
          .where("storeInMasterRecipe", "==", true)
          .orderBy("created_at", "desc")
      )
      .snapshotChanges()
      .subscribe((data) => {
        let i = 0;
        this.recipes_list_temp = data.map((e) => {
          i++;
          return {
            uid: e.payload.doc.id,
            recipe_name: e.payload.doc.data()["recipe_name"],
            recipe_cover_image: e.payload.doc.data()["recipe_cover_image"],
            ingredients: e.payload.doc.data()["ingredients"],
            instructions: e.payload.doc.data()["instructions"],
            status: e.payload.doc.data()["status"]
              ? e.payload.doc.data()["status"]
              : "show",
            serial: i - 1,
          };
        });
        this.recipes_list = this.recipes_list_temp;
        this.recipes_list = new MatTableDataSource(this.recipes_list);
        this.recipes_list.sort = this.leadsSort;
        this.recipes_list.paginator = this.leadsPaginator;
      });
  }

  test() {
    // remove this code after testing
    this.afs
      .collection("/recipes", (ref) =>
        ref
          .where("storeInMasterRecipe", "==", false)
          .orderBy("created_at", "desc")
      )
      .snapshotChanges()
      .subscribe((data) => {
        let i = 0;
        data.map(async (e) => {
          // uid: e.payload.doc.id
          const itemRefx2 = this.afs.doc("/recipes/" + e.payload.doc.id);
          const oldData = await itemRefx2.get().toPromise();
          itemRefx2.update({ storeInMasterRecipe: true });
        });
      });
    // remove this code after testing
  }

  view_recipe(uid) {
    this.router.navigate(["/admin/edit-recipe/" + uid]);
  }

  gotoRecipeApiTest() {
    this.router.navigate(["/store/recipe-api-test"]);
  }

  checkbox_single_select_event(event, i) {
    if (event.target.checked == true) {
      this.checkbox_delete_array[i] = true;
    } else {
      this.checkbox_delete_array[i] = false;
    }
  }
  async RecipeData(){
		await this.afs.collection('/recipes', ref => 
			ref.where("storeInMasterRecipe", "==", true)
      .orderBy("created_at", "desc")
		).get().toPromise().then(snapshot => {
			let i = 0;
			this.recipes_list_temp_data = snapshot.docs.map(e => {
				i++;
				return {
					uid: e.id,
					recipe_name: e.data()['recipe_name'],
					recipe_cover_image: e.data()['recipe_cover_image'],
					ingredients: e.data()['ingredients'],
					instructions: e.data()['instructions'],
					status: e.data()['status'] ? e.data()['status'] : "show",
					serial: i - 1,
				};
			});
		}).catch(error => {
			console.error("Error fetching recipes:", error);
		});
		
	}
	async checkbox_select_all_event(event)
	{
		await this.RecipeData()
    let value = event.target.checked;
    let cnt = this.recipes_list_temp.length;
    for (let i = 0; i < cnt; i++) {
      if (value == true) {
        this.checkbox_delete_array[i] = true;
      } else {
        this.checkbox_delete_array[i] = false;
      }
    }
  }

  toggle_change(event, uid) {
    let toggle_status = event.target.checked;
    let status = "";

    if (toggle_status == false) {
      status = "hide";
    } else {
      status = "show";
    }

    let value = {
      status: status,
    };

    let update_recipe_status = this.afs
      .doc("/recipes/" + uid)
      .set(value, { merge: true })
      .then((docRef) => {});

    this._snackBar.open("Status updated successfully!", "", {
      duration: 1000,
    });
  }

  goToAddNewRecipe() {
    this.router.navigate(["/store/recipe-add"]);
  }

  openDeleteModal() {
    if (this.checkbox_delete_array.length == 0) {
      this._snackBar.open("Error: Please select some products to delete!", "", {
        duration: 1500,
      });
      return false;
    }
    this.open(this.deleteModal, "modal-basic-title3");
  }

  async delete_products()
	{
		let element: HTMLElement = document.getElementsByClassName('delete_product_modal')[0] as HTMLElement;
		element.click();
		this._snackBar.open('Please wait while the products are being deleted successfully!', '', {
			duration: 2000,
		});
		this.loading=true;
		for(let d=0;d<this.checkbox_delete_array.length;d++){
      let val = this.checkbox_delete_array[d];
      if (val == true) {
        this.checkbox_delete_array[d] = 0;
        const itemRefx2 = this.afs.doc(
          "/recipes/" + this.recipes_list_temp[d].uid
        );
        const oldData = itemRefx2.get().toPromise();
        itemRefx2.update({ storeInMasterRecipe: false });
			}
		}
		this._snackBar.open("Recipes deleted successfully!",'',{
				duration: 1500,
			});
			this.checkbox_delete_array=[]
			this.checkbox_select_all=false;
      this.loading=false;
			this.recipes_list_temp_data=[]
	}

  open(content, title) {
    this.modalService.open(content, { ariaLabelledBy: title }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
