import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceFormatService {

  constructor() { }
  price_format(format,labelSize,price_label_value1,price_label_value2,price_label_value3,current_price_label_format){
    let price_label_svg
    if (format == 'format1') {
			price_label_svg = '<div id="format1" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
		}
		if (format == 'format2') {
			if (price_label_value1) {
				price_label_svg = '<div id="format2" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format2" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>';
			}
		}
		if (format == 'format3') {
			if (price_label_value1) {
				price_label_svg = '<div id="format3" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span class=><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format3" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
			}
		}
		if (format == 'format4') {
			if (price_label_value1) {
				price_label_svg = '<div id="format4" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format4" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
			}
		}
		if (format == 'format5') {
			if (price_label_value2) {
				price_label_svg = '<div id="format5" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format5" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
			}
		}
		if (format == 'format6') {
			price_label_value3 = price_label_value3 == '' ? 'Free' : price_label_value3
			if (price_label_value3 == 'Free') {
				price_label_svg = '<div id="format6" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format6" class="circular-card-deal-wrapper ' + labelSize + ' withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div></div></div></div>';
			}
		}
		if (current_price_label_format == 'format7') {
			if (price_label_value1) {
				price_label_svg = '<div id="format7" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format7" class="circular-card-deal-wrapper ' + labelSize + '"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
			}
		}
		if (current_price_label_format == 'format8') {
			price_label_svg = '<div id="format8" class="circular-card-deal-wrapper ' + labelSize + ' withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal" style="text-transform:unset;font-weight:950;line-height:0.8"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Market</span></div><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Price</span></div></div></div></div></div></div></div>';
		}
    return price_label_svg
  }
  selected_price_format(current_price_label_format,price_label_value1,price_label_value2,price_label_value3){
	let price_label_svg
	if (current_price_label_format == 'format1') {
		price_label_svg = '<div id="format1" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
		}
	if (current_price_label_format == 'format2') {
		if (price_label_value1) {
			price_label_svg = '<div id="format2" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container" style="font-size: 40px;"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format2" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format3') {
		if (price_label_value1) {
			price_label_svg = '<div id="format3" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format3" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format4') {
		if (price_label_value1) {
			price_label_svg = '<div id="format4" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format4" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format5') {
		if (price_label_value1) {
			price_label_svg = '<div id="format5" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format5" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format6') {
		if (price_label_value3 == 'Free') {
			price_label_svg = '<div id="format6" class="circular-card-deal-wrapper"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format6" class="circular-card-deal-wrapper withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div></div></div></div>';
		}
	}
	if (current_price_label_format == 'format7') {
		if (price_label_value1) {
			price_label_svg = '<div id="format7" class="circular-card-deal-wrapper withNumber"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format7" class="circular-card-deal-wrapper withNumber"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format8') {
			price_label_svg = '<div id="format8" class="circular-card-deal-wrapper withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal" style="text-transform:unset;font-weight:950;line-height:0.8"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Market</span></div><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Price</span></div></div></div></div></div></div></div>';
	}
	return price_label_svg
  } 
    
//Digital coupon sale price format
    sale_price(format,sale_price_label_value1,sale_price_label_value2,sale_price_label_value3){
		let sale_price_label_svg
		let sale_content_price_label_svg
		if (format == 'Sale Price format1') {
			if (sale_price_label_value1) {
				sale_content_price_label_svg='<div class="sell_title_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div></div></div></div>'
				sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2">' + sale_price_label_value1 + '</div><div class="price_format_data_3">' + sale_price_label_value2 + '</div></div></div></div>';
			} else {
				if(sale_price_label_value2){
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper sell_wrapper_format_1"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_2">' + sale_price_label_value1 + '</div><div class="price_format_data_3">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper sell_wrapper_format_1"></div>';
				}
			}
		}
		
		if (format == 'Sale Price format2') {
			if (sale_price_label_value2) {
				sale_content_price_label_svg='<div class="sell_title_wrapper sell_price_format2_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format2"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice" id="sale_price_for_value" >'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value" id="sale_price_doller">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value" id="sale_price_cent">'+ sale_price_label_value3 +'</div></div></div></div>'
				sale_price_label_svg = '<div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value">'+ sale_price_label_value3 +'</div></div></div></div>'
			} else {
				if(sale_price_label_value3){
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_2"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format2"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice" id="sale_price_for_value">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value" id="sale_price_doller">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value" id="sale_price_cent">'+ sale_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value">'+ sale_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
				}else{
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_2"><div class="sell_title">Sale<br> Price<div>'
					sale_price_label_svg = '<div class="price_format_wrapper"></div>';
				}
			}
		}
		return {
			sale_price_label_svg,
			sale_content_price_label_svg
		};		
	}
	selected_sale_price_format(current_sale_price_label_format,sale_price_label_value1,sale_price_label_value2,sale_price_label_value3){	
		let sale_content_price_label_svg
		let sale_price_label_svg
		if (current_sale_price_label_format == 'Sale Price format1') {
			if (sale_price_label_value1) {
				sale_content_price_label_svg='<div class="sell_title_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div></div></div></div>'
				sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2">' + sale_price_label_value1 + '</div><div class="price_format_data_3">' + sale_price_label_value2 + '</div></div></div></div>';
			} else {
				if(sale_price_label_value2){
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_2">' + sale_price_label_value1 + '</div><div class="price_format_data_3">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper"></div>';
				}
			}
		}
		
		if (current_sale_price_label_format == 'Sale Price format2') {
			if (sale_price_label_value2) {
				sale_content_price_label_svg='<div class="sell_title_wrapper sell_price_format2_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format2"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice" id="sale_price_for_value" >'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value" id="sale_price_doller">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value" id="sale_price_cent">'+ sale_price_label_value3 +'</div></div></div></div>'
				sale_price_label_svg = '<div class="sell_title_wrapper sell_price_format2_wrapper"><div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value">'+ sale_price_label_value3 +'</div></div></div></div>'
			} else {
				if(sale_price_label_value3){
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_2"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format2"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice" id="sale_price_for_value">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value" id="sale_price_doller">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value" id="sale_price_cent">'+ sale_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value">'+ sale_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
				}else{
					sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_2"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format2"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice" id="sale_price_for_value">'+ sale_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value" id="sale_price_doller">'+ sale_price_label_value2 +'</div><div class="format_2_offer_value" id="sale_price_cent">'+ sale_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					sale_price_label_svg = '<div class="sell_title_wrapper"></div>'
				}
			}
		};		
		return {
			sale_price_label_svg,
			sale_content_price_label_svg
		};		
	}

	//digital coupon final price format
	final_price(format,final_price_label_value1,final_price_label_value2,final_price_label_value3,){
		let final_content_price_label_svg;
		let final_price_label_svg;
		if (format == 'Final Price Format1') {
			if (final_price_label_value1) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div></div></div></div>'
				final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div></div></div></div>';
			} else {
				if (final_price_label_value2) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
				final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_doller"></div>';
				}
			}
		}
		
		if (format == 'Final Price Format2') {
			if (final_price_label_value2) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper" id="final_price_format2"><div class="price_format_2 "><div class="price_Format_2_dataprice" id="final_price_for_value">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value" id="final_price_doller">' +final_price_label_value2 +'</div><div class="format_2_offer_value" id="final_price_cent">' +final_price_label_value3 +'</div></div></div></div>'
				final_price_label_svg = '<div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value">'+ final_price_label_value2 +'</div><div class="format_2_offer_value">'+ final_price_label_value3 +'</div></div></div></div>'
			} else {
				if (final_price_label_value3) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper"><div class="price_format_2 final_price_wrraper_format2"><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_price_value">' +final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
				final_price_label_svg = '<div class="price_format_wrapper"><div class="price_format_2 sale_condition_change_format_2"><div class="price_Format_2_dataprice">'+ final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">'+ final_price_label_value2 +'</div><div class="format_2_offer_value">'+ final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
				}else{
					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper"><div class="price_format_2 final_price_wrraper_format2"><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_price_value">' +final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					final_price_label_svg = '<div class="price_format_wrapper"></div>';
				}
			}
		}
		 return{
			final_price_label_svg,
			final_content_price_label_svg
		 }
	}
	 
	selected_final_price_format(current_final_price_label_format,final_price_label_value1,final_price_label_value2,final_price_label_value3){
		let final_price_label_svg;
		let final_content_price_label_svg
		if (current_final_price_label_format == 'Final Price Format1') {
			if (final_price_label_value1) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div></div></div></div>'
				final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div></div></div></div>';
			} else {
				if(final_price_label_value2){
					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ final_price_label_value1 +'</div><div class="price_format_data_3">'+ final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"></div>';
				}
			}
		}
		
		if (current_final_price_label_format == 'Final Price Format2') {
			if (final_price_label_value2) {
				final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper"><div class="price_format_2 "><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_offer_value">' +final_price_label_value3 +'</div></div></div></div>'
				final_price_label_svg = '<div class="final_price_format_wrapper"><div class="price_format_2 "><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_doller_symbol">$</div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_offer_value">' +final_price_label_value3 +'</div></div></div></div>'
			} else {
				if(final_price_label_value3){
					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper"><div class="price_format_2 final_price_wrraper_format2"><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_price_value">' +final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					final_price_label_svg = '<div class="final_price_format_wrapper"><div class="price_format_2 final_price_wrraper_format2"><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_price_value">' +final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
				}else{

					final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper"><div class="price_format_2 final_price_wrraper_format2"><div class="price_Format_2_dataprice">' +final_price_label_value1 +'</div><div class="for_title"><span class="forma_2_F_text">F</span><span class="format_2_0_text">O</span><span class="format_2_R_text">R</span></div><div class="format_2_price_value">' +final_price_label_value2 +'</div><div class="format_2_price_value">' +final_price_label_value3 +'</div><div class="format_2_doller_symbol">¢</div></div></div></div>'
					final_price_label_svg = '<div class="final_price_format_wrapper"></div>'
				}
			}
		}
		return{
			final_price_label_svg,
			final_content_price_label_svg
		 }
	}
	
	//digital coupon price format
	digital_coupon_price_format(current_digital_coupon_price_label_format,digital_coupon_price_label_value1,digital_coupon_price_label_value2,digital_coupon_price_label_value3){
	     let digital_coupon_price_label_svg;
		 let digital_content_coupon_price_label_svg;
		if (current_digital_coupon_price_label_format == 'Digital Coupon') {
			if (digital_coupon_price_label_value1) {
				digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + digital_coupon_price_label_value2 + '</div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
				digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + digital_coupon_price_label_value2 + '</div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>';
			} else {
				if(digital_coupon_price_label_value2){	
					digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
					digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
				}else{
					digital_content_coupon_price_label_svg='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
					digital_coupon_price_label_svg = '<div class="digital_coupon_data"></div>';
				}
			}
		}
		 return{
			digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg
		 }
	}

	selected_digital_coupon_price(current_digital_coupon_price_label_format,digital_coupon_price_label_value1,digital_coupon_price_label_value2,digital_coupon_price_label_value3){
		let digital_coupon_price_label_svg;
		let digital_content_coupon_price_label_svg;
		if (current_digital_coupon_price_label_format == 'Digital Coupon') {
			if (digital_coupon_price_label_value1) {
				digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + digital_coupon_price_label_value2 + '</div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
				digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + digital_coupon_price_label_value2 + '</div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>';
			} else {
				if (digital_coupon_price_label_value1) {
					digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
					digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
				}else{
					digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + digital_coupon_price_label_value3 + '</div></div></div>'
					digital_coupon_price_label_svg = '<div class="digital_coupon_data"></div>'
				}
			}
		}
		 return{
			digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg
		 }
	}
}
