<div class="modal_div">
  <div class="lib-button">
    <div class="header-content d-flex justify-content-between mb-2">
      <h1 mat-dialog-title class="mb-1 headerText">{{ headerText }}</h1>
      <div class="model_buttons">
        <button *ngIf="data?.from !='recipebox' && data?.from != 'digital-circular-library'"
          class="btn-success add_btn_style" [disabled]="buttonDisabled" (click)="filter_saved_items()">Add</button>
        <button *ngIf="data?.from !='recipebox' && data?.from == 'digital-circular-library'"
          class="btn-success add_btn_style" [disabled]="buttonDisabled" (click)="filter_saved_items_library()">Add
          Image</button>
        <button *ngIf="data?.from =='recipebox' && data?.from != 'digital-circular-library'"
          class="btn-success add_btn_style" [disabled]="buttonDisabled"
          (click)="filter_saved_items_from_recipebox()">Add</button>
        <button class="btn-danger add_btn_style" (click)="close_dialog()">Close</button>
      </div>
    </div>
    <div class="listing-buttons">
      <button *ngIf="data?.from !='recipebox'" class="ml-3 store-btn"
        [ngClass]=" tabName == 'store-item' ? 'high-tabs' : '' " (click)="storeItem()">Store Products</button>
      <button *ngIf="data?.from !='recipebox'" class="ml-3 store-btn"
        [ngClass]=" tabName == 'save-item' ? 'high-tabs' : '' " (click)="saveItem()">Saved Products</button>
      <button *ngIf="data?.from !='recipebox'" class="ml-3 store-btn"
        [ngClass]=" tabName == 'master-item' ? 'high-tabs' : '' " (click)="masterLibrary()">Master Library</button>
      <button *ngIf="data?.from !='recipebox'" class="ml-3 store-btn"
        [ngClass]=" tabName == 'video-item' ? 'high-tabs' : '' " (click)="videoLibrary()">Video List</button>
      <button *ngIf="data?.from !='recipebox'" class="ml-3 store-btn"
        [ngClass]=" tabName == 'master-video-item' ? 'high-tabs' : '' " (click)="masterVideoLibrary()">Master Video
        List</button>
    </div>
  </div>
</div>

<div class="col-md-4">
  <div class="filter-and-search-area d-flex">
    <div class="dropdown">
      <div class="select-access-box">
        <span _ngcontent-eke-c147=""><img _ngcontent-eke-c147=""
            src="../../assets/images/search-icon-for-placeholder.png" alt=""></span>
        <input id="SearchForm" type="text" placeholder="Search Product" class="form-control search-form"
          style="padding-left: 40px;" (keyup.enter)="doFilter($event.target.value)">
      </div>
    </div>
  </div>
</div>

<div *ngIf="multiple_checked" class="col-md-12">
  <span class="text-danger">Please select only one product</span>
</div>

<div class="visualization-table col-12 table-responsive"
  style="padding-top: 0px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">


  <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort"
    [dataSource]="main_items" style="padding-top:0;">
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;">
        <div *ngIf="data?.from !='recipebox' && data?.from != 'digital-circular-library'" class="recipe-checkbox">
          <input type="checkbox" id="" [ngModel]="checkbox_select_all"
            (click)="checkbox_select_all_event($event, main_items)">
        </div>
      </th>
      <td mat-cell *matCellDef="let element;let i = index;">
        <div class="recipe-checkbox" *ngIf="tabName == 'master-item'">
          <input type="checkbox" id="" class="checkbox-delete-{{i}}" [(ngModel)]="checkbox_checked_item[element.serial]"
            (click)="checkbox_single_select_event($event,i, element.serial)">
        </div>
        <div class="recipe-checkbox"
          *ngIf="tabName == 'save-item' || tabName == 'video-item' || tabName == 'master-video-item' || tabName == 'store-item'">
          <input type="checkbox" id="" class="checkbox-delete-{{element.serial}}"
            [(ngModel)]="checkbox_checked_item[element.serial]"
            (click)="checkbox_single_select_event($event,i,element.serial)">
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;"># </th>
      <td mat-cell *matCellDef="let element; let i = index;"> {{element.serial +1}} </td>
    </ng-container>
    <ng-container matColumnDef="upc">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;"> UPC </th>
      <td mat-cell *matCellDef="let element">{{element.upc_code}}</td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;"> Image </th>
      <td mat-cell *matCellDef="let element">
        <a href="{{element.product_image}}&size=418&thumbnail=true&palette=RGB" target="_blank"
          *ngIf="element.product_image !=''">
          <ng-container *ngIf="element.isVideo == true; else elseblock">
            <video src="{{element.product_image}}"
              style="margin-bottom: 1rem;border-radius: 5px;height:24px;width:24px;"></video>
          </ng-container>
          <ng-template #elseblock>
            <img src="{{element.product_image}}&size=418&thumbnail=true&palette=RGB"
              style="margin-bottom: 1rem; border-radius: 5px;height:24px;width:24px;">
          </ng-template>
        </a>
        <img src="assets/images/Rectangle 316.png"
          style="    margin-bottom: 1rem;    border-radius: 5px;height:24px;width:24px;"
          *ngIf="element.product_image ==''">
      </td>
    </ng-container>
    <ng-container matColumnDef="product_desc">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;"> Product
        Description </th>
      <td mat-cell *matCellDef="let element">{{element.product_description}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;"> Category
      </th>
      <td mat-cell *matCellDef="let element">{{element.category}}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef style="padding-bottom:15px;font-weight: 700;">Price</th>
      <td mat-cell *matCellDef="let element">{{element.cost}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
  <mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="pageSize"
    [pageIndex]="pageIndex" [length]="totalItems" (page)="onPageChange($event)"></mat-paginator>
</div>
<div class="loader" *ngIf="popup_loading">
  <div class="loading">Loading&#8230;</div>
</div>