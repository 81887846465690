<div style="margin-left:1rem;">
	<img src="{{recipe_data.image}}" style="height:130px;margin-top:1rem;margin-bottom:1rem;border-radius:15px;">
	<h3 style="font-weight:700;">{{recipe_data.title}}</h3>

	<h4 style="margin-top:2rem;"><b>Instructions</b></h4>
	<p *ngFor="let data of recipe_data.analyzedInstructions[0].steps; let i = index;">
		Step {{i+1}}: {{data.step}}
	</p>

	<h4><b>Ingredients</b></h4>
	<div class="row">
		<div class="col-2"  *ngFor="let ingredients of recipe_data.extendedIngredients">
			<div class="card" style="margin-bottom:1rem;" >
				<p style="padding:0;margin:0;text-align:center;">{{ingredients.name}}</p>
				<img src="https://spoonacular.com/cdn/ingredients_100x100/{{ingredients?.image}}" style="height:120px;object-fit: contain;">
			</div>
		</div>
	</div>
	
</div>