<div class="pagecontent-wrapper">
	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<div class="row no-gutters">
				<div class="visualization-heading col-12">
					
					<!-- <div class="heading-info">
						<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Admin Dashboard</h4>
					</div> -->
				</div>
			</div>
		</div>
	</div>

	<div class="row" style="margin-left: 0;margin-top:1rem;">
		<div class="cards" style="width:100%;">
      <div class="row">
        <div class="col-md-2">
          <div class="admin-card">
            <div class="admin-store-icon"><img src="../../assets/images/home-svg.svg" alt=""></div>
            <div class="admin-store-count">
              <h3 class="mb-0">110</h3>
            </div>
            <div class="card-sub-headline"><span>Total Stores</span></div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="admin-card">
            <div class="admin-store-icon"><img src="../../assets/images/home-svg.svg" alt=""></div>
            <div class="admin-store-count">
              <h3 class="mb-0">110</h3>
            </div>
            <div class="card-sub-headline"><span>Total Stores</span></div>
          </div>
        </div>
      </div>
    </div>


	</div>
</div>


