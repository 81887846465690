<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title4" style="font-size: 18px;"><i class="fas fa-chevron-left"
                style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete
            Recipes</h4>
        <button type="button" class="close delete_product_modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>


    <div class="modal-body">

        <p>Are you sure want to delete all the selected recipes? </p>
        <button class="btn btn-danger float-right" (click)="delete_products()">Yes, Delete them </button>
        <button class="btn btn-light float-right" style="margin-right: 1rem;" (click)="modal.dismiss('Cross click')">No,
            Don't Delete</button>

    </div>


</ng-template>


<div class="content">
    <section class="recipe-section">

        <div class="recipes-headline mb-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="headline">
                        <h5>Recipes</h5>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="add-delete-btn">
                        <a (click)="openDeleteModal()" style="cursor:pointer;" *ngIf="checkbox_delete_array.length > 0 && this.recipes_list_temp_data.length>0">
                            <img src="../../assets/images/delete-icon.png" alt="delete-icon"> Delete
                        </a>
                        <!-- <a (click)="test()" class="add-new-recipe-button" style="cursor:pointer;">test</a> -->
                        <!-- <a (click)="goToAddNewRecipe()" class="add-new-recipe-button" style="cursor:pointer;">Add New
                            Recipe</a> -->
                        <!-- <a  (click)="gotoRecipeApiTest()" class="btn btn-dark" style="color: #fff;cursor:pointer;">Recipe API test</a> -->
                    </div>
                </div>
            </div>
        </div>



        <div class="row no-gutters" style="margin-top: 1rem;background: #f7f7f7;">
            <div class="visualization-table col-12 table-responsive"
                style="padding-top: 0px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">


                <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort"
                    [dataSource]="recipes_list" style="padding-top:0;">


                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="padding-left:24px;font-weight: 700;">
                            <div class="recipe-checkbox">
                                <input type="checkbox" id="" [ngModel]="checkbox_select_all"
                                    (click)="checkbox_select_all_event($event)">
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index;"
                            style="width:10px;vertical-align: middle;padding-left: 24px;">
                            <div class="recipe-checkbox">
                                <input type="checkbox" id="" class="checkbox-delete-{{element.serial}}"
                                    [(ngModel)]="checkbox_delete_array[element.serial]"
                                    (click)="checkbox_single_select_event($event,element.serial)">
                            </div>
                        </td>
                    </ng-container>



                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="font-weight: 700;"> Image </th>
                        <td mat-cell *matCellDef="let element" style="width:182px;">
                            <a href="{{element.recipe_cover_image}}" target="_blank"
                                *ngIf="element.recipe_cover_image !=''">
                                <img src="{{element.recipe_cover_image}}"
                                    style="    background: #c4c4c4; margin-bottom: 1rem;    border-radius: 5px;height:80px;width:142px; max-width:142px;margin:10px 0px;">
                            </a>
                            <img src="assets/images/Rectangle 316.png"
                                style="    margin-bottom: 1rem;    border-radius: 5px;height: 80px;width: 148px;background:#cecece;"
                                *ngIf="element.recipe_cover_image ==''">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="font-weight: 700;"> </th>
                        <td mat-cell *matCellDef="let element" style="vertical-align:middle; font-size: 17px;
    font-weight: 600;color:#666d77;">{{element.recipe_name}}</td>
                    </ng-container>


                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="font-weight: 700;"> Show/Hide </th>
                        <td mat-cell *matCellDef="let element" style="vertical-align:middle;">

                            <label class="switch" style="margin-top: 3px;">
                                <input type="checkbox" (change)="toggle_change($event,element.uid)" checked
                                    *ngIf="element.status=='show'">
                                <input type="checkbox" (change)="toggle_change($event,element.uid)"
                                    *ngIf="element.status=='hide'">
                                <span class="slider round"></span>
                            </label>

                        </td>
                    </ng-container>


                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="font-weight: 700;">Actions</th>
                        <td mat-cell *matCellDef="let element" style="vertical-align:middle;">
                            <button class="btn btn-sm btn-outline-success" (click)="view_recipe(element.uid)">View/Edit
                                Details</button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
                </table>
                <mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]"
                    [pageSize]="10"></mat-paginator>
            </div>
        </div>


    </section>
</div>
<div class="loader" *ngIf="loading">
	<div class="loading"> Loading&#8230;
	</div>
</div>