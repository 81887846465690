import { AuthService } from "../../shared/services/auth.service";
import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ 
    // Shared styles
    './styles/visualization-heading.scss',
    './styles/banner-callout.scss',
    // Page styles
    // Partial styles
    './styles/layout-c-specific.scss',
    './dashboard.component.scss'
  ]
})
export class DashboardComponent implements OnInit {

stat_dieter:any = 0;
stat_order:any = 0;
stat_appointment:any = 0;
stat_diary:any = 0;
stat_measurement:any = 0;
stat_message:any =0;
stat_goal:any = 0;
stat_user:any= 0;
stat_consultant:any = 0;
users:any;
consultants:any;
appointments:any;
dieters:any;
orders:any;
diary:any;
measurements:any;
goals:any;
messages:any;
stat_product:any = 0;
products:any;
current_view:any = 'all';
user:any;
enquirys:any;
tableColumns  :  string[] = ['created_at','name', 'email','message'];
@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;

 constructor( @Inject(PLATFORM_ID) private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  public afs: AngularFirestore,
  private formBuilder: FormBuilder,
  public authService: AuthService)  { }

  ngOnInit(): void {
  
    this.afs.collection('/contact_us/', ref => ref.orderBy("created_at","desc")).snapshotChanges().subscribe(data => {
        this.enquirys = data.map(e => {
          return {
            uid: e.payload.doc.id,
            contact_by_name: e.payload.doc.data()['contact_by_name'],
            contact_by_email: e.payload.doc.data()['contact_by_email'],
            contact_msg: e.payload.doc.data()['contact_msg'],
            created_at: e.payload.doc.data()['created_at'],
            contact_by_photo: e.payload.doc.data()['contact_by_photo']

          };
        });
      this.enquirys = new MatTableDataSource(this.enquirys);
      this.enquirys.sort = this.leadsSort;
      this.enquirys.paginator = this.leadsPaginator;
    });
  }

  changeView(data){
    this.current_view = data;
    this.stat_measurement = 0;
    this.stat_diary = 0;
    let current_year = new Date().getFullYear();
    let current_month = new Date().getUTCMonth()+1;

    let now = new Date();
    let nowts = new Date().getTime()/1000;
    let quarter = Math.floor((now.getMonth() / 3));
    let firstDate = new Date(now.getFullYear(), quarter * 3, 1).getTime()/1000;
   // let endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    let last30 = new Date();
    let yearstart = new Date(current_year+'-01-01').getTime()/1000;
    last30.setDate(last30.getDate() - 30);

    let last30ts = Math.round(new Date(last30).getTime()/1000);
    if(data == 'all')
    {
      this.ngOnInit();
      return false;
    }
    let start_date_range = 0;
    let end_date_range = 0;
    if(data == 'month')
    {
      start_date_range = last30ts;
      end_date_range = nowts;
    }

    if(data == 'quarter')
    {
      start_date_range = firstDate;
      end_date_range = nowts;
    }

    if(data == 'year')
    {
      start_date_range = yearstart;
      end_date_range = nowts;
    }

    this.afs.collection('/users', ref => ref.where('created_timestamp','>=',start_date_range).where('created_timestamp','<=',end_date_range).orderBy('created_timestamp', 'desc')).snapshotChanges().subscribe(data => {
            this.users = data.map(e => {
              return{
                uid: e.payload.doc.id,
                created_timestamp:e.payload.doc.data()['created_timestamp']
              };
            });

        this.stat_user = this.users.length;
      });


    this.afs.collection('/dieters',ref=> ref.where('joined_date','>=',start_date_range).where('joined_date','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.dieters = data.map(e => {
              return{
                uid: e.payload.doc.id,
                joined_date: e.payload.doc.data()['joined_date'],
              };
            });

        this.stat_dieter = this.dieters.length;
        this.stat_diary = 0;
        this.stat_measurement = 0;
        for(let result of this.dieters){
            this.afs.collection('/dieters/'+result.uid+'/food_diary').snapshotChanges().subscribe(data => {
            this.diary = data.map(e => {
                return{
                  uid: e.payload.doc.id,
                };
              });
              this.stat_diary += this.diary.length;
            });

            this.afs.collection('/dieters/'+result.uid+'/my_measurements').snapshotChanges().subscribe(data => {
            this.measurements = data.map(e => {
                return{
                  uid: e.payload.doc.id,
                };
              });
              this.stat_measurement += this.measurements.length;
            });


        }
    });

    this.afs.collection('/orders',ref=>ref.where('order_created','>=',start_date_range).where('order_created','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.orders = data.map(e => {
              return{
                uid: e.payload.doc.id,
                order_created: e.payload.doc.data()['order_created'],
              };
            });

        this.stat_order = this.orders.length;
    });

    this.afs.collection('/goals',ref => ref.where('goal_created','>=',start_date_range).where('goal_created','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.goals = data.map(e => {
              return{
                uid: e.payload.doc.id,
                goal_created: e.payload.doc.data()['goal_created'],
              };
            });

        this.stat_goal = this.goals.length;
    });

    this.afs.collection('/messages',ref => ref.where('sent_date','>=',start_date_range).where('sent_date','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.messages = data.map(e => {
              return{
                uid: e.payload.doc.id,
                sent_date: e.payload.doc.data()['sent_date'],
              };
            });

        this.stat_message = this.messages.length;
    });

    this.afs.collection('/products',ref=> ref.where('created_timestamp','>=',start_date_range).where('created_timestamp','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.products = data.map(e => {
              return{
                uid: e.payload.doc.id,
              };
            });

        this.stat_product = this.products.length;
      });

    this.afs.collection('/appointments',ref=>ref.where('appointment_timestamp','>=',start_date_range).where('appointment_timestamp','<=',end_date_range)).snapshotChanges().subscribe(data => {
            this.appointments = data.map(e => {
              return{
                uid: e.payload.doc.id,
                appointment_timestamp: e.payload.doc.data()['appointment_timestamp'],
              };
            });

        this.stat_appointment = this.appointments.length;
  });

    // this.afs.collection('/consultants').snapshotChanges().subscribe(data => {
    //         this.consultants = data.map(e => {
    //           return{
    //             uid: e.payload.doc.id,
    //           };
    //         });

    //     this.stat_consultant = this.consultants.length;
    //   });


    
  }

}
