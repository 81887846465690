import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-add-new-group',
  templateUrl: './add-new-group.component.html',
  styleUrls: ['./add-new-group.component.scss']
})
export class AddNewGroupComponent implements OnInit {

  groupaddForm: FormGroup;
  selectedStore = [];
  submitted = false;
  loading:boolean = false;
  closeResult:any;
  modalRef:any;
  create_warehouse_form:FormGroup;
  selected_login:any;
  user:any;
  users:any;
  stores_list:any;

  constructor(
    public afs: AngularFirestore,
    private router:Router,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
  ) { 
    this.groupaddForm = new FormGroup({
			groupName: new FormControl(null, Validators.required),
      store      : new FormControl(null, Validators.required)
	  });
  }

  ngOnInit(): void {
			this.user = JSON.parse(localStorage.getItem('userrole'));
			this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
        this.users = data.map(e => {
          return{
            uid: e.payload.doc.id,
            displayName: e.payload.doc.data()['displayName'],
            email : e.payload.doc.data()['email'],
            branch : e.payload.doc.data()['branch'],
            role: e.payload.doc.data()['role']
          };
        });
			});
      this.afs.collection('/stores',ref=>ref.where('type','==', 'main_store').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
        this.stores_list = data.map(e => {
          return{
            uid: e.payload.doc.id,
            company_name: e.payload.doc.data()['company_name'],
            email: e.payload.doc.data()['email'],
            group_id: e.payload.doc.data()['group_id'],
            group_name: e.payload.doc.data()['group_name'],
            phone: e.payload.doc.data()['phone'],
            store_id: e.payload.doc.data()['store_id'],
            store_owner_name: e.payload.doc.data()['store_owner_name'],
            warehouse_id: e.payload.doc.data()['warehouse_id'],
            warehouse_name: e.payload.doc.data()['warehouse_name'],
            created_at:e.payload.doc.data()['created_at'],
            can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
            status:e.payload.doc.data()['status'],
            user_id:e.payload.doc.data()['user_id'],
            branch_count: e.payload.doc.data()['branch_count']
          };
        });	
        this.selectAllForDropdownItems(this.stores_list)
      });
  }
  
  get registerFormControl() {
    return this.groupaddForm.controls;
  }

  goToLogin() {
    window.location.href = '/login';
  }
  ChangePassword() {
    $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
    $('#exampleModal').css('opacity', '1');
  }
  backToUser(){
    this.router.navigate(['/admin/branch-group']);
  }

  select_branch(event){
  }

  async group_add(data) {
    this.loading = true;
    this.submitted = true;
    if(this.groupaddForm.valid){
			let groupdata = {
				groupName: data.groupName?data.groupName:"",
        store : data.store,
        created_at: new Date()
			};
			let useradd = await this.afs.collection('/group_list').add(groupdata).then(docRef => {
        const itemRefx= this.afs.doc('/group_list/'+docRef.id);
			    itemRefx.update({ uid: docRef.id});
      })
      this.loading = false;
			this.router.navigate(['/admin/branch-group']);
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
