<mat-sidenav-container *ngIf="!ghostmode">

	<mat-sidenav 
class="admin-main-sidenav fixed-sidenav clear-sidenav-background"
#mainSidenav
[mode]="mainSideMenuMode"
[opened]="mainSidenavOpened"
position="start"
(openedStart)="openedStart()"
(openedChange)="mainSidenavToggled($event)" style="box-shadow: 0px 0px 9px 1px #ddd !important;">
<app-main-menu class="show-branding use-active-item-line-decoration"></app-main-menu>
</mat-sidenav>

<mat-sidenav-content class="admin-main-content" style="box-shadow: 0px 0px 9px 1px #ddd !important;">

<app-top-navbar class="admin-top-navbar show-bare-branding add-user-divider" style="box-shadow: 0px 0px 9px 1px #ddd;height:64px;
background: #fff;
"></app-top-navbar>
<router-outlet></router-outlet>
</mat-sidenav-content>
<mat-sidenav class="admin-alt-sidenav fixed-sidenav" #altSidenav mode="over" opened="false" position="end" (openedChange)="altSidenavToggled($event)" style="box-shadow: 0px 0px 9px 1px #ddd !important;">
<app-dynamic-menu></app-dynamic-menu>
</mat-sidenav>
</mat-sidenav-container>






<mat-sidenav-container *ngIf="ghostmode">

	<mat-sidenav 
class="admin-main-sidenav fixed-sidenav clear-sidenav-background"
#mainSidenav
[mode]="mainSideMenuMode"
[opened]="mainSidenavOpened"
position="start"
(openedStart)="openedStart()"
(openedChange)="mainSidenavToggled($event)" style="box-shadow: 0px 0px 9px 1px #ddd !important;margin-top: 4rem;">
<app-main-menu class="show-branding use-active-item-line-decoration"></app-main-menu>
</mat-sidenav>

<mat-sidenav-content class="admin-main-content" style="box-shadow: 0px 0px 9px 1px #ddd !important;margin-left: 0 !important;min-height: 0 !important;z-index: 6;">
	  <div class="ghostbar" style="min-height: 3rem;background:#4891E0;position: fixed;width: 100%; top:0;   box-shadow: inset 0px 0px 8px 0px #2c67a8;">
	  	<div class="row mat-toolbar-row" style="width:100%;margin:0;">
	  		<div class="col-3" style="cursor: pointer;"  (click)="back_to_admin()">
	  			<p style="    font-size: 18px;margin-bottom: 0;font-weight: 500;color:#fff;"><img src="assets/imgs/icon-left.svg" style="margin-right: 10px;margin-top: -3px;height: 26px;">Back to Admin Panel</p>
	  		</div>
	  		<div class="col-6 text-center" >
	  			<p class="text-center"  style="margin-bottom: 0;    font-size: 20px;font-weight: 600;color: #fff;">You are viewing Store Panel</p>
	  		</div>
	  		<div class="col-3 text-right" style="padding-right: 0;">
	  			<p style="margin-bottom: 0;font-size: 16px;font-weight: 600;color:#fff;">
	  				<img src="./assets/imgs/user-placeholder.png" style="height:30px;width: 30px;margin-right: 8px;border-radius: 32px;border:1px solid #71b01d;" *ngIf="user && user.photoURL == ''">
	  				<img src="{{user && user.photoURL}}" style="height:30px;width: 30px;margin-right: 8px;border-radius: 32px;border:1px solid #71b01d;" *ngIf="user && user.photoURL != ''">

	  				{{user && user?.displayName}} <img src="assets/imgs/icon-logout.svg" style="float:right;text-align: right;margin-left: 1rem;margin-top: 3px;cursor: pointer;" (click)="authService.SignOut()"></p>

	  		</div>
	  	</div>
		</div>
</mat-sidenav-content>
<mat-sidenav-content class="admin-main-content" style="box-shadow: 0px 0px 9px 1px #ddd !important;">

<app-top-navbar class="admin-top-navbar show-bare-branding add-user-divider" style="box-shadow: 0px 0px 9px 1px #ddd;height:64px;
background: #fff;margin-top: 4rem;
"></app-top-navbar>
<router-outlet></router-outlet>
</mat-sidenav-content>
<mat-sidenav class="admin-alt-sidenav fixed-sidenav" #altSidenav mode="over" opened="false" position="end" (openedChange)="altSidenavToggled($event)" style="box-shadow: 0px 0px 9px 1px #ddd !important;">
<app-dynamic-menu></app-dynamic-menu>
</mat-sidenav>
</mat-sidenav-container>
