<div class="pagecontent-wrapper">
	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<div class="leads-visualization visualizations-section">
				<div class="row no-gutters">
					<div class=" col-6">
						<div class="heading-info">
							<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">{{store_data && store_data[0]?.store_name}}</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row" style="margin-left: 0;margin-top:1rem;">
		<div class="col-3">
			<div class="card" style="box-shadow: 0px 0px 8.65145px rgb(134 134 134 / 25%);border-radius: 5px;">
				<div class="row" style="padding: 1rem;">
				<div class="col-9 ">
					<p style="margin-bottom: 0;font-size:25px;font-weight: 500">10000</p>
					<p style="color:#5E9A0B;margin-bottom: 0;"> Total Employees</p>
				</div>
				<div class="col-3 text-right">
					<img src="assets/imgs/stat-people.svg" style="height: 40px;">
				</div>
				</div>
			</div>
		</div>
		<div class="col-3">
			<div class="card" style="box-shadow: 0px 0px 8.65145px rgb(134 134 134 / 25%);border-radius: 5px;">
				<div class="row" style="padding: 1rem;">
				<div class="col-9 ">
					<p style="margin-bottom: 0;font-size:25px;font-weight: 500">56/125</p>
					<p style="color:#5E9A0B;margin-bottom: 0;">Pending Incidents</p>
				</div>
				<div class="col-3 text-right">
					<img src="assets/imgs/stat-incident.svg" style="height: 40px;">
				</div>
				</div>
			</div>
		</div>
		<div class="col-3">
			<div class="card" style="box-shadow: 0px 0px 8.65145px rgb(134 134 134 / 25%);border-radius: 5px;">
				<div class="row" style="padding: 1rem;">
				<div class="col-9 ">
					<p style="margin-bottom: 0;font-size:25px;font-weight: 500">41</p>
					<p style="color:#5E9A0B;margin-bottom: 0;"> Pending Logs</p>
				</div>
				<div class="col-3 text-right">
					<img src="assets/imgs/stat-log.svg" style="height: 40px;">
				</div>
				</div>
			</div>
		</div>
	</div>
</div>

