import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {


  modalRef:NgbModalRef;
    storeaddForm: FormGroup;
    group_add_form:FormGroup;
    filteruserform: FormGroup;
    warehouse_add_form:FormGroup;
    filter_store_form:FormGroup;
    store_update_form:FormGroup;
  
    completedMarketingTasks: number;
    completedSalesTasks: number;
    completedCustomerSupportTasks: number;
    isBrowser: boolean;
    statistics: any;
    topSellers: any;
    laggingSellers: any;
    articles: any;
    tasks: any;
    users:any;
    currentTab:any;
      
    datasource = [];
    filter_status: any = '';
    currentrole:any = 'all';
    warehouselist:any;
    groupaddlist:any;
    add_select_group:any;
    add_select_warehouse:any;
    groups_list:any;
    temp_users_data:any;
    temp_warehouses:any;
    temp_check_dup_location:any;
    store_get_data:any;
    temp_user:any
  
    warehouseaddlist:any;
    leadsTableDataSource: any;
    statuslist:any= [{name: "Client"},{name:"Trial"}];
    tableColumns  :  string[] = ['id', 'Name','Store','actions'];
    closeResult: string;
    title = 'appBootstrap';
  
  
    @ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
    @ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
    @ViewChild('groupModal') groupModal;
    @ViewChild('warehouseModal') warehouseModal;
    @ViewChild('mymodal') mymodal;
    @ViewChild('closebtn', {static: true}) closebtn:ElementRef;
    @ViewChild('storeupdateModal') storeupdateModal;
  
    constructor( @Inject(PLATFORM_ID) private platformId: object,
    private router:Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private _snackBar: MatSnackBar) {
  
      this.filter_store_form = new FormGroup({
        warehouse_id: new FormControl(0),
        store_id: new FormControl(),
      });
  
      this.group_add_form = new FormGroup({
        group_name: new FormControl(null, Validators.compose([Validators.required])),
        go_name: new FormControl(null, Validators.compose([Validators.required])),
        go_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
        go_phone: new FormControl(),
      });
     }
  
    ngOnInit() {
      this.afs.collection('/group_list',ref => ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
        this.groups_list = data.map(e => {
          return{
            uid: e.payload.doc.id,
            groupName: e.payload.doc.data()['groupName'],
            store : e.payload.doc.data()['store']
          };
        });
        this.groups_list = new MatTableDataSource(this.groups_list);
        this.groups_list.sort = this.leadsSort;
        this.groups_list.paginator = this.leadsPaginator;
      });
    }
      
    deleteGroup(id) {
      let del_grp = this.afs.doc('/group_list/'+id).delete();
      this._snackBar.open('Group deleted successfully', '', {
      duration: 1000,
      });
    }
  
    filter_store(value)
    {
      if(value.store_id == null && value.warehouse_id == null)
      {
        this._snackBar.open("Error: No parameters to filter", '', {
        duration: 3000,
        });
        return false;
      }
      if(value.warehouse_id == 0 && value.store_id != "" && value.store_id != null)
      {
        //ONLY FILTER BY STORE ID
        this.groups_list.filter = value.store_id.trim().toLocaleLowerCase();
      } 
      else if(value.warehouse_id != "" && value.store_id != "" && value.warehouse_id != null && value.store_id != null )
      {
        //FILTER BY BOTH WAREHOUSE ID AND STORE ID
        this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
      this.groups_list = data.map(e => {
        return{
        uid: e.payload.doc.id,
        company_name: e.payload.doc.data()['company_name'],
        email: e.payload.doc.data()['email'],
        group_id: e.payload.doc.data()['group_id'],
        group_name: e.payload.doc.data()['group_name'],
        phone: e.payload.doc.data()['phone'],
        store_id: e.payload.doc.data()['store_id'],
        store_owner_name: e.payload.doc.data()['store_owner_name'],
        warehouse_id: e.payload.doc.data()['warehouse_id'],
        warehouse_name: e.payload.doc.data()['warehouse_name'],
        created_at:e.payload.doc.data()['created_at'],
        can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
        status:e.payload.doc.data()['status'],
        user_id:e.payload.doc.data()['user_id'],
        branch_count: e.payload.doc.data()['branch_count'],
        parent_id: e.payload.doc.data()['parent_id']
        };
      }); 

        this.groups_list = new MatTableDataSource(this.groups_list);
        this.groups_list.sort = this.leadsSort;
        this.groups_list.paginator = this.leadsPaginator;
        this.groups_list.filter = value.store_id.trim().toLocaleLowerCase();
      });
      }
      else if(value.warehouse_id != 0 && value.warehouse_id != null)
      {
        //FILTER BY  WAREHOUSE ID 
        this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
          this.groups_list = data.map(e => {
            return{
            uid: e.payload.doc.id,
            company_name: e.payload.doc.data()['company_name'],
            email: e.payload.doc.data()['email'],
            group_id: e.payload.doc.data()['group_id'],
            group_name: e.payload.doc.data()['group_name'],
            phone: e.payload.doc.data()['phone'],
            store_id: e.payload.doc.data()['store_id'],
            store_owner_name: e.payload.doc.data()['store_owner_name'],
            warehouse_id: e.payload.doc.data()['warehouse_id'],
            warehouse_name: e.payload.doc.data()['warehouse_name'],
            created_at:e.payload.doc.data()['created_at'],
            can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
            status:e.payload.doc.data()['status'],
            user_id:e.payload.doc.data()['user_id'],
            branch_count: e.payload.doc.data()['branch_count'],
            parent_id: e.payload.doc.data()['parent_id']
            };
          }); 
          this.groups_list = new MatTableDataSource(this.groups_list);
          this.groups_list.sort = this.leadsSort;
          this.groups_list.paginator = this.leadsPaginator;
        });
      } else{
        this.ngOnInit();
      }
      this._snackBar.open("Filter applied successfully", '', {
        duration: 1000,
      });
    }
    
    addGroup(){
      this.router.navigate(['/user-add']);
    }
  
    addNewGroup()
    {
      this.router.navigate(['/admin/add-new-group']);
    }

    edit_group(uid)
    {
      this.router.navigate(['/admin/group-edit/'+ uid]);
    }
    
}
