<div class="master-recipe-list">
    <section class="recipe-section">

        <div class="recipes-headline mb-3">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="master_recipes_heading">
                        <div class="headline">
                            <h5>Master Recipes</h5>
                        </div>
                        <div class="action-buttons">
                            <div class="model_buttons">
                                <button class="btn-success add_btn_style" (click)="add_recipe_to_store()">Add</button>
                                <!-- <button *ngIf="data?.from =='recipebox'" class="btn-success add_btn_style" (click)="filter_saved_items_from_recipebox()">Add</button> -->
                                <button class="btn-danger add_btn_style" (click)="close_dialog()">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="filter-and-search-area filter_search_input d-flex">
                    <div class="dropdown">
                        <div class="select-access-box">
                            <span _ngcontent-eke-c147=""><img _ngcontent-eke-c147=""
                                    src="../../assets/images/search-icon-for-placeholder.png" alt="" class="table_search_icon"></span>
                            <input id="SearchForm" type="text" placeholder="Search Product"
                                class="form-control search-form" 
                                (keyup)="doFilter($event.target.value)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters" style="margin-top: 1rem;background: #f7f7f7;">
            <div class="visualization-table col-12 table-responsive"
                style="padding-top: 0px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">
                <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort"
                    [dataSource]="master_recipes_list" style="padding-top:0;">
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef 
                            style="padding-bottom:15px;font-weight: 700;">
                            <div class="recipe-checkbox">
                                <input type="checkbox" id="" [ngModel]="checkbox_select_all"
                                    (click)="checkbox_select_all_event($event)">
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index;"
                            style="vertical-align: middle;">
                            <div class="recipe-checkbox">
                                <input type="checkbox" id="" class="checkbox-delete-{{element.serial}}"
                                    [(ngModel)]="checkbox_delete_array[element.serial]"
                                    (click)="checkbox_single_select_event($event,element.serial)">
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef 
                            style="padding-bottom:15px;font-weight: 700;"> Image </th>
                        <td mat-cell *matCellDef="let element" >
                            <a href="{{element.recipe_cover_image}}" target="_blank"
                                *ngIf="element.recipe_cover_image !=''">
                                <img src="{{element.recipe_cover_image}}"
                                    style="background: #c4c4c4; margin-bottom: 1rem;border-radius: 5px;height:40px;width: 90px;max-width:90px;margin:10px 0px;">
                            </a>
                            <img src="assets/images/Rectangle 316.png"
                                style="    margin-bottom: 1rem;border-radius: 5px;height: 40px;width: 148px;background:#cecece;"
                                *ngIf="element.recipe_cover_image ==''">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef 
                            style="padding-bottom:15px;font-weight: 700;"> Recipe Name </th>
                        <td mat-cell *matCellDef="let element" style="vertical-align:middle; font-size: 17px;
font-weight: 600;color:#666d77;">{{element.recipe_name}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
                </table>
                <mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]"
                    [pageSize]="10"></mat-paginator>
            </div>
        </div>
    </section>
</div>