import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {

  useraddForm: FormGroup;
  submitted = false;
  selectedStore = [];
  selectedStoreData = [];
  loading:boolean = false;
  closeResult:any;
  modalRef:any;
  create_warehouse_form:FormGroup;
  selected_login:any;
  user:any;
  users:any;
  userFetch : any;
  stores_list:any;
  stores: any;
  user_role = [
    {id: 'admin', role:'Admin'},
    {id: 'admin-manager', role:'Admin Manager'},
    {id: 'user', role:'User'},
  ];
  isUser:any;


  constructor(
    public afs: AngularFirestore,
    private router:Router,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
  ) { 

    this.useraddForm = new FormGroup({
			displayName: new FormControl(null, Validators.required),
      userRole : new FormControl(null, Validators.required),
			email       : new FormControl(null, [Validators.required, Validators.email]),
      password    : new FormControl(null, Validators.required),
      store      : new FormControl([])
	  });

  }

  ngOnInit(): void {
			this.user = JSON.parse(localStorage.getItem('userrole'));
			this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
        this.users = data.map(e => {
          return{
            uid: e.payload.doc.id,
            displayName: e.payload.doc.data()['displayName'],
            email : e.payload.doc.data()['email'],
            store : e.payload.doc.data()['store'],
            role: e.payload.doc.data()['role']
          };
        });
			});
      this.afs.collection('/stores',ref=>ref.where('type','==', 'main_store').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
        this.stores_list = data.map(e => {
          return{
            uid: e.payload.doc.id,
            company_name: e.payload.doc.data()['company_name'],
            email: e.payload.doc.data()['email'],
            group_id: e.payload.doc.data()['group_id'],
            group_name: e.payload.doc.data()['group_name'],
            phone: e.payload.doc.data()['phone'],
            store_id: e.payload.doc.data()['store_id'],
            store_owner_name: e.payload.doc.data()['store_owner_name'],
            warehouse_id: e.payload.doc.data()['warehouse_id'],
            warehouse_name: e.payload.doc.data()['warehouse_name'],
            created_at:e.payload.doc.data()['created_at'],
            can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
            status:e.payload.doc.data()['status'],
            user_id:e.payload.doc.data()['user_id'],
            branch_count: e.payload.doc.data()['branch_count']
          };
        });	
        this.selectAllForDropdownItems(this.stores_list)
      });
  }

  get registerFormControl() {
    return this.useraddForm.controls;
  }

  goToLogin() {
    window.location.href = '/login';
  }
  ChangePassword() {
    $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
    $('#exampleModal').css('opacity', '1');
  }
  backToUser(){
    this.router.navigate(['/admin/users-permission']);
  }

  select_branch(event){
    this.selectedStoreData = event.map((x) => {
        return {'uid' : x.uid, 'company_name': x.company_name }
      })
  }

  handle_user(event){
    if(event == 'admin'){
      this.useraddForm.get('store').clearValidators();               
    } else {                
      // this.useraddForm.get('store').setValidators(Validators.required);               
    }
    this.useraddForm.controls['store'].updateValueAndValidity();
  }

  async user_add(data) {
    this.loading = true;
    this.submitted = true;
    if(this.useraddForm.valid){
      const batch = this.afs.firestore.batch()
      this.afAuth
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(async (result) => {
          let userdata = {
            email: data.email ? data.email : "",
            displayName: data.displayName ? data.displayName : "",
            uid: result.user.uid,
            role: data.userRole ? data.userRole : "user",
            store: this.selectedStoreData,
            created_at: new Date(),
          };
          let useradd = await this.afs
            .doc("/users/" + result.user.uid)
            .set(userdata, { merge: true });
          this.router.navigate(["/admin/users-permission"]);
        })
        .catch((error) => {
          this._snackBar.open(error.message, "", {
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
      }else{
        this.loading = false;
    }
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
    window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
    })
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }
}
