<ng-template #viewIncidentModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Incident Details</h4>
		<button type="button" class="close close_incident_detail_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<div class="modal-body">
			
			<div class="row">
				<div class="col-12">
					<h5 style="margin-bottom: 0;">
						{{view_details.incident_name}}
						<span class="tag tag-success" style="background: #ff9800;color: #fff;font-size: 12px; padding: 2px 6px;border-radius: 3px;" *ngIf="view_details.status == 'pending'">Pending</span>
						<span class="tag tag-success" style="background: #71B01D;color: #fff;font-size: 12px; padding: 2px 6px;border-radius: 3px;"  *ngIf="view_details.status == 'completed'">Completed</span>
					</h5>
					<p style="color:#828282;font-size: 13px;">Reported by {{view_details.reported_by}}</p>
					<p style="    color: #333;font-size: 15px;">{{view_details.description}}</p>
				</div>
			</div>
			<div class="col-12 text-right">
				<p style="font-size: 12px;color: #828282;">{{view_details.created_at.seconds*1000 | date:"MMM dd yyyy hh:mm a"}}</p>
			</div>
			<div class="col-12" style="padding-left: 0;margin-bottom: 1rem;">
				<img src="assets/imgs/icon-attachment.svg" style="margin-right: 5px;">
				<span *ngIf="view_details.image1_url != '' && view_details.image2_url != '' ">2</span> 
				<span *ngIf="view_details.image1_url != '' && view_details.image2_url == '' ">1</span>
				<span *ngIf="view_details.image1_url == '' && view_details.image2_url != '' ">1</span> 
				<span *ngIf="view_details.image1_url == '' && view_details.image2_url == '' ">0</span>
			</div>
			<div class="col-12" style="padding-left: 0">
				<a href="{{view_details.image1_url}}" target="_blank" *ngIf="view_details.image1_url !=''"><img src="{{view_details.image1_url}}" style="height: 44px;width:44px;border-radius: 5px;" ></a>
				<a href="{{view_details.image2_url}}" target="_blank" *ngIf="view_details.image2_url !=''"><img src="{{view_details.image2_url}}" style="height: 44px;width:44px;border-radius:5px;margin-left: 1rem;">
				</a>
			</div>

			<div class="col-12 text-center" style="margin-top: 2rem;margin-bottom: 1rem;" *ngIf="view_details.status=='pending'">
				<button type="submit" class="btn btn-success" (click)="mark_as_complete(view_details.uid)">Mark as Completed</button>
			</div>
		
		
	</div>

</ng-template>

<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-8">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Incident Reports</h4>
							</div>
						</div>
						
						<div class="col-4 float-right">
							
				
					    				
					    				<div class="input-group mb-3">
											<div class="input-group-prepend">
										    	<span class="input-group-text" id="basic-addon1" style="background:#fff;"><i class="fas fa-search" style="color:#ddd;"></i></span>
										  	</div>
									 		<input class="form-control"   (keyup)="search_incident($event.target.value)" type="text" placeholder="Search by Incident Name" >
										</div>
					  			
					  			
							
						</div>

						

						
					</div>
				</div>

			<div class="row no-gutters" style="margin-top: 1rem;">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="incident_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="incident_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Incident Name </th>
							<td mat-cell *matCellDef="let element"> {{element.incident_name}} </td>
						</ng-container>
						
						<ng-container matColumnDef="reported_by">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Reported by </th>
							<td mat-cell *matCellDef="let element"> {{element.reported_by}}
							</td>
						</ng-container> 
						<ng-container matColumnDef="reported_on">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Reported on </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy hh:mm a'}} </td>
						</ng-container>
						<ng-container matColumnDef="attachments">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Attachments </th>
							<td mat-cell *matCellDef="let element"> 
								<span *ngIf="element.image1_url != '' && element.image2_url != '' ">2</span> 
								<span *ngIf="element.image1_url != '' && element.image2_url == '' ">1</span>
								<span *ngIf="element.image1_url == '' && element.image2_url != '' ">1</span> 
								<span *ngIf="element.image1_url == '' && element.image2_url == '' ">0</span>
							</td>
						</ng-container>
						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Status </th>
							<td mat-cell *matCellDef="let element">
								<span *ngIf="element.status== 'pending'" style="color:#F2994A;font-weight: 600;">Pending</span>
								<span *ngIf="element.status== 'completed'" style="color:#71B01D;font-weight: 600;">Completed</span>
							</td>
						</ng-container>
						
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
							<button class="btn btn-sm btn-outline-success" (click)="view_incident_details(element)">View Details</button>
							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
