<div class="row no-gutters recover-password-page">
	<div class="col-md-7 col-sm-12 form-section" style="padding:5rem 11rem;padding-bottom: 0;">
		<h1 class="page-title">Dont worry!</h1>
		<span class="page-subtitle">
			We are here to help you recovering your password. Please enter the email address you used when you joined and we will send you an email with the instructions to reset your password.
		</span>
		
		<form class="recover-password-form" >
			<div class="form-group">
				<label for="email">Email</label>
				<input type="email" class="form-control" id="email" formControlName="email" #passwordResetEmail>
			</div>
			<div class="form-call-to-actions row no-gutters">
				<a class="col-xs-12 col-sm-6 form-action text-link text-primary" routerLink="/sign-in" style="text-decoration:none;color:#4891E0 !important;"> <i class="fas fa-chevron-left" style="margin-right: 10px;"></i>Back to Login</a>
				<!-- <button class="col-xs-12 col-sm-6 form-action recover-password-btn" type="submit">Recover Password</button> -->
				<button class="col-xs-12 col-sm-6 form-action recover-password-btn" value="Recover Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">Recover Password</button>
			</div>
		</form>
	</div>

	<div class="col-md-5 col-lg-5 d-none d-md-block image-section">
    <cc-image-shell style="    background-position: center;" class="signin-image" animation="spinner" [mode]="'cover'" [src]="'https://via.placeholder.com/600x800/619719/fff?text=[DIGITAL-CIRCULAR-LOGO]'" [alt]="'app image'"></cc-image-shell>
  </div>
</div>
