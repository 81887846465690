import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { finalize, catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import * as Papa from 'papaparse';

@Component({
	selector: 'app-product-images',
	templateUrl: './product-images.component.html',
	styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit {


	datasource = [];
	tableColumns: string[] = ['checkbox', 'id', 'upc', 'image', 'product_desc', 'category', 'price', 'action'];
	title = 'appBootstrap';
	saved_items_list: any;
	user: any;
	current_store_uid: any;
	importcsvdata: any = [];
	saved_items_list_temp: any;
	duplicates_temp: any;
	closeResult: any;
	current_selected_product: any;
	delete_pro = false;
	imageurl1: any = '';
	imageurl2: any = '';
	ref: any;
	task: any;
	imageSrc: any;
	imageurl: any;
	show_add_product: boolean = false;
	checkbox_delete_array: any = [];
	checkbox_select_all: any;
	categories_list: any;

	product_edit_form: FormGroup;
	product_add_form: FormGroup;
	image_uploading: boolean = false;
	show_icon_results: any;
	icons: any;
	current_unsplash_type: any;
	product_store_images: any = [];
	search_icon_form: FormGroup;

	pageSize = 10;
	totalItems: any;
	startRange = 1;
	pageIndex = 0;
	masterPageIndex1 = 0;
	masterPrevPageIndx: any;
	masterTotalItem: any;
	masterFirstItem: any;
	masterLastItem: any;
	filterValue: any;
	filterFlag: boolean;
	filterstoreTotalItem: any;
	checkbox_checked_item: any[];
	oldPageSize: number;
	searchClient: SearchClient;
	startIndex: any;

	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('leadsSort', { static: true }) leadsSort: MatSort;
	@ViewChild('leadsPaginator', { static: true }) leadsPaginator: MatPaginator;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('deleteModal') deleteModal;
	@ViewChild('storeImageSearchModal') storeImageSearchModal;
	isGIF: boolean;
	isVideo: any;
	storeInMaster: boolean;
	matchImage: boolean = true;
	domain_name: string = '';
	loading: boolean;



	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar
	) {

		this.searchClient = algoliasearch(
			environment.algolia.appId,
			environment.algolia.apiKey,
		);

		this.product_edit_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			isVideo: new FormControl(false),
			storeInMaster:new FormControl(false),
			master_uid: new FormControl(''),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});

	}


	downloadFile(data: any) {
		const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
		const header = Object.keys(data[0]);
		let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		csv.unshift(header.join(','));
		let csvArray = csv.join('\r\n');

		var blob = new Blob([csvArray], { type: 'text/csv' })
		saveAs(blob, "saved-items-" + new Date().getTime() + ".csv");
	}

	openIconModal(type) {
		this.current_unsplash_type = type;
		this.open(this.iconSearchModal, 'modal-basic-title5');
		//https://api.iconify.design/search?query=dashboard&limit=120
		//https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
	}

	openStoreImgModal(type) {
		this.current_unsplash_type = type;
		this.open(this.storeImageSearchModal, 'modal-basic-title6');
	}


	submit_search_icon(value) {

		this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&query=' + value.query + '&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => { this.icons = res['results']; this.show_icon_results = true; });
	}



	export_csv() {
		this.downloadFile(this.saved_items_list_temp);
		this._snackBar.open("File downloaded successfully!", '', {
			duration: 1500,
		});
	}

	set_current_image(value) {
		//setting image from unsplash;

		if (this.current_unsplash_type == 'edit') {
			//setting unsplash image when editing a product
			this.imageurl1 = value;
			this.product_edit_form.patchValue({
				product_image: value,
			});
		}
		if (this.current_unsplash_type == 'add') {
			//setting unsplash image wheen adding a product
			this.imageurl2 = value;
			this.product_add_form.patchValue({
				product_image: value,
			});
		}
		let element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
		element.click();
	}


	set_current_image_store(value) {
		//setting image from other stores;

		if (this.current_unsplash_type == 'store_image_edit') {
			//setting unsplash image when editing a product
			this.imageurl1 = value;
			this.product_edit_form.patchValue({
				product_image: value,
			});
		}
		if (this.current_unsplash_type == 'store_image_add') {
			//setting unsplash image wheen adding a product
			this.imageurl2 = value;
			this.product_add_form.patchValue({
				product_image: value,
			});
		}

		let element: HTMLElement = document.getElementsByClassName('close_store_image_modal')[0] as HTMLElement;
		element.click();
	}

	async ngOnInit() {
		this.filterFlag = false;
		this.user = JSON.parse(localStorage.getItem('userrole'));
		this.masterLastItem = undefined;
		this.pageIndex = 0;

		await this.getTotalMasterItemCount('all');
		this.all_items1();


		this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data()['value'],
				};
			});

		});
	}

	async onPageChange(event: any) {
		this.oldPageSize = this.pageSize;
		this.masterPageIndex1 = event.pageIndex;
		this.masterPrevPageIndx = event.previousPageIndex;
		this.pageSize = event.pageSize;
		this.checkbox_select_all = false;

		if (this.filterValue && this.filterValue !== '') {
			this.masterFirstItem = undefined;
			this.masterLastItem = undefined;
			await this.searchMaster(this.filterValue, event)
		} else {
			if (this.oldPageSize !== this.pageSize) {
				this.pageIndex = 0;
				this.masterFirstItem = undefined;
				this.masterLastItem = undefined;
				await this.all_items('all');
				await this.getTotalMasterItemCount('all');
			} else {
				await this.all_items('all');
				await this.getTotalMasterItemCount('all');
			}
		}

	}

	async getTotalMasterItemCount(value) {
		const valueLower = value.toLocaleLowerCase();
		const valueUpper = value.toLocaleUpperCase();
		const valueArrayLower = valueLower.split(" ");
		const valueArrayUpper = valueUpper.split(" ");
		const combinedArray = valueArrayLower.concat(valueArrayUpper);
		if (value == 'all' || value == '') {
			if (this.masterTotalItem === undefined) {
				try {
					const querySnapshot = this.afs.collection('/master-items', ref => ref
						.where('product_image', '==', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'))
						.get()
						.toPromise();
					this.masterTotalItem = (await querySnapshot).size; // Cache the totalItems
					this.totalItems = this.masterTotalItem;
					// Update the paginator length
					this.leadsPaginator.length = this.totalItems;
				} catch (error) {
					console.error("Error fetching data: ", error);
				}
			} else {
				this.totalItems = this.masterTotalItem;
				// If totalItems is already cached, update the paginator length
				this.leadsPaginator.length = this.totalItems;
			}
		} else {
			const Value = value.trim()
			try {
				const querySnapshot = await this.afs.collection("/master-items", (ref) => ref
					.where('product_image', '==', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726')
					.where('tag', 'array-contains-any', combinedArray)

					.orderBy("product_description", "asc")
					.orderBy("uid", "desc")).get().toPromise();

				this.filterstoreTotalItem = querySnapshot.size; // Cache the totalItems
				this.totalItems = this.filterstoreTotalItem;
				this.leadsPaginator.length = this.totalItems;
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}
	}

	async all_items1() {
		this.pageIndex = 0;
		this.checkbox_select_all = false;
		await this.all_items('all');
		await this.getTotalMasterItemCount('all');
	}

	async all_items(value) {
		this.checkbox_select_all = false;
		this.checkbox_delete_array = [];
		this.checkbox_checked_item = [];

		let query;
		if (
			!this.masterLastItem ||
			this.masterPageIndex1 == this.masterPrevPageIndx
		) {
			this.startRange = this.pageIndex * this.pageSize;
			query = this.afs.collection('/master-items', ref => ref
				.where('product_image', '==', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726')
				.orderBy('product_description', 'asc')
				// .orderBy("created_at", "desc")
				.orderBy("uid", "desc")
				.limit(this.pageSize)
			);
		} else {
			if (this.masterPageIndex1 > this.masterPrevPageIndx) {
				this.startRange = (this.pageIndex + 1) * this.pageSize;
				query = this.afs.collection('/master-items', ref => ref
					.where('product_image', '==', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726')
					.orderBy('product_description', 'asc')
					// .orderBy("created_at", "desc")
					.orderBy("uid", "desc")
					.startAfter(this.masterLastItem.product_description, this.masterLastItem.uid)
					.limit(this.pageSize)
				);
			} else {
				this.startRange = (this.pageIndex - 1) * this.pageSize;
				query = this.afs.collection('/master-items', ref => ref
					.where('product_image', '==', 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726')
					.orderBy('product_description', 'asc')
					// .orderBy("created_at", "desc")
					.orderBy("uid", "desc")
					.endBefore(
						this.masterFirstItem.product_description,
						this.masterFirstItem.uid,
					)
					.limitToLast(this.pageSize)
				);
			}
		}
		try {
			const querySnapshot = await query.get().toPromise();
			let i = this.startRange;
			this.saved_items_list_temp = querySnapshot.docs.map((doc) => {
				i++;
				const data = doc.data();

				this.leadsPaginator.length = this.totalItems;
				return {
					upc_code: data["upc_code"],
					product_description: data["product_description"],
					product_name_slug: data["product_name_slug"],
					pack: data["pack"] ? data["pack"] : "",
					price_label_format: data["price_label_format"]
						? data["price_label_format"]
						: "",
					price_label_value1: data["price_label_value1"]
						? data["price_label_value1"]
						: "",
					price_label_value2: data["price_label_value2"]
						? data["price_label_value2"]
						: "",
					price_label_value3: data["price_label_value3"]
						? data["price_label_value3"]
						: "",
					price_label_highlight: data["price_label_highlight"]
						? data["price_label_highlight"]
						: "",
					product_url: data["product_url"] ? data["product_url"] : "",
					new_window: data["new_window"] ? data["new_window"] : "",
					hide_price_label: data["hide_price_label"],
					hide_addtolist_label: data["hide_addtolist_label"],
					hide_productname_label: data["hide_productname_label"],
					cost: data["cost"],
					srp: data["srp"],
					category: data["category"],
					uid: data["uid"],
					product_image: data["product_image"]
						? data["product_image"]
						: "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: data["price_label_svg"],
					serial: i - 1,
					is_saved: data["is_saved"] ? data["is_saved"] : "",
					isVideo: data["isVideo"] ? data["isVideo"] : "",
					isBanner: data["isBanner"] ? data["isBanner"] : "",
					autoplay: data["autoplay"] ? data["autoplay"] : false,
					loopvideo: data["loopvideo"] ? data["loopvideo"] : false,
					bannerText: data["bannerText"] ? data["bannerText"] : false,
					storeInMaster: data["storeInMaster"] ? data["storeInMaster"] : "",
					created_at: data["created_at"],
					master_uid: data['master_uid'] ? data['master_uid'] : '',

				};
			});


			const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
			if (lastDoc) {
				this.masterLastItem = {
					product_description: lastDoc.data()?.product_description,
					created_at: lastDoc.data()?.created_at,
					uid: lastDoc.data()?.uid,
					product_image: lastDoc.data()?.product_image,
				};
			}
			const firstDoc = querySnapshot.docs[0];
			if (firstDoc) {
				this.masterFirstItem = {
					product_description: firstDoc.data()?.product_description,
					created_at: firstDoc.data()?.created_at,
					uid: firstDoc.data()?.uid,
					product_image: firstDoc.data()?.product_image,
				};
			}


			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
			this.pageIndex = this.masterPageIndex1;
			this.pageIndex = this.leadsPaginator.pageIndex;
			this.leadsPaginator.length = this.totalItems;

		} catch (error) {
			console.error("Error fetching data: ", error);
		}
		this.delete_pro = false;
	}

	goToAddNewStore() {
		this.router.navigate(['./add-new-store']);
	}

	doFilter = async (value: string) => {
		this.pageIndex = 0;
		this.masterLastItem = undefined
		this.masterFirstItem = undefined
		this.filterValue = value.trim();
		this.filterFlag = true
		this.searchMaster(this.filterValue, "first")
	}

	async searchMaster(value, event) {
		const index = this.searchClient.initIndex(environment.algolia.indexName);
		try {
			var data

			// Define your additional query for the "product_image" field
			data = await index.search(value, {
				hitsPerPage: this.pageSize,
				page: event === "first" ? 0 : event.pageIndex,
				filters: `product_image:'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726'`,
			});

			this.pageIndex = data.page;
			// Calculate the starting index for the current page
			this.startIndex = this.pageIndex * this.pageSize;

			// Add a 'serial' property to each record in data.hits
			this.saved_items_list_temp = data.hits.map((record, index) => ({
				...record,
				serial: this.startIndex + index // Calculate the serial based on page and position
			}));

			this.totalItems = data.nbHits
			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
		} catch (error) {
			console.error('Error searching Algolia:', error);
		}
	}

	view_product(data) {
		this.show_add_product = false;
		this.current_selected_product = data;
		this.imageurl1 = data.product_image + "&size=408&thumbnail=true&palette=RGB";
		this.product_edit_form.patchValue({
			uid         : data.uid,
			product_image   : data.product_image,
			upc_code      : data.upc_code,
			product_description : data.product_description,
			pack        : data.pack,
			price_label_format  : data.price_label_format,
			category      : data.category,
			product_url     : data.product_url,
			hide_price_label  : data.hide_price_label,
			hide_addtolist_label: data.hide_addtolist_label,
			hide_productname_label : data.hide_productname_label,
			isVideo			: data.isVideo,
			storeInMaster:data.storeInMaster,
			master_uid:data.master_uid
	  
		  });
		  this.isVideo = data.isVideo
		this.afs.collection('/store-items', ref => ref.where('product_image', '!=', '').where('upc_code', '==', data.upc_code)).snapshotChanges().subscribe(data => {
			this.product_store_images = data.map(e => {
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_image: e.payload.doc.data()['product_image'],
				};
			});

		});
		function scrollToTop() {
			const element = document.querySelector('.digital-circular-headline');
			  if (element) {
				element.scrollIntoView({
				  behavior: 'smooth', // Smooth scroll
				  block: 'start' // Align the top of the element with the top of the scroll area
				});
			  } else {
				console.log('Element with class "digital-circular-headline" not found.');
			  }
			}
		scrollToTop()	

	}

	import_csv(files: FileList) {

		if (files && files.length > 0) {
			let file: File = files.item(0);

			let reader: FileReader = new FileReader();
			let fileid = new Date().getTime();      //setting temporary name so that we can know what was the source of the upload
			reader.readAsText(file, 'UTF-8');
			reader.onload = (e) => {
				let filestring: string = reader.result as string;

				Papa.parse(filestring, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
					  // Process the parsed CSV data here
					  if (result.data && result.data.length > 0) {
						this.importcsvdata = result.data.map(row => {
							const tag = row.Description.split(" ")
							let product_name_slug = row.Description.replace(`'`, '');
							
							let product_upload_data = {
								upc_code: row.UPC,
								product_description: row.Description,
								pack: row.Pack,
								srp: row.SRP,
								category: row.Category,
								upload_file_id: fileid,
								product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
								product_name_slug: product_name_slug,
								price_label_format: row.PriceLabelFormat ? row.PriceLabelFormat : "",
								price_label_svg: '',
								price_label_value1: '',
								price_label_value2: '',
								price_label_value3: '',
								price_label_highlight: row.PriceLabelHighlight ? row.PriceLabelHighlight : "",
								product_url: row.ProductUrl ? row.ProductUrl : "",
								hide_price_label: row.HidePriceLable ? row.HidePriceLable : "",
								hide_addtolist_label: row.HideAddtolistLabel ? row.HideAddtolistLabel : "",
								hide_productname_label: row.HideProductnameLabel ? row.HideProductnameLabel : "",
								cost: row.Cost ? row.Cost : "",
								store_uid: this.current_store_uid,
								system_notes: "Uploaded using csv from saved-items",
								new_window: row.NewWindow ? row.NewWindow : "",
								autoplay: row.AutoPlay ? row.AutoPlay : "",
								secondery_product_image: [],
								tag: tag ? tag : []
						  	};
							 // Your specific logic for handling the "cols[3]" data
							 if (row.SRP) {
								const srpValue = parseFloat(row.SRP);
								if (!isNaN(srpValue)) {
									product_upload_data.price_label_value1 = srpValue.toFixed(2);
									const srpString = product_upload_data.price_label_value1;
									if (row.SRP.includes('/off')) {
										product_upload_data.price_label_format = "format7";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('Buy')) {
										product_upload_data.price_label_format = "format6";
									} else if (row.SRP.includes('each') || row.SRP.includes('/lb') || row.SRP.includes('pound')) {
										product_upload_data.price_label_format = "format4";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else if (row.SRP.includes('/') || row.SRP.includes('for')) {
										product_upload_data.price_label_format = "format5";
									} else if (srpString.includes('.')) {
										product_upload_data.price_label_format = "format2";
										if (srpString.includes('.')) {
											const parts = srpString.split(".");
											product_upload_data.price_label_value1 = parts[0];
											product_upload_data.price_label_value2 = parts[1];
										}
									} else {
										product_upload_data.price_label_format = "format1";
									}
								}
							}
							return product_upload_data;
						});
					  }
					},
				});
				// // if you want to be more clever...
				let result = this.saved_items_list_temp.filter(o1 => this.importcsvdata.some(o2 => o1.upc_code === o2.upc_code));

				this.duplicates_temp = result;
				if (this.importcsvdata.length == 0) {
					this._snackBar.open("No products to import.", '', {
						duration: 1500,
					});
				}
				if (result.length > 0) {
					// this._snackBar.open("Some products already exist in the system.",'',{
					//  duration: 1500,
					// });
					this.open(this.uploadExistModal, 'modal-basic-title1');


				}
				if (result.length == 0) {

					this.importcsvdata.forEach((value, key) => {
						value.created_at = new Date();
						let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
							const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
							itemRefx2.update({ uid: docRef.id });

						});
					});
					this._snackBar.open("Items imported successfully!", '', {
						duration: 1500,
					});

				}
			}
		}
	}

	set_current_icon(data) {

	}


	upload(event,ind) {
		this.matchImage = false
		this.image_uploading = true;
		if (event.target.files && event.target.files[0]) {
		  const file = event.target.files[0];
	
		  const reader = new FileReader();
		  reader.onload = e => 
			console.log("INDDDDD--",ind);
			let tempimg = reader.result;
		  // this.imageurl1 = reader.result;
		  // this.imageurl2 = reader.result;
		
		  reader.readAsDataURL(file);
		
		}
	
		// create a random id
		const randomId = "digital-menus-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/'+randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task =  this.ref.put(event.target.files[0]);
	
		//this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
		  finalize(() => {
			this.ref.getDownloadURL().subscribe(url => {
			  this.ref.getMetadata().subscribe(res => {
							if (res.contentType.split('/')[1] == 'gif') {
								this.isGIF = true
								this.isVideo = false
							} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
								this.isVideo = true
								this.product_edit_form.patchValue({
									isVideo: true,
									storeInMaster:true
								})
								this.product_add_form.patchValue({
									isVideo: true
								})
								if (this.isVideo || this.isGIF) {
									this.storeInMaster = true;
								} else {
									this.product_add_form.controls['storeInMaster'].setValue(false);
									this.storeInMaster = false;
								}
							} else {
								this.isGIF = false
								this.isVideo = false
							}
						})
						if (this.isVideo) {
							this.storeInMaster = true;
						} else {
							this.product_edit_form?.controls['storeInMaster']?.setValue(false);
							this.storeInMaster = false;
						}
			  if(ind == '1')  
			  { 
				this.imageurl1 = url; 
				this.product_edit_form.patchValue({
				  product_image   :  url,
				});
			  }
	 
			  if(ind == '2')  
			  { 
				this.imageurl2 = url; 
				this.product_add_form.patchValue({
				  product_image   :  url,
				});
			  }
		  
			  this.image_uploading= false;
			
			});
		  })
		).subscribe(); 
		
	  }
	

	openAddProduct() {
		this.show_add_product = true;
		this.current_selected_product = [];
		this.product_add_form.reset();
	}

	trigger_import_csv() {
		let element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
		element.click();
	}

	trigger_upload_image() {
		let element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
		element.click();
	}

	trigger_upload_image_add() {
		let element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
		element.click();
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	open(content, title) {
		this.modalService.open(content, { ariaLabelledBy: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	import_ignore_duplicate() {


		this.importcsvdata.forEach((value, key) => {
			let value2 = [];
			value2.push(value);
			let result2 = value2.filter(o1 => this.saved_items_list_temp.some(o2 => o1.upc_code === o2.upc_code));

			if (result2.length == 0) {
				//only import unique values thats why storing the unique values here

				let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					itemRefx2.update({ uid: docRef.id });

				});
			}
		});

		let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
		element.click();

		this._snackBar.open(this.importcsvdata.length + " Products imported successfully!", '', {
			duration: 2000,
		});

	}

	import_update_duplicate() {

		this.importcsvdata.forEach((value, key) => {

			let item = this.duplicates_temp.find(i => i.upc_code === value.upc_code);
			if (item) {

				//this product already exists in the system so update it.
				let update_to_db = this.afs.doc('/master-items/' + item.uid).set(value, { merge: true }).then(docRef => {
				});

			} else {

				value.created_at = new Date();
				//this product is new so create a new record.
				let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					itemRefx2.update({ uid: docRef.id });
				});

			}

		});

		let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
		element.click();

		this._snackBar.open(this.importcsvdata.length + " Products imported successfully!", '', {
			duration: 2000,
		});
	}

	convertFacebookWatchLink(link) {
		const regex = /^https:\/\/www\.facebook\.com\/watch\/\?v=(\d+)$/i;
		const match = link.match(regex);
		if (match) {
			const videoId = match[1];
			
			const canonicalUrl = `https://www.facebook.com/facebook/videos/${videoId}/`;
			
			return canonicalUrl;
		}else{
			return link
		}

	}
	async product_update(data) {
		if (data.product_image.includes("via.placeholder.com")) {
			data.product_image = "";
		}
		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}
		
		if (!data.isBanner) {
			data.bannerText = '';
		}
		if (data.upc_code == "" || data.upc_code == null) {
			this._snackBar.open("Error:UPC Code is Required", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.product_description == "" || data.product_description == null) {
			this._snackBar.open("Error:Product description is Required", '', {
				duration: 1500,
			});
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		data.tag = data?.product_description.split(" ")
		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube-nocookie.com" || domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				data.storeInMaster=true
				var ytid = data.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
				ytid = ytid.replace('/embed/', '');
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name='youtube'
			}
			if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
				data.product_url=await this.convertFacebookWatchLink(data?.product_url)
				const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
				const encodedURL = encodeURIComponent(data?.product_url);
				
				const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url=data?.product_url
				this.domain_name='facebook'
			}
		}

		if (data.isVideo == 'youtube' || data.isVideo == 'facebook' || data.isVideo == true) {
			if (data.storeInMaster) {				
					const itemRefx2 = await this.afs.doc('/master-items/' + data.uid).get().toPromise();
					const master_old_data: any = itemRefx2.data();
					const master_data = { ...data, secondery_product_image: master_old_data.secondery_product_image || [] }
					delete master_data.master_uid
					if (!this.matchImage) {
						if (data.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_data.secondery_product_image.push({ 'id': Date.now(), 'url': data?.product_image })
						}
					}
					if (this.user?.role != 'admin' && this.current_selected_product.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						delete master_data.product_image
					}
					const master_product_data = await this.afs.doc('master-items/' + data.uid).set(master_data, { merge: true })				
			} else {
				if (data.master_uid != '' && data.master_uid != null) {
					await this.afs.doc('/master-items/' + data.master_uid).delete().then(() => {
						data.master_uid = '';
						// Perform additional actions if needed
					})
					.catch((error) => {
							console.error('Error deleting product: ', error);
						});
					}
				}
		} else {
			const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description)).get().toPromise();
		   querySnapshot.docs.map((doc: any) => ({
				upc_code: doc.data()['upc_code'],
				product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
				product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
				pack: doc.data()['pack'] ? doc.data()['pack'] : '',
				price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
				price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
				price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
				price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
				price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
				product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
				hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : '',
				hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : '',
				hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : '',
				cost: doc.data()['cost'] ? doc.data()['cost'] : '',
				srp: doc.data()['srp'] ? doc.data()['srp'] : '',
				category: doc.data()['category'] ? doc.data()['category'] : '',
				uid: doc.data()['uid'],
				product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
				price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
				is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
				tag: doc.data()['tag'] ? doc.data()['tag'] : [],
				secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
				upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
			}));
			let master_result = querySnapshot.docs[0]?.data();
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result.secondery_product_image.push({ 'id': Date.now(), 'url': data.product_image })
					}
					master_result.upc_code = data.upc_code
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				if (data.product_image == "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726") {
					data.secondery_product_image = []
				} else {
					data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
				}
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data.secondery_product_image
				})
			}
		}
		this._snackBar.open("Product details updated successfully!", '', {
			duration: 1500,
		});   
	}


	product_store(data) {
		if (data.upc_code == "" || data.upc_code == null) {
			this._snackBar.open("Error:UPC Code is Required", '', {
				duration: 1500,
			});
			return false;
		}
		if (data.product_description == "" || data.product_description == null) {
			this._snackBar.open("Error:Product description is Required", '', {
				duration: 1500,
			});
			return false;
		}
		let temp_store = [];
		temp_store.push(data);
		let result = this.saved_items_list_temp.filter(o1 => temp_store.some(o2 => o1.upc_code === o2.upc_code));
		if (result.length > 0) {
			this._snackBar.open("Error: This Product already exists in your Saved Products!", '', {
				duration: 1500,
			});
			return false;
		}

		data.price_label_value1 = "";
		data.price_label_value2 = "";
		data.price_label_value3 = "";
		data.price_label_highlight = "";
		data.cost = "";
		data.srp = "";

		data.created_at = new Date();
		data.system_notes = "Created manually using form";
		data.store_uid = this.current_store_uid;
		const itemRefx = this.afs.collection('/master-items').add(data).then(docRef => {
			const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			this.product_add_form.reset();
		});
		this._snackBar.open("Product added successfully!", '', {
			duration: 1500,
		});

	}


	checkbox_select_all_event(event, item) {
		this.checkbox_checked_item = [];
		let serial
		if(item._paginator){
			serial = item._paginator.pageIndex*this.pageSize
		}else{
			serial = this.startIndex
		}
		let value = event.target.checked;
		let cnt = this.saved_items_list_temp.length;
		if (value == true) {
			this.checkbox_select_all = event.target.checked;
			for (let batchStart = serial; batchStart < serial + this.pageSize; batchStart++) {
				this.checkbox_checked_item[batchStart] = true
			}
			for (let batchStart = 0; batchStart < this.pageSize; batchStart++) {
				this.checkbox_delete_array[batchStart] = true;
			}
		} else {
			this.checkbox_select_all = event.target.checked;
			this.checkbox_delete_array = []
			this.checkbox_checked_item = []
		}


	}
	checkbox_single_select_event(event, i, serial) {

		if (event.target.checked == true) {
			this.checkbox_delete_array[i] = true;
			this.checkbox_checked_item[serial] = true
		} else {
			this.checkbox_delete_array[i] = false;
			this.checkbox_checked_item[serial] = false
		}



	}

	openDeleteModal() {
		if (this.checkbox_delete_array.length == 0) {
			this._snackBar.open("Error: Please select some products to delete!", '', {
				duration: 1500,
			});
			return false;
		}
		this.open(this.deleteModal, 'modal-basic-title3');

	}
	async delete_products() {
		this.loading = true
		let element: HTMLElement = document.getElementsByClassName('delete_product_modal')[0] as HTMLElement;
		element.click();
		this._snackBar.open('Please wait while the products are being deleted successfully!', '', {
			duration: 2000,
		});

		let cnt = this.checkbox_delete_array.length;
		try{
		for (let d = 0; d < cnt; d++) {
			let val = this.checkbox_delete_array[d];
			if (val == true) {
				this.checkbox_delete_array[d] = 0;
				const itemRefx = await this.afs.doc('/master-items/' + this.saved_items_list_temp[d].uid).delete();
			}
		}
	    } catch (error) {
		this.loading = false;
		console.error("Error in Delete Document:", error);
		}

		this._snackBar.open("Products deleted successfully!", '', {
			duration: 1500,
		});
		this.loading=false
		await this.ngOnInit();
		this.checkbox_delete_array = [];
		this.checkbox_checked_item = [];
		this.checkbox_select_all = false;

	}

	gotoAll() {
		this.router.navigate(['/store/all-items']);
	}
	gotoSaved() {
		this.router.navigate(['/store/saved-items']);
	}

	save_item(uid) {
		let update_to_db = this.afs.doc('/master-items/' + uid).set({ is_saved: true }, { merge: true }).then(docRef => {
		});
		this._snackBar.open("Item has been moved to Saved Products!", '', {
			duration: 1500,
		});
	}

	unsave_item(uid) {
		let update_to_db = this.afs.doc('/master-items/' + uid).set({ is_saved: false }, { merge: true }).then(docRef => {
		});

		this._snackBar.open("Item removed from saved!", '', {
			duration: 1500,
		});
	}

}
