<ng-template #couponAddModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title1" style="font-size: 18px;"><i class="fas fa-chevron-left"
				style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add New
			Coupon</h4>
		<button #closebtn type="button" class="close close_add_store_modal close_add_coupon_modal" aria-label="Close"
			(click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">
		<form [formGroup]="coupon_add_form" (ngSubmit)="coupon_add(coupon_add_form.value);">
			<div class="product-details pr-3 pl-3 ">

				<div class="product-image mt-3 mb-3 flex-nowrap ">
					<!-- {{imageSrc}} -->
					<div class="pro-image" (click)="trigger_addimage_click()">
						<img src="../../assets/images/Rectangle 316.png" alt=""
							*ngIf="imageSrc === ''">
						<img src="{{imageSrc}}" style="max-width: 63px;height:58px;" *ngIf="imageSrc !== ''">
					</div>
					<div class="pro-image-details">
						<h5>Coupon Image</h5>
						<span>Upload PNG/JPG/JPEG/Gif</span> <br>
						<span>Max. 2 MB</span>
					</div>
					<input type="file" style="opacity:0;background:transparent" class="add_image_file"
						formControlName="coupon_image" [(ngModel)]="photoURL" (change)="upload($event)"
						accept=".png,.jpg">
				</div>
				<div class="product-form">
					<div class="form-group">
						<input type="text" class="form-control" id="Upc" placeholder="UPC" formControlName="upc" />
					</div>
					<div class="form-group">
						<input type="text" class="form-control" id="CouponDescription" placeholder="Coupon  Description"
							formControlName="coupon_description" />
					</div>

					<div class="form-group">
						<input type="text" class="form-control" id="label" placeholder="Label"
							formControlName="label" />
					</div>
					<div class="form-group">
						<input type="text" class="form-control" id="CouponCode"
							placeholder="Coupon code EG. CHRISTAMS12" formControlName="coupon_code" />
					</div>
					<div class="start-end-date pb-4">
						<div class="start-date d-flex flex-column  w-50">
							<label for="StartDate">Valid From</label>
							<input type="date" id="StartDate" placeholder="Start Date" class="form-control"
								formControlName="valid_from">
						</div>
						<div class="end-date d-flex flex-column w-50">
							<label for="EndDate">Valid Till</label>
							<input type="date" id="EndDate" placeholder="End Date" class="form-control"
								formControlName="valid_till">
						</div>
					</div>
					<div class="pro-note">
						<span>Coupon will be removed from site on end of expiry date</span>
					</div>

					<div class="submit-btn text-center">
						<button type="submit" class="pro-detail-submit-btn btn btn-success">Add</button>
					</div>
				</div>


			</div>
		</form>
	</div>

</ng-template>


<ng-template #clearAllModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left"
				style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Clear all
			Coupons</h4>
		<button type="button" class="close close_clearall_modal" aria-label="Close"
			(click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

		<p style="margin-top:2rem;margin-bottom:2rem;">Are you sure you want to delete all coupons permanently?  </p>
		<button class="btn btn-danger float-right" (click)="delete_all_coupons()">Yes, Delete All </button>
		<button class="btn btn-light float-right" (click)="modal.dismiss('Cross click')"
			style="margin-right: 1rem;">No</button>

	</div>


</ng-template>

<div class="content" *ngIf="store_data && store_data[0]?.access_coupon == 'National Coupons Only'">
	<p style="font-size: 21px;text-align: center;display: flex;align-items: center;height: 50vh;">You have selected
		National Coupons as your coupons provider, So the coupons data will be automatically provided by them on your
		website. <br>
		In case you wish to change it, Contact us.
	</p>
</div>

<div class="content"
	*ngIf="store_data && store_data[0]?.access_coupon == 'Manual Coupons Only' || store_data && store_data[0]?.access_coupon == 'Manual & National Coupons'">
	<section>
		<div class="dcp-button-bar">
			<div class="row align-items-center">
				<div class="col-md-4">
					<div class="digital-circular-headline">
						<h3>Coupons</h3>
					</div>
				</div>
				<div class="col-md-5 d-flex justify-content-end">
					<div class="button-area justify-content-center">
						<!-- <div class="preview-btn">
							<a href="#">
								<img src="../../assets/images/preview.png" alt="" class="pr-2" />
								Preview</a>
						</div> -->


						<div class="publish-btn save-btn" style="cursor:pointer;" (click)="trigger_cms_submit_btn()">
							<a> Save</a>
						</div>

						<!--  <div class="discard-changes-btn">
							<a href="#">Discard Changes</a>
						</div> -->
					</div>
				</div>
				<div class="col-md-3 d-flex justify-content-end">
					<div class="button-area">


						<div class="save-btn add-new-coupon-btn">
							<a data-toggle="modal" data-target="#addCoupon" (click)="open_create_modal()"
								style="cursor:pointer;">Add New Coupon</a>
						</div>




					</div>
				</div>
			</div>
		</div>
	</section>

	<section>

		<div class="layout-types">
			<div class="row">
				<div class="col-md-12">
					<div class=" alert alert-warning" style="margin-bottom:1rem;"
						*ngIf="store_data && store_data[0]?.access_coupon == 'Manual & National Coupons'">
						Your store has access to Manual coupons and National coupons both, the latter will be directly
						added to your website.
					</div>
				</div>

				<div class="col-md-9 col-sm-6">

					<form [formGroup]="coupon_cms_form" (ngSubmit)="coupon_cms_update(coupon_cms_form.value);">

						<div class="layout-selection">


							<div class="include-header">
								<h5 class="pb-2 mb-0">
									Include header?
									<span>(Add text for your header below and select checkbox)</span>
								</h5>
								<button type="submit" style="opacity:0;background:transparent;"
									class="submit_cms_form_btn">submit11</button>
								<div class="form-check">
									<input class="form-check-input" type="checkbox" id="flexCheckDefault"
										formControlName="coupon_header_include"
										checked="{{store_data[0]?.coupon_header_include}}" />

									<input class="form-check-label fees-input" for="flexCheckDefault"
										value="We sell at our cost + 10% at the checkout! Our cost includes freight fees & warehouse expenses."
										formControlName="coupon_header_text">
								</div>
							</div>
							<div class="clear-items-text text-right pb-3">
								<a style="color: #4a4a4a;text-decoration: underline;cursor:pointer;"
									(click)="open_clearall_modal()">Clear All Products</a>
							</div>
							<div class="product-thumbnail">
								<div class="row" style="padding-top: 10px;height: 700px;overflow: auto;">

									<!-- <div class="add-new-product">
											<div class="btn-group dropright">
												<div class="AddNewProductBtn" data-toggle="dropdown"
													aria-haspopup="true" aria-expanded="false">
													<svg xmlns="http://www.w3.org/2000/svg" class="image-suggetion-icon" viewBox="0 0 512 512"
														class="s-ion-icon">
														<path
															d="M256 48C141.125 48 48 141.125 48 256s93.125 208 208 208 208-93.125 208-208S370.875 48 256 48zm107 229h-86v86h-42v-86h-86v-42h86v-86h42v86h86v42z">
														</path>
													</svg>
													<h5>Add New Product</h5>
												</div>
												<div class="dropdown-menu">
													<a class="dropdown-item" href="#">Add New Product</a>
													<a class="dropdown-item" href="#">From csv File</a>
													<a class="dropdown-item" href="#">Import all tiles from Ongoing
														layout</a>

													<a class="dropdown-item" href="#">Import Items from Saved Products</a>
												</div>
											</div>
										</div> -->
									<div class="col-4" *ngFor="let coupon of coupons_list" style="padding-top: 10px;">
										<div class="card"  [ngClass]="{'expired-border': coupon.is_expired =='yes'}" style="    padding-left: 12px;padding-right: 12px;padding-top: 1px;padding-bottom: 12px;" >
                                            <div><img src="imgs/minus.png" id="{{coupon.uid}}" class="deleteicon_click deleteicon hide_bar_add" style="position: absolute;width: 24px;height: 24px;right: 1px;top: 0px;" (click)="deleteCoupon(coupon.uid)"></div>
											&nbsp;
											<div style="text-align:center;">
												<img src="{{coupon.coupon_image}}"
													style="height: 160px;max-width: 100%;min-height:160px;"
													*ngIf="coupon && coupon.coupon_image !=''" (click)="editCouponPass(coupon)">
												<img src="https://via.placeholder.com/200/e7e7e7/e7e7e7"
													style="height: 160px;max-width: 100%;min-height:160px;"
													*ngIf="coupon && coupon.coupon_image ==''" (click)="editCouponPass(coupon)">
											</div>
											<div>
												<p
													style="    font-size: 21px;color: #76b100;font-weight: 600;margin-bottom:0;">
													{{coupon.label}}</p>
											</div>
											<div [ngClass]="{'expired-color': coupon.is_expired =='yes'}">
												<p style="font-size:13px;" >{{coupon.valid_from.seconds*1000| date:"MMMM
													d,y"}} - {{coupon.valid_till.seconds*1000| date:"MMMM d,y"}}</p>
											</div>
											<div>
												<p style="font-size:12px;color: #2d2d2d;">{{coupon.coupon_description}}
												</p>
											</div>
											<div>
												<p style="font-size:11px;margin-bottom:0;">Coupon Code</p>
											</div>
											<div style="text-align:center;">
												<p
													style="margin-bottom: 0;border: 2px dashed #76b100;color: #76b100;font-weight: 600;margin-top: 5px;text-transform: uppercase;">
													{{coupon.coupon_code}}</p>
											</div>

										</div>
									</div>




								</div>
							</div>
							<div class="terms-condition pt-4">
								<h5>Terms & conditions</h5>
								<div class="form-group">
									<textarea class="form-control" id="TermsCondition" rows="5"
										formControlName="coupon_terms">
We reserve the right to correct typographical or pictorial errors
Quantity rights reserved. None sold to dealers.
All Products subject to quantity availability.
No rainchecks please.
									</textarea>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-3 col-sm-6">
					<div class="product-details" *ngIf="current_edit_coupon">
						<!-- {{current_edit_coupon | json}} -->
						<div class="product-details-text">
							<h5 class="mb-0">Coupon Details</h5>
							<span>
								 Click on the product from layout to view/edit details</span>
						</div>
						
						<div class="product-form">
							<form [formGroup]="coupon_edit_form" (ngSubmit)="coupon_update(coupon_edit_form.value);">

								<div class="product-image mt-3 mb-3 flex-nowrap ">
									<div class="pro-image">
										<!-- <img src="../../assets/images/Rectangle 316.png" alt=""
											*ngIf="current_edit_coupon && current_edit_coupon.coupon_image == ''"> -->
		
										<img src="../../assets/images/Rectangle 316.png" alt="" *ngIf="imageurl == ''">
										<img src="{{imageurl}}" *ngIf="imageurl != ''"
										style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;background: #ececec;">
										<input type="file" style="opacity:0;background:transparent" class="add_image_file"
								formControlName="coupon_image" (change)="upload($event)"
								accept=".png,.jpg">
									</div>
									<div class="pro-image-details">
										<h5>Coupon Image</h5>
										<span>Upload PNG/JPG/JPEG/Gif</span> <br>
									</div>
									
								</div>
								<div class="form-group">
									<input type="text" class="form-control" id="Upc" placeholder="UPC"
										formControlName="upc" />
								</div>
								<div class="form-group">
									<input type="text" class="form-control" id="CouponDescription"
										placeholder="Coupon  Description" formControlName="coupon_description" />
								</div>

								<div class="form-group">
									<input type="text" class="form-control" id="label" placeholder="Label"
										formControlName="label" />
								</div>
								<div class="form-group">
									<input type="text" class="form-control" id="CouponCode"
										placeholder="Coupon code EG. CHRISTAMS12" formControlName="coupon_code" />
								</div>
								<div class="coupens start-end-date pb-4">
									<div class="start-date d-flex flex-column">
										<label for="StartDate">Valid From</label>
										<input type="date" id="StartDate" placeholder="Start Date" class="form-control"
											formControlName="valid_from">
									</div>
								</div>
								<div class="coupens start-end-date pb-4">
									<div class="end-date d-flex flex-column">
										<label for="EndDate">Valid Till</label>
										<input type="date" id="EndDate" placeholder="End Date" class="form-control"
											formControlName="valid_till" value="2021-09-01">
									</div>
								</div>
								<div class="pro-note">
									<span>Coupon will be removed from site on end of expiry date</span>
								</div>
								<div class="pro-checkboxes">

								</div>
								<div class="submit-btn text-center" *ngIf="current_edit_coupon">
									<button type="submit" class="pro-detail-submit-btn btn btn-primary">Update</button>
								</div>
							</form>
						</div>


					</div>
				</div>
			</div>
		</div>
	</section>
</div>