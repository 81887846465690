import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef, OnDestroy } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-new-store',
  templateUrl: './add-new-store.component.html',
  styleUrls: ['./add-new-store.component.scss']
})
export class AddNewStoreComponent implements OnInit, OnDestroy {

storeaddForm: FormGroup;
closeResult:any;
modalRef:any;
create_warehouse_form:FormGroup;
warehouselist:any = [];
required_label_warehouse:boolean = false;
selected_warehouse:any;
user:any;
users:any;
passwordField : boolean = false;
private searchSubscription?: Subscription;
private readonly searchSubject = new Subject<string | undefined>();
showLoading : boolean = false
showBtnLoading: boolean = false
userByEmail : any
@ViewChild('warehouseAddModal') warehouseAddModal;
users_list: any;
drp_user_list: any;
selectedStoreData: any = [];
arrAccessLayout = [ 
	{ name: 'Layout Access'},
	{ name: 'Custom Grid Layout Only'},
	{ name: 'Upload Circular Images / URL Only'},
	{ name: 'Both Layouts'},
];
arrAccessRecipe = [ 
	{ name: 'Recipe Access'},
	{ name: 'Manual Recipe Builder'},
	{ name: 'National Recipes'},
];
arrAccessCoupon = [ 
	{ name: 'Coupons Access'},
	{ name: 'Manual & National Coupons'},
	{ name: 'Manual Coupons Only'},
	{ name: 'National Coupons Only'},
];

  constructor( @Inject(PLATFORM_ID) private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  private formBuilder: FormBuilder,public authService: AuthService,
  public afs: AngularFirestore,
  public afAuth: AngularFireAuth,
  private modalService: NgbModal,
  private storage: AngularFireStorage,private _snackBar: MatSnackBar,
  private http:HttpClient) {

	this.storeaddForm = new FormGroup({
			company_name: new FormControl(null, Validators.compose([Validators.required])),
			// store_owner_name: new FormControl(null, Validators.compose([Validators.required])),
			// email       : new FormControl(null),
			password    : new FormControl(null),
			phone       : new FormControl(null),
			warehouse   : new FormControl(0),
			store_id    : new FormControl( null),
			zip_code    : new FormControl(null),
			address     : new FormControl(null),
			menu1_name  : new FormControl(null),
			menu1_link  : new FormControl(null),
			menu2_name  : new FormControl(null),
			menu2_link  : new FormControl(null),
			menu3_name  : new FormControl(null),
			menu3_link  : new FormControl(null),
			menu4_name  : new FormControl(null),
			menu4_link  : new FormControl(null),
			menu5_name  : new FormControl(null),
			menu5_link  : new FormControl(null),
			menu6_name  : new FormControl(null),
			menu6_link  : new FormControl(null),
			menu7_name  : new FormControl(null),
			menu7_link  : new FormControl(null),
			menu8_name	: new FormControl(null),
			menu8_link 	: new FormControl(null),
			menu9_name 	: new FormControl(null),
			menu9_link 	: new FormControl(null),
			access_layout: new FormControl(),
			access_recipe: new FormControl(),
			access_coupon: new FormControl(),
			displayRecipePage  : new FormControl(true),
			displayCouponPage  : new FormControl(true),
			displayCircularPage  : new FormControl(true),
			displayBundlePage  : new FormControl(true),
			displayMenuPage  : new FormControl(true),
	  });

		this.create_warehouse_form = new FormGroup({
			warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
    	});


  }

  ngOnInit(): void {
	this.user = JSON.parse(localStorage.getItem('userrole'));
	this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
		this.users = data.map(e => {
			return{
			uid: e.payload.doc.id,
			photoURL: e.payload.doc.data()['photoURL']?e.payload.doc.data()['photoURL']:'./assets/imgs/user-placeholder.png',
			displayName: e.payload.doc.data()['displayName'],
			role: e.payload.doc.data()['role']
			};
		});
	});

	this.afs.collection('/users',ref=>ref.where('role','in', ['user', 'admin']).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.users_list = data.map(e => {
		  return{
			uid: e.payload.doc.id,
			displayName: e.payload.doc.data()['displayName'],
			role: e.payload.doc.data()['role'],
			email : e.payload.doc.data()['email'],
			store : e.payload.doc.data()['store']
		  };
		});
		this.drp_user_list = this.users_list.map((user, index) => {
			return {
				index: index,
				uid: user.uid,
				displayName: user.displayName,
				store: user.store
			};
		  });
	  });

  	this.afs.collection('/warehouses',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name']?e.payload.doc.data()['warehouse_name']:"",
			};
		});	
	});
	
	this.searchSubscription = this.searchSubject
    .pipe(
      debounceTime(2500),
      distinctUntilChanged(),
    )
    .subscribe((results) => {
		this.checkEmail(results)
	});
  }

  select_branch(event){
    this.selectedStoreData = event.map((x) => {
      return {'uid' : x.uid, 'displayName': x.displayName, 'store': x.store }
    })
  }
  
  async checkEmail(value){
	const usersData = await this.afs.collection('/users', ref => ref.where('email', '==', value)).get().toPromise()
	this.showLoading = false
	if(usersData.docs.length  == 0 && value != ''){
		this.passwordField = true
		this.storeaddForm.get('password').setValidators(Validators.required);               
	}else{
		this.userByEmail = usersData.docs[0].data()
		this.passwordField = false
		this.storeaddForm.get('password').clearValidators();               
	}
	this.storeaddForm.controls['password'].updateValueAndValidity();
  }

  goToLogin() {
	window.location.href = '/login';
  }
  ChangePassword() {
	$('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
	$('#exampleModal').css('opacity', '1');
  }
  backToStore(){
	this.router.navigate(['/admin/stores']);
  }

async store_add(data){

	if(!data.company_name){
		this._snackBar.open("Company Name is required!",'',{
			duration: 2000,
		});
		return false;
	}

	// if(!data.store_owner_name){
	// 	this._snackBar.open("Store Name is required!",'',{
	// 		duration: 2000,
	// 	});
	// 	return false;
	// }

	if(this.selectedStoreData.length <= 0){
		this._snackBar.open("Assign Atleast One User!",'',{
			duration: 2000,
		});
		return false;
	}

	let company_name_slug = data.company_name.replace(/\s/g, "");
	company_name_slug = company_name_slug.replace(/[^\w\s]/gi, '');

	let storedata = {
		// email: data.email?data.email:"",
		company_name:data.company_name?data.company_name:"",
		company_name_slug: company_name_slug,
		// store_owner_name: data.store_owner_name?data.store_owner_name:"",
		phone: data.phone? data.phone:"",
		warehouse: data.warehouse?data.warehouse:"",
		store_id: data.store_id?data.store_id:"",
		zip_code: data.zip_code?data.zip_code:"",
		address: data.address?data.address:"",
		menu1_name: data.menu1_name?data.menu1_name:"",
		menu1_link: data.menu1_link?data.menu1_link:"",
		menu2_name: data.menu2_name?data.menu2_name:"",
		menu2_link: data.menu2_link?data.menu2_link:"",
		menu3_name: data.menu3_name?data.menu3_name:"",
		menu3_link: data.menu3_link?data.menu3_link:"",
		menu4_name: data.menu4_name?data.menu4_name:"",
		menu4_link: data.menu4_link?data.menu4_link:"",
		menu5_name: data.menu5_name?data.menu5_name:"",
		menu5_link: data.menu5_link?data.menu5_link:"",
		menu6_name: data.menu6_name?data.menu6_name:"",
		menu6_link: data.menu6_link?data.menu6_link:"",
		menu7_name: data.menu7_name?data.menu7_name:"",
		menu7_link: data.menu7_link?data.menu7_link:"",
		menu8_name: data.menu8_name?data.menu8_name:"",
		menu8_link: data.menu8_link?data.menu8_link:"",
		menu9_name: data.menu9_name?data.menu9_name:"",
		menu9_link: data.menu9_link?data.menu9_link:"",
		access_layout: data.access_layout?data.access_layout:"",
		access_recipe: data.access_recipe?data.access_recipe:"",
		access_coupon: data.access_coupon?data.access_coupon:"",
		type: 'main_store',
		can_login: '1',
		created_at: new Date(),
		manifestFileName:"",
		// displayRecipePage  : false,
		// displayCouponPage  : false,
		displayRecipePage  : data.displayRecipePage,
		displayCouponPage  : data.displayCouponPage,
		displayCircularPage  : data.displayCircularPage,
		displayBundlePage  : data.displayBundlePage,
		displayMenuPage  : data.displayMenuPage
	};

	this.http.get<{message:string, url:string, fileName:string}>(environment.ServerURL + `/manifest?name=${company_name_slug}`).subscribe(res=>{
		storedata.manifestFileName = res.fileName
	},err=>{
		console.log('err ===== ',err);
	})

	if(this.selectedStoreData.length > 0){
		let storeadd = this.afs.collection('/stores').add(storedata).then(async docRef => {
			this.showBtnLoading = true
			const itemRefx= this.afs.doc('/stores/'+ docRef.id);
			itemRefx.update({ uid: docRef.id, parent_id: docRef.id});
	
			this.selectedStoreData.map(async (data, index) => {
				if(data.store){
					const newStores = [...data.store, {'uid': docRef.id, 'company_name': storedata['company_name']}]
					this.afs.collection("/users").doc(data.uid).update({store: newStores})
				}
			});

			let start_date = new Date();
			start_date = new Date(start_date.getTime() - 86400000)
			const end_date = new Date(start_date.getTime() + (86400000*15));

			start_date.setHours(0, 0, 0, 0);
			end_date.setHours(23, 59, 59, 999);
			const include_header_checkbox = 0;
			let layout_type 
			if(data.access_layout == "Both Layouts" || data.access_layout == "Custom Grid Layout Only"){
				layout_type = 'grid'
			}else{
				layout_type = 'page'
			}
			const layout_store_data =
			{
				store_uid: docRef.id,
				start_date,
				end_date,
				header_text: '',
				include_header_checkbox,
				is_publish: 0,
				layout_json: [],
				layout_type: layout_type ? layout_type : 'grid',
				page1_type: '',
				page2_type: '',
				page3_type: '',
				page4_type: '',
				page5_type: '',
				page1_url: '',
				page2_url: '',
				page3_url: '',
				page4_url: '',
				page5_url: '',
				layout_json_temp: '',
				layout_json_saved: '',
				terms: '',
				is_circular:true,
				last_save_timestamp: new Date().getTime(),
				last_publish_timestamp: new Date().getTime(),
				updated_at: new Date()
			};
			const bundle_store_data =
			{
				store_uid: docRef.id,
				start_date,
				end_date,
				header_text: '',
				include_header_checkbox,
				is_publish: 0,
				bundle_json: [],
				layout_type: layout_type ? layout_type : 'grid',
				page1_type: '',
				page2_type: '',
				page3_type: '',
				page4_type: '',
				page5_type: '',
				page1_url: '',
				page2_url: '',
				page3_url: '',
				page4_url: '',
				page5_url: '',
				bundle_json_temp: '',
				bundle_json_saved: '',
				terms: '',
				is_bundle:true,
				last_save_timestamp: new Date().getTime(),
				last_publish_timestamp: new Date().getTime(),
				updated_at: new Date()
			};

			const menu_store_data =
			{
				store_uid: docRef.id,
				start_date,
				end_date,
				header_text: '',
				include_header_checkbox,
				is_publish: 0,
				menu_json: [],
				layout_type: layout_type ? layout_type : 'grid',
				page1_type: '',
				page2_type: '',
				page3_type: '',
				page4_type: '',
				page5_type: '',
				page1_url: '',
				page2_url: '',
				page3_url: '',
				page4_url: '',
				page5_url: '',
				menu_json_temp: '',
				menu_json_saved: '',
				terms: '',
				is_menu:true,
				last_save_timestamp: new Date().getTime(),
				last_publish_timestamp: new Date().getTime(),
				updated_at: new Date()
			};


			try{
				let layout_json_temp = { layout_json_temp: layout_store_data.layout_json_temp }
				const layout_data_temp = await this.afs.collection('/layout-json-temp').add(layout_json_temp);
				const docRef_temp = await layout_data_temp.get();
				const itemRefx2_temp = this.afs.doc('/layout-json-temp/' + docRef_temp.id);
				await itemRefx2_temp.update({ uid: docRef_temp.id });
				layout_store_data.layout_json_temp = docRef_temp.id

				let layout_json_saved = { layout_json_saved: layout_store_data.layout_json_saved }
				let layout_data_saved = await this.afs.collection('/layout-json-saved').add(layout_json_saved);
				const docRef_saved = await layout_data_saved.get();
				const itemRefx2_saved = this.afs.doc('/layout-json-saved/' + docRef_saved.id);
				await itemRefx2_saved.update({ uid: docRef_saved.id });
				layout_store_data.layout_json_saved = docRef_saved.id

				const layout_data_new = await this.afs.collection('/layouts-noad').add(layout_store_data)
				const docRef_add = await layout_data_new.get();
				const itemRefx2Noad = await this.afs.doc('/layouts-noad/' + docRef_add.id);
				await itemRefx2Noad.update({ uid: docRef_add.id });

              //:::::::::::bundle_layout_createion_process::::::::::::
				let bundle_json_temp = { bundle_json_temp: bundle_store_data.bundle_json_temp }
				console.log('bundle_json_temp',bundle_json_temp)
				const bundle_data_temp = await this.afs.collection('/bundle-json-temp').add(bundle_json_temp);
				console.log('bundle_data_temp',bundle_data_temp)
				const docRef_temp1 = await bundle_data_temp.get();
				const itemRefx2_temp1 = this.afs.doc('/bundle-json-temp/' + docRef_temp1.id);
				await itemRefx2_temp1.update({ uid: docRef_temp1.id });
				bundle_store_data.bundle_json_temp = docRef_temp1.id

				let bundle_json_saved = { bundle_json_saved: bundle_store_data.bundle_json_saved }
				let bundle_data_saved = await this.afs.collection('/bundle-json-saved').add(bundle_json_saved);
				const docRef_saved1 = await bundle_data_saved.get();
				const itemRefx2_saved1 = this.afs.doc('/bundle-json-saved/' + docRef_saved1.id);
				await itemRefx2_saved1.update({ uid: docRef_saved1.id });
				bundle_store_data.bundle_json_saved = docRef_saved1.id

				const bundle_data_new = await this.afs.collection('/bundles').add(bundle_store_data)
				const docRef_add1 = await bundle_data_new.get();
				const itemRefx2Noad1 = await this.afs.doc('/bundles/' + docRef_add1.id);
				await itemRefx2Noad1.update({ uid: docRef_add1.id });

				// :::::::::::menu_layout_createion_process::::::::::::
				let menu_json_temp = { menu_json_temp: menu_store_data.menu_json_temp }
				console.log('menu_json_temp',menu_json_temp)
				const menu_data_temp = await this.afs.collection('/menu-json-temp').add(menu_json_temp);
				console.log('menu_data_temp',menu_data_temp)
				const menu_docRef_temp1 = await menu_data_temp.get();
				const menu_itemRefx2_temp1 = this.afs.doc('/menu-json-temp/' + menu_docRef_temp1.id);
				await menu_itemRefx2_temp1.update({ uid: menu_docRef_temp1.id });
				menu_store_data.menu_json_temp = menu_docRef_temp1.id

				let menu_json_saved = { menu_json_saved: menu_store_data.menu_json_saved }
				let menu_data_saved = await this.afs.collection('/menu-json-saved').add(menu_json_saved);
				const menu_docRef_saved1 = await menu_data_saved.get();
				const menu_itemRefx2_saved1 = this.afs.doc('/menu-json-saved/' + menu_docRef_saved1.id);
				await menu_itemRefx2_saved1.update({ uid: menu_docRef_saved1.id });
				menu_store_data.menu_json_saved = menu_docRef_saved1.id

				const menu_data_new = await this.afs.collection('/menus').add(menu_store_data)
				const menu_docRef_add1 = await menu_data_new.get();
				const menu_itemRefx2Noad1 = await this.afs.doc('/menus/' + menu_docRef_add1.id);
				await menu_itemRefx2Noad1.update({ uid: menu_docRef_add1.id });

				this._snackBar.open("Store created successfully!",'',{
					duration: 2000,
				});
				this.router.navigate(['/admin/stores']);
			} catch (error){
				console.error("Error :- ",error)
			}
			this.showBtnLoading = false
		});
	}
	
	// if(this.passwordField){
	// 	this.afAuth.createUserWithEmailAndPassword(data.email, data.password).then((result)=>{		
	// 		const user = result.user
	// 		let userdata = {
	// 			email: data.email?data.email:"",
	// 			displayName: data.store_owner_name?data.store_owner_name:"",
	// 			uid: user.uid,
	// 			role: "user",
	// 			created_at: new Date(),
	// 			can_login: '1',
	// 		};

	// 	this.afs.doc('/users/'+ user.uid).set(userdata,{merge:true}).then(docRef => {
	// 		console.log("User added to users collection");
	// 	});

	// 	storedata['user_id'] = user.uid;
	// 	this.handleAddStore(storedata,user.uid,false)
	// }, async (err) => {
		// this._snackBar.open(err.message, '', {
		// 	duration: 2000,
		// });
	// })
	// }else{
	// 	storedata['user_id'] = this.userByEmail.uid;
	// 	this.handleAddStore(storedata, this.userByEmail.uid, true)
	// }
  }

  handleAddStore(storedata, user_id, userExist){
	if(userExist){
		const userInfo:any = this.userByEmail
		if(userInfo.store){
			userInfo.store.push(storedata['company_name_slug'])				
		}else{
			userInfo.store = [storedata['company_name_slug']]
		}
		let storeadd = this.afs.collection('/stores').add(storedata).then(async docRef => {
			const itemRefx= this.afs.doc('/stores/'+ docRef.id);
			itemRefx.update({ uid: docRef.id, parent_id: docRef.id,user_id : userInfo.uid }); 
			const itemRefx2= this.afs.doc('/users/' + userInfo.uid);
			const oldData = await itemRefx2.get().toPromise()
			const oldStores:any = oldData.data()
			itemRefx2.update({ store: [...oldStores.store,{'uid': docRef.id, 'company_name': storedata['company_name']}] }); 
		});
	}else{
		//store data
		let storeadd = this.afs.collection('/stores').add(storedata).then(docRef => {
			const itemRefx= this.afs.doc('/stores/'+ docRef.id);
			itemRefx.update({ uid: docRef.id, parent_id: docRef.id,user_id : user_id }); 
			const itemRefx2= this.afs.doc('/users/' + user_id);
			itemRefx2.update({ store: [{'uid': docRef.id, 'company_name': storedata['company_name']}] }); 
		});	
		//sending password reset mail to store owner
		this.ForgotPassword(storedata.email);
	}
	this.router.navigate(['/admin/stores']);
  }

  addWarehouse() {
  	this.open(this.warehouseAddModal,'modal-basic-title2');
  }

  emailVerified(email){
	const searchQuery = email;
	this.showLoading = true
  	this.searchSubject.next(searchQuery?.trim());
  }

  warehouseChange()
  {
  	let selected_value = this.selected_warehouse;
  	if(selected_value == "add")
  	{
  		//open the warehouse add modal
  		this.open(this.warehouseAddModal,'modal-basic-title2');
  	}
  }

  submit_warehouse(data)
  {
	let thedata={
		warehouse_name: data.warehouse_name,
		created_at: new Date(),
	};

  	let radio_add = this.afs.collection('/warehouses').add(thedata).then(docRef => {
		const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
		itemRefx.update({ uid: docRef.id}); 
	});

	this.create_warehouse_form.reset();
	let element: HTMLElement = document.getElementsByClassName('close_add_warehouse_modal')[0] as HTMLElement;
	element.click();

	this._snackBar.open("Warehouse created successfully", '', {
		duration: 1000,
	});

  }

  	open(content,title) {
		this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	ForgotPassword(passwordResetEmail) {
		return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		// window.alert('Password reset email sent, check your inbox.');
		}).catch((error) => {
		//window.alert(error)
		})
	}
	delete_warehouse(uid)
	{
	    let del_ware = this.afs.doc('/warehouses/'+uid).delete();
	    this._snackBar.open('Warehouse deleted successfully', '', {
	      duration: 1000,
	    });
	}

	public ngOnDestroy(): void {
		this.searchSubscription?.unsubscribe();
	  }

}
