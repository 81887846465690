import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';
import { MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {

currenturl:any;
  constructor(
    public authService: AuthService,
    public router: Router,
    private _snackBar: MatSnackBar
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //console.log('aa',this.authService.userData.providerData[0]);
    // if(this.authService)
    // {
    //     let provider = "ggg";
    //     //let provider = this.authService.userData.providerData[0].providerId;
    //     console.log('the provider is ',provider);
    //     if(provider == 'facebook.com' || provider == 'google.com')
    //     {
    //         let user = JSON.parse(localStorage.getItem('userrole'));
    //         console.log('in guard',user);
    //         if(next.data.roles && next.data.roles.indexOf(user.role) === -1){
    //         //role not authorized
    //         this.router.navigate(["/sign-in"])

    //         }else{
    //         return true;
    //         }
    //     }

    // }
    if(this.authService)
    {      
      if(this.authService.isLoggedIn == true) {
          let user = JSON.parse(localStorage.getItem('userrole'));
            // if(user.can_login == "0")
            // {
            //      this.router.navigate(["/sign-in"]);
            //      this._snackBar.open("Error: Your account is blocked. Please contact admin for more info.G", '', {
            //           duration: 5000,
            //         });
            //     return false;
            // }
            if(next.data.roles && next.data.roles.indexOf(user.role) === -1){
            //role not authorized
            this.router.navigate(["/sign-in"])
            }else{
              return true;
            }
        }
    }
    
    //return true;
    // const currentUser = this.authService.currentUser;


    //  let aa = JSON.parse(localStorage.getItem('userrole'));
    //  console.log('thiss',aa);
    // if(currentUser){
    //   //check if the route is retricted by role
    //   console.log('dddddd',currentUser.role);
    //   if(next.data.roles && next.data.roles.indexOf(currentUser.role) === -1){
    //     //role not authorized
    //     this.router.navigate(["/sign-in"])
      
    //   }else{
    //     return true;
    //   }
    // }

    //return true;
  }

}
