import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class ComponentsService {

  testEvent = new EventEmitter()
  testUpcomingEvent = new EventEmitter()
  testNoadEvent = new EventEmitter()
  recipeEvent = new EventEmitter()
  parentStoreEvent = new EventEmitter()
  currentTemplateEvent = new EventEmitter()
  layOutTypeEvent = new EventEmitter()
  layoutCreated:boolean=false
  upcomingLayoutCreated:boolean=false;
  noAdLayoutCreated:boolean=false;
  bundleCreated:boolean=false;
  menuCreated:boolean=false;
  constructor(private http: HttpClient,
    public afs: AngularFirestore) { }
  productArray = []
  recipeArray = []
  setProductArray(val) {
    this.productArray = val
  }
  getProductArray() {
    return this.productArray
  }

  setRecipeArray(val) {
    this.recipeArray = val
  }

  getRecipeArray() {
    return this.recipeArray
  }

  displayRecipeItems() {
    this.recipeEvent.emit()
  }

  displayItems() {
    this.testEvent.emit()
  }

  displayUpcomingItems() {
    this.testUpcomingEvent.emit()
  }

  displayNoadItems() {
    this.testNoadEvent.emit()
  }

  parentStoreId(val) {
    localStorage.setItem('parentStoreId', val)
    this.parentStoreEvent.emit()
  }

  currentTemplateId(val) {

    this.currentTemplateEvent.emit(val)
  }

  oldProducts = []
  setOldProducts(val) {
    this.oldProducts = val || []
  }

  getOldProducts() {
    return this.oldProducts
  }

  oldRecipe = []
  setOldRecipe(val) {
    this.oldRecipe = val || []
  }

  getOldRecipe() {
    return this.oldRecipe
  }

  layout_type
  setLayoutType(type) {
    this.layOutTypeEvent.next(type)
  }
  // setStoreID(id){
  //   this.setStoreIdEvent
  // }

  // cards
  getCardsPageData(): Observable<any> {
    return this.http.get('/assets/data/components/cards.json');
  }

  // lists
  getListsPageData(): Observable<any> {
    return this.http.get('/assets/data/components/lists.json');
  }

  countWords(description) {
    const words = description.match(/\b\w+\b/g);
    return words ? words.length : 0;
  }

  formatDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return year + '-' + month + '-' + day;
  }

  async resizeGrid(val, current_template_id, isCategoryUpdate, tableName) {
    const layoutRef = this.afs.doc(`/${tableName}/` + current_template_id);

    try {
      const doc = await layoutRef.get().toPromise();
      const currentData: any = doc.data();

    
        if (Array.isArray(currentData?.layout_json_temp)) {
        if (currentData && currentData.layout_json_temp) {
          const updatedJsonTemp = this.updatedJsonTemp(currentData, val, isCategoryUpdate)
          await layoutRef.update({
            layout_json_temp: updatedJsonTemp
          })
        } else {
          console.error("layout_json_temp not found in the document");
        }
      }else if (typeof (currentData?.layout_json_temp) == "string") {
        const layoutRefTemp = this.afs.doc('/layout-json-temp/' + currentData?.layout_json_temp);
        const docSaved: any = await layoutRefTemp.get().toPromise();
        const currentDataTemp = await docSaved.data();
        const updatedJsonTemp = this.updatedJsonTemp(currentDataTemp, val, isCategoryUpdate)

        await layoutRefTemp.update({
          layout_json_temp: updatedJsonTemp
        })
      }else{
        console.error("layout json temp null or undefined")
      }
    } catch (error) {
      console.error("Error fetching/updating document:", error);
    }
  }
  async resizeBundleGrid(val, current_template_id, isCategoryUpdate, tableName) {
    const layoutRef = this.afs.doc(`/${tableName}/` + current_template_id);

    try {
      const doc = await layoutRef.get().toPromise();
      const currentData: any = doc.data();

    
        if (Array.isArray(currentData?.bundle_json_temp)) {
        if (currentData && currentData.bundle_json_temp) {
          const updatedJsonTemp = this.updatedBundleJsonTemp(currentData, val, isCategoryUpdate)
          await layoutRef.update({
            bundle_json_temp: updatedJsonTemp
          })
        } else {
          console.error("bundle_json_temp not found in the document");
        }
      }else if (typeof (currentData?.bundle_json_temp) == "string") {
        const layoutRefTemp = this.afs.doc('/bundle-json-temp/' + currentData?.bundle_json_temp);
        const docSaved: any = await layoutRefTemp.get().toPromise();
        const currentDataTemp = await docSaved.data();
        const updatedJsonTemp = this.updatedBundleJsonTemp(currentDataTemp, val, isCategoryUpdate)

        await layoutRefTemp.update({
          bundle_json_temp: updatedJsonTemp
        })
      }else{
        console.error("layout json temp null or undefined")
      }
    } catch (error) {
      console.error("Error fetching/updating document:", error);
    }
  }
  async resizeMenuGrid(val, current_template_id, isCategoryUpdate, tableName) {
    const layoutRef = this.afs.doc(`/${tableName}/` + current_template_id);

    try {
      const doc = await layoutRef.get().toPromise();
      const currentData: any = doc.data();

    
        if (Array.isArray(currentData?.menu_json_temp)) {
        if (currentData && currentData.menu_json_temp) {
          const updatedJsonTemp = this.updatedMenuJsonTemp(currentData, val, isCategoryUpdate)
          await layoutRef.update({
            menu_json_temp: updatedJsonTemp
          })
        } else {
          console.error("menu_json_temp not found in the document");
        }
      }else if (typeof (currentData?.menu_json_temp) == "string") {
        const layoutRefTemp = this.afs.doc('/menu-json-temp/' + currentData?.menu_json_temp);
        const docSaved: any = await layoutRefTemp.get().toPromise();
        const currentDataTemp = await docSaved.data();
        const updatedJsonTemp = this.updatedMenuJsonTemp(currentDataTemp, val, isCategoryUpdate)

        await layoutRefTemp.update({
          menu_json_temp: updatedJsonTemp
        })
      }else{
        console.error("layout json temp null or undefined")
      }
    } catch (error) {
      console.error("Error fetching/updating document:", error);
    }
  }

  updatedJsonTemp(currentDataTemp, val, isCategoryUpdate) {
    let x = 0;
    let y = 0;
    const updatedLayoutJsonTemp = currentDataTemp.layout_json_temp.map(obj => {
      var temp: any = document.createElement('div');
      temp.innerHTML = obj.content;
      const value1 = temp.getElementsByClassName('dollar-amount')[0]?.innerText
      const value2 = temp.getElementsByClassName('cents-amount')[0]?.innerText
      const textLength = value1 ? value2 ? (value1.trim() + value2.trim())?.replace('.', '')?.length : (value1.trim())?.replace('.', '')?.length : ''
      var circularCardDealWrapper = temp.getElementsByClassName("circular-card-deal-wrapper")[0];
      var offerLabel = temp.getElementsByClassName("offerLabel")[0];
      const isDigitalCoupon = obj.digital_coupon === true;
      const categoryIsMeat = isCategoryUpdate && obj.category === "Meat";
      if (circularCardDealWrapper) {
        if(categoryIsMeat){
          if (val == 2) {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          }
        }else{
          if (val == 2) {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          }
        }
      }
      if (offerLabel) {
        if (val == 2) {
          this.getFontClassOffer('textSize2', offerLabel)
        } else {
          this.getFontClassOffer('textSize1', offerLabel)
        }
      }
      obj.content = temp.innerHTML

      
      const updatedObj = {
        ...obj,
        w: isDigitalCoupon ? obj.w : categoryIsMeat ? 2 : val,
        h: isDigitalCoupon ? obj.h : categoryIsMeat ? 2 : val,
        x: x,
        y: y
      };

      // Update x and y for the next iteration
      x += isDigitalCoupon ? obj.w : updatedObj.w;
      if (x >= 6) {
        x = 0;
        y++;
      }
      return updatedObj

    });
    return updatedLayoutJsonTemp;
  }

  updatedBundleJsonTemp(currentDataTemp, val, isCategoryUpdate) {
    let x = 0;
    let y = 0;
    const updatedBundlesJsonTemp = currentDataTemp.bundle_json_temp.map(obj => {
      var temp: any = document.createElement('div');
      temp.innerHTML = obj.content;
      const value1 = temp.getElementsByClassName('dollar-amount')[0]?.innerText
      const value2 = temp.getElementsByClassName('cents-amount')[0]?.innerText
      const textLength = value1 ? value2 ? (value1.trim() + value2.trim())?.replace('.', '')?.length : (value1.trim())?.replace('.', '')?.length : ''
      var circularCardDealWrapper = temp.getElementsByClassName("circular-card-deal-wrapper")[0];
      var offerLabel = temp.getElementsByClassName("offerLabel")[0];
      const isDigitalCoupon = obj.digital_coupon === true;
      const categoryIsMeat = isCategoryUpdate && obj.category === "Meat";
      if (circularCardDealWrapper) {
        if(categoryIsMeat){
          if (val == 2) {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          }
        }else{
          if (val == 2) {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          }
        }
      }
      if (offerLabel) {
        if (val == 2) {
          this.getFontClassOffer('textSize2', offerLabel)
        } else {
          this.getFontClassOffer('textSize1', offerLabel)
        }
      }
      obj.content = temp.innerHTML

      
      const updatedObj = {
        ...obj,
        w: isDigitalCoupon ? obj.w : categoryIsMeat ? 2 : val,
        h: isDigitalCoupon ? obj.h : categoryIsMeat ? 2 : val,
        x: x,
        y: y
      };

      // Update x and y for the next iteration
      x += isDigitalCoupon ? obj.w : updatedObj.w;
      if (x >= 6) {
        x = 0;
        y++;
      }
      return updatedObj

    });
    return updatedBundlesJsonTemp;
  }

  updatedMenuJsonTemp(currentDataTemp, val, isCategoryUpdate) {
    let x = 0;
    let y = 0;
    const updatedMenuJsonTemp = currentDataTemp.menu_json_temp.map(obj => {
      var temp: any = document.createElement('div');
      temp.innerHTML = obj.content;
      const value1 = temp.getElementsByClassName('dollar-amount')[0]?.innerText
      const value2 = temp.getElementsByClassName('cents-amount')[0]?.innerText
      const textLength = value1 ? value2 ? (value1.trim() + value2.trim())?.replace('.', '')?.length : (value1.trim())?.replace('.', '')?.length : ''
      var circularCardDealWrapper = temp.getElementsByClassName("circular-card-deal-wrapper")[0];
      var offerLabel = temp.getElementsByClassName("offerLabel")[0];
      const isDigitalCoupon = obj.digital_coupon === true;
      const categoryIsMeat = isCategoryUpdate && obj.category === "Meat";
      if (circularCardDealWrapper) {
        if(categoryIsMeat){
          if (val == 2) {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          }
        }else{
          if (val == 2) {
            this.getFontClass('baseSmall', textLength, circularCardDealWrapper)
          } else {
            this.getFontClass('extraSmall', textLength, circularCardDealWrapper)
          }
        }
      }
      if (offerLabel) {
        if (val == 2) {
          this.getFontClassOffer('textSize2', offerLabel)
        } else {
          this.getFontClassOffer('textSize1', offerLabel)
        }
      }
      obj.content = temp.innerHTML

      
      const updatedObj = {
        ...obj,
        w: isDigitalCoupon ? obj.w : categoryIsMeat ? 2 : val,
        h: isDigitalCoupon ? obj.h : categoryIsMeat ? 2 : val,
        x: x,
        y: y
      };

      // Update x and y for the next iteration
      x += isDigitalCoupon ? obj.w : updatedObj.w;
      if (x >= 6) {
        x = 0;
        y++;
      }
      return updatedObj

    });
    return updatedMenuJsonTemp;
  }
  getFontClass(className, textLength, ele) {
    switch (textLength) {
      case 1:
        ele.className = `circular-card-deal-wrapper ${className} singleDigit`
        break;
      case 2:
        ele.className = `circular-card-deal-wrapper ${className} doubleDigit`
        break;
      case 3:
        ele.className = `circular-card-deal-wrapper ${className} tripleDigit`
        break;
      case 4:
        ele.className = `circular-card-deal-wrapper ${className} fourDigit`
        break;
      default:
        ele.className = `circular-card-deal-wrapper ${className} singleDigit`
        break;
    }
  }

  getFontClassOffer(className, ele) {
    ele.className = `offerLabel ${className}`
    return ele.className
  }

  async renewToken() {
    try {
      this.http
        .get("https://api.syndigo.com/api/auth/?username=grocerystoreservices&secret=BxDsbOnawv3N6nQmk%2F%2FNPyo5uhI15yQOGlooB%2BOahekL0rk5j47JXnsH68A5nFuE6zR27pZhaBciwkWEqJlVGg%3D%3D")
        .subscribe(res => {
          localStorage.setItem('CHX_token', res['Value'])
        });
    } catch (error) {
      throw new Error("Token renewal failed");
    }
  }

  async setTempLayout(data, uid) {
    await this.afs
      .doc('/layout-json-temp/' + uid)
      .set({
        layout_json_temp: data
      }, { merge: true })
  }
  async setSavedLayout(data, uid) {
    await this.afs
      .doc('/layout-json-saved/' + uid)
      .set({
        layout_json_saved: data
      }, { merge: true })
  }

  async getTempLayout(uid) {
    const layoutRefTemp = this.afs.doc('/layout-json-temp/' + uid);
    const docTemp: any = await layoutRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getSavedLayout(uid) {
    const layoutRefTemp = this.afs.doc('/layout-json-saved/' + uid);
    const docTemp: any = await layoutRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
 //for bundle
  async getBundleTempLayout(uid) {
    const bundleRefTemp = this.afs.doc('/bundle-json-temp/' + uid);
    const docTemp: any = await bundleRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getBundleSavedLayout(uid) {
    const bundleRefTemp = this.afs.doc('/bundle-json-saved/' + uid);
    const docTemp: any = await bundleRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }

  async setBundleTempLayout(data, uid) {
    await this.afs
      .doc('/bundle-json-temp/' + uid)
      .set({
        bundle_json_temp: data
      }, { merge: true })
  }
  async setBundleSavedLayout(data, uid) {
    await this.afs
      .doc('/bundle-json-saved/' + uid)
      .set({
        bundle_json_saved: data
      }, { merge: true })
  }

  //for menu 
  async getMenuTempLayout(uid) {
    const menuRefTemp = this.afs.doc('/menu-json-temp/' + uid);
    const docTemp: any = await menuRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getMenuSavedLayout(uid) {
    const menuRefTemp = this.afs.doc('/menu-json-saved/' + uid);
    const docTemp: any = await menuRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }

  async setMenuTempLayout(data, uid) {
    await this.afs
      .doc('/menu-json-temp/' + uid)
      .set({
        menu_json_temp: data
      }, { merge: true })
  }
  async setMenuSavedLayout(data, uid) {
    await this.afs
      .doc('/menu-json-saved/' + uid)
      .set({
        menu_json_saved: data
      }, { merge: true })
  }
  extractTextFromHTML(html) {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	}

}