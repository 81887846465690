<ng-template #iconSearchModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Search Images</h4>
		<button type="button" class="close close_unsplash_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">
				<form [formGroup]="search_icon_form" (ngSubmit)="submit_search_icon(search_icon_form.value)" style="width: 100%;padding: 0 1rem;" >

				
				<div class="row">
					<div class="col-9">
						<input  placeholder="Search by product name"  class="form-control" formControlName="query">
					</div>
				
					<div class="col-3 text-center" >
						<button type="submit" class="btn btn-primary float-center text-center" >Search</button>
					</div>
				</div>
			</form>

			<div class="row" style="margin-top: 1rem;padding: 0 2rem;">
				
				<div class="row" *ngIf="show_icon_results">
					<label class="col-12">Results</label>
					<div class="col-12">
						<img src="{{data.urls.regular}}" style="cursor: pointer;height:85px;margin: 5px;width: 132px;padding: 0px;border: 1px solid #cfcaca;border-radius:5px;image-rendering: -webkit-optimize-contrast;" *ngFor="let data of icons" (click)="set_current_image(data.urls.regular)" >
						<div *ngIf="icons && icons.length <= 0">No images found</div>
					</div>
				</div>
				
			</div>
			<div class="row" style="padding-right:2rem;color: #818181;">
					<div class="col-12 text-right" style="    padding-right: 5px;">
						<p style="    margin-bottom: 0;margin-top: 1rem">Powered by <a href="https://unsplash.com" target="_blank" style="color: #818181;text-decoration: underline;margin-bottom: 0;margin-top: 1rem;">Unsplash</a></p>
					</div>
				</div>


	
	</div>
	
</ng-template>

<ng-template #uploadExistModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Duplicate Products Warning</h4>
		<button type="button" class="close close_upload_exist_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

			<p >Some products already exist in the system. What would you like to do? </p>
				<button class="btn btn-warning float-right" (click)="import_update_duplicate()">Update duplicates </button>
				<button class="btn btn-success float-right" style="margin-right: 1rem;" (click)="import_ignore_duplicate()">Don't Import duplicates</button>

	</div>
	
	
</ng-template>

 <ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title4" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete Products</h4>
		<button type="button" class="close delete_product_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

			<p >Are you sure want to delete all the selected products? </p>
				<button class="btn btn-danger float-right" (click)="delete_products('product')">Yes, Delete them </button>
				<button class="btn btn-light float-right" style="margin-right: 1rem;" (click)="modal.dismiss('Cross click')">No, Don't Delete</button>

	</div>
	
	
</ng-template>

<ng-template #matchdeleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title4" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete Products</h4>
		<button type="button" class="close delete_product_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">

			<p >The products you are trying to delete is an active product in a circular. Are you sure what to delete it? </p>
				<button class="btn btn-danger float-right" (click)="delete_products('grid')">Yes, Delete them </button>
				<button class="btn btn-light float-right" style="margin-right: 1rem;" (click)="modal.dismiss('Cross click')">No, Don't Delete</button>

	</div>
	
	
</ng-template>

 <div class="content">

	  <div class="container-fluid p-0">

		<div class="digital-circular-headline ">
		  <h3>All Products <!-- <span class="fas fa-search" (click)="openIconModal()"></span> --> </h3>  			
		</div>

		<div class="row" style="margin-left:0">
			
				<div class="all" style="cursor: pointer;background: #71b01d;padding: 4px 14px;border-radius: 28px;margin-right: 15px;color: #fff;    border: 1px solid #71b01d;" (click)="gotoAll()">
					All Products</div>
				<div class="saved" style="cursor: pointer;background: transparent;padding: 4px 14px;border-radius: 28px;margin-right: 15px;color: #71b01d;border: 1px solid #71b01d;" (click)="gotoSaved()">
					<img src="../assets/imgs/save-green.svg" style="height: 14px;margin-right: 8px;margin-top: -3px;">Saved Products</div>
		</div>
		<div class="row align-items-center" style="margin-top: 1rem;">
		  <div class="col-md-4">
			<div class="filter-and-search-area d-flex">
			  <div class="dropdown">
				<div class="select-access-box">
				  <span _ngcontent-eke-c147=""><img _ngcontent-eke-c147=""
					  src="../../assets/images/search-icon-for-placeholder.png" alt=""></span>
					  <input id="SearchForm" type="text" placeholder="Search Product" class="form-control search-form" style="padding-left: 40px;"  (keyup)="doFilter($event.target.value)">
				</div>
			  </div>

			
			</div>

		  </div>


		  <div class="col-md-5 d-flex justify-content-end">
			<div  class="add-delete-btn" style="cursor:pointer;" *ngIf="isDeleteShow"><a (click)="openDeleteModal()"><img  src="../../assets/images/delete-icon.png" alt="delete-icon" style="cursor:pointer;"> &nbsp; Delete</a></div>
			<div class="clear-filter"> <a class="clear-filter-btn" (click)="grid_import_from_csv()" style="cursor:pointer;">Import CSV</a></div>
			<input type="file" #fileInput class="upload_file_import" accept=".csv" (change)="import_csv($event.target.files)" style="opacity:0.1;position: absolute;width: 1px;">

			<div class="clear-filter"> <a (click)="export_csv()" class="clear-filter-btn" style="cursor: pointer;">Export CSV</a></div>
		  </div>
		  <div class="col-md-3">
			<div class="add-new-store-button">
			  <a (click)="openAddProduct()" class="theme-btn">+ Add New Product</a>
			</div>
		  </div>
		</div>
		<div class="row ">
		  <div class="col-md-9">
		<div class="owners-record-table">

		  <div class="row no-gutters" style="margin-top: 1rem;background: #f7f7f7;">
		<div class="visualization-table col-12 table-responsive" style="padding-top: 0px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

		 
		   <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="saved_items_list" style="padding-top:0;" >
		   	 <ng-container matColumnDef="checkbox">
			 <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;">
				<div class="recipe-checkbox">
					<input type="checkbox" id="" [ngModel]="checkbox_select_all" (click)="checkbox_select_all_event($event, saved_items_list)">
				</div>
			</th>
			<td mat-cell *matCellDef="let element;let i = index;">
				<div class="recipe-checkbox">
				  <input type="checkbox" id="" class="checkbox-delete-{{element.serial}}" [(ngModel)]="checkbox_checked_item[element.serial]" (click)="checkbox_single_select_event($event,i,element.serial)" >
			  </div>
			</td>
			</ng-container>

		  <ng-container matColumnDef="id">
			 <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"># </th>
			  <td mat-cell *matCellDef="let element; let i = index;"> {{element.serial +1}} </td>
			</ng-container>
			<!-- <ng-container matColumnDef="uid">
			  <th mat-header-cell *matHeaderCellDef  style=""> UID </th>
			  <td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
			</ng-container> -->
			<ng-container matColumnDef="upc">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> UPC </th>
			  <td mat-cell *matCellDef="let element">{{element.upc_code}}</td>
			</ng-container>
			
			<ng-container matColumnDef="image">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Image </th>
			  <td mat-cell *matCellDef="let element">
			  	<a href="{{element.product_image}}&size=418&thumbnail=true&palette=RGB" target="_blank" *ngIf="element.product_image !=''">
			  		<img *ngIf="!element.isVideo || element.isVideo=='youtube' ||element.isVideo=='facebook'" src="{{element.product_image}}&size=418&thumbnail=true&palette=RGB" style="border-radius: 5px;height:24px;width:24px;" >
					<video *ngIf="element.isVideo==true" src="{{element.product_image}}" style="border-radius: 5px;height:24px;width:24px;" ></video>
			  	</a>
			  	<img src="assets/images/Rectangle 316.png" style="border-radius: 5px;height:24px;width:24px;" *ngIf="element.product_image ==''">
			  </td>
			</ng-container>
			<!-- <ng-container matColumnDef="group">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Group </th>
			  <td mat-cell *matCellDef="let element">
				{{element.group_name}}

			  </td>
			</ng-container> -->
			<ng-container matColumnDef="product_desc">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Product Description </th>
			  <td mat-cell *matCellDef="let element">{{element.product_description}}</td>
			</ng-container>
			<!-- <ng-container matColumnDef="store_id">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Store ID </th>
			  <td mat-cell *matCellDef="let element"> {{element.store_id}} </td>
			</ng-container> -->
			<ng-container matColumnDef="category">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Category </th>
			  <td mat-cell *matCellDef="let element">{{element.category}}</td>
			</ng-container>
			<!-- <ng-container matColumnDef="status">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;"> Status </th>
			  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
			</ng-container> -->

			<ng-container matColumnDef="price">
			  <th mat-header-cell *matHeaderCellDef  style="font-weight: 700;">Price</th>
			  <td mat-cell *matCellDef="let element">{{element.cost}}</td>
			</ng-container>
		
		  <!--  <ng-container matColumnDef="lastlogindate">
			  <th mat-header-cell *matHeaderCellDef  style=""> Last login at </th>
			  <td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
			</ng-container> -->


			<ng-container matColumnDef="action">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: 700;">Actions</th>
			  <td mat-cell *matCellDef="let element" style="display: flex;   align-items: center;   justify-content: center;  height: 45px; ">
			  	 <img src="assets/imgs/save-green.svg" style="margin-right:8px;cursor: pointer;" id="{{element.uid}}" *ngIf="element.is_saved" (click)="unsave_item(element.uid)">
			  	 <img src="assets/imgs/save-grey.svg" style="margin-right:8px;cursor: pointer;" id="{{element.uid}}" *ngIf="!element.is_saved" (click)="save_item(element.uid)">
			  <button class="btn btn-sm btn-success" (click)="view_product(element)" *ngIf="current_selected_product && current_selected_product.uid == element.uid">View</button>
			  <button class="btn btn-sm btn-outline-success" (click)="view_product(element)" *ngIf="current_selected_product && current_selected_product.uid != element.uid">View </button>
			  <button class="btn btn-sm btn-outline-success" (click)="view_product(element)" *ngIf="!current_selected_product">View </button>


			  <!-- <button style="box-shadow: none !important;
			  background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
			  font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
			  </td>
			</ng-container>  
			
	
			 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
			 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
		  </table>
		  <mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="pageSize"
		  [pageIndex]="pageIndex" [length]="totalItems" (page)="onPageChange($event)"></mat-paginator>		
	  </div>
	</div>
		</div>
	  </div>
	  <div class="col-md-3 col-sm-6 pl-0" *ngIf="show_add_product">
	  	<form [formGroup]="product_add_form" (ngSubmit)="product_store(product_add_form.value);">

		  <div class="product-details">
			  <div class="product-details-text">
				  <h5 class="mb-0">Add New Product</h5>
				  <span>Enter product details below</span>
			  </div>
			  <div class="product-image mt-3 mb-3 flex-nowrap ">
				  	<div class="pro-image" (click)="trigger_upload_image_add()">
						<div *ngIf="image_uploading">
							<img src="assets/imgs/loading2.gif">
						</div>
						<div *ngIf="!image_uploading">
							<img src="../../assets/images/Rectangle 316.png" alt=""
							*ngIf="imageurl2 == ''">
							<video src="{{imageurl2}}" *ngIf="imageurl2 != '' && isVideo==true"
								style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;"
								autoplay muted loop></video>
							<img src="{{imageurl2}}" alt=""
								*ngIf="imageurl2 != '' && (!isVideo || isVideo=='youtube' || isVideo=='facebook')"
								style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;background: #ececec;">
						</div>
					</div>
					<input type="file" name="upload_image_update"  accept="video/* image/*" (change)="upload($event,'2')" class="update_image_upload2" style="opacity:0;position:absolute;" >
					<div class="pro-image-details">
						<h5>Product Image</h5>
						<span style="font-size: 9px;cursor:pointer;" (click)="openIconModal('add')">Select via Unsplash</span><br>
						<span>Upload PNG/JPG/JPEG/Gif</span> <br>
						<span>Max. 2 MB</span>
					</div>
			  </div>
			  <div class="product-form">

					<div class="form-group">
						<input type="text" class="form-control" id="ProductDescription"
							placeholder="Product Name" [matAutocomplete]="auto"
							(keyup)="autoComplete($event.target.value)"
							formControlName="product_description" />
						<mat-autocomplete #auto="matAutocomplete">
							<mat-option *ngFor="let item of matchingItems" (click)="selectItem(item)">
								{{item.product_description}}
							</mat-option>
						</mat-autocomplete>
					</div>
					<div class="form-group">
						<input type="text" class="form-control" id="Upc" placeholder="UPC" formControlName="upc_code" />
					</div>
					<div class="form-group">
						<input type="text" class="form-control" id="Pack" placeholder="Pack" formControlName="pack" />
					</div>
					<div class="form-group">
						<input type="hidden" class="form-control" id="PriceLabel"
							placeholder="Product Price Label" formControlName="price_label_format" />
					</div>
					<div class="form-group">
						<ng-select  bindLabel="Select Group"   class="" formControlName="category"  [clearable]="false" placeholder="Select Category" notFoundText="No category found"   #addgroupid >
							<ng-option *ngFor="let type of categories_list;let j = index" [value]="type.value">{{type.value}}</ng-option>
						</ng-select>
					</div>
					<input type="text" class="form-control"
						(input)="showStoreInMaster($event.target.value)" id="videoUrl"
						placeholder="video URL/ banner link/ site links"
						formControlName="product_url" />
					<div class="pro-checkboxes">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" id="new_window"
								formControlName="new_window">
							<label class="form-check-label" for="new_window">
								Open this link in new Window
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" id="autoplay"
								formControlName="autoplay">
							<label class="form-check-label" for="autoplay">
								Autoplay video
							</label>
						</div>
						<div class="form-check" *ngIf="storeInMaster">
							<input class="form-check-input" type="checkbox" id="loopvideo"
								formControlName="loopvideo">
							<label class="form-check-label" for="loopvideo">
								Loop video
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" id="isBanner"
								formControlName="isBanner" (change)="showEditor_add()">
							<label class="form-check-label" for="isBanner">
								Is Banner
							</label>
						</div>
						<div class="form-group" *ngIf="product_add_form.get('isBanner').value">
							<angular-editor [config]="config" formControlName="bannerText"></angular-editor>
						</div>
						<div class="form-check" *ngIf="storeInMaster">
							<input class="form-check-input" type="checkbox" id="storeInMaster"
								formControlName="storeInMaster">
							<label class="form-check-label" for="storeInMaster">
								Store Video in Master Products
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value=""
								id="defaultCheck1" formControlName="hide_price_label">
							<label class="form-check-label" for="defaultCheck1">
								Hide Price Label
							</label>
						</div>

						<div class="form-check">
							<input class="form-check-input" type="checkbox" value=""
								id="defaultCheck2" formControlName="hide_addtolist_label">
							<label class="form-check-label" for="defaultCheck2">
								Hide Add to List Label</label>
						</div>

						<div class="form-check">
							<input class="form-check-input" type="checkbox" value=""
								id="defaultCheck3" formControlName="hide_productname_label">
							<label class="form-check-label" for="defaultCheck3">
								Hide Product Name Label
							</label>
						</div>
					</div>
					<div class="submit-btn text-center">
						<button type="submit" class="pro-detail-submit-btn btn btn-primary" [disabled]="buttonDisabled">Add</button>
					</div>
			  </div>

			  
		  </div>
	   </form>
		</div>

		<div class="col-md-3 col-sm-6 pl-0" *ngIf="current_selected_product && !show_add_product ">
		  <div class="product-details">
			  <div class="product-details-text">
				  <h5 class="mb-0">Item Details</h5>
				  <span>Click on the product from layout to view/edit details</span>
			  </div>
			  <div class="product-image mt-3 mb-3 flex-nowrap ">
				  	<div class="pro-image" (click)="trigger_upload_image()">
						<div *ngIf="!image_uploading">
							<img src="../../assets/images/Rectangle 316.png" alt=""
								*ngIf="imageurl1 == ''">
							<video src="{{imageurl1}}" *ngIf="imageurl1 != '' && isVideo==true"
								style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;"
								autoplay muted loop></video>
							<img src="{{imageurl1}}" alt=""
								*ngIf="imageurl1 != '' &&( !isVideo || isVideo=='youtube'||isVideo=='facebook')"
								style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;background: #ececec;">
						</div>
						<div *ngIf="image_uploading">
							<img src="assets/imgs/loading2.gif">
						</div>
						<input type="file" name="upload_image_update" accept="video/* image/*"
							(change)="upload($event,'1')" class="update_image_upload"
							style="opacity:0;position:absolute;">
					</div>
				  	<div *ngIf="image_uploading">
				  		<img src="assets/imgs/loading2.gif">
				  	</div>
				  <div class="pro-image-details">
					  <h5>Product Image</h5>
					  <span style="font-size: 9px;cursor:pointer;" (click)="openIconModal('edit')">Select via Unsplash</span><br>
					  <span>Upload PNG/JPG/JPEG/Gif</span> <br>
					  <span>Max. 2 MB</span>
				  </div>
			  </div>
				<div class="product-form">
					<form [formGroup]="product_edit_form" (ngSubmit)="product_update(product_edit_form.value);">

						<div class="form-group">
							<input type="text" class="form-control" id="Upc" placeholder="UPC" formControlName="upc_code" />
						</div>
						<div class="form-group">
				<input type="text" class="form-control" id="ProductDescription"
				placeholder="Product Description" formControlName="product_description" />
				</div>
				<div class="form-group">
				<input type="text" class="form-control" id="Pack" placeholder="Pack" formControlName="pack" />
				</div>
				<div class="form-group">
				<input type="hidden" class="form-control" id="PriceLabel"
				placeholder="Product Price Label" formControlName="price_label_format" />
				</div>
				<div class="form-group">
				<ng-select  bindLabel="Select Group"   class="" formControlName="category"  [clearable]="false" placeholder="Select Category" notFoundText="No category found"   #addgroupid >
				<ng-option *ngFor="let type of categories_list;let j = index" [value]="type.value">{{type.value}}</ng-option>
				</ng-select>
				</div>
					<div class="pro-checkboxes">
						<input type="text" class="form-control" id="videoUrl"
						(input)="showStoreInMaster($event.target.value, true)"
						placeholder="video URL/ banner link/ site links"
						formControlName="product_url" />
				<div class="pro-checkboxes">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="new_window"
							formControlName="new_window">
						<label class="form-check-label" for="new_window">
							Open this link in new Window
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="autoplay"
							formControlName="autoplay">
						<label class="form-check-label" for="autoplay">
							Autoplay video
						</label>
					</div>
					<div class="form-check" *ngIf="storeInMaster">
						<input class="form-check-input" type="checkbox" id="loopvideo"
							formControlName="loopvideo">
						<label class="form-check-label" for="loopvideo">
							Loop video
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="isBanner"
							formControlName="isBanner">
						<label class="form-check-label" for="isBanner">
							Is Banner
						</label>
					</div>
					<div class="form-group" *ngIf="product_edit_form.get('isBanner').value">
						<angular-editor [config]="config" formControlName="bannerText"></angular-editor>
					</div>
					<div class="form-check" *ngIf="storeInMaster">
						<input class="form-check-input" type="checkbox" id="storeInMaster"
							formControlName="storeInMaster">
						<label class="form-check-label" for="storeInMaster">
							Store Video in Master Products
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="defaultCheck1"
							formControlName="hide_price_label">
						<label class="form-check-label" for="defaultCheck1">
							Hide Price Label
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="defaultCheck2"
							formControlName="hide_addtolist_label">
						<label class="form-check-label" for="defaultCheck2">
							Hide Add to list Label
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="defaultCheck3"
							formControlName="hide_productname_label">
						<label class="form-check-label" for="defaultCheck3">
							Hide Product Name Label
						</label>
					</div>
					<div class="form-group" *ngIf="this.isBundleProduct" >
						<div class="bundle-input" style="display: flex;">												
							<input class="form-control"type="text" id="bundleProduct" formControlName="bundleProduct" #bundleProduct (keydown.enter)="onEnter($event)" placeholder="Add product to bundle" />&nbsp;
							<button type="button"  style=" width: 52px;height: 32px;text-align: center;display: flex;align-items: center;justify-content: center" class="btn btn-primary" (click)="updateToBundledList()">Add</button>
						</div>
					  </div>
				  
					  <div *ngIf="bundledList?.length > 0 && this.isBundleProduct" class="bundled-list">
						<h6>Products in the Bundle:</h6>
						<ul>
						  <li *ngFor="let product of bundledList">
							<img src="assets/imgs/icons8-delete.svg" alt="Remove" (click)="removeFromBundledList(product)" class="remove-icon" />
							{{ product }}
							<!-- <button type="button" (click)="removeFromBundledList(product)">Remove</button> -->
						  </li>
						</ul>
					  </div>
				</div>
				</div>
				<div class="submit-btn text-center">
					<button type="submit" class="pro-detail-submit-btn btn btn-primary" [disabled]="buttonDisabled">Update</button>
				</div>
				</form>
				</div>

			  
		  </div>
	  
		</div>
		</div>

	  </div>

</div>
<div class="loader" *ngIf="loading">
	<div class="loading"> Loading&#8230;
	</div>
</div>