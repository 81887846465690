import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/shared/services/auth.service";
import { RecipesComponent } from "../recipes.component";

@Component({
  selector: "app-all-recipe-dialog",
  templateUrl: "./all-recipe-dialog.component.html",
  styleUrls: ["./all-recipe-dialog.component.scss"],
})
export class AllRecipeDialogComponent implements OnInit {
  get_current_store_id: any;
  user: any;
  current_store_uid: any;
  master_recipes_list_temp: any;
  master_recipes_list: any;
  leadsSort: any;
  leadsPaginator: any;
  store_data: any;
  checkbox_select_all: any;
  checkbox_delete_array: any = [];
  tableColumns: string[] = ["checkbox", "image", "name"];
  main_items: any;
  // store_recipe_list: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private modalService: NgbModal,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RecipesComponent>
  ) {}

  ngOnInit(): void {
    this.get_current_store_id = localStorage.getItem("current_store_id");
    if (this.get_current_store_id) {
      this.user = JSON.parse(this.get_current_store_id);
    } else {
      this.user = JSON.parse(localStorage.getItem("userrole"));
    }
    this.current_store_uid = this.user?.store_uid;

    this.afs
      .collection("/recipes", (ref) =>
        ref
          .where("storeInMasterRecipe", "==", true)
          .orderBy("created_at", "desc")
      )
      .snapshotChanges()
      .subscribe((data) => {
        let i = 0;
        this.master_recipes_list_temp = data.map((e) => {
          i++;
          return {
            uid: e.payload.doc.id,
            recipe_name: e.payload.doc.data()["recipe_name"],
            recipe_cover_image: e.payload.doc.data()["recipe_cover_image"],
            ingredients: e.payload.doc.data()["ingredients"],
            instructions: e.payload.doc.data()["instructions"],
            store_uid: e.payload.doc.data()["store_uid"],
            store_recipe_uid: e.payload.doc.data()["store_recipe_uid"],
            store_list: e.payload.doc.data()["store_list"],
            products_array: e.payload.doc.data()["products_array"],
            status: e.payload.doc.data()["status"]
              ? e.payload.doc.data()["status"]
              : "show",
            serial: i - 1,
          };
        });
        this.master_recipes_list = this.master_recipes_list_temp;
        this.master_recipes_list = new MatTableDataSource(
          this.master_recipes_list
        );
        this.master_recipes_list.sort = this.leadsSort;
        this.master_recipes_list.paginator = this.leadsPaginator;
        this.main_items = this.master_recipes_list;
      });
  }

  checkbox_select_all_event(event) {
    let value = event.target.checked;
    let cnt = this.master_recipes_list_temp.length;
    for (let i = 0; i < cnt; i++) {
      if (value == true) {
        this.checkbox_delete_array[i] = true;
      } else {
        this.checkbox_delete_array[i] = false;
      }
    }
  }

  checkbox_single_select_event(event, i) {
    if (event.target.checked == true) {
      this.checkbox_delete_array[i] = true;
    } else {
      this.checkbox_delete_array[i] = false;
    }
  }

  close_dialog() {
    this.dialogRef.close();
  }

  doFilter = (value: string) => {
    this.main_items.filter = value.trim().toLocaleLowerCase();
  };

  add_recipe_to_store() {
    if (
      this.checkbox_delete_array.length <= 0 ||
      !this.checkbox_delete_array.includes(true)
    ) {
      this._snackBar.open("Please Select Atleast One Recipe", "", {
        duration: 2000,
      });
      return false;
    }
    this.master_recipes_list_temp.forEach(async (data, index) => {
      if (this.checkbox_delete_array[index] == true) {
        if (!data.store_list.some((obj) => obj === this.current_store_uid)) {
          const itemRefx2 = this.afs.doc("/recipes/" + data.uid);
          const oldData = await itemRefx2.get().toPromise();
          const oldStores: any = oldData.data();
          itemRefx2
            .update({
              store_list: [...oldStores.store_list, this.current_store_uid],
            })
            .then(() => {});
        }
      }
    });
    this._snackBar.open("Recipe added successfully!", "", {
      duration: 2000,
    });
    this.dialogRef.close();
  }
}
