import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {

  groupeditForm: FormGroup;
  loading:boolean = false;
  submitted = false;
  user:any;
  users:any;
  stores_list:any;
  id:any;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    public afs: AngularFirestore,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
  ) { 
    this.groupeditForm = new FormGroup({
			groupName: new FormControl(null, Validators.required),
      store      : new FormControl(null, Validators.required)
	  });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('userrole'));

    this.id = this.route.snapshot.params['id'];
    this.afs.collection('/group_list',ref=>ref.where('uid','==',this.id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
        this.user = data.map(e => {
          return{
            uid: e.payload.doc.id,
            groupName: e.payload.doc.data()['groupName'],
            store : e.payload.doc.data()['store']
          };
        });
        this.groupeditForm.patchValue({
          groupName 		: this.user[0]?.groupName,
          store          : this.user[0]?.store        
        });
    });

    this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
      this.users = data.map(e => {
      return{
      uid: e.payload.doc.id,
      photoURL: e.payload.doc.data()['photoURL']?e.payload.doc.data()['photoURL']:'./assets/imgs/user-placeholder.png',
      displayName: e.payload.doc.data()['displayName'],
      role: e.payload.doc.data()['role']
      };
      });
    });

    this.afs.collection('/stores',ref=>ref.where('type','==', 'main_store').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
      this.stores_list = data.map(e => {
        return{
          uid: e.payload.doc.id,
          company_name: e.payload.doc.data()['company_name'],
          email: e.payload.doc.data()['email'],
          group_id: e.payload.doc.data()['group_id'],
          group_name: e.payload.doc.data()['group_name'],
          phone: e.payload.doc.data()['phone'],
          store_id: e.payload.doc.data()['store_id'],
          store_owner_name: e.payload.doc.data()['store_owner_name'],
          warehouse_id: e.payload.doc.data()['warehouse_id'],
          warehouse_name: e.payload.doc.data()['warehouse_name'],
          created_at:e.payload.doc.data()['created_at'],
          can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
          status:e.payload.doc.data()['status'],
          user_id:e.payload.doc.data()['user_id'],
          branch_count: e.payload.doc.data()['branch_count']
        };
      });	
      this.selectAllForDropdownItems(this.stores_list)
    });
  }

  get registerFormControl() {
    return this.groupeditForm.controls;
  }

  goToLogin() {
    window.location.href = '/login';
  }
  ChangePassword() {
    $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
    $('#exampleModal').css('opacity', '1');
  }
  backToUser(){
    this.router.navigate(['/admin/branch-group']);
  }

  group_edit(data){
    this.loading = true;
    this.submitted = true;
    if(this.groupeditForm.valid){
      let groupData = {
        groupName:data.groupName,
        store: data.store,
        updated_at: new Date()
      };
      let groupadd = this.afs.doc('/group_list/'+this.id).set(groupData,{merge:true}).then(docRef => {
      });
      this._snackBar.open('Group Update successfully', '', {
        duration: 2000,
      });
      this.loading = false;
      this.router.navigate(['/admin/branch-group']);
    }
  }

  select_branch(event){
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
}
