import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, NgZone, ElementRef, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentsService } from '../../components.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import algoliasearch ,{ SearchClient } from 'algoliasearch/lite';
import { LibraryProductAddService } from 'src/app/shared/services/library-product-add.service';
import { DigitalMenuComponent } from './digital-menu.component';

@Component({
	selector: 'app-all-items-dialog',
	templateUrl: 'all-items-dialog.html',
	styleUrls: ['./saved-items-dialog.scss']
})
export class AllItemsMenuDialogComponent implements OnInit {
	datasource = [];
	tableColumns: string[] = ['checkbox', 'id', 'upc', 'image', 'product_desc', 'category', 'price'];
	title = 'appBootstrap';
	saved_items_list: any;
	user: any;
	current_store_uid: any;
	importcsvdata: any = [];
	saved_items_list_temp: any;
	duplicates_temp: any;
	closeResult: any;
	current_selected_product: any;
	imageurl1: any = '';
	imageurl2: any = '';
	ref: any;
	task: any;
	imageSrc: any;
	imageurl: any;
	tabName: any;
	iframe_src: any;
	popup_loading: any;
	show_add_product: boolean = false;
	checkbox_filterd_save_items_array: any = [];
	checkbox_checked_item : any = []
	checkbox_select_all: any;
	categories_list: any;
	main_items: any;
	save_list: any;
	master_library: any;
	product_edit_form: FormGroup;
	product_add_form: FormGroup;
	image_uploading: boolean = false;
	show_icon_results: any;
	icons: any;
	master_list: any;
	ghost_mode: boolean = false;
	get_current_store_id: any;
	items_for_grid: any = []
	isGIF: boolean
	headerText: any = 'Choose Products To Add To Circular';
	multiple_checked: boolean = false;
	store_data:any;
	video_data:any;
	pageSize = 10;
	totalItems: any;
	startRange = 1;
	pageIndex = 0;
	lastItem: any;
	firstItem: any;
	storeTotalItem: any;
	filterstoreTotalItem: any;
	storePrevPageIndx: any;
	storePageIndex: any;
	masterPageIndex1 = 0;
	masterPrevPageIndx: any;
	masterTotalItem: any;
	masterFirstItem: any;
	masterLastItem: any;
	cnt: number;
	filterValue : any;
	filterFlag: boolean;
	searchClient: SearchClient;
	startIndex : any;
	buttonDisabled : boolean = false

	search_icon_form: FormGroup;
	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('leadsSort', { static: true }) leadsSort: MatSort;
	@ViewChild('leadsPaginator', { static: true }) leadsPaginator: MatPaginator;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('deleteModal') deleteModal;
	master_saved_items_list_temp: any = [];
	master_saved_items_list: any;
	previoustabName: any;
	imageUrl1Pro: any;
	domain_name: string='';
	storeData: any;


	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private componentService: ComponentsService,
		private libraryProductAdd : LibraryProductAddService,
		public dialogRef: MatDialogRef<DigitalMenuComponent>,
		private cdr: ChangeDetectorRef
	) {
		this.searchClient = algoliasearch(
			environment.algolia.appId,
			environment.algolia.apiKey,
		  );

		if (this.router.url.includes('admin/edit-recipe')) {
			this.headerText = 'Choose Products To Add To Recipe';
		}
		this.product_edit_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			isBanner: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(""),
			product_image: new FormControl(""),
			upc_code: new FormControl(""),
			product_description: new FormControl(""),
			pack: new FormControl(""),
			isBanner: new FormControl(""),
			product_url: new FormControl(""),
			category: new FormControl(""),
			price_label_format: new FormControl(""),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
		});

		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});
	}

	submit_search_icon(value) {
		this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&query=' + value.query + '&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => { 
				this.icons = res['results']; 
				this.show_icon_results = true; 
			});
	}

	// export_csv()
	// {
	// 	this.downloadFile(this.saved_items_list_temp);
	// 	this._snackBar.open("File downloaded successfully!",'',{
	// 		duration: 1500,
	// 	});
	// }

	async ngOnInit() {
		if (this.data.from == 'recipebox') {
			this.masterLibrary()
		} else {
			let chkghost = localStorage.getItem('ghost_mode');
			if (chkghost) {
				this.ghost_mode = true;
			} else {
				this.ghost_mode = false;
			}
			this.get_current_store_id = localStorage.getItem('current_store_id');
			if (this.get_current_store_id) {
				this.user = JSON.parse(this.get_current_store_id);
			} else {
				this.user = JSON.parse(localStorage.getItem('userrole'));
			}
			this.current_store_uid = this.user.store_uid;
			this.filterFlag = false
			this.storeItem()
			await this.afs.collection('/stores', ref => ref.where('uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
				this.storeData = data.map(e => {
					return {
						uid: e.payload.doc.id,
						access_coupon: e.payload.doc.data().access_coupon,
						access_layout: e.payload.doc.data().access_layout,
						access_recipe: e.payload.doc.data().access_recipe,
						company_name_slug: e.payload.doc.data().company_name_slug,
						branch_name_slug: e.payload.doc.data().branch_name_slug,
						theme_base_color: e.payload.doc.data().theme_base_color ? e.payload.doc.data().theme_base_color : '#28a745'
					};
				});
			});
		}
	}

  async onPageChange(event: any) {
	this.checkbox_filterd_save_items_array = []
	this.checkbox_checked_item = []
	this.checkbox_select_all = false
	this.pageSize = event.pageSize;
   	if (this.tabName === "master-item") {
		this.masterPageIndex1 = event.pageIndex;
		this.masterPrevPageIndx = event.previousPageIndex;
		if(this.filterValue){
			await this.searchMaster(this.filterValue, event);
		}else{
			await this.masterLibrary1('all');
			await this.getTotalMasterItemCount('all');
		}
    }
  }

  async getTotalMasterItemCount(value) {
	const valueLower = value.toLocaleLowerCase();
	const valueUpper = value.toLocaleUpperCase();
	const valueArrayLower = valueLower.split(" ");
	const valueArrayUpper = valueUpper.split(" ");
	const combinedArray = valueArrayLower.concat(valueArrayUpper);
	if(value=='all' || value ==''){
		if (this.masterTotalItem === undefined) {
			try {
				const querySnapshot = await this.afs.collection("/master-items", (ref) => ref.where('isBundleProduct', '==', false)).get().toPromise();
				this.masterTotalItem = querySnapshot.size; // Cache the totalItems
				this.totalItems = this.masterTotalItem;
				// Update the paginator length
				this.leadsPaginator.length = this.totalItems;
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		} else {
			this.totalItems = this.masterTotalItem;
			this.leadsPaginator.length = this.totalItems;
		}
	}else{
		const Value =  value.trim()
		try {
			const querySnapshot = await this.afs.collection("/master-items", (ref) => ref
			.where('tag', 'array-contains-any', combinedArray)
			.orderBy("product_description", "asc")
			.orderBy("uid", "desc")).get().toPromise();

			this.filterstoreTotalItem = querySnapshot.size; // Cache the totalItems
			this.totalItems = this.filterstoreTotalItem;
			this.leadsPaginator.length = this.totalItems;
		} catch (error) {
			console.error("Error fetching data: ", error);
			}
		}
	}

	storeItem() {
		this.checkbox_filterd_save_items_array = []
		this.leadsPaginator.pageIndex = 0;
		this.tabName = 'store-item'
		this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).where('isBundleProduct', '==', false).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					product_name_slug: e.payload.doc.data()['product_name_slug'],
					pack: e.payload.doc.data()['pack'] ? e.payload.doc.data()['pack'] : '',
					price_label_format: e.payload.doc.data()['price_label_format'] ? e.payload.doc.data()['price_label_format'] : '',
					price_label_value1: e.payload.doc.data()['price_label_value1'] ? e.payload.doc.data()['price_label_value1'] : '',
					price_label_value2: e.payload.doc.data()['price_label_value2'] ? e.payload.doc.data()['price_label_value2'] : '',
					price_label_value3: e.payload.doc.data()['price_label_value3'] ? e.payload.doc.data()['price_label_value3'] : '',
					price_label_highlight: e.payload.doc.data()['price_label_highlight'] ? e.payload.doc.data()['price_label_highlight'] : '',
					product_url: e.payload.doc.data()['product_url'] ? e.payload.doc.data()['product_url'] : '',
					new_window: e.payload.doc.data()['new_window'] ? e.payload.doc.data()['new_window'] : '',
					hide_price_label: e.payload.doc.data()['hide_price_label'] ? e.payload.doc.data()['hide_price_label'] : false,
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'] ? e.payload.doc.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.payload.doc.data()['hide_productname_label'] ? e.payload.doc.data()['hide_productname_label'] : false,
					cost: e.payload.doc.data()['cost'] ? e.payload.doc.data()['cost'] : '',
					srp: e.payload.doc.data()['srp'] ? e.payload.doc.data()['srp'] : '',
					category: e.payload.doc.data()['category'] ? e.payload.doc.data()['category'] : '',
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg'] ? e.payload.doc.data()['price_label_svg'] : '',
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'] ? e.payload.doc.data()['is_saved'] : '',
					isVideo: e.payload.doc.data()['isVideo'] ? e.payload.doc.data()['isVideo'] : '',
					isBanner: e.payload.doc.data()['isBanner'] ? e.payload.doc.data()['isBanner'] : '',
					autoplay: e.payload.doc.data()['autoplay'] ? e.payload.doc.data()['autoplay'] : false,
					loopvideo: e.payload.doc.data()['loopvideo'] ? e.payload.doc.data()['loopvideo'] : false,
					bannerText: e.payload.doc.data()['bannerText'] ? e.payload.doc.data()['bannerText'] : false,
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					offerLabel: e.payload.doc.data()['offerLabel'] ? e.payload.doc.data()['offerLabel'] : '',
					labelSide: e.payload.doc.data()['labelSide'] ? e.payload.doc.data()['labelSide'] : '',
					master_uid:e.payload.doc.data()['master_uid'] ? e.payload.doc.data()['master_uid'] : '',
					digital_coupon: e.payload.doc.data()['digital_coupon'] ? e.payload.doc.data()['digital_coupon'] : false,
			        sale_content_price_label_svg: e.payload.doc.data()['sale_content_price_label_svg']?e.payload.doc.data()['sale_content_price_label_svg']:'',
			        sale_price_label_format: e.payload.doc.data()['sale_price_label_format'] ?e.payload.doc.data()['sale_price_label_format']:'',
			        sale_price_label_value1: e.payload.doc.data()['sale_price_label_value1'] ? e.payload.doc.data()['sale_price_label_value1']: '',
			        sale_price_label_value2: e.payload.doc.data()['sale_price_label_value2']? e.payload.doc.data()['sale_price_label_value2']: '',
			        sale_price_label_value3: e.payload.doc.data()['sale_price_label_value3'] ? e.payload.doc.data()['sale_price_label_value3']: '',
			        sale_price_label_svg: e.payload.doc.data()['sale_price_label_svg']?e.payload.doc.data()['sale_price_label_svg']:'',
			        final_price_label_format: e.payload.doc.data()['final_price_label_format'] ?e.payload.doc.data()['final_price_label_format']:'',
			        final_price_label_value1: e.payload.doc.data()['final_price_label_value1'] ? e.payload.doc.data()['final_price_label_value1']: '',
			        final_price_label_value2: e.payload.doc.data()['final_price_label_value2']? e.payload.doc.data()['final_price_label_value2']: '',
			        final_price_label_value3: e.payload.doc.data()['final_price_label_value3'] ? e.payload.doc.data()['final_price_label_value3']: '',
			        final_price_label_svg: e.payload.doc.data()['final_price_label_svg'] ?e.payload.doc.data()['final_price_label_svg'] :'',
			        final_content_price_label_svg: e.payload.doc.data()['final_content_price_label_svg'] ?e.payload.doc.data()['final_content_price_label_svg'] :'',
			        digital_coupon_price_label_format: e.payload.doc.data()['digital_coupon_price_label_format'] ?e.payload.doc.data()['digital_coupon_price_label_format']:'',
			        digital_coupon_price_label_value1: e.payload.doc.data()['digital_coupon_price_label_value1'] ? e.payload.doc.data()['digital_coupon_price_label_value1']: '',
			        digital_coupon_price_label_value2: e.payload.doc.data()['digital_coupon_price_label_value2']? e.payload.doc.data()['digital_coupon_price_label_value2']: '',
			        digital_coupon_price_label_value3: e.payload.doc.data()['digital_coupon_price_label_value3'] ? e.payload.doc.data()['digital_coupon_price_label_value3']: '',
			        digital_coupon_price_label_svg: e.payload.doc.data()['digital_coupon_price_label_svg'] ?e.payload.doc.data()['digital_coupon_price_label_svg']:'',
			        digital_content_coupon_price_label_svg: e.payload.doc.data()['digital_content_coupon_price_label_svg'] ?e.payload.doc.data()['digital_content_coupon_price_label_svg']:'',
			        note: e.payload.doc.data()['note'] ?e.payload.doc.data()['note']:'',
			        link: e.payload.doc.data()['link'] ?e.payload.doc.data()['link']:'',
			        usage_limit: e.payload.doc.data()['usage_limit'] ?e.payload.doc.data()['usage_limit']:'',
					isBundleProduct: e.payload.doc.data()['isBundleProduct'] ?e.payload.doc.data()['isBundleProduct']: false,
				};
			});
			this.saved_items_list = this.saved_items_list_temp;
			this.store_data = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
			this.main_items = this.saved_items_list;
		});
	}
  
  saveItem() {
    this.checkbox_select_all = false;
	this.checkbox_checked_item = []
	this.leadsPaginator.pageIndex = 0;
		this.checkbox_filterd_save_items_array = []
		this.tabName = 'save-item'
		this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_saved', '==', true).where('isBundleProduct', '==', false).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					product_name_slug: e.payload.doc.data()['product_name_slug'],
					pack: e.payload.doc.data()['pack'] ? e.payload.doc.data()['pack'] : '',
					price_label_format: e.payload.doc.data()['price_label_format'] ? e.payload.doc.data()['price_label_format'] : '',
					price_label_value1: e.payload.doc.data()['price_label_value1'] ? e.payload.doc.data()['price_label_value1'] : '',
					price_label_value2: e.payload.doc.data()['price_label_value2'] ? e.payload.doc.data()['price_label_value2'] : '',
					price_label_value3: e.payload.doc.data()['price_label_value3'] ? e.payload.doc.data()['price_label_value3'] : '',
					price_label_highlight: e.payload.doc.data()['price_label_highlight'] ? e.payload.doc.data()['price_label_highlight'] : '',
					product_url: e.payload.doc.data()['product_url'],
					new_window: e.payload.doc.data()['new_window'] ? e.payload.doc.data()['new_window'] : '',
					hide_price_label: e.payload.doc.data()['hide_price_label'] ? e.payload.doc.data()['hide_price_label'] : false,
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'] ? e.payload.doc.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.payload.doc.data()['hide_productname_label'] ? e.payload.doc.data()['hide_productname_label'] :false,
					cost: e.payload.doc.data()['cost'] ? e.payload.doc.data()['cost'] : '',
					srp: e.payload.doc.data()['srp'] ? e.payload.doc.data()['srp'] : '',
					category: e.payload.doc.data()['category'] ? e.payload.doc.data()['category'] : '',
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg']? e.payload.doc.data()['price_label_svg'] : '',
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'] ? e.payload.doc.data()['is_saved'] : '',
					isVideo: e.payload.doc.data()['isVideo'] ? e.payload.doc.data()['isVideo'] : '',
					isBanner: e.payload.doc.data()['isBanner'] ? e.payload.doc.data()['isBanner'] : '',
					autoplay: e.payload.doc.data()['autoplay'] ? e.payload.doc.data()['autoplay'] : false,
					loopvideo: e.payload.doc.data()['loopvideo'] ? e.payload.doc.data()['loopvideo'] : false,
					bannerText: e.payload.doc.data()['bannerText'] ? e.payload.doc.data()['bannerText'] : false,
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					offerLabel: e.payload.doc.data()['offerLabel'] ? e.payload.doc.data()['offerLabel'] : '',
					labelSide: e.payload.doc.data()['labelSide'] ? e.payload.doc.data()['labelSide'] : '',
					master_uid:e.payload.doc.data()['master_uid'] ? e.payload.doc.data()['master_uid'] : '',
					digital_coupon: e.payload.doc.data()['digital_coupon'] ? e.payload.doc.data()['digital_coupon'] : false,
			        sale_content_price_label_svg: e.payload.doc.data()['sale_content_price_label_svg']?e.payload.doc.data()['sale_content_price_label_svg']:'',
			        sale_price_label_format: e.payload.doc.data()['sale_price_label_format'] ?e.payload.doc.data()['sale_price_label_format']:'',
			        sale_price_label_value1: e.payload.doc.data()['sale_price_label_value1'] ? e.payload.doc.data()['sale_price_label_value1']: '',
			        sale_price_label_value2: e.payload.doc.data()['sale_price_label_value2']? e.payload.doc.data()['sale_price_label_value2']: '',
			        sale_price_label_value3: e.payload.doc.data()['sale_price_label_value3'] ? e.payload.doc.data()['sale_price_label_value3']: '',
			        sale_price_label_svg: e.payload.doc.data()['sale_price_label_svg']?e.payload.doc.data()['sale_price_label_svg']:'',
			        final_price_label_format: e.payload.doc.data()['final_price_label_format'] ?e.payload.doc.data()['final_price_label_format']:'',
			        final_price_label_value1: e.payload.doc.data()['final_price_label_value1'] ? e.payload.doc.data()['final_price_label_value1']: '',
			        final_price_label_value2: e.payload.doc.data()['final_price_label_value2']? e.payload.doc.data()['final_price_label_value2']: '',
			        final_price_label_value3: e.payload.doc.data()['final_price_label_value3'] ? e.payload.doc.data()['final_price_label_value3']: '',
			        final_price_label_svg: e.payload.doc.data()['final_price_label_svg'] ?e.payload.doc.data()['final_price_label_svg'] :'',
			        final_content_price_label_svg: e.payload.doc.data()['final_content_price_label_svg'] ?e.payload.doc.data()['final_content_price_label_svg'] :'',
			        digital_coupon_price_label_format: e.payload.doc.data()['digital_coupon_price_label_format'] ?e.payload.doc.data()['digital_coupon_price_label_format']:'',
			        digital_coupon_price_label_value1: e.payload.doc.data()['digital_coupon_price_label_value1'] ? e.payload.doc.data()['digital_coupon_price_label_value1']: '',
			        digital_coupon_price_label_value2: e.payload.doc.data()['digital_coupon_price_label_value2']? e.payload.doc.data()['digital_coupon_price_label_value2']: '',
			        digital_coupon_price_label_value3: e.payload.doc.data()['digital_coupon_price_label_value3'] ? e.payload.doc.data()['digital_coupon_price_label_value3']: '',
			        digital_coupon_price_label_svg: e.payload.doc.data()['digital_coupon_price_label_svg'] ?e.payload.doc.data()['digital_coupon_price_label_svg']:'',
			        digital_content_coupon_price_label_svg: e.payload.doc.data()['digital_content_coupon_price_label_svg'] ?e.payload.doc.data()['digital_content_coupon_price_label_svg']:'',
			        note: e.payload.doc.data()['note'] ?e.payload.doc.data()['note']:'',
			        link: e.payload.doc.data()['link'] ?e.payload.doc.data()['link']:'',
			        usage_limit: e.payload.doc.data()['usage_limit'] ?e.payload.doc.data()['usage_limit']:'',
					isBundleProduct: e.payload.doc.data()['isBundleProduct'] ?e.payload.doc.data()['isBundleProduct']: false,
				};
			});

			this.save_list = this.saved_items_list_temp;
			this.save_list = new MatTableDataSource(this.save_list);
			this.save_list.sort = this.leadsSort;
			this.save_list.paginator = this.leadsPaginator;
			this.main_items = this.save_list
		});
	}
  async masterLibrary() {
    this.checkbox_filterd_save_items_array = [];
	this.checkbox_checked_item = []
    this.masterLastItem = null;
    this.pageIndex = 0;
	this.leadsPaginator.pageIndex = 0;
    this.checkbox_select_all = false;
	if(this.filterValue){
		await this.searchMaster(this.filterValue, "first");
	}else{
		await this.masterLibrary1('all');
		await this.getTotalMasterItemCount('all');
	}
}

  async masterLibrary1(value) {
    this.tabName = "master-item";
    let query;
    if (
      !this.masterLastItem ||
      this.masterPageIndex1 == this.masterPrevPageIndx
    ) {
      	this.startRange = this.pageIndex * this.pageSize;
		query = this.afs.collection("/master-items", (ref) => ref.where('isBundleProduct', '==', false).orderBy("uid", "desc").limit(this.pageSize));
    } else {
		if (this.masterPageIndex1 > this.masterPrevPageIndx) {
			this.startRange = (this.pageIndex + 1) * this.pageSize;
			query = this.afs.collection("/master-items", (ref) => ref
			.where('isBundleProduct', '==', false)
			// .orderBy("created_at", "desc")
			.orderBy("uid", "desc")
			.startAfter( this.masterLastItem.uid)
			.limit(this.pageSize));
		} else {
			this.startRange = (this.pageIndex - 1) * this.pageSize;
			query = this.afs.collection("/master-items", (ref) => ref
			.where('isBundleProduct', '==', false)
				.orderBy("uid", "desc").endBefore(
					// this.masterFirstItem.created_at,
					this.masterFirstItem.uid
				)
				.limitToLast(this.pageSize)
			);
		}
	}
    try {
      const querySnapshot = await query.get().toPromise();
      let i = this.startRange;
      this.saved_items_list_temp = querySnapshot.docs.map((doc) => {
        i++;
        const data = doc.data();
        this.leadsPaginator.length = this.totalItems;
        return {
          upc_code: data["upc_code"],
          product_description: data["product_description"],
          product_name_slug: data["product_name_slug"],
          pack: data["pack"] ? data["pack"] : "",
          price_label_format: data["price_label_format"] ? data["price_label_format"]: "",
          price_label_value1: data["price_label_value1"] ? data["price_label_value1"]: "",
          price_label_value2: data["price_label_value2"] ? data["price_label_value2"]: "",
          price_label_value3: data["price_label_value3"] ? data["price_label_value3"]: "",
          price_label_highlight: data["price_label_highlight"] ? data["price_label_highlight"]: "",
          product_url: data["product_url"] ? data["product_url"] : "",
          new_window: data["new_window"] ? data["new_window"] : "",
          hide_price_label: data["hide_price_label"] ? data["hide_price_label"] : false,
          hide_addtolist_label: data["hide_addtolist_label"] ? data["hide_addtolist_label"] : false,
          hide_productname_label: data["hide_productname_label"] ? data["hide_productname_label"] : false,
          cost: data["cost"] ? data["cost"] : '',
          srp: data["srp"] ? data["srp"] : '',
          category: data["category"] ? data["category"] : '',
          uid: data["uid"],
          product_image: data["product_image"] ? data["product_image"] : "https://via.placeholder.com/30/cecece/cecece",
          price_label_svg: data["price_label_svg"] ? data["price_label_svg"] : "",
          serial: i - 1,
          is_saved: data["is_saved"] ? data["is_saved"] : "",
          isVideo: data["isVideo"] ? data["isVideo"] : "",
          isBanner: data["isBanner"] ? data["isBanner"] : "",
          autoplay: data["autoplay"] ? data["autoplay"] : false,
          loopvideo: data["loopvideo"] ? data["loopvideo"] : false,
          bannerText: data["bannerText"] ? data["bannerText"] : false,
          storeInMaster: data["storeInMaster"] ? data["storeInMaster"] : "",
          created_at: data["created_at"],
		  offerLabel: data['offerLabel'] ? data['offerLabel'] : '',
		  labelSide: data['labelSide'] ? data['labelSide'] : '',
		  digital_coupon: data['digital_coupon'] ? data['digital_coupon'] : false,
		  sale_content_price_label_svg: data['sale_content_price_label_svg']?data['sale_content_price_label_svg']:'',
		  sale_price_label_format: data['sale_price_label_format'] ?data['sale_price_label_format']:'',
		  sale_price_label_value1: data['sale_price_label_value1'] ? data['sale_price_label_value1']: '',
		  sale_price_label_value2: data['sale_price_label_value2']? data['sale_price_label_value2']: '',
		  sale_price_label_value3: data['sale_price_label_value3'] ? data['sale_price_label_value3']: '',
		  sale_price_label_svg: data['sale_price_label_svg']?data['sale_price_label_svg']:'',
		  final_price_label_format: data['final_price_label_format'] ?data['final_price_label_format']:'',
		  final_price_label_value1: data['final_price_label_value1'] ? data['final_price_label_value1']: '',
		  final_price_label_value2: data['final_price_label_value2']? data['final_price_label_value2']: '',
		  final_price_label_value3: data['final_price_label_value3'] ? data['final_price_label_value3']: '',
		  final_price_label_svg: data['final_price_label_svg'] ?data['final_price_label_svg'] :'',
		  final_content_price_label_svg: data['final_content_price_label_svg'] ?data['final_content_price_label_svg'] :'',
		  digital_coupon_price_label_format: data['digital_coupon_price_label_format'] ?data['digital_coupon_price_label_format']:'',
		  digital_coupon_price_label_value1: data['digital_coupon_price_label_value1'] ? data['digital_coupon_price_label_value1']: '',
		  digital_coupon_price_label_value2: data['digital_coupon_price_label_value2']? data['digital_coupon_price_label_value2']: '',
		  digital_coupon_price_label_value3: data['digital_coupon_price_label_value3'] ? data['digital_coupon_price_label_value3']: '',
		  digital_coupon_price_label_svg: data['digital_coupon_price_label_svg'] ?data['digital_coupon_price_label_svg']:'',
		  digital_content_coupon_price_label_svg: data['digital_content_coupon_price_label_svg'] ?data['digital_content_coupon_price_label_svg']:'',
		  note: data['note'] ?data['note']:'',
		  link: data['link'] ?data['link']:'',
		  usage_limit: data['usage_limit'] ?data['usage_limit']:'' ,
		  isBundleProduct: data['isBundleProduct'] ?data['isBundleProduct']:false,  	  	  
        };
      });

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      if (lastDoc) {
        this.masterLastItem = {
			product_description: lastDoc.data()?.product_description,
			created_at: lastDoc.data()?.created_at,
			uid: lastDoc.data()?.uid,
        };
      }
      const firstDoc = querySnapshot.docs[0];
      if (firstDoc) {
        this.masterFirstItem = {
			product_description: firstDoc.data()?.product_description,
			created_at: firstDoc.data()?.created_at,
			uid: firstDoc.data()?.uid,
			};
		}
		this.master_library = this.saved_items_list_temp;
		this.master_library = new MatTableDataSource(this.master_library);
      	this.master_library.sort = this.leadsSort;
		this.master_library.paginator = this.leadsPaginator;
		this.main_items = this.master_library
      	this.pageIndex = this.masterPageIndex1;
		this.pageIndex = this.leadsPaginator.pageIndex;
		this.leadsPaginator.length = this.totalItems;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  videoLibrary() {
    this.checkbox_select_all = false;
	this.checkbox_checked_item = []
	this.leadsPaginator.pageIndex = 0;
		this.checkbox_filterd_save_items_array = []
		this.tabName = 'video-item'
		this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).where('isBundleProduct', '==', false).where('isVideo', 'in', [true, 'youtube','facebook']).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					product_name_slug: e.payload.doc.data()['product_name_slug'],
					pack: e.payload.doc.data()['pack'] ? e.payload.doc.data()['pack'] : '',
					price_label_format: e.payload.doc.data()['price_label_format'] ? e.payload.doc.data()['price_label_format'] : '',
					price_label_value1: e.payload.doc.data()['price_label_value1'] ? e.payload.doc.data()['price_label_value1'] : '',
					price_label_value2: e.payload.doc.data()['price_label_value2'] ? e.payload.doc.data()['price_label_value2'] : '',
					price_label_value3: e.payload.doc.data()['price_label_value3'] ? e.payload.doc.data()['price_label_value3'] : '',
					price_label_highlight: e.payload.doc.data()['price_label_highlight'] ? e.payload.doc.data()['price_label_highlight'] : '',
					product_url: e.payload.doc.data()['product_url'] ? e.payload.doc.data()['product_url'] : '',
					new_window: e.payload.doc.data()['new_window'] ? e.payload.doc.data()['new_window'] : '',
					hide_price_label: e.payload.doc.data()['hide_price_label'] ? e.payload.doc.data()['hide_price_label'] : false,
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'] ? e.payload.doc.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.payload.doc.data()['hide_productname_label'] ? e.payload.doc.data()['hide_productname_label'] :false,
					cost: e.payload.doc.data()['cost'],
					srp: e.payload.doc.data()['srp'],
					category: e.payload.doc.data()['category'],
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg'],
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'],
					isVideo: e.payload.doc.data()['isVideo'],
					isBanner: e.payload.doc.data()['isBanner'] ? e.payload.doc.data()['isBanner'] : '',
					autoplay: e.payload.doc.data()['autoplay'] ? e.payload.doc.data()['autoplay'] : false,
					loopvideo: e.payload.doc.data()['loopvideo'] ? e.payload.doc.data()['loopvideo'] : false,
					bannerText: e.payload.doc.data()['bannerText'] ? e.payload.doc.data()['bannerText'] : false,
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					offerLabel: e.payload.doc.data()['offerLabel'] ? e.payload.doc.data()['offerLabel'] : '',
					labelSide: e.payload.doc.data()['labelSide'] ? e.payload.doc.data()['labelSide'] : '',
					master_uid:e.payload.doc.data()['master_uid'] ? e.payload.doc.data()['master_uid'] : '',
					digital_coupon: e.payload.doc.data()['digital_coupon'] ? e.payload.doc.data()['digital_coupon'] : false,
			        sale_content_price_label_svg: e.payload.doc.data()['sale_content_price_label_svg']?e.payload.doc.data()['sale_content_price_label_svg']:'',
			        sale_price_label_format: e.payload.doc.data()['sale_price_label_format'] ?e.payload.doc.data()['sale_price_label_format']:'',
			        sale_price_label_value1: e.payload.doc.data()['sale_price_label_value1'] ? e.payload.doc.data()['sale_price_label_value1']: '',
			        sale_price_label_value2: e.payload.doc.data()['sale_price_label_value2']? e.payload.doc.data()['sale_price_label_value2']: '',
			        sale_price_label_value3: e.payload.doc.data()['sale_price_label_value3'] ? e.payload.doc.data()['sale_price_label_value3']: '',
			        sale_price_label_svg: e.payload.doc.data()['sale_price_label_svg']?e.payload.doc.data()['sale_price_label_svg']:'',
			        final_price_label_format: e.payload.doc.data()['final_price_label_format'] ?e.payload.doc.data()['final_price_label_format']:'',
			        final_price_label_value1: e.payload.doc.data()['final_price_label_value1'] ? e.payload.doc.data()['final_price_label_value1']: '',
			        final_price_label_value2: e.payload.doc.data()['final_price_label_value2']? e.payload.doc.data()['final_price_label_value2']: '',
			        final_price_label_value3: e.payload.doc.data()['final_price_label_value3'] ? e.payload.doc.data()['final_price_label_value3']: '',
			        final_price_label_svg: e.payload.doc.data()['final_price_label_svg'] ?e.payload.doc.data()['final_price_label_svg'] :'',
			        final_content_price_label_svg: e.payload.doc.data()['final_content_price_label_svg'] ?e.payload.doc.data()['final_content_price_label_svg'] :'',
			        digital_coupon_price_label_format: e.payload.doc.data()['digital_coupon_price_label_format'] ?e.payload.doc.data()['digital_coupon_price_label_format']:'',
			        digital_coupon_price_label_value1: e.payload.doc.data()['digital_coupon_price_label_value1'] ? e.payload.doc.data()['digital_coupon_price_label_value1']: '',
			        digital_coupon_price_label_value2: e.payload.doc.data()['digital_coupon_price_label_value2']? e.payload.doc.data()['digital_coupon_price_label_value2']: '',
			        digital_coupon_price_label_value3: e.payload.doc.data()['digital_coupon_price_label_value3'] ? e.payload.doc.data()['digital_coupon_price_label_value3']: '',
			        digital_coupon_price_label_svg: e.payload.doc.data()['digital_coupon_price_label_svg'] ?e.payload.doc.data()['digital_coupon_price_label_svg']:'',
			        digital_content_coupon_price_label_svg: e.payload.doc.data()['digital_content_coupon_price_label_svg'] ?e.payload.doc.data()['digital_content_coupon_price_label_svg']:'',
			        note: e.payload.doc.data()['note'] ?e.payload.doc.data()['note']:'',
			        link: e.payload.doc.data()['link'] ?e.payload.doc.data()['link']:'',
			        usage_limit: e.payload.doc.data()['usage_limit'] ?e.payload.doc.data()['usage_limit']:'',
					isBundleProduct: e.payload.doc.data()['isBundleProduct'] ?e.payload.doc.data()['isBundleProduct']: false,
				};
			});

			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
			this.main_items = this.saved_items_list;
		});
	}

	masterVideoLibrary() {
    	this.checkbox_select_all = false;
		this.checkbox_filterd_save_items_array = []
		this.checkbox_checked_item = []
		this.leadsPaginator.pageIndex = 0;
		this.tabName = 'master-video-item'
		this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.current_store_uid).where('isBundleProduct', '==', false).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.video_data = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					product_name_slug: e.payload.doc.data()['product_name_slug'],
					pack: e.payload.doc.data()['pack'] ? e.payload.doc.data()['pack'] : '',
					price_label_format: e.payload.doc.data()['price_label_format'] ? e.payload.doc.data()['price_label_format'] : '',
					price_label_value1: e.payload.doc.data()['price_label_value1'] ? e.payload.doc.data()['price_label_value1'] : '',
					price_label_value2: e.payload.doc.data()['price_label_value2'] ? e.payload.doc.data()['price_label_value2'] : '',
					price_label_value3: e.payload.doc.data()['price_label_value3'] ? e.payload.doc.data()['price_label_value3'] : '',
					price_label_highlight: e.payload.doc.data()['price_label_highlight'] ? e.payload.doc.data()['price_label_highlight'] : '',
					product_url: e.payload.doc.data()['product_url'] ? e.payload.doc.data()['product_url'] : '',
					new_window: e.payload.doc.data()['new_window'] ? e.payload.doc.data()['new_window'] : '',
					hide_price_label: e.payload.doc.data()['hide_price_label'] ? e.payload.doc.data()['hide_price_label'] : false,
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'] ? e.payload.doc.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.payload.doc.data()['hide_productname_label'] ? e.payload.doc.data()['hide_productname_label'] :false,
					cost: e.payload.doc.data()['cost'],
					srp: e.payload.doc.data()['srp'],
					category: e.payload.doc.data()['category'],
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg'],
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'] ? e.payload.doc.data()['is_saved'] : '',
					isVideo: e.payload.doc.data()['isVideo'] ? e.payload.doc.data()['isVideo'] : '',
					isBanner: e.payload.doc.data()['isBanner'] ? e.payload.doc.data()['isBanner'] : '',
					autoplay: e.payload.doc.data()['autoplay'] ? e.payload.doc.data()['autoplay'] : false,
					loopvideo: e.payload.doc.data()['loopvideo'] ? e.payload.doc.data()['loopvideo'] : false,
					bannerText: e.payload.doc.data()['bannerText'] ? e.payload.doc.data()['bannerText'] : false,
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					offerLabel: e.payload.doc.data()['offerLabel'] ? e.payload.doc.data()['offerLabel'] : '',
					labelSide: e.payload.doc.data()['labelSide'] ? e.payload.doc.data()['labelSide'] : '',
					digital_coupon: e.payload.doc.data()['digital_coupon'] ? e.payload.doc.data()['digital_coupon'] : false,
			        sale_content_price_label_svg: e.payload.doc.data()['sale_content_price_label_svg']?e.payload.doc.data()['sale_content_price_label_svg']:'',
			        sale_price_label_format: e.payload.doc.data()['sale_price_label_format'] ?e.payload.doc.data()['sale_price_label_format']:'',
			        sale_price_label_value1: e.payload.doc.data()['sale_price_label_value1'] ? e.payload.doc.data()['sale_price_label_value1']: '',
			        sale_price_label_value2: e.payload.doc.data()['sale_price_label_value2']? e.payload.doc.data()['sale_price_label_value2']: '',
			        sale_price_label_value3: e.payload.doc.data()['sale_price_label_value3'] ? e.payload.doc.data()['sale_price_label_value3']: '',
			        sale_price_label_svg: e.payload.doc.data()['sale_price_label_svg']?e.payload.doc.data()['sale_price_label_svg']:'',
			        final_price_label_format: e.payload.doc.data()['final_price_label_format'] ?e.payload.doc.data()['final_price_label_format']:'',
			        final_price_label_value1: e.payload.doc.data()['final_price_label_value1'] ? e.payload.doc.data()['final_price_label_value1']: '',
			        final_price_label_value2: e.payload.doc.data()['final_price_label_value2']? e.payload.doc.data()['final_price_label_value2']: '',
			        final_price_label_value3: e.payload.doc.data()['final_price_label_value3'] ? e.payload.doc.data()['final_price_label_value3']: '',
			        final_price_label_svg: e.payload.doc.data()['final_price_label_svg'] ?e.payload.doc.data()['final_price_label_svg'] :'',
			        final_content_price_label_svg: e.payload.doc.data()['final_content_price_label_svg'] ?e.payload.doc.data()['final_content_price_label_svg'] :'',
			        digital_coupon_price_label_format: e.payload.doc.data()['digital_coupon_price_label_format'] ?e.payload.doc.data()['digital_coupon_price_label_format']:'',
			        digital_coupon_price_label_value1: e.payload.doc.data()['digital_coupon_price_label_value1'] ? e.payload.doc.data()['digital_coupon_price_label_value1']: '',
			        digital_coupon_price_label_value2: e.payload.doc.data()['digital_coupon_price_label_value2']? e.payload.doc.data()['digital_coupon_price_label_value2']: '',
			        digital_coupon_price_label_value3: e.payload.doc.data()['digital_coupon_price_label_value3'] ? e.payload.doc.data()['digital_coupon_price_label_value3']: '',
			        digital_coupon_price_label_svg: e.payload.doc.data()['digital_coupon_price_label_svg'] ?e.payload.doc.data()['digital_coupon_price_label_svg']:'',
			        digital_content_coupon_price_label_svg: e.payload.doc.data()['digital_content_coupon_price_label_svg'] ?e.payload.doc.data()['digital_content_coupon_price_label_svg']:'',
			        note: e.payload.doc.data()['note'] ?e.payload.doc.data()['note']:'',
			        link: e.payload.doc.data()['link'] ?e.payload.doc.data()['link']:'',
			        usage_limit: e.payload.doc.data()['usage_limit'] ?e.payload.doc.data()['usage_limit']:'',
					isBundleProduct: e.payload.doc.data()['isBundleProduct'] ?e.payload.doc.data()['isBundleProduct']: false,
				};
			});
		});


		this.afs.collection('/master-items', ref => ref.where("isVideo", "in", [true, 'youtube','facebook']).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			let i = 0;
			this.saved_items_list_temp = data.map(e => {
				i++;
				return {
					upc_code: e.payload.doc.data()['upc_code'],
					product_description: e.payload.doc.data()['product_description'],
					product_name_slug: e.payload.doc.data()['product_name_slug'],
					pack: e.payload.doc.data()['pack'] ? e.payload.doc.data()['pack'] : '',
					price_label_format: e.payload.doc.data()['price_label_format'] ? e.payload.doc.data()['price_label_format'] : '',
					price_label_value1: e.payload.doc.data()['price_label_value1'] ? e.payload.doc.data()['price_label_value1'] : '',
					price_label_value2: e.payload.doc.data()['price_label_value2'] ? e.payload.doc.data()['price_label_value2'] : '',
					price_label_value3: e.payload.doc.data()['price_label_value3'] ? e.payload.doc.data()['price_label_value3'] : '',
					price_label_highlight: e.payload.doc.data()['price_label_highlight'] ? e.payload.doc.data()['price_label_highlight'] : '',
					product_url: e.payload.doc.data()['product_url'] ? e.payload.doc.data()['product_url'] : '',
					new_window: e.payload.doc.data()['new_window'] ? e.payload.doc.data()['new_window'] : '',
					hide_price_label: e.payload.doc.data()['hide_price_label'] ? e.payload.doc.data()['hide_price_label'] : false,
					hide_addtolist_label: e.payload.doc.data()['hide_addtolist_label'] ? e.payload.doc.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.payload.doc.data()['hide_productname_label'] ? e.payload.doc.data()['hide_productname_label'] :false,
					cost: e.payload.doc.data()['cost'] ? e.payload.doc.data()['cost'] : '',
					srp: e.payload.doc.data()['srp'] ? e.payload.doc.data()['srp'] : '',
					category: e.payload.doc.data()['category'] ? e.payload.doc.data()['category'] : '',
					uid: e.payload.doc.id,
					product_image: e.payload.doc.data()['product_image'] ? e.payload.doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
					price_label_svg: e.payload.doc.data()['price_label_svg'],
					serial: i - 1,
					is_saved: e.payload.doc.data()['is_saved'] ? e.payload.doc.data()['is_saved'] : '',
					isVideo: e.payload.doc.data()['isVideo'] ? e.payload.doc.data()['isVideo'] : '',
					isBanner: e.payload.doc.data()['isBanner'] ? e.payload.doc.data()['isBanner'] : '',
					autoplay: e.payload.doc.data()['autoplay'] ? e.payload.doc.data()['autoplay'] : false,
					loopvideo: e.payload.doc.data()['loopvideo'] ? e.payload.doc.data()['loopvideo'] : false,
					bannerText: e.payload.doc.data()['bannerText'] ? e.payload.doc.data()['bannerText'] : false,
					storeInMaster: e.payload.doc.data()['storeInMaster'] ? e.payload.doc.data()['storeInMaster'] : '',
					offerLabel: e.payload.doc.data()['offerLabel'] ? e.payload.doc.data()['offerLabel'] : '',
					labelSide: e.payload.doc.data()['labelSide'] ? e.payload.doc.data()['labelSide'] : '',
					digital_coupon: e.payload.doc.data()['digital_coupon'] ? e.payload.doc.data()['digital_coupon'] : false,
			        sale_content_price_label_svg: e.payload.doc.data()['sale_content_price_label_svg']?e.payload.doc.data()['sale_content_price_label_svg']:'',
			        sale_price_label_format: e.payload.doc.data()['sale_price_label_format'] ?e.payload.doc.data()['sale_price_label_format']:'',
			        sale_price_label_value1: e.payload.doc.data()['sale_price_label_value1'] ? e.payload.doc.data()['sale_price_label_value1']: '',
			        sale_price_label_value2: e.payload.doc.data()['sale_price_label_value2']? e.payload.doc.data()['sale_price_label_value2']: '',
			        sale_price_label_value3: e.payload.doc.data()['sale_price_label_value3'] ? e.payload.doc.data()['sale_price_label_value3']: '',
			        sale_price_label_svg: e.payload.doc.data()['sale_price_label_svg']?e.payload.doc.data()['sale_price_label_svg']:'',
			        final_price_label_format: e.payload.doc.data()['final_price_label_format'] ?e.payload.doc.data()['final_price_label_format']:'',
			        final_price_label_value1: e.payload.doc.data()['final_price_label_value1'] ? e.payload.doc.data()['final_price_label_value1']: '',
			        final_price_label_value2: e.payload.doc.data()['final_price_label_value2']? e.payload.doc.data()['final_price_label_value2']: '',
			        final_price_label_value3: e.payload.doc.data()['final_price_label_value3'] ? e.payload.doc.data()['final_price_label_value3']: '',
			        final_price_label_svg: e.payload.doc.data()['final_price_label_svg'] ?e.payload.doc.data()['final_price_label_svg'] :'',
			        final_content_price_label_svg: e.payload.doc.data()['final_content_price_label_svg'] ?e.payload.doc.data()['final_content_price_label_svg'] :'',
			        digital_coupon_price_label_format: e.payload.doc.data()['digital_coupon_price_label_format'] ?e.payload.doc.data()['digital_coupon_price_label_format']:'',
			        digital_coupon_price_label_value1: e.payload.doc.data()['digital_coupon_price_label_value1'] ? e.payload.doc.data()['digital_coupon_price_label_value1']: '',
			        digital_coupon_price_label_value2: e.payload.doc.data()['digital_coupon_price_label_value2']? e.payload.doc.data()['digital_coupon_price_label_value2']: '',
			        digital_coupon_price_label_value3: e.payload.doc.data()['digital_coupon_price_label_value3'] ? e.payload.doc.data()['digital_coupon_price_label_value3']: '',
			        digital_coupon_price_label_svg: e.payload.doc.data()['digital_coupon_price_label_svg'] ?e.payload.doc.data()['digital_coupon_price_label_svg']:'',
			        digital_content_coupon_price_label_svg: e.payload.doc.data()['digital_content_coupon_price_label_svg'] ?e.payload.doc.data()['digital_content_coupon_price_label_svg']:'',
			        note: e.payload.doc.data()['note'] ?e.payload.doc.data()['note']:'',
			        link: e.payload.doc.data()['link'] ?e.payload.doc.data()['link']:'',
			        usage_limit: e.payload.doc.data()['usage_limit'] ?e.payload.doc.data()['usage_limit']:'',
					isBundleProduct: e.payload.doc.data()['isBundleProduct'] ?e.payload.doc.data()['isBundleProduct']: false,
				};
			});

			this.saved_items_list = this.saved_items_list_temp;
			this.saved_items_list = new MatTableDataSource(this.saved_items_list);
			this.saved_items_list.sort = this.leadsSort;
			this.saved_items_list.paginator = this.leadsPaginator;
			this.main_items = this.saved_items_list;
		});
	}
	checkbox_select_all_event(event, item) {
		this.checkbox_checked_item = []
		this.checkbox_filterd_save_items_array = []
		let serial
		if(item._paginator){
			serial = item._paginator.pageIndex*this.pageSize
		}else{
			serial = this.startIndex
		}
		this.checkbox_select_all = event.target.checked;
		if(this.tabName == "master-item" ){
			if(event.target.checked == true){
				this.checkbox_select_all = event.target.checked;
				for (let batchStart = serial; batchStart < serial+this.pageSize && batchStart < serial+this.saved_items_list_temp.length; batchStart++) {
					this.checkbox_checked_item[batchStart] = true
				}
				for (let batchStart = 0; batchStart < this.saved_items_list_temp.length; batchStart++) {
					this.checkbox_filterd_save_items_array[batchStart] = true;
				}
			}else{
				this.checkbox_select_all = event.target.checked;
				this.checkbox_filterd_save_items_array = []
				this.checkbox_checked_item = []
			}
		}else{
			if(event.target.checked == true){
				this.checkbox_select_all = event.target.checked;
				for (let batchStart = serial; batchStart < serial+this.pageSize && batchStart < serial+this.saved_items_list_temp.length; batchStart++) {
					this.checkbox_checked_item[batchStart] = true
					this.checkbox_filterd_save_items_array[batchStart] = true;
				}
			}else{
				this.checkbox_select_all = event.target.checked;
				this.checkbox_filterd_save_items_array = []
				this.checkbox_checked_item = []
			}
		}
	}

  	checkbox_single_select_event(event, i, serial) {
		if(this.tabName == "master-item"){
			if (event.target.checked == true) {
				this.checkbox_filterd_save_items_array[i] = true;
				this.checkbox_checked_item[serial] = true
			} else {
				this.checkbox_filterd_save_items_array[i] = false;
				this.checkbox_checked_item[serial] = false
			}
		}else{
			if (event.target.checked == true) {
				this.checkbox_filterd_save_items_array[serial] = true;
				this.checkbox_checked_item[serial] = true
			} else {
				this.checkbox_filterd_save_items_array[serial] = false;
				this.checkbox_checked_item[serial] = false
			}
		}
	}

	filter_saved_items() {
		this.previoustabName=this.tabName
		this.popup_loading=true;
		let tempIndexes = []
		this.buttonDisabled = true
		if (this.checkbox_filterd_save_items_array?.length > 0) {
			this.checkbox_filterd_save_items_array?.forEach((item, index) => {
				if (item === true) {
					tempIndexes.push(index)
				}
			});
		}
		let saved_items_to_return = []
		let temp_ids = []
		let temp_des = []
		this.componentService.getOldProducts().forEach(i => temp_ids.push(i.id))
		this.componentService.getOldProducts().forEach(i => temp_des.push(i))
		let duplicate = false
		const currentProductTitles = temp_des?.map(item => {
			const titleMatch = item?.content?.match(/<div class="product-title".*?<\/div>/s); 
			const pack = item?.pack
			let title
			if (titleMatch) {
				title = this.componentService.extractTextFromHTML(titleMatch[0]);
			} else {
				title = null;
			}
			return {
				pack: pack,
				title: title
			};
		});
		tempIndexes.forEach(p => {
			if (temp_ids.includes(this.saved_items_list_temp[p]?.uid)) {
				duplicate = true
			} else {
				const productDescription = this.saved_items_list_temp[p]?.product_description;
				const packDetails = this.saved_items_list_temp[p]?.packDetails;
				if (productDescription && currentProductTitles?.includes(productDescription &&packDetails)) {
					duplicate = true
				} else {
					saved_items_to_return?.push(this.saved_items_list_temp[p]);
				}
			}
		})
		saved_items_to_return = saved_items_to_return?.filter((obj, index, array) =>
			array.findIndex(item => item?.product_description === obj?.product_description && item?.pack === obj?.pack) === index
		);
		if(this.tabName == "master-item" || this.tabName == "master-video-item"){
			const processSavedItems = async () => {
				if(saved_items_to_return.length > 0){
					for (let index = 0; index < saved_items_to_return.length; index++) {
						this.tabName=this.previoustabName
						const product = saved_items_to_return[index];
						let data = '';
						if (this.tabName == "master-item") {
							data = this.store_data.find(item => item.product_name_slug == product.product_name_slug);
						} else {
							data = this.video_data.find(item => item.product_name_slug == product.product_name_slug);
						}
						if(!data){
							product.created_at = new Date()
							product.store_uid = this.current_store_uid
							product.master_uid = product.uid
							this.tabName = 'store-item'
							this.checkbox_checked_item=[];
							this.checkbox_select_all=false
							const product_data = await this.afs.collection('store-items').add(product).then(docRef => {
								const itemRefx2 = this.afs.doc('/store-items/' + docRef.id);
								itemRefx2.update({ uid: docRef.id });
								itemRefx2.get().subscribe(res=>{
									saved_items_to_return[index] = res.data()
									if(index === saved_items_to_return.length - 1){
										this.add_product(saved_items_to_return, duplicate)
									}
								})
							})
						}else{
							saved_items_to_return[index] = data
							if (temp_ids.includes(saved_items_to_return[index]?.uid)) {
								duplicate = true
							}
							if(index === saved_items_to_return.length - 1){
								this.add_product(saved_items_to_return, duplicate)
							}						
						}
					}
				} else if(tempIndexes.length > 0){
					this._snackBar.open("Please select some product that's not in Layout", '', {
						duration: 1500,
					});
				}
			}
			processSavedItems().then(() => {
				this.dialogRef.close();
				this.buttonDisabled = false
			});
		}else{
			this.add_product(saved_items_to_return, duplicate)
			this.dialogRef.close();
			this.buttonDisabled = false
		}
	}
    convertFacebookWatchLink(link) {
		const regex = /^https:\/\/www\.facebook\.com\/watch\/\?v=(\d+)$/i;
		const match = link.match(regex);
		if (match) {
			const videoId = match[1];
			const canonicalUrl = `https://www.facebook.com/facebook/videos/${videoId}/`;
			return canonicalUrl;
		}else{
			return link
		}
	}
	async add_product(saved_items_to_return, duplicate){
		const product_array = this.componentService.getOldProducts();
		saved_items_to_return.forEach(async (product, index) => {
			if (product === undefined) {
				return;
			}
			let product_url = ''
			var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
			if (product?.product_url && product?.product_url != '' && httpRegex.test(product?.product_url)) {
				var domain = new URL(product?.product_url)
				if (domain?.hostname == "www.youtube.com") {
					var ytid = product?.product_url.match(/[\w\-]{11,}/)[0];
					product.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
					product_url = product?.product_url + `?playlist=${ytid}&loop=${product.loopvideo?1:0}&autoplay=${product.autoplay ? 1 : 0}&mute=${product.autoplay ? 1 : 0}`
					product.product_url = product_url
					this.domain_name='youtube'
				}
				if (domain?.hostname == "www.youtube-nocookie.com" ) {
					var ytid = product.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
					ytid = ytid.replace('/embed/', '');
					product.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
					const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
					product_url = modifiedUrl + `?playlist=${ytid}&loop=${product.loopvideo ? 1 : 0}&autoplay=${product.autoplay ? 1 : 0}&mute=${product.autoplay ? 1 : 0}&rel=0`;					product.product_url = product_url
					product.product_url = product_url
					this.domain_name='youtube'
				}
				if (domain?.hostname == "www.facebook.com") {
					product.product_url=await this.convertFacebookWatchLink(product?.product_url)
					const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
					const encodedURL = encodeURIComponent(product?.product_url);
					const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${product.loopvideo ? 1 : 0}&autoplay=${product.autoplay ? 1 : 0}&mute=${product.autoplay ? 1 : 0}&rel=0`;
					product.product_url=product?.product_url
					this.domain_name='facebook'
				}
			}
			let gifClass = ''
			if (this.isGIF == true) {
				gifClass = ' gifImg '
			}
			product.price_label_svg = product?.price_label_svg ? product?.price_label_svg.replace(/baseSmall|base|medium|large|extraLarge|extra2large|extra3large/g, 'extraSmall'): '';					
			let product_data = await this.libraryProductAdd.generateContentData(product,gifClass,this.storeData,this.domain_name);			
			product_array.push(product_data);
		});
		this.componentService.setProductArray(product_array)
		this.componentService.displayItems()
		if (duplicate) {
			if (this.checkbox_filterd_save_items_array.length > 1) {
				this._snackBar.open("Some Products Already present in layout. Other products imported!", '', {
					duration: 1500,
				});
			} else {
				this._snackBar.open("Selected Product is already present in layout!", '', {
					duration: 1500,
				});
			}
		} else {
			if (this.checkbox_filterd_save_items_array.length > 0) {
				this._snackBar.open("Selected items imported to your grid successfully!", '', {
					duration: 1500,
				});
			} else {
				this._snackBar.open("No any product imported. Please select items to import!", '', {
					duration: 1500,
				});
			}
		}
		this.popup_loading=false;
	}

	async filter_saved_items_library() {
		this.buttonDisabled = true
		let tempt = this.checkbox_filterd_save_items_array.filter(res => res == true)
		if (tempt.length == 1) {
			this.multiple_checked = false;
			let tempIndexes = []
			this.popup_loading = true;
			this.checkbox_filterd_save_items_array.forEach((item, index) => {
				if (item === true) {
					tempIndexes.push(index)
				}
			});
			let saved_items_to_return = []
			let temp_ids = []
			let duplicate = false
			tempIndexes.forEach(p => {
				if (temp_ids.includes(this.saved_items_list_temp[p]?.uid)) {
					duplicate = true
				} else {
					saved_items_to_return.push(this.saved_items_list_temp[p])
				}
			})

			saved_items_to_return.forEach(async (product, index) => {
				const data: any = this.afs.collection('master-items').doc(this.data.selected_master_product_uid)
				try {
					const item_data: any = (await data.get().toPromise()).data()
					data.update({
						product_image: product.product_image,
						product_url: product.product_url ? product.product_url : "",
						isVideo: product.isVideo
					}, { merge: true }).then((data) => {
					}).catch(err => {
						console.log("err", err);
					});
					const isObjectpresent = await item_data.secondery_product_image.find((x) => (x.uid == product.uid))
					if (!isObjectpresent) {
						const obj_item = { id: product.uid, url: product.product_image }
						data.update({
							secondery_product_image: firebase.firestore.FieldValue.arrayUnion(obj_item)
						});
					}
					await this.afs.collection('store-items').doc(this.data.selected_product_uid).set({ product_image: product.product_image, product_url: product.product_url,	isVideo: product.isVideo  }, { merge: true }).then((data) => {
					}).catch(err => {
						console.log("err", err);
					});
				} catch {
					console.log("error")
				}
				const singleLayout: any = await this.afs.collection('menus').doc(this.data.current_template_id).get().toPromise()
				if (Array.isArray(singleLayout.data()?.menu_json_temp)) {
					temp_layout_data = singleLayout.data().menu_json_temp
		
				}else if (typeof (singleLayout.data()?.menu_json_temp) == "string") {
					const layoutRef = this.afs.doc('/menu-json-temp/' + singleLayout.data()?.menu_json_temp);
					try {
						const doc: any = await layoutRef.get().toPromise();
						const menu_json_temp = doc.data() || {};
						var temp_layout_data = menu_json_temp.menu_json_temp;
					} catch (error) {
						console.error(error)
					}
				}else{
					console.error("layout json temp null or undefined")

				}
				temp_layout_data = temp_layout_data.map((x) => {
					if (x.id == this.data.selected_product_uid) {
						if(x.product_image){
							x.product_image=product.product_image
						}
						const temp = document.createElement('div');
						temp.innerHTML = x.content;
				     	const img_ele: any = temp.querySelector('img, video'); /// Use querySelector to get the first <img> element
                    	if (img_ele) {
							const img_id = img_ele.id;
							const img_upc = img_ele.getAttribute('upc');
							img_ele.upc=img_upc;
							img_ele.id= img_id;
					 	}
					    let content_data = '';
				    if (product.isVideo === true) {
		                {
					    content_data = '<div class="fill_video"><video playsinline id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: 100%;object-fit:cover;object-position: top;" autoplay muted loop></video></div>';
				   
					}} else {
					    content_data = '<img id=\'' + img_ele.id + '\' upc=\'' + img_ele.upc + '\' src="' + product.product_image + '&size=408&thumbnail=true&palette=RGB" style="height: 100%;width: auto;object-fit:cover;object-position: top;">';
				   }  
			    	img_ele.parentNode.removeChild(img_ele); 
					temp.innerHTML = content_data + temp.innerHTML;
					x.content = temp.innerHTML
						return x
					} else {
						return x
					}
				})

			if (Array.isArray(singleLayout.data()?.menu_json_temp)) {
				await this.afs.collection('menus').doc(this.data.current_template_id).set({ menu_json_temp: temp_layout_data }, { merge: true }).then(async (data) => {
					let element: HTMLElement = document.getElementsByClassName('close_image_model')[0] as HTMLElement;
					if (element) {
						element.click();
					}
					this._snackBar.open("Product Image set successfully.", '', {
						duration: 1500,
					});
					this.popup_loading = false;
					this.dialogRef.close();
					this.componentService.setProductArray(temp_layout_data)
					this.componentService.displayItems()
				}).catch(err => {
					this.popup_loading = false;
					console.log("err", err);
				});
			}else if (typeof (singleLayout.data()?.menu_json_temp) == "string") {
				await this.afs.collection('menu-json-temp').doc(singleLayout.data()?.menu_json_temp).set({ menu_json_temp: temp_layout_data }, { merge: true }).then(async (data)=>{
					let element: HTMLElement = document.getElementsByClassName('close_image_model')[0] as HTMLElement;
					if (element) {
						element.click();
					}
					this._snackBar.open("Product Image set successfully.", '', {
						duration: 1500,
					});
					this.popup_loading = false;
					this.dialogRef.close();
					this.componentService.setProductArray(temp_layout_data)
					this.componentService.displayItems()
				})
			}else{
				console.error("layout json temp null or undefined")
			}
			this.imageUrl1Pro=product.product_image
			});
		} else {
			this.multiple_checked = true;
		}
		this.buttonDisabled = false
	}

	filter_saved_items_from_recipebox() {
		let tempIndexes = []
		this.buttonDisabled = true
		if (this.checkbox_filterd_save_items_array.length > 0) {
			this.checkbox_filterd_save_items_array.forEach((item, index) => {
				if (item === true) {
					tempIndexes.push(index)
				}
			});
		}
		let saved_items_to_return = []
		let temp_ids = []
		let duplicate = false
		tempIndexes.forEach(p => {
			if (temp_ids.includes(this.saved_items_list_temp[p]?.uid)) {
				duplicate = true
			} else {
				saved_items_to_return.push(this.saved_items_list_temp[p])
			}
		})
		if (duplicate) {
			this._snackBar.open("Some Items you selected are already persent in layout!", '', {
				duration: 1500,
			});
		}
		let product_array = []
		saved_items_to_return.forEach((product, index) => {
			let product_data = {
				id: product.uid,
				upc_code: product.upc_code,
				product_image: product.product_image,
				product_description: product.product_description,
				uid: product.uid,
				pack: product.pack
			};
			product_array.push(product_data);
		});
		this.componentService.setRecipeArray(product_array)
		this.componentService.displayRecipeItems()
		this.dialogRef.close();
		this.buttonDisabled = false
	}

	close_dialog() {
		this.dialogRef.close();
	}

	save_item(uid) {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: true }, { merge: true }).then(docRef => {
		});
		this._snackBar.open("Item has been moved to Saved Products!", '', {
			duration: 1500,
		});
	}

	unsave_item(uid) {
		let update_to_db = this.afs.doc('/store-items/' + uid).set({ is_saved: false }, { merge: true }).then(docRef => {
		});

		this._snackBar.open("Item removed from saved!", '', {
			duration: 1500,
		});
	}

	async searchMaster(value, event){
		const index = this.searchClient.initIndex(environment.algolia.indexName);
		try {
			var data
			if(event == "first"){
				data = await index.search(value,{
					hitsPerPage: this.pageSize,
					page: 0
				});
			}else{
				data = await index.search(value,{
					hitsPerPage: this.pageSize,
					page: event.pageIndex
				});
			}
			this.pageIndex = data.page;
			// Calculate the starting index for the current page
			this.startIndex = this.pageIndex * this.pageSize;
			// Add a 'serial' property to each record in data.hits
			this.saved_items_list_temp = data.hits.map((record, index) => ({
			...record,
			serial: this.startIndex + index // Calculate the serial based on page and position
			}));

			this.totalItems = data.nbHits
			this.checkbox_checked_item = []
			this.master_library = this.saved_items_list_temp;
			this.master_library = new MatTableDataSource(this.master_library);
			this.main_items = this.master_library
			} catch (error) {
				console.error('Error searching Algolia:', error);
			}
	}

	doFilter = async (value: string) => {
		this.lastItem = undefined
		this.firstItem = undefined
		this.masterLastItem = undefined
		this.masterFirstItem = undefined
		this.filterValue = value.trim();
		this.filterFlag = true
		if(this.tabName === "master-item"){
			await this.searchMaster(value, "first");
		}
		else{	
			this.main_items.filter = value.trim().toLocaleLowerCase();
		}
	}
}