import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router,NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from "@angular/common";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: [
    './styles/main-menu.component.scss'
  ]
})  
export class MainMenuComponent implements OnInit {
user:any;
currentRoute: string;
currenturl:string; 
val:boolean = false; 
ghost_mode:boolean = false;
route:any;
  constructor(location: Location,public authService: AuthService,private router: Router) {
    this.currenturl = location.path();
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
            this.currenturl = event['view']['location']['pathname'];
        }); 
      router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
        this.currenturl = this.route;
      } else {
        this.route = "Home";
      }
    });
  }

  ngOnInit() {
   // this.currenturl = location.path();
    let chkghost = (localStorage.getItem('ghost_mode'));
    if(chkghost)
    {
      this.ghost_mode = true;
      this.user  = JSON.parse(localStorage.getItem('current_store_id'));
    } else{
      this.ghost_mode = false;
      this.user = JSON.parse(localStorage.getItem('userrole'));
    }    
  }

}  
