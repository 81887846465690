<div class="pagecontent-wrapper">
	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<div class="row no-gutters">
				<div class="visualization-heading col-12">
					
					<div class="heading-info">
						<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Logs</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>