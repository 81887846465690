import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  userRole: any;
  googleauthemail: any;
  loguserrole: any;
  loading: boolean = false;
  userref_count = 0;
  public currentUser: any;
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this.userRole = user;

        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afs.collection("users").ref.where("email", "==", result.user.email).onSnapshot(snap => {
            snap.forEach(userRef => {
              this.currentUser = userRef.data();
              this.SetUserData(userRef.data());
              //this.setUserStatus(this.currentUser)
              if (userRef.data()['role'] == "admin" || "admin-manager") {
                this.router.navigate(["dashboard"]); //navigate to blank-user is not admin
              } else if (userRef.data()['role'] == "consultant") {
                this.router.navigate(["consultant/dashboard"]);
              } else if (userRef.data()['role'] == "dieter") {
                this.router.navigate(["/register-as-consultant"]);
              }
              else {
                this.router.navigate(["/"]);
              }
            })
          });
          //this.router.navigate(['dashboard']);
        });
      }).catch((error) => {
        window.alert(error.message)
        this.loading = false;
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();

        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  getUsers() {
    return this.afs.collection('users', ref => ref.orderBy('dateOfReg', 'desc')).snapshotChanges();
  }

  getAllProducts() {
    return this.afs.collection('products').snapshotChanges();
  }

  getConsultantRequests() {
    return this.afs.collection('consultant_requests').snapshotChanges();
  }

  getContactus() {
    return this.afs.collection('contactus').snapshotChanges();
  }

  getAllAudits() {
    return this.afs.collection('audits').snapshotChanges();
  }

  getAllPlans() {
    return this.afs.collection('plans').snapshotChanges();
  }

  getAllOrders() {
    return this.afs.collection('orders').snapshotChanges();
  }

  deleteProduct(id) {
    this.afs.doc('products/' + id).delete();
    return this.afs.collection('products').snapshotChanges();
  }

  deleteAudit(id) {
    this.afs.doc('audits/' + id).delete();
    return this.afs.collection('audits').snapshotChanges();
  }

  deletePlan(id) {
    this.afs.doc('plans/' + id).delete();
    return this.afs.collection('plans').snapshotChanges();
  }

  deleteConsultantRequest(id) {
    this.afs.doc('consultant_requests/' + id).delete();
    return this.afs.collection('consultant_requests').snapshotChanges();
  }

  getProductDetails(id) {
    return this.afs.collection('products', ref => ref.where('uid', '==', id)).snapshotChanges();
  }

  getPlanDetails(id) {
    return this.afs.collection('plans', ref => ref.where('uid', '==', id)).snapshotChanges();
  }

  getAuditDetails(id) {
    return this.afs.collection('audits', ref => ref.where('uid', '==', id)).snapshotChanges();
  }

  getMyDieters() {
    let id = "UILhnZY52jd0v95NEFbLi0Y6Pos1";
    return this.afs.collection('dieters', ref => ref.where('consultantid', '==', id)).snapshotChanges();
  }

  updateProduct(recordID, record) {
    this.afs.doc('products/' + recordID).update(record);
  }

  getUsersByRole(role) {
    if (role != "all") {
      return this.afs.collection('users', ref => ref.where('role', '==', role).orderBy('weight', 'desc').orderBy('created_timestamp', 'desc')).snapshotChanges();
    } else {
      return this.afs.collection('users', ref => ref.orderBy('created_timestamp', 'desc')).snapshotChanges();
    }
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null) ? true : false;
  }

  get theCurrentUser(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user : user;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  //   async signInWithApple() {
  //     const provider = new firebase.auth.OAuthProvider('apple.com');
  //     const result = await auth.signInWithPopup(provider);

  // }
  AppleAuth() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    return this.afAuth.signInWithPopup(provider).then((result) => {
      this.afs.collection("users").ref.where("email", "==", result.user.email).onSnapshot(snap => {
        snap.forEach(userRef => {
          //this.currentUser = userRef.data();
          //setUserStatus
          this.currentUser = userRef.data();
          this.SetUserData(userRef.data());
          //this.setUserStatus(this.currentUser)
          if (userRef.data()['role'] == "admin" || "admin-manager") {
            this.router.navigate(["dashboard"]); //navigate to blank-user is not admin
          } else if (userRef.data()['role'] == "consultant") {
            this.router.navigate(["consultant/dashboard"]);
          } else if (userRef.data()['role'] == "dieter") {
            this.router.navigate(["/register-as-consultant"]);
            //this.ngZone.run(() => this.router.navigate(["/register-as-consultant"]));
          }
          else {
            this.router.navigate(["/"]);
          }
        })
      });

    });

    //return this.AuthLogin(new auth.AppleAuthProvider());
  }

  FacebookAuth() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
        this.SetUserDataGoogle(result.additionalUserInfo.profile, result.user, result.additionalUserInfo.isNewUser);
        this.googleauthemail = result.additionalUserInfo.profile['email'];
        this.afs.collection("users").ref.where("email", "==", this.googleauthemail).onSnapshot(snap => {
          snap.forEach(userRef => {
            this.userref_count += 1;
            this.currentUser = userRef.data();
            this.loguserrole = userRef.data()['role'];
            // this.SetUserData(userRef.data());
            if (this.userref_count > 1) {
              if (userRef.data()['role'] == "admin" || "admin-manager") {
                this.router.navigate(["/dashboard"]);
                // this.ngZone.run(() => this.router.navigate(["/dashboard"]));
              } else if (userRef.data()['role'] == "consultant") {
                this.router.navigate(["/consultant/dashboard"]);
                //this.ngZone.run(() => this.router.navigate(["/consultant/dashboard"]));
              } else if (userRef.data()['role'] == "dieter") {
                this.router.navigate(["/register-as-consultant"]);
                //this.ngZone.run(() => this.router.navigate(["/register-as-consultant"]));
              }
              else {
                //no role
                this.router.navigate(["/register-as-consultant"]);
                // this.ngZone.run(() => this.router.navigate(["/register-as-consultant"])); 
              }
            }
          })
        });

      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserDataGoogle(user, result, isNewUser) {
    let role = "";
    this.afs.collection("users").ref.where("email", "==", user.email).onSnapshot(snap => {
      snap.forEach(userRef => {
        role = userRef.data()['role'];
      })
    });
    if (isNewUser) {
      role = "dieter";
    }
    let currenttimestamp = + new Date();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.uid}`);
    const userData: any = {
      uid: result.uid,
      email: user.email,
      displayName: user.name,
      photoURL: user.picture ? user.picture : '',
      emailVerified: user.verified_email ? user.verified_email : true,
    }

    localStorage.setItem('userrole', JSON.stringify(userData));
    this.userRole = userData;
    return userRef.set(userData, {
      merge: true
    })
  }

  approveConsultantRequest(uid) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    const userData = {
      uid: uid,
      role: 'consultant'
    }
    userRef.set(userData, {
      merge: true
    })
    const userRef2: AngularFirestoreDocument<any> = this.afs.doc(`consultant_requests/${uid}`);
    const userData2 = {
      uid: uid,
      status: 'Approved'
    }
    return userRef2.set(userData2, {
      merge: true
    })
  }

  updateConsulantProfile(namme, eemail, password, photoURLL) {
    let userx = JSON.parse(localStorage.getItem('userrole'));
    let uid = userx.uid;

    if (password != "") {
      //   this.afAuth.currentUser.updatePassword(password).then(function() {
      // }).catch(function(error) {
      //   //alert(error)
      // });

      this.afAuth.currentUser.then(u => u.updatePassword(password))
        .then(() => {
          console.log('password updated successfully')
        })
    }
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    const userData = {
      uid: uid,
      email: eemail,
      displayName: namme,
      photoURL: photoURLL ? photoURLL : '',
      can_login: 1
    }
    return userRef.set(userData, {
      merge: true
    })
  }


  updateAdminProfile(namme, eemail, password, photoURLL) {
    let userx = JSON.parse(localStorage.getItem('userrole'));
    let uid = userx.uid;

    if (password != "") {
      //   this.afAuth.currentUser.updatePassword(password).then(function() {
      // }).catch(function(error) {
      //   //alert(error)
      // });

      this.afAuth.currentUser.then(u => u.updatePassword(password))
        .then(() => {
          console.log('password updated successfully')
        })
    }
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`admins/${uid}`);
    const userData = {
      uid: uid,
      email: eemail,
      displayName: namme,
      photoURL: photoURLL ? photoURLL : '',
      can_login: '1'
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  registerConsultantRequest(user) {
    //let crequest: AngularFirestoreDocument<any>;
    //crequest = this.afs.doc('consultant_requests');
    let todaydate = new Date().toString();
    const reqRef: AngularFirestoreDocument<any> = this.afs.doc(`consultant_requests/${user.uid}`);
    return reqRef.set({ uid: user.uid, name: user.name, email: user.email, createdat: todaydate, status: 'Pending' }, { merge: true });
    //return this.afs.collection('consultant_requests').snapshotChanges();
  }

  SetUserData(user) {
    if (user.role == "") {
      user.role = "dieter";
    }
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL ? user.photoURL : '',
      emailVerified: user.emailVerified,
      role: user.role,
      can_login: '1',
      store_uid: user.store_uid
    }

    localStorage.setItem('userrole', JSON.stringify(userData));
    this.userRole = userData;
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('ghost_mode');
      localStorage.removeItem('group_default_store');
      localStorage.removeItem('userrole');
      localStorage.clear()
      localStorage.removeItem('current_store_id');
      this.router.navigate(['sign-in']);
    })
  }



  userChanges() {
    this.afAuth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.afs.collection("users").ref.where("email", "==", currentUser.email).onSnapshot(snap => {
          snap.forEach(userRef => {
            this.currentUser = userRef.data();
            //setUserStatus
            this.SetUserData(this.currentUser);

            if (userRef.data()['role'] == "admin" || "admin-manager") {
              this.ngZone.run(() => this.router.navigate(["/dashboard"]));
            } else if (userRef.data()['role'] == "consultant") {
              this.ngZone.run(() => this.router.navigate(["/consultant/dashboard"]));
            } else {
              this.ngZone.run(() => this.router.navigate(["/"]));
            }
          })
        })
      } else {
        //this is the error you where looking at the video that I wasn't able to fix
        //the function is running on refresh so its checking if the user is logged in or not
        //hence the redirect to the login
        this.ngZone.run(() => this.router.navigate(["/login"]));
      }
    })
  }

}