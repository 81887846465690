import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saved-items',
  templateUrl: './saved-items.component.html',
  styleUrls: ['./saved-items.component.scss']
})
export class SavedItemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
