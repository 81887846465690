import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import {finalize,catchError,tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-recipe-api-test-detail',
	templateUrl: './recipe-api-test-detail.component.html',
	styleUrls: ['./recipe-api-test-detail.component.scss']
})
export class RecipeApiTestDetailComponent implements OnInit {

id:any; 
recipe_data:any;

	constructor( @Inject(PLATFORM_ID) 
	private platformId  : object,
	private route       : ActivatedRoute,
	private router      : Router,
	private formBuilder : FormBuilder,
	public  authService : AuthService,
	public  afs         : AngularFirestore,
	public  afAuth      : AngularFireAuth,
	private afStorage   : AngularFireStorage,
	private modalService: NgbModal,
	private storage     : AngularFireStorage,
	private _snackBar   : MatSnackBar,
	private http 		: HttpClient, 
	) {  }

	ngOnInit(): void {
		this.id = this.route.snapshot.params['id'];

		 const headerDict = {
		'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
		'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
		'Access-Control-Allow-Headers': 'Content-Type',
	  }
 
	  const requestOptions = {                                                                                                                                                                                 
		headers: new HttpHeaders(headerDict), 
	  }; 
	  this.http.get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/'+this.id+'/information?includeNutrition=false',
		   requestOptions).subscribe(res => {  this.recipe_data = res; 
		});
 

 
	} 

}
