import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-store-edit',
  templateUrl: './store-edit.component.html',
  styleUrls: ['./store-edit.component.scss']
})
export class StoreEditComponent implements OnInit {


storeaddForm: FormGroup;
closeResult:any;
modalRef:any;
create_warehouse_form:FormGroup;
warehouselist:any = [];
required_label_warehouse:boolean = false;
selected_warehouse:any;
id:any;
main_store_data:any;
user:any;
users:any;
oldSelectedUsers: any;
arrAccessLayout = [
	{ name: 'Custom Grid Layout Only'},
	{ name: 'Upload Circular Images / URL Only'},
	{ name: 'Both Layouts'},
];
arrAccessRecipe = [
	{ name: 'Manual Recipe Builder'},
	{ name: 'National Recipes'},
];
arrAccessCoupon = [
	{ name: 'Manual & National Coupons'},
	{ name: 'Manual Coupons Only'},
	{ name: 'National Coupons Only'},
];

@ViewChild('warehouseAddModal') warehouseAddModal;
	users_list: { uid: string; displayName: any; role: any; email: any; store: any; }[];
	selectedUserData: any;
	selectedUsers: any = [];

  constructor( @Inject(PLATFORM_ID) private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  private formBuilder: FormBuilder,public authService: AuthService,
  public afs: AngularFirestore,
  public afAuth: AngularFireAuth,
  private modalService: NgbModal,
  private storage: AngularFireStorage,private _snackBar: MatSnackBar) {

  this.storeaddForm = new FormGroup({
	  company_name: new FormControl(null, Validators.compose([Validators.required])),
	//   store_owner_name: new FormControl(null, Validators.compose([Validators.required])),
	//   email       : new FormControl(null),
	  phone       : new FormControl(null),
	  userlist      : new FormControl([{displayName:"TEST", uid:"wddZ4qaMJGg9g74BrukjMQIeqnh1"}], Validators.required),
	  warehouse   : new FormControl(0),
	  store_id    : new FormControl( null),
	  branch_name : new FormControl(null),
	  zip_code    : new FormControl(null),
	  address     : new FormControl(null),
	  menu1_name  : new FormControl(null),
	  menu1_link  : new FormControl(null),
	  menu2_name  : new FormControl(null),
	  menu2_link  : new FormControl(null),
	  menu3_name  : new FormControl(null),
	  menu3_link  : new FormControl(null),
	  menu4_name  : new FormControl(null),
	  menu4_link  : new FormControl(null),
	  menu5_name  : new FormControl(null),
	  menu5_link  : new FormControl(null),
	  menu6_name  : new FormControl(null),
	  menu6_link  : new FormControl(null),
	  menu7_name  : new FormControl(null),
	  menu7_link  : new FormControl(null),
	  menu8_name  : new FormControl(null),
	  menu8_link  : new FormControl(null),
	  menu9_name  : new FormControl(null),
	  menu9_link  : new FormControl(null),
	  access_layout: new FormControl(),
	  access_recipe: new FormControl(),
	  access_coupon: new FormControl(),
	  displayRecipePage  : new FormControl(''),
	  displayCouponPage  : new FormControl(''),
	  displayBundlePage  : new FormControl(''),
	  displayMenuPage  : new FormControl(''),
	  displayCircularPage  : new FormControl(''),
	});

	this.create_warehouse_form = new FormGroup({
	  warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
	  });


  }

  ngOnInit(): void {
	this.id = this.route.snapshot.params['id'];

	this.afs.collection('/users',ref=>ref.where('role','in', ['user', 'admin']).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.users_list = data.map(e => {
			return{
				uid: e.payload.doc.id,
				displayName: e.payload.doc.data()['displayName'],
				role: e.payload.doc.data()['role'],
				email : e.payload.doc.data()['email'],
				store : e.payload.doc.data()['store']
			};
		});

		this.selectedUserData = this.users_list
      .filter((d) => d.store.some((c) => c.uid == this.id))
      .map((e) => e.uid);
	  this.oldSelectedUsers = [...this.selectedUserData];
		  this.storeaddForm.patchValue({
			userlist: this.selectedUserData,
		  });
	});
	this.user = JSON.parse(localStorage.getItem('userrole'));

	//this.user = JSON.parse(localStorage.getItem('userrole'));
	this.afs
    .collection("/users", (ref) => ref.where("uid", "==", this.user.uid))
    .snapshotChanges()
    .subscribe((data) => {
      this.users = data.map((e) => {
        return {
          uid: e.payload.doc.id,
          photoURL: e.payload.doc.data()["photoURL"]
            ? e.payload.doc.data()["photoURL"]
            : "./assets/imgs/user-placeholder.png",
          displayName: e.payload.doc.data()["displayName"],
          role: e.payload.doc.data()["role"],
          store: e.payload.doc.data()["store"],
        };
      });
    });



	this.afs.collection('/warehouses',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name']?e.payload.doc.data()['warehouse_name']:"",
			};
		}); 
	});

	this.afs.collection('/stores',ref=>ref.where('uid','==',this.id)).snapshotChanges().subscribe(data => {
	  this.main_store_data = data.map(e => {
		return{
		  	uid               	: e.payload.doc.id,
			// email 				: e.payload.doc.data()['email'],
			company_name 		: e.payload.doc.data()['company_name'],
			// store_owner_name 	: e.payload.doc.data()['store_owner_name'],
			phone 				: e.payload.doc.data()['phone'],
			warehouse 			: e.payload.doc.data()['warehouse'] ? e.payload.doc.data()['warehouse'] : null ,
			store_id 			: e.payload.doc.data()['store_id'],
			branch_name 		: e.payload.doc.data()['branch_name'],
			zip_code 			: e.payload.doc.data()['zip_code'],
			address 			: e.payload.doc.data()['address'],
			menu1_name 			: e.payload.doc.data()['menu1_name'],
			menu1_link 			: e.payload.doc.data()['menu1_link'],
			menu2_name 			: e.payload.doc.data()['menu2_name'],
			menu2_link 			: e.payload.doc.data()['menu2_link'],
			menu3_name 			: e.payload.doc.data()['menu3_name'],
			menu3_link 			: e.payload.doc.data()['menu3_link'],
			menu4_name 			: e.payload.doc.data()['menu4_name'],
			menu4_link 			: e.payload.doc.data()['menu4_link'],
			menu5_name 			: e.payload.doc.data()['menu5_name'],
			menu5_link 			: e.payload.doc.data()['menu5_link'],
			menu6_name 			: e.payload.doc.data()['menu6_name'],
			menu6_link 			: e.payload.doc.data()['menu6_link'],
			menu7_name 			: e.payload.doc.data()['menu7_name'],
			menu7_link 			: e.payload.doc.data()['menu7_link'],
			menu8_name 			: e.payload.doc.data()['menu8_name'],
			menu8_link 			: e.payload.doc.data()['menu8_link'],
			menu9_name 			: e.payload.doc.data()['menu9_name'],
			menu9_link  		: e.payload.doc.data()['menu9_link'],
			type        		: e.payload.doc.data()['type'],
			branch_count 		: e.payload.doc.data()['branch_count'],
			can_login 			: e.payload.doc.data()['can_login'],
			access_layout		: e.payload.doc.data()['access_layout'] ? e.payload.doc.data()['access_layout'] : null,
			access_recipe 		: e.payload.doc.data()['access_recipe'] ? e.payload.doc.data()['access_recipe'] : null,
			access_coupon 		: e.payload.doc.data()['access_coupon'] ? e.payload.doc.data()['access_coupon'] : null,
			user_id   			: e.payload.doc.data()['user_id'],
			displayRecipePage  : e.payload.doc.data()['displayRecipePage'] ? e.payload.doc.data()['displayRecipePage'] : false,
			displayCouponPage  : e.payload.doc.data()['displayCouponPage'] ? e.payload.doc.data()['displayCouponPage'] : false,
			displayCircularPage : e.payload.doc.data()['displayCircularPage'] ? e.payload.doc.data()['displayCircularPage'] : false,
			displayBundlePage  : e.payload.doc.data()['displayBundlePage'] ? e.payload.doc.data()['displayBundlePage'] : false,
			displayMenuPage  : e.payload.doc.data()['displayMenuPage'] ? e.payload.doc.data()['displayMenuPage'] : false,
		};
	  }); 

	//   if(this.main_store_data){
	// 	 this.selectedUsers =  this.users_list.map((element) => {
	// 		return {...element, subElements: element.store.filter((s) => s.uid === this.main_store_data[0].uid)}
	// 	  })
	// 	  this.storeaddForm.patchValue({
	// 		userlist: this.selectedUsers,
	// 	  });
	//   }
	  
	  	// this.afs
        // .collection("/users", (ref) =>
        //   ref.where("store", "array-contains", {
        //     company_name: this.main_store_data[0].company_name,
        //     uid: this.main_store_data[0].uid,
        //   })
        // )
        // .snapshotChanges()
        // .subscribe((data) => {
        //   // data.map(e => {
        //   // 	e.payload.doc.data()['store'].map(obj => {
        //   // 		return this.selectedUsers.push(obj.uid);
        //   // 	})
        //   // });
        //   this.selectedUsers = data.map((e) => {
        //     return {
        //       uid: e.payload.doc.id,
        //       displayName: e.payload.doc.data()["displayName"],
        //       email: e.payload.doc.data()["email"],
        //       store: e.payload.doc.data()["store"]?.map((x) => {
        //         return x.uid;
        //       }),
        //       role: e.payload.doc.data()["role"],
        //     };
        //   });
        // });
    //   this.storeaddForm.patchValue({
    //     userlist: this.selectedUsers[0]?.uid,
    //   });

		this.storeaddForm.patchValue({
			company_name 		: this.main_store_data[0]?.company_name,
			// store_owner_name 	: this.main_store_data[0]?.store_owner_name,
			// email 				: this.main_store_data[0]?.email,
			phone 				: this.main_store_data[0]?.phone,
			warehouse 			: this.main_store_data[0]?.warehouse,
			store_id 			: this.main_store_data[0]?.store_id,
			branch_name 		: this.main_store_data[0]?.branch_name,
			zip_code 			: this.main_store_data[0]?.zip_code,
			address 			: this.main_store_data[0]?.address,
			menu1_name 			: this.main_store_data[0]?.menu1_name,
			menu1_link 			: this.main_store_data[0]?.menu1_link,
			menu2_name 			: this.main_store_data[0]?.menu2_name,
			menu2_link 			: this.main_store_data[0]?.menu2_link,
			menu3_name			: this.main_store_data[0]?.menu3_name,
			menu3_link 			: this.main_store_data[0]?.menu3_link,
			menu4_name 			: this.main_store_data[0]?.menu4_name,
			menu4_link 			: this.main_store_data[0]?.menu4_link,
			menu5_name 			: this.main_store_data[0]?.menu5_name,
			menu5_link 			: this.main_store_data[0]?.menu5_link,
			menu6_name 			: this.main_store_data[0]?.menu6_name,
			menu6_link 			: this.main_store_data[0]?.menu6_link,
			menu7_name 			: this.main_store_data[0]?.menu7_name,
			menu7_link 			: this.main_store_data[0]?.menu7_link,
			menu8_name 			: this.main_store_data[0]?.menu8_name,
			menu8_link			: this.main_store_data[0]?.menu8_link,
			menu9_name 			: this.main_store_data[0]?.menu9_name,
			menu9_link 			: this.main_store_data[0]?.menu9_link,
			access_layout 		: this.main_store_data[0]?.access_layout,
			access_recipe 		: this.main_store_data[0]?.access_recipe,
			access_coupon 		: this.main_store_data[0]?.access_coupon,
			displayCouponPage 		: this.main_store_data[0]?.displayCouponPage,
			displayRecipePage 		: this.main_store_data[0]?.displayRecipePage,
			displayBundlePage 		: this.main_store_data[0]?.displayBundlePage,
			displayMenuPage 		: this.main_store_data[0]?.displayMenuPage,
			displayCircularPage 		: this.main_store_data[0]?.displayCircularPage,
		});
	});
  }
  select_branch(event){
    this.selectedUserData = event.map((x) => {
        return x.uid
      })
  }
  goToLogin() {
  window.location.href = '/login';
  }
  ChangePassword() {
  $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
  $('#exampleModal').css('opacity', '1');
  }
  backToStore(){
  this.router.navigate(['/admin/stores']);
  }

  async store_add(data) {
	console.log(data,"data")
	const storesToDelete = this.oldSelectedUsers.filter(item => !this.selectedUserData.includes(item));
	const storesToAdd = this.selectedUserData.filter(item => !this.oldSelectedUsers.includes(item));

	if (storesToDelete.length > 0) {
    let temp: any = await this.afs
      .collection("/users", (ref) => ref.where("uid", "in", storesToDelete)).get().toPromise();
		const batch = this.afs.firestore.batch();
		temp.docs.forEach(async (doc) => {
		let userData = (await doc.ref.get()).data();
		const q1 = userData["store"].filter((object) => {
			return object.uid !== this.id;
		});
		await doc.ref.update({ store: q1 });
		});
  }
	let storedata = {
		access_coupon: data.access_coupon?data.access_coupon:"",
		access_layout: data.access_layout?data.access_layout:"",
	access_recipe: data.access_recipe?data.access_recipe:"",
	// email: data.email?data.email:"",
	company_name:data.company_name?data.company_name:"",
	// store_owner_name: data.store_owner_name?data.store_owner_name:"",
	phone: data.phone? data.phone:"",
	warehouse: data.warehouse?data.warehouse:"",
	store_id: data.store_id?data.store_id:"",
	branch_name: data.branch_name?data.branch_name:"",
	zip_code: data.zip_code?data.zip_code:"",
	address: data.address?data.address:"",
	menu1_name: data.menu1_name?data.menu1_name:"",
	menu1_link: data.menu1_link?data.menu1_link:"",
	menu2_name: data.menu2_name?data.menu2_name:"",
	menu2_link: data.menu2_link?data.menu2_link:"",
	menu3_name: data.menu3_name?data.menu3_name:"",
	menu3_link: data.menu3_link?data.menu3_link:"",
	menu4_name: data.menu4_name?data.menu4_name:"",
	menu4_link: data.menu4_link?data.menu4_link:"",
	menu5_name: data.menu5_name?data.menu5_name:"",
	menu5_link: data.menu5_link?data.menu5_link:"",
	menu6_name: data.menu6_name?data.menu6_name:"",
	menu6_link: data.menu6_link?data.menu6_link:"",
	menu7_name: data.menu7_name?data.menu7_name:"",
	menu7_link: data.menu7_link?data.menu7_link:"",
	menu8_name: data.menu8_name?data.menu8_name:"",
	menu8_link: data.menu8_link?data.menu8_link:"",
	menu9_name: data.menu9_name?data.menu9_name:"",
	menu9_link: data.menu9_link?data.menu9_link:"",
	displayCouponPage : data.displayCouponPage ? data.displayCouponPage : false,
	displayRecipePage : data.displayRecipePage ? data.displayRecipePage : false,
	displayBundlePage : data.displayBundlePage ? data.displayBundlePage : false,
	displayMenuPage : data.displayMenuPage ? data.displayMenuPage : false,
	displayCircularPage : data.displayCircularPage ? data.displayCircularPage : false,
  };
  console.log(storedata,"storeData")

//   let storeadd = await this.afs.collection("/stores").add(storedata).then((docRef) => {
//       const itemRefx = this.afs.doc("/stores/" + docRef.id);
//       itemRefx.update({ uid: docRef.id, parent_id: docRef.id });

	if(storesToAdd.length > 0){
		storesToAdd.map(async (data, index) => {
			let userRef;
		if (typeof data == "string") {
			userRef = this.afs.doc("/users/" + data).ref;
		}
		if (typeof data == "object") {
		userRef = this.afs.doc("/users/" + data.user_uid).ref;
		}
		const userD: any = (await userRef.get()).data();
		// const users = (await this.afs.collection('/users', ref => ref.where('uid', '==', data.user_uid)).get().toPromise()).docs[0].data()
		const newArr = [
		...userD["store"],
		{ uid: this.id, company_name: storedata["company_name"] },
		];
		userD.store = newArr;
		
		userRef.update({ store: newArr });
		// .snapshotChanges().subscribe(data => {
			// 	data.map(e => {
		// 		const arr = e.payload.doc.data();
		// 		const newArr = [...arr['store'], { uid: this.id, company_name: storedata["company_name"] }, ]
		// 		this.afs.collection("/users").doc(arr['uid']).update({store: newArr});
		// 	});
		// });

		// if (data.user_uid) {
		// 	console.log('iffffffffffff');
		// 	data = this.users_list.filter(e => e.uid == data.user_uid)
		//   	const newStores = [...data[0].store, { uid: this.id, company_name: storedata["company_name"] }, ];
		// 	const temp = this.afs.collection('users').doc(data.user_uid).ref
		// 	temp.update({'store':newStores})
		// } else {
			// 	console.log('elseeeeeeeeeee');
			// }
		});
	}
//     });
  	if(data.email != this.main_store_data[0]?.email)
  	{

		  this.afAuth.createUserWithEmailAndPassword(data.email, "123456")
			.then((result) => {
			  let userdata = {
				email: data.email?data.email:"",
				// displayName: data.store_owner_name?data.store_owner_name:"",
				uid: result.user.uid,
				role: "group",
				created_at: new Date(),
				can_login: '1',
				store_uid: this.id,
			  };
			  let useradd = this.afs.doc('/users/'+ result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  });
			  storedata['user_id'] = result.user.uid;
			  let storeadd = this.afs.doc('/stores/'+ this.id).set(storedata,{merge:true});
				// const itemRefx= this.afs.doc('/stores/'+ this.id);
				// itemRefx.update({ user_id: result.user.uid });



				//remove the store id from the old user and delete the old user
				const itemRefx3 = this.afs.doc('/users/'+ this.main_store_data[0]?.user_id).set({store_uid: "user-deleted-"+this.id + "--"+ new Date().getTime() },{merge:true});
			  this.ForgotPassword(data.email);
			  this.router.navigate(['/admin/stores']);
			  this._snackBar.open(" Store updated successfully", '', {
					duration: 1000,
				});
		  }, (err) => {
			console.log(err,"Error");
			this._snackBar.open(err.message, '', {
			  duration: 2000,
			});
		  });
	} else {
		let store_update = this.afs.doc('/stores/'+ this.id).set(storedata,{merge:true});
		 this.router.navigate(['/admin/stores']);
		 this._snackBar.open(" Store updated successfully", '', {
					duration: 1000,
				});
	}
		this.updateLayout(data.access_layout)
  }

  async updateLayout(type){
	let layout_type 
			if(type == "Both Layouts" || type == "Custom Grid Layout Only"){
				layout_type = 'grid'
			}else{
				layout_type = 'page'
			}	
	  this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.id)).snapshotChanges().pipe(take(1)) .subscribe((data: any) => {
		let temp = data.map(e => {
			return {
				id: e.payload.doc.data().uid,
			};
		});
		temp.map(async (t) => {
				await this.afs.doc('/layouts/'+ t.id).set({layout_type:layout_type},{merge:true});

		})
	});
	this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.id)).snapshotChanges().pipe(take(1)) .subscribe((data: any) => {
		let temp = data.map(e => {
			return {
				id: e.payload.doc.data().uid,
			};
		});
		temp.map(async (t) => {
				await this.afs.doc('/layouts-noad/'+ t.id).set({layout_type:layout_type},{merge:true});

		})
	});
	this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.id)).snapshotChanges().pipe(take(1)) .subscribe((data: any) => {
		let temp = data.map(e => {
			return {
				id: e.payload.doc.data().uid,
			};
		});
		temp.map(async (t) => {
				await this.afs.doc('/bundles/'+ t.id).set({layout_type:layout_type},{merge:true});

		})
	});
	this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.id)).snapshotChanges().pipe(take(1)) .subscribe((data: any) => {
		let temp = data.map(e => {
			return {
				id: e.payload.doc.data().uid,
			};
		});
		temp.map(async (t) => {
				await this.afs.doc('/menus/'+ t.id).set({layout_type:layout_type},{merge:true});

		})
	});
  }

  addWarehouse(){
	this.open(this.warehouseAddModal,'modal-basic-title2');
  }
  warehouseChange() {
	let selected_value = this.selected_warehouse;
	if(selected_value == "add"){
	  this.open(this.warehouseAddModal,'modal-basic-title2');
	}
  }

  submit_warehouse(data){
  let thedata={
	warehouse_name: data.warehouse_name,
	created_at: new Date(),
  };

	let radio_add = this.afs.collection('/warehouses').add(thedata).then(docRef => {
	const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
	itemRefx.update({ uid: docRef.id}); 
  });

  this.create_warehouse_form.reset();
  let element: HTMLElement = document.getElementsByClassName('close_add_warehouse_modal')[0] as HTMLElement;
  element.click();

  this._snackBar.open("Warehouse created successfully", '', {
	duration: 1000,
  });

  }

	open(content,title) {
	this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
	this.closeResult = `Closed with: ${result}`;
	}, (reason) => {
	});
  }

  ForgotPassword(passwordResetEmail) {
	return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
	.then(() => {
	}).catch((error) => {
	})
  }
  delete_warehouse(uid){
	  let del_ware = this.afs.doc('/warehouses/'+uid).delete();
	  this._snackBar.open('Warehouse deleted successfully', '', {
		duration: 1000,
	  });
  }
}
