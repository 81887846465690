<div class="content" style="padding:20px;">
		<section class="recipe-section">

	<div class="row">
		<div class="col-12">
			<h1>Recipe API (RAPID-API)</h1>
		</div>
		<div class="col-6" style="margin-bottom:1rem;">
			<div class="card" style="padding:1rem;">
				<h4 style="padding:10px;">Search Recipe by Ingredient Name</h4>
				<hr style="margin:0;">
				<div class="" style="padding:10px;">
					<label>Enter Ingredient name</label>
					<input name="recipe_name" class="form-control form-group"  (blur)="recipe_search($event.target.value)" placeholder="Example: apple,chocolate,cream">
					<button class="btn btn-success">Search</button>
				</div>
			</div>
		</div>
		<div class="col-6" style="margin-bottom:1rem;">
			<div class="card" style="padding:1rem;">
				<h4 style="padding:10px;">Search Recipe by Recipe Name</h4>
				<hr style="margin:0;">
				<div class="" style="padding:10px;">
					<label>Enter Recipe name</label>
					<input name="recipe_name" class="form-control form-group"  (blur)="recipe_search($event.target.value)" placeholder="Example: Apple Pie">
					<button class="btn btn-success">Search</button>
				</div>
			</div>
		</div>


		<div class="col-6" style="margin-bottom:1rem;">
			<div class="card" style="padding:1rem;">
				<h4 style="padding:10px;">Get Image of Product by UPC Code(CXH API)</h4>
				<hr style="margin:0;">
				<div class="" style="padding:10px;">
					<label>Enter UPC code</label>
					<input name="recipe_name" class="form-control form-group"  (blur)="upc_search($event.target.value)" placeholder="Example: 00049000050103">
					<button class="btn btn-success">Search</button>
					<p *ngIf="product_image_data == 'Not get'" >Please search with valid UPC code</p>
					<img src="https://assets.edgenet.com/{{product_image_data[0].Value}}?fileType=png&size=185&thumbnail=true" *ngIf="product_image_data != 'Not get'&& product_image_data?.length > 0" style="width: 130px;border:1px solid #000;">
				</div>
			</div>
		</div>

		<div class="" *ngIf="recipe_list && recipe_list.length == 0 && loading == false" style="margin-left:15px;">
			<p>No recipes found!</p>
		</div>

		<div class="" *ngIf="loading" style="margin-left:15px;">
			<p>Finding Recipes...</p>
		</div>

 
		<div class="" style="margin-bottom:1rem;padding-bottom:1rem;min-height: 20px;"></div>
			<div class="col-3" *ngFor = "let data of recipe_list" >
				<div  class="card" style="padding:1rem;border-radius: 13px;margin-bottom:1rem;" (click)="gotoRecipeDetail(data.id)">
					<img class="card-image" src="{{data.image}}" style="border-radius: 13px;border: 1px solid #c7c7c7;min-height:151px;min-width:201px;background:#e7e7e7;">
					<p class="card-body" style="padding:0;margin:1px;margin-top:8px;height:50px;">{{data.title}}</p>
				</div>
			</div>
		</div>
</section>
</div>